import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  HostListener,
} from '@angular/core';

import { SliceWithData } from '../../../../typings';
import { HeroCarouselSlice, HeroItems } from '@repo/shared';
import { WINDOW } from '../../../services/window.provider';
import { newSwipe, Swipe } from '../../../vendors/swipe';
import { TagCommanderService } from '../../../services/tag-commander.service';

const SLIDE_TRANSITION_DURATION = 400;
const SLIDE_TRANSITION_TIMEOUT = 4000;
const SLIDE_TRANSITION_ONHOVER_TIMEOUT = 7000;

@Component({
  selector: 'slice-hero-carousel',
  template: `
    <ng-container *ngIf="data">
      <ng-container *ngIf="data.items.length === 1">
        <slice-super-hero [data]="data.items[0].hero"></slice-super-hero>
      </ng-container>
      <ng-container *ngIf="data.items.length > 1">
        <div
          class="swiper-wrapper"
          inViewport
          [inViewportOptions]="{ threshold: 1, partial: false }"
          (inViewportAction)="onIntersection($event)"
        >
          <div #swiper class="swipe">
            <div class="swipe-wrap">
              <div
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseOut()"
                class="hero"
                *ngFor="let hero of data.items"
              >
                <slice-super-hero [data]="hero.hero" [trackTagCommander]="false"></slice-super-hero>
              </div>
            </div>
            <div class="indicators">
              <span
                class="dot"
                *ngFor="let hero of data.items; let i = index"
                [class.active]="i === currentIndex"
                (click)="goToSlide(i)"
              >
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./slice-hero-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceHeroCarouselComponent implements SliceWithData, AfterViewInit, OnDestroy {
  @Input() data: HeroCarouselSlice;
  @ViewChild('swiper') swiperRef: ElementRef<HTMLDivElement>;

  currentIndex = 0;

  onmouse_enter_timeout_id: undefined | number;

  get heroes(): HeroItems[] {
    return this.data.items;
  }

  swiperElement: HTMLDivElement;
  swiperIsScrolled = false;
  marginOfTopElement = 250;

  get targetElement(): DOMRect | undefined {
    return this.win.document.querySelector('.indicators')?.getBoundingClientRect();
  }

  get clientHeight(): number {
    return this.win.innerHeight || document.documentElement.clientHeight;
  }

  swiper: Swipe;
  tagTriggeredList: number[] = [];

  @HostListener('window:scroll')
  handleScroll() {
    if (this.swiperRef) {
      this.controlCarouselAutoPlayAtScroll();
    }
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly tagCommanderService: TagCommanderService,
  ) {
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
  }

  ngAfterViewInit(): void {
    if (this.win && this.swiperRef) {
      this.swiperElement = this.swiperRef.nativeElement;
      this.swiper = newSwipe(this.swiperElement, {
        startSlide: 0,
        speed: SLIDE_TRANSITION_DURATION,
        auto: this.data.timer || SLIDE_TRANSITION_TIMEOUT,
        autoRestart: true,
        draggable: true,
        continuous: true,
        disableScroll: false,
        stopPropagation: true,
        transitionEnd: this.onTransitionEnd,
      });
      this.controlCarouselPositionAtInit();
    }
  }

  ngOnDestroy(): void {
    if (this.swiper) {
      this.swiper.kill();
    }
  }

  goToSlide(index: number): void {
    this.swiper.slide(index, SLIDE_TRANSITION_DURATION);
  }

  onIntersection({ visible }: { visible: boolean }): void {
    if (visible) {
      this.triggerTrackEvent();
    }
  }

  triggerTrackEvent(): void {
    const CURRENT_HERO_STRING = this.heroes[this.currentIndex].hero?.campaignCode as string;
    if (!this.tagTriggeredList.includes(this.currentIndex) && CURRENT_HERO_STRING) {
      this.tagCommanderService.trackEventPrint(CURRENT_HERO_STRING);
      this.tagTriggeredList.push(this.currentIndex);
    }
  }

  onTransitionEnd(index: number): void {
    this.currentIndex = index;
    this.changeDetector.detectChanges();
    this.triggerTrackEvent();
  }

  checkIfScrollIsOutOfTarget(): boolean {
    if (this.targetElement) {
      return this.targetElement.top - this.marginOfTopElement >= 0 && this.targetElement.bottom <= this.clientHeight;
    }
    return false;
  }

  controlCarouselPositionAtInit() {
    this.checkIfScrollIsOutOfTarget() ? this.swiper.restart() : this.swiper.stop();
  }

  controlCarouselAutoPlayAtScroll(): void {
    const IS_VISIBLE = this.checkIfScrollIsOutOfTarget();
    if (IS_VISIBLE && !this.swiperIsScrolled) {
      this.swiper.restart();
      this.swiperIsScrolled = true;
    } else if (!IS_VISIBLE && this.swiperIsScrolled) {
      this.swiper.stop();
      this.swiperIsScrolled = false;
    }
  }

  cancelTimout(timeoutId) {
    clearTimeout(timeoutId);
  }

  onMouseEnter(): void {
    this.swiper.stop();
    this.onmouse_enter_timeout_id = window.setTimeout(() => {
      this.swiper.restart();
    }, SLIDE_TRANSITION_ONHOVER_TIMEOUT);
  }

  onMouseOut(): void {
    this.cancelTimout(this.onmouse_enter_timeout_id);
    this.swiper.restart();
  }
}

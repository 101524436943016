import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ArticleType } from '@repo/shared';

@Component({
  selector: 'cb-article-info-bar',
  template: `
    <div class="subtitle">
      <div *ngIf="publishOn">
        <span [class]="iconByType"></span>
        <time [dateTime]="publishOn" class="publish-on">{{ prettyPublishOn }}</time>
      </div>
      <div *ngIf="readingTime">
        <span class="dot"></span><span class="ic-eye"></span><span class="reading-time">{{ readingTime }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./article-info-bar.component.scss'],
})
export class ArticleInfoBarComponent implements OnInit {
  @Input() publishOn: Date;
  @Input() readingTime: string;
  @Input() type: ArticleType;

  iconByType: string;

  ngOnInit(): void {
    this.iconByType = `ic-${this.type}`;
  }

  get prettyPublishOn(): string {
    return moment(this.publishOn).format('DD/MM/YYYY');
  }
}

import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { LandingPageEventOfferComponent } from './landing-page-event-offer/landing-page-event-offer.component';
import { SliceLandingPageEventOfferListComponent } from './slice-landing-page-event-offer-list.component';

@NgModule({
  imports: [CommonModule, RouterModule, CommonBehaviorsModule, MatTabsModule],
  declarations: [SliceLandingPageEventOfferListComponent, LandingPageEventOfferComponent],
  exports: [SliceLandingPageEventOfferListComponent],
  entryComponents: [SliceLandingPageEventOfferListComponent],
})
export class SliceLandingPageEventOfferListModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiPushBlockComponent } from './push-block.component';
import { UiPushBlockGroupComponent } from './push-block-group.component';
import { UiPushBlockHeaderComponent } from './push-block-header.component';
import { UiPushBlockItemComponent } from './push-block-item.component';
import { UiPushBlockLinksDirective } from './push-block-links.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    UiPushBlockComponent,
    UiPushBlockGroupComponent,
    UiPushBlockHeaderComponent,
    UiPushBlockItemComponent,
    UiPushBlockLinksDirective,
  ],
  exports: [
    UiPushBlockComponent,
    UiPushBlockGroupComponent,
    UiPushBlockHeaderComponent,
    UiPushBlockItemComponent,
    UiPushBlockLinksDirective,
  ],
})
export class UiPushBlockModule {}

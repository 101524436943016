import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlicesNewsBlocService {
  private totalItemsSource$ = new Subject<number>();

  constructor() {}
  /**
   * value of articles to send
   * @param value
   */
  updateValueItem(value: number): void {
    this.totalItemsSource$.next(value);
  }

  public geTotalItemsSource$(): Subject<number> {
    return this.totalItemsSource$;
  }
}

import { Component, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { HeaderButtonsLinks } from '../header';
import { Link, Logos, MenuItem } from '@repo/shared';
import { ResponsiveService } from '../../../services/responsive.service';
import { StateService } from '../../../services/state.service';
import { STICKY_HEADER_HEIGHT_DESKTOP } from '../../../common/constants/sizes';
import { WINDOW } from '../../../services/window.provider';
import { AnalyticsSubPaths } from '../../../services/tag-commander.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

const LCL_LOGO_SIGNATURE = '/assets/images/lcl_logo_bleu_signature.svg';
const LCL_LOGO_GRIFFE = '/assets/images/lcl_logo_bleu_griffe.svg';
const IC_CHAT = '/assets/images/ic_chat.svg';
const IC_LOCK = '/assets/images/ic_lock.svg';

@Component({
  selector: 'app-desktop-header',
  template: `
    <div #header class="header regular" role="banner">
      <h1 *ngIf="showH1Title; else untitledLogo">
        <a
          class="logo"
          [attr.href]="buttons.home && buttons.home.url"
          [attr.target]="buttons.home && buttons.home.target"
        >
          <img
            *ngIf="logo && logo.dimensions; else defaultLogo"
            [attr.src]="logo.url"
            [attr.alt]="logo.alt"
            [attr.height]="logo.dimensions.height"
            [attr.width]="logo.dimensions.width"
          />
          <ng-template #defaultLogo>
            <img [attr.src]="lclLogoSignature" alt="LCL" height="115" width="155" />
          </ng-template>
        </a>
      </h1>
      <ng-template #untitledLogo>
        <a
          class="logo"
          [attr.href]="buttons.home && buttons.home.url"
          [attr.target]="buttons.home && buttons.home.target"
        >
          <img
            *ngIf="logo && logo.dimensions; else defaultLogo"
            [attr.src]="logo.url"
            [attr.alt]="logo.alt"
            [attr.height]="logo.dimensions.height"
            [attr.width]="logo.dimensions.width"
          />
          <ng-template #defaultLogo>
            <img [attr.src]="lclLogoSignature" alt="LCL" height="115" width="155" />
          </ng-template>
        </a>
      </ng-template>

      <div class="lines">
        <div class="first-line">
          <div class="button link market" *ngIf="markets">
            <button>
              <span>{{ marketsMenuLabel }}</span>
              <i class="arrow"></i>
            </button>
            <ul class="market-list">
              <li *ngFor="let market of markets">
                <a
                  [attr.href]="market.url"
                  [attr.target]="market.target"
                  analyticsOn
                  [analyticsLabel]="market.label"
                  [analyticsSubPath]="analyticsSubPath"
                  >{{ market.label }}</a
                >
              </li>
            </ul>
          </div>
          <div class="actions-buttons">
            <app-desktop-header-action-button
              [style.width]="buttonWidth"
              [backgroundcolorButton]="colorBtnContact"
              [labelColorButton]="labelColorBtnContact"
              *ngIf="buttons.chat"
              [url]="buttons.chat.url"
              [secondary]="true"
              [imgSrc]="iconBtnContact"
              [imgWidth]="33"
              [imgHeight]="32"
              [label]="buttons.chat.label"
              [target]="buttons.chat.target"
            >
            </app-desktop-header-action-button>
            <app-desktop-header-action-button
              [style.width]="buttonWidth"
              [backgroundcolorButton]="colorBtnOpenAccount"
              [labelColorButton]="labelColorBtnOpenAccount"
              [imgSrc]="iconBtnOpenAccount"
              [imgWidth]="33"
              [imgHeight]="32"
              *ngIf="buttons.openAccount"
              [url]="buttons.openAccount.url"
              [target]="buttons.openAccount.target"
              [label]="buttons.openAccount.label"
            >
            </app-desktop-header-action-button>
            <app-desktop-header-action-button
              [style.width]="buttonWidth"
              [backgroundcolorButton]="colorBtnMyAccount"
              [labelColorButton]="labelColorBtnMyAccount"
              *ngIf="buttons.myAccount"
              [url]="buttons.myAccount.url"
              [imgSrc]="iconBtnMyAccount"
              [imgWidth]="15"
              [imgHeight]="20"
              [label]="buttons.myAccount.label"
            >
            </app-desktop-header-action-button>
          </div>
        </div>
        <app-desktop-header-second-line [universes]="universes" [communications]="communications">
        </app-desktop-header-second-line>
      </div>
    </div>

    <div class="header sticky" [class.visible]="stickyHeaderShown" aria-hidden="true">
      <a
        class="small-logo"
        [attr.href]="buttons.home && buttons.home.url"
        [attr.target]="buttons.home && buttons.home.target"
      >
        <img
          *ngIf="logo && logo.sticky && logo.sticky.dimensions; else defaultLogoSticky"
          [attr.src]="logo.sticky.url"
          [attr.alt]="logo.sticky.alt"
          [attr.height]="logo.sticky.dimensions.height"
          [attr.width]="logo.sticky.dimensions.width"
        />
        <ng-template #defaultLogoSticky>
          <img [attr.src]="lclLogoGriffe" alt="LCL" height="70" width="70" />
        </ng-template>
      </a>
      <app-desktop-header-second-line
        [universes]="universes"
        [communications]="communications"
        [buttons]="buttons"
        [inStickyHeader]="true"
        [megamenuShowable]="stickyHeaderShown"
      >
      </app-desktop-header-second-line>
    </div>
  `,
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent implements OnInit, OnDestroy {
  @Input() universes: MenuItem[];
  @Input() communications: MenuItem[];
  @Input() markets: Link[];
  @Input() marketsMenuLabel: string;
  @Input() buttons: HeaderButtonsLinks = {};
  @Input() logo: Logos | undefined;
  @Input() showH1Title: Boolean;

  analyticsSubPath = AnalyticsSubPaths.HeaderMenuMarket;
  lclLogoSignature: string = LCL_LOGO_SIGNATURE;
  lclLogoGriffe: string = LCL_LOGO_GRIFFE;
  icChat: string = IC_CHAT;
  icLock: string = IC_LOCK;
  stickyHeaderShown = false;

  get buttonWidth(): SafeStyle {
    const buttonLength = Object.values(this.buttons).length;
    return this.sanitizer.bypassSecurityTrustStyle(`calc(100% / ${buttonLength}`);
  }

  @ViewChild('header', { static: true })
  private header: ElementRef<HTMLElement>;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly stateService: StateService,
    private readonly responsiveService: ResponsiveService,
    @Inject(WINDOW) private readonly win: Window,
    private readonly ngZone: NgZone,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.responsiveService.subscribeOnDesktop(matches => {
        if (matches) {
          this.onScroll();
        }
      }),
    );

    if (this.win) {
      this.ngZone.runOutsideAngular(() => {
        this.subscription.add(
          fromEvent(this.win, 'scroll').subscribe(() => {
            this.onScroll();
          }),
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription = (null as unknown) as Subscription;
  }

  onScroll(): void {
    const stickyHeaderShown = this.header.nativeElement.getBoundingClientRect().bottom < 0;

    if (stickyHeaderShown !== this.stickyHeaderShown) {
      this.stickyHeaderShown = stickyHeaderShown;

      this.ngZone.run(() => {
        this.stateService.update({
          stickyHeaderBottomPosition: this.stickyHeaderShown ? STICKY_HEADER_HEIGHT_DESKTOP : 0,
        });
      });
    }
  }

  get colorBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.desktopBackgroundColor;
  }

  get colorBtnOpenAccount(): string | undefined {
    return this.buttons.openAccount?.buttonLinkData?.desktopBackgroundColor;
  }

  get colorBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.desktopBackgroundColor;
  }

  get labelColorBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.desktopLabelColor;
  }

  get labelColorBtnOpenAccount(): string | undefined {
    return this.buttons.openAccount?.buttonLinkData?.desktopLabelColor;
  }

  get labelColorBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.desktopLabelColor;
  }

  get iconBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.desktopIcon?.url;
  }

  get iconBtnOpenAccount(): string | undefined {
    return this.buttons.openAccount?.buttonLinkData?.desktopIcon?.url;
  }

  get iconBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.desktopIcon?.url;
  }
}

import { Component, Inject, Input } from '@angular/core';
import { FormGroup, FormArray, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { WINDOW } from '../../../services/window.provider';

const IC_CLOSE_CROSS = '/assets/images/ic_close_cross.svg';

@Component({
  selector: 'file-upload-form',
  template: `
    <div class="uploadFileContainer" (click)="fileUpload.click()" fileDragDrop (fileDropped)="selectFile($event)">
      <input
        class="upload-file"
        hidden
        type="file"
        [accept]="acceptFiles"
        #fileUpload
        (change)="selectFile($event.target.files)"
      />
      <p class="files-instructions">Ajoutez ou déposez un fichier</p>
      <p>Taille maximale {{ this.maxFileSize }}Mo - {{ this.maxFiles }} pièces jointes au maximum</p>
    </div>
    <div class="uploadButtonWrapper">
      <cb-cta-btn-link
        class="upload-button"
        (click)="fileUpload.click()"
        [button]="{ label: 'Ajouter un fichier', type: 'mobile-view-add-file' }"
        [buttonWithoutLink]="true"
      ></cb-cta-btn-link>
      <p class="maxSizeTip">Taille maximale {{ this.maxFileSize }}Mo - {{ this.maxFiles }} pièces jointes au maximum</p>
    </div>
    <div class="filesList" *ngFor="let file of files; let i = index">
      <span class="ic-pdf fileIcon"></span>
      <div id="fileName">
        <p>{{ truncateMiddle(file.name) }}</p>
      </div>
      <button class="deleteFile" (click)="deleteFile(i)">
        <img [src]="deleteFileIcon" />
      </button>
    </div>
  `,
  styleUrls: ['./form-upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadComponent,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() maxFiles = 5;
  @Input() maxFileSize = 2; // Mo
  @Input() maxFilesSize = 4; // Mo

  constructor(@Inject(WINDOW) private win: Window) {}

  files: any = [];
  uploadFileForm: FormGroup;

  readonly deleteFileIcon: any = IC_CLOSE_CROSS;
  readonly acceptFiles = '.doc,.docx,.pdf,.xls,.xlsx,.gif,.jpg,.jpeg,.bmp,.png,.PNG';

  onChange: any = () => {};
  onTouch: any = () => {};

  get filesToUpload(): FormArray {
    return this.uploadFileForm.get('filesToUpload') as FormArray;
  }

  selectFile(files: any): void {
    for (const item of files) {
      this.files.push(item);
    }
    this.onChange(this.files);
    this.onTouch(this.files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.onChange(this.files);
    this.onTouch(this.files);
  }

  truncateMiddle(fileName: string): string | undefined {
    if (fileName) {
      const fileNameLength = fileName.length;
      if (this.win && this.win.matchMedia('(max-width: 900px)').matches) {
        if (fileNameLength > 30) {
          return this.shortenFileName(fileName, fileNameLength, 10);
        }
      } else {
        if (fileNameLength > 60) {
          return this.shortenFileName(fileName, fileNameLength, 20);
        }
      }
      return fileName;
    }
  }

  shortenFileName(fileName: string, fileNameLength: number, substring: number): string {
    return fileName.substring(0, substring) + '...' + fileName.substring(fileNameLength - substring);
  }

  writeValue(_value: null): void {
    this.files = [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { ConfigService } from '../config.service';
import { Logger } from './logger';
import { SilentLogger } from './silent.logger';
import { FormattedConsoleLogger } from './formatted-console.logger';
import { ConsoleLogger } from './console.logger';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly request?: Request;

  constructor(
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: string,
    private injector: Injector,
  ) {
    if (isPlatformServer(platformId)) {
      this.request = this.injector.get(REQUEST);
    }
  }

  get(namespace: string): Logger {
    if (isPlatformServer(this.platformId)) {
      return new FormattedConsoleLogger(namespace, this.configService.get('LOG_LEVEL'), this.request);
    }

    const prod = this.configService.get<boolean>('PROD');
    if (prod) {
      return new SilentLogger();
    }
    return new ConsoleLogger(namespace);
  }
}

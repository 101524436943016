import { first } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  Optional,
} from '@angular/core';

import { Button, SuperHeroSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { isContentEmpty } from '../../../helpers/rich-text.helpers';
import { InteractService } from '../../../services/interact.service';
import { InteractOfferWithPostEvent } from '../../../services/interact.service.type';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { WindowLoadEventService } from '../../../window-load-event.service';
import { StateService } from '../../../services/state.service';
import { HOMEPAGE_MARKET_UID } from '../../../common/enum/enum-lcl';

const INTERACT_INTERACTION_POINT = 'HERO';
const INTERACT_POST_EVENT_RENDER = 'PageAccueil_Offer';
const INTERACT_POST_EVENT_CLICK = 'PageAccueil_Clic';

type TitleTypography = {
  [key: string]: string | number | undefined;
};

@Component({
  // Info: The component selector `slice-super-hero` have to be added to
  // `INTERACT_TARGET_COMPONENTS` which is defined in `front/src/interact.js`.
  selector: 'slice-super-hero',
  template: `
    <ng-template #contentTemplate>
      <div class="content content--oversized">
        <h1 *ngIf="isMarket" class="title" [style]="titleStyles">{{ data.title }}</h1>
        <h2 class="subtitle" [style]="subtitleStyles">{{ data.subtitle }}</h2>
        <div class="description" [style]="descriptionStyles" [innerHTML]="data.richText"></div>
        <div class="buttons">
          <ng-container *ngFor="let button of data.buttons">
            <cb-cta-btn-link
              class="cta-button"
              *ngIf="button"
              [button]="button"
              [sliceType]="data.type"
              [buttonBgColor]="button.button_color"
              [labelStyles]="initializeButtonStyles(button)"
              (linkClicked)="clickHandler($event, button.label)"
            ></cb-cta-btn-link>
          </ng-container>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="isDataValid && isReady">
      <cb-fullwidth-background [class]="fullwidthBackgroundClass" [image]="data.image">
        <div class="spacer"></div>
        <ng-container *ngIf="isDesktopMode">
          <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </ng-container>
      </cb-fullwidth-background>
      <ng-container *ngIf="!isDesktopMode">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./slice-super-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceSuperHeroComponent implements SliceWithData<SuperHeroSlice>, OnInit {
  @Input() data: SuperHeroSlice = {} as SuperHeroSlice;
  @Input() trackTagCommander = true;
  @Input() isCarousel: Boolean = true;

  isDesktopMode: boolean;

  isReady = true;

  hasInteractStrategy: boolean;

  interactEventContext: string;

  interactOffer: InteractOfferWithPostEvent;
  isHomePage: Boolean;
  isMarket: Boolean;

  titleStyles: TitleTypography = {};
  subtitleStyles: TitleTypography = {};
  descriptionStyles: TitleTypography = {};
  labelStyles: TitleTypography = {};

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly tagCommanderService: TagCommanderService,
    private readonly stateService: StateService,
    @Optional() private readonly interactService: InteractService,
    @Optional() private readonly windowLoadEventService: WindowLoadEventService,
  ) {}

  @HostBinding('class.hero--with-cta')
  get hasCTA(): boolean {
    return this.data.buttons ? this.data.buttons.length > 0 : false;
  }

  @HostBinding('class.mega-hero')
  get isMegaHero(): boolean {
    return this.data.megaHero;
  }

  @HostBinding('class.with-anim')
  get isAnimated(): boolean {
    return this.isReady && this.hasInteractStrategy;
  }

  get isDataValid(): boolean {
    return this.data
      ? !isContentEmpty(this.data.title as string) || !isContentEmpty(this.data.richText as string)
      : false;
  }

  get fullwidthBackgroundClass(): string {
    return this.isDesktopMode ? 'wrapper' : 'background';
  }

  ngOnInit(): void {
    this.initializeTitlesStyles();
    this.checkInteractOffer();
    this.shouldDisplayCarouselTitle();
  }

  initializeButtonStyles(button: Button): TitleTypography {
    return {
      color: button.label_color,
      'font-size': `${button.label_size}px`,
      btnColor: button.label_color,
    };
  }

  initializeTitlesStyles(): void {
    if (this.data) {
      this.titleStyles['color'] = this.data.title_color;
      this.titleStyles['font-size'] = `${this.data.title_size}px`;
      this.subtitleStyles['color'] = this.data.subtitle_color;
      this.subtitleStyles['font-size'] = `${this.data.subtitle_size}px`;
      this.descriptionStyles['color'] = this.data.text_color;
      this.descriptionStyles['font-size'] = `${this.data.text_size}px`;
    }
  }

  private checkInteractOffer(): void {
    this.hasInteractStrategy = !!(this.data.persoContext && this.interactService && this.interactService.isEnabled);
    if (!this.hasInteractStrategy) {
      this.trackEventPrint();
      return;
    }
    this.isReady = false;
    this.interactEventContext = this.data.persoContext as string;
    this.interactService.getOffers(this.interactEventContext, INTERACT_INTERACTION_POINT).subscribe(offers => {
      if (offers.length && offers[0].slices.length && offers[0].slices[0].type === 'super_hero') {
        this.interactOffer = offers[0];
        this.data = this.interactOffer.slices[0] as SuperHeroSlice;
        this.interactOffer.postEvent(INTERACT_POST_EVENT_RENDER).subscribe();
      }
      this.isReady = true;
      this.changeDetectorRef.detectChanges();
      this.trackEventPrint();
    });
  }

  clickHandler(event: Event, buttonLabel: string): void {
    const link = event.currentTarget as HTMLAnchorElement;
    if (!this.interactOffer || !link || !link.dataset || !link.click) {
      this.trackEventClick(buttonLabel);
      return;
    }

    const DONE = 'done';
    if (link.dataset.interactPostEvent !== DONE) {
      event.preventDefault();
      event.stopPropagation();

      this.interactOffer.postEvent(INTERACT_POST_EVENT_CLICK).subscribe(() => {
        link.dataset.interactPostEvent = DONE;
        link.click();
      });
    } else {
      this.trackEventClick(buttonLabel);
    }
  }

  private trackEventPrint(): void {
    if (this.windowLoadEventService && this.data.campaignCode && this.trackTagCommander) {
      this.windowLoadEventService.load$.pipe(first()).subscribe(() => {
        this.tagCommanderService.trackEventPrint(this.data.campaignCode as string);
      });
    }
  }

  private trackEventClick(buttonLabel: string): void {
    const label = ['Hero', ...(this.data.campaignCode ? [this.data.campaignCode] : []), buttonLabel].join('_');
    this.tagCommanderService.trackClickNavigationEvent(label);
  }

  shouldDisplayCarouselTitle() {
    const state = this.stateService.get() ?? null;
    const homePageUid = state && state.homepageId ? state.homepageId : '';
    const pageMarket = state.pageMarket;
    const isHomePageExist = HOMEPAGE_MARKET_UID.get(homePageUid)?.valueOf();
    this.isMarket = pageMarket ? true : false;
    // display condition value
    this.isHomePage = isHomePageExist && !this.isMarket ? true : false;
    this.stateService.updateIsHomePageMarketState(this.isHomePage);
  }
}

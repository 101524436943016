/*
 * CA PARAMETER
 */
var domain = 'https://diagnostic.nexecur.fr';
var page = '/diagnosis/form/big';
var organization = '003-04090200';
var origin = document.getElementById('iframe-nexecur-origin').innerHTML;
var redirect = '0';

function displayNexecurIframe(domain, page, organization, origin, redirect) {
  /*
   * TRACKING PARAMETER
   */
  var query = location.search.substr(1);
  var parentParams = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    parentParams[item[0]] = decodeURIComponent(item[1]);
  });

  var iframeUrl = domain + page + '?organization=' + organization + '&origin=' + origin + '&redirect=' + redirect;
  var utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'type_logement', 'gclid'];
  utmParams.forEach(function (utmParam) {
    if (typeof parentParams[utmParam] != 'undefined') {
      iframeUrl += '&' + utmParam + '=' + parentParams[utmParam];
    }
  });
  iframeUrl += '&parent_page=' + location.pathname;

  /*
   * CONSTRUCT IFRAME
   */
  var iframeNexecur = document.createElement('iframe');
  iframeNexecur.setAttribute('id', 'iframe-nexecur');
  iframeNexecur.setAttribute('src', iframeUrl);
  iframeNexecur.setAttribute('width', '100%');
  iframeNexecur.setAttribute('height', '600');
  iframeNexecur.setAttribute('frameBorder', 0);
  document.getElementById('iframe-nexecur-container').appendChild(iframeNexecur);

  /*
   * LISTENER (resize iframe and redirect)
   */
  var myEventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  var myEventListener = window[myEventMethod];
  var myEventMessage = myEventMethod == 'attachEvent' ? 'onmessage' : 'message';
  myEventListener(
    myEventMessage,
    function (e) {
      if (e.origin == domain) {
        var parameters = e.data.split('=');
        //Post message to change iframe height
        if (parameters[0] == 'height') {
          document.getElementById('iframe-nexecur').height = parseInt(parameters[1]);
        }
      }
    },
    false,
  );
}

displayNexecurIframe(domain, page, organization, origin, redirect);

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription, animationFrameScheduler } from 'rxjs';

import { newSweetScroll, SweetScroll } from '../../../vendors/sweet-scroll';
import { newSwipe, Swipe } from '../../../vendors/swipe';

import { WINDOW } from '../../../services/window.provider';
import { getAbsoluteOffsets, getWindowScrollPosition } from '../../../helpers/dom.helpers';

import { UiSlideshowAnchorDirective } from './slideshow-anchor.directive';
import { UiSlideshowSlideDirective } from './slideshow-slide.directive';
import { UiSlideshowNavDirective } from './slideshow-nav.directive';
import { RouterService } from '../../../services/router.service';
import { BREAKPOINT_DESKTOP } from '../../../common/constants/breakpoints';
import { StateService } from '../../../services/state.service';

const SWEET_SCROLL_DURATION = 800;
const SWEET_SCROLL_EASING = 'easeOutExpo';
const SWIPE_SLIDE_DURATION = 800;

@Component({
  selector: 'ui-slideshow-block',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div #navGhost class="nav-ghost"></div>
    <div #navContainer class="nav-container" *ngIf="nav && nav.anchors.length">
      <div class="nav-wrap">
        <div [@navAlign]="{ value: navOffsetLeft, params: { navOffsetLeft: navOffsetLeft } }">
          <ng-content select="[uiSlideshowNav]"></ng-content>
        </div>
      </div>
      <div
        #navInk
        [@navInkAlign]="{ value: navInkOffsetLeft, params: { navInkOffsetLeft: navInkOffsetLeft } }"
        (@navInkAlign.start)="handleNavInkAlignAnimation($event)"
        (@navInkAlign.done)="handleNavInkAlignAnimation($event)"
        class="nav-ink"
      ></div>
    </div>
    <div
      #slidesContainer
      [@autoScrolling]="autoScrollingState"
      (@autoScrolling.start)="handleAutoScrollingAnimation($event)"
      (@autoScrolling.done)="handleAutoScrollingAnimation($event)"
      class="slides-container"
      [class.swipe]="swipeEnabled"
    >
      <div [class.swipe-wrap]="!_isBreakpointDesktop && win">
        <ng-content select="[uiSlideshowSlide]"></ng-content>
      </div>
    </div>
  `,
  animations: [
    trigger('autoScrolling', [
      state(
        'autoscrolling',
        style({
          opacity: '0.5',
          transform: 'scale(0.85) translateY(-32px)',
        }),
      ),
      state(
        'idle',
        style({
          opacity: '1',
          transform: 'scale(1) translateY(0)',
        }),
      ),
      transition('idle => autoscrolling', animate('0.6s ease-in')),
      transition('autoscrolling => idle', animate('0.4s ease-out')),
    ]),
    trigger('navAlign', [
      state('*', style({ transform: 'translateX({{ navOffsetLeft }}px)' }), { params: { navOffsetLeft: 0 } }),
      transition(':enter', animate(0)),
      transition('* => *', animate(200)),
    ]),
    trigger('navInkAlign', [
      state('*', style({ left: '{{ navInkOffsetLeft }}px', bottom: '0' }), { params: { navInkOffsetLeft: 0 } }),
      transition(':enter', animate(0)),
      transition('* => *', animate('0.4s ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSlideshowBlockComponent implements AfterViewInit, OnInit, OnDestroy {
  _fixTopOffsetDesktop = 0;
  menuLabelPage: string | null;
  get fixTopOffsetDesktop(): number {
    return this._fixTopOffsetDesktop;
  }

  @Input() set fixTopOffsetDesktop(value: number) {
    this._fixTopOffsetDesktop = +value;
  }

  _fixBottomOffsetDesktop = 0;
  get fixBottomOffsetDesktop(): number {
    return this._fixBottomOffsetDesktop;
  }

  @Input() set fixBottomOffsetDesktop(value: number) {
    this._fixBottomOffsetDesktop = +value;
  }

  _fixTopOffset = 0;
  get fixTopOffset(): number {
    return this._fixTopOffset;
  }

  @Input() set fixTopOffset(value: number) {
    this._fixTopOffset = +value;
  }

  _fixBottomOffset = 0;
  get fixBottomOffset(): number {
    return this._fixBottomOffset;
  }

  @Input() set fixBottomOffset(value: number) {
    this._fixBottomOffset = +value;
  }

  get fixTopOffsetResponsive(): number {
    return this._isBreakpointDesktop ? this.fixTopOffsetDesktop : this.fixTopOffset;
  }

  get fixBottomOffsetResponsive(): number {
    return this._isBreakpointDesktop ? this.fixBottomOffsetDesktop : this.fixBottomOffset;
  }

  _breakpointDesktopSubscription: Subscription;
  _breakpointDesktop: number;
  _isBreakpointDesktop = false;
  _firstBreakpointChange = true;

  get breakpointDesktop(): number {
    return this._breakpointDesktop;
  }

  @Input() set breakpointDesktop(value: number) {
    this._breakpointDesktop = +value;

    if (this._breakpointDesktopSubscription) {
      this._breakpointDesktopSubscription.unsubscribe();
    }

    const breakpointDesktopObserver = this.breakpointObserver.observe(`(min-width: ${value}px)`);

    this._breakpointDesktopSubscription = breakpointDesktopObserver.subscribe(breakpointState =>
      this.handleBreakpointChange(breakpointState),
    );
  }

  @HostBinding('class.is-autoscrolling') isAutoScrolling = false;

  get swipeEnabled(): boolean {
    return !this._isBreakpointDesktop && !!(this.win && this.nav && this.nav.anchors.length);
  }

  get autoScrollingState(): 'autoscrolling' | 'idle' {
    return this.isAutoScrolling ? 'autoscrolling' : 'idle';
  }

  @ViewChild('navContainer') navContainer: ElementRef<HTMLDivElement>;
  @ViewChild('navInk') navInk: ElementRef<HTMLDivElement>;
  @ViewChild('navGhost', { static: true }) navGhost: ElementRef<HTMLDivElement>;
  @ViewChild('slidesContainer', { static: true }) slidesContainer: ElementRef<HTMLDivElement>;

  @ContentChild(UiSlideshowNavDirective) nav: UiSlideshowNavDirective;
  @ContentChildren(UiSlideshowSlideDirective) slides: QueryList<UiSlideshowSlideDirective>;

  autoScrollingTarget: UiSlideshowSlideDirective;
  autoScrollingTargetTop: number;

  navOffsetLeft = 0;
  navInkOffsetLeft = 0;

  onAnchorClickSubscription: Subscription;

  sweetScroll: SweetScroll;

  swipe: Swipe;

  constructor(
    @Inject(WINDOW) readonly win: Window,
    readonly routerService: RouterService,
    readonly breakpointObserver: BreakpointObserver,
    readonly elementRef: ElementRef<HTMLElement>,
    readonly renderer: Renderer2,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly stateService: StateService,
  ) {}

  ngOnInit(): void {
    if (typeof this.breakpointDesktop === 'undefined') {
      this.breakpointDesktop = BREAKPOINT_DESKTOP;
    }

    this.menuLabelPage = this.stateService.get().labelPage;

    if (this.win) {
      this.sweetScroll = newSweetScroll();

      this.swipe = newSwipe(this.slidesContainer.nativeElement, {
        startSlide: 0,
        draggable: true,
        autoRestart: false,
        continuous: false,
        disableScroll: true,
        stopPropagation: true,
        callback: (_index, element) => this.handleSwipe(element),
      });
    }
  }

  ngOnDestroy(): void {
    if (this._breakpointDesktopSubscription) {
      this._breakpointDesktopSubscription.unsubscribe();
      this._breakpointDesktopSubscription = (null as unknown) as Subscription;
    }

    if (this.onAnchorClickSubscription) {
      this.onAnchorClickSubscription.unsubscribe();
      this.onAnchorClickSubscription = (null as unknown) as Subscription;
    }

    if (this.swipe) {
      this.swipe.kill();
      this.swipe = (null as unknown) as Swipe;
    }

    if (this.sweetScroll) {
      this.sweetScroll.destroy();
      this.sweetScroll = (null as unknown) as SweetScroll;
    }
  }

  ngAfterViewInit(): void {
    if (this.nav) {
      const navElement = this.nav.element as HTMLElement;
      if (navElement) this.setAttributesAccessibility(navElement);

      this.onAnchorClickSubscription = this.nav.onAnchorClick(anchorClicked => {
        this.prepareAutoScrolling(anchorClicked);
      });
    }

    if (this.swipe) {
      if (!this.swipeEnabled) {
        this.swipe.kill();
      } else {
        this.swipe.setup();
        this.initMobileSwiperWithAnchor();
      }
    }

    this.refreshNavBar();
  }

  setAttributesAccessibility(el: HTMLElement): void {
    this.renderer.setAttribute(el, 'role', 'navigation');
    this.renderer.setAttribute(el, 'aria-label', `Menu ${this.menuLabelPage}`);
  }

  handleBreakpointChange(breakpointState: BreakpointState): void {
    const firstSlideVisible = this.getFirstSlideVisible();

    this._isBreakpointDesktop = breakpointState.matches;

    if (this._isBreakpointDesktop) {
      this.navOffsetLeft = 0;

      if (this.swipe) {
        this.swipe.kill();
      }
    } else {
      if (this.sweetScroll) {
        this.sweetScroll.stop(false);
      }

      if (this.swipe) {
        if (this.nav && this.nav.anchors.length) {
          this.swipe.setup();
          this.swipeToSlide(firstSlideVisible);
        } else {
          this.swipe.kill();
        }
      }
    }

    if (this._firstBreakpointChange) {
      this._firstBreakpointChange = false;

      if (this.slides) {
        const targetSlideId = this.routerService.getHash();
        const targetSlide = targetSlideId && this.slides.find(slide => slide.id === targetSlideId);
        if (targetSlide) {
          // note: defer initial scroll
          setTimeout(() => {
            this.sweetScroll.to(
              { top: this.getTopPosition(targetSlide.elementRef.nativeElement) },
              {
                duration: SWEET_SCROLL_DURATION,
                easing: SWEET_SCROLL_EASING,
                cancellable: false,
              },
            );

            if (!this._isBreakpointDesktop) {
              this.swipeToSlide(targetSlide);
            }
          }, 60);
        }
      }
    }
  }

  handleAutoScrollingAnimation(event: AnimationEvent): void {
    if (
      this.isAutoScrolling &&
      event.fromState !== 'autoscrolling' &&
      event.toState === 'autoscrolling' &&
      event.phaseName === 'done'
    ) {
      if (this.autoScrollingTarget) {
        this.alignNavInkForSlide(this.autoScrollingTarget);
      } else {
        this.isAutoScrolling = false;
      }
    }
  }

  handleNavInkAlignAnimation(event: AnimationEvent): void {
    if (this.isAutoScrolling && event.fromState !== 'void' && event.phaseName === 'done') {
      this.continueAutoScrolling();
    }
  }

  handleSwipe(elementTarget: HTMLElement): void {
    const slideTarget = this.slides.find(slide => slide.element === elementTarget);

    if (!slideTarget) {
      return;
    }

    this.routerService.replaceHash(slideTarget.id);

    this.alignNavBar(slideTarget);

    const anchor = this.nav.getAnchorById(slideTarget.id);

    if (anchor) {
      this.nav.setAnchorActive(anchor);
    }
  }

  continueAutoScrolling(): void {
    this.routerService.replaceHash(this.autoScrollingTarget.id);

    if (this.sweetScroll) {
      this.sweetScroll.to(
        { top: this.autoScrollingTargetTop },
        {
          duration: SWEET_SCROLL_DURATION,
          easing: SWEET_SCROLL_EASING,
          cancellable: false,
          complete: () => {
            this.isAutoScrolling = false;
            this.changeDetectorRef.markForCheck();
          },
        },
      );
    }
  }

  swipeToSlide(slide: UiSlideshowSlideDirective): void {
    if (!this.swipe) {
      return;
    }

    const slideIndex = this.slides.toArray().indexOf(slide);

    if (slideIndex === -1) {
      return;
    }

    this.swipe.slide(slideIndex, SWIPE_SLIDE_DURATION);
  }

  prepareAutoScrolling(anchor: UiSlideshowAnchorDirective): void {
    if (this.isAutoScrolling) {
      return;
    }

    const targetId = anchor.getTargetId();
    const slide = this.slides.find(_slide => _slide.id === targetId);

    if (!slide) {
      return;
    }

    this.nav.setAnchorActive(anchor);

    if (!this._isBreakpointDesktop) {
      const navPositionTop = getAbsoluteOffsets(this.navGhost.nativeElement).top - this.fixTopOffset;

      this.win.scrollTo(this.win.pageXOffset, navPositionTop);

      animationFrameScheduler.schedule(() => this.swipeToSlide(slide));

      return;
    }

    const winScrollPosition = getWindowScrollPosition(this.win);
    const top = this.getTopPosition(slide.element);

    if (Math.floor(top) === Math.floor(winScrollPosition.top)) {
      return;
    }

    this.autoScrollingTarget = slide;
    this.autoScrollingTargetTop = top;

    this.isAutoScrolling = true;

    this.changeDetectorRef.markForCheck();
  }

  getTopPosition(element: HTMLElement): number {
    const topOffset = this.navContainer.nativeElement.offsetHeight + this.fixTopOffsetResponsive;
    const elementOffsets = getAbsoluteOffsets(element);
    return elementOffsets.top - topOffset;
  }

  @HostListener('window:resize')
  @HostListener('window:scroll')
  refreshNavBar(): void {
    this.refreshNavPosition();
    this.refreshNavInkPosition();
    this.refreshNavAnchorActivate();
  }

  refreshNavAnchorActivate(): void {
    if (!this.nav || this.isAutoScrolling) {
      return;
    }

    const firstSlideVisible = this.getFirstSlideVisible();
    const anchor = firstSlideVisible && firstSlideVisible.id ? this.nav.getAnchorById(firstSlideVisible.id) : null;
    this.nav.setAnchorActive(anchor);
  }

  refreshNavPosition(): void {
    if (!this.win || !this.nav || !this.slides.length || !this.nav.anchors.length) {
      return;
    }

    const winScrollPosition = getWindowScrollPosition(this.win);

    const container = this.elementRef.nativeElement;
    const containerAbsoluteOffsets = getAbsoluteOffsets(container);
    const containerOffsets = { top: container.offsetTop, left: container.offsetLeft };
    const nav = this.navContainer.nativeElement;
    const navGhost = this.navGhost.nativeElement;

    const startFixPoint = containerAbsoluteOffsets.top - this.fixTopOffsetResponsive;
    const stopFixPoint = startFixPoint + container.offsetHeight - nav.offsetHeight - this.fixBottomOffsetResponsive;

    this.renderer.setStyle(nav, 'left', `${containerOffsets.left}px`);
    this.renderer.setStyle(nav, 'width', `${container.offsetWidth}px`);
    this.renderer.setStyle(navGhost, 'width', `${nav.offsetWidth}px`);
    this.renderer.setStyle(navGhost, 'height', `${nav.offsetHeight}px`);

    if (winScrollPosition.top < startFixPoint || winScrollPosition.top > stopFixPoint) {
      let top = containerOffsets.top;
      if (winScrollPosition.top > stopFixPoint) {
        top += container.offsetHeight - nav.offsetHeight - this.fixBottomOffsetResponsive;
      }

      this.renderer.setStyle(nav, 'position', 'absolute');
      this.renderer.setStyle(nav, 'top', `${top}px`);
    } else {
      this.renderer.setStyle(nav, 'position', 'fixed');
      this.renderer.setStyle(nav, 'top', `${this.fixTopOffsetResponsive}px`);
    }

    if (this._isBreakpointDesktop) {
      this.renderer.removeStyle(nav, 'overflow');
    } else {
      this.renderer.setStyle(nav, 'overflow', 'hidden');

      const firstSlideVisible = this.getFirstSlideVisible();

      if (firstSlideVisible) {
        this.alignNavBar(firstSlideVisible);
      }
    }
  }

  refreshNavInkPosition(): void {
    if (!this.nav || this.isAutoScrolling) {
      return;
    }

    const firstSlideVisible = this.getFirstSlideVisible();

    if (!firstSlideVisible) {
      return;
    }

    this.alignNavInkForSlide(firstSlideVisible);
  }

  alignNavBar(slide: UiSlideshowSlideDirective): void {
    const container = this.elementRef.nativeElement;
    const anchor = this.nav.getAnchorById(slide.id);

    if (anchor) {
      this.navOffsetLeft = container.offsetWidth / 2 - anchor.element.offsetLeft - anchor.element.offsetWidth / 2;

      this.changeDetectorRef.markForCheck();
    }
  }

  alignNavInkForSlide(slide: UiSlideshowSlideDirective): void {
    const navInk = this.navInk.nativeElement;
    let offsetLeft = 0;

    if (this._isBreakpointDesktop) {
      const slideNavAnchor = this.nav.getAnchorById(slide.id);

      if (slideNavAnchor) {
        const anchor = slideNavAnchor.element;

        offsetLeft = anchor.offsetLeft + (anchor.offsetWidth - navInk.offsetWidth) / 2;
      }
    } else {
      offsetLeft = this.navContainer.nativeElement.offsetWidth / 2 - navInk.offsetWidth / 2;
    }

    const needManualContinueAutoScrolling = this.isAutoScrolling && offsetLeft === this.navInkOffsetLeft;

    if (needManualContinueAutoScrolling) {
      this.continueAutoScrolling();
    } else {
      this.navInkOffsetLeft = offsetLeft;
    }
  }

  getFirstSlideVisible(): UiSlideshowSlideDirective {
    if (!this.slides) {
      return (null as unknown) as UiSlideshowSlideDirective;
    }

    let firstSlideVisible!: UiSlideshowSlideDirective;
    let firstSlideVisibleBounding: ClientRect;

    this.slides.forEach(slide => {
      if (!slide.id || !this.nav.getAnchorById(slide.id)) {
        return;
      }

      const slideBounding = slide.element.getBoundingClientRect
        ? slide.element.getBoundingClientRect()
        : ({ top: 0, left: 0 } as ClientRect);
      let isFirstSlide = false;

      if (!firstSlideVisible) {
        isFirstSlide = true;
      } else if (this._isBreakpointDesktop) {
        const offsetTop = this.navContainer.nativeElement.offsetHeight + this.navContainer.nativeElement.offsetTop;
        const firstSlideTop = Math.abs(firstSlideVisibleBounding.top) - offsetTop;
        const slideTop = Math.abs(slideBounding.top) - offsetTop;
        isFirstSlide = slideTop < firstSlideTop;
      } else {
        isFirstSlide = Math.abs(slideBounding.left) < Math.abs(firstSlideVisibleBounding.left);
      }

      if (isFirstSlide) {
        firstSlideVisible = slide;
        firstSlideVisibleBounding = slideBounding;
      }
    });
    return firstSlideVisible;
  }

  initMobileSwiperWithAnchor(): void {
    if (this.slides) {
      const targetSlideId = this.routerService.getHash();
      const targetSlide = targetSlideId && this.slides.find(slide => slide.id === targetSlideId);

      if (targetSlide) {
        this.swipeToSlide(targetSlide);
      }
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'zone-description-tableau',
  template: `
    <ng-container *ngIf="this.data.description_tableau">
      <div class="description-container" [innerHTML]="data.description_tableau"></div>
    </ng-container>
  `,
  styleUrls: ['./zone-description-tableau.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ZoneDescriptionTableauComponent {
  @Input() data: any;
}

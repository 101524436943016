import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TagCommanderService } from '../../../../services/tag-commander.service';

@Component({
  selector: 'zone-liste-avantages',
  template: `
    <div class="liste-container">
      <div class="title" *ngIf="data.sous_titre_tableau">{{ data.sous_titre_tableau }}</div>
      <div class="liste">
        <ng-container *ngFor="let item of data.avantage_offre">
          <ng-container *ngIf="item.libelle_avantage">
            <div class="avantage">
              <div class="icon-container">
                <img class="icon" [src]="item.picto_avantage.url" />
              </div>
              <div class="libelle" [style.color]="item.statut_avantage ? '#747474' : '#CCCCCC'">
                {{ item.libelle_avantage }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="link-wrapper" *ngIf="data.url_lien.url">
        <a class="link" [href]="data.url_lien.url" (click)="clickHandler(data.libelle_lien)">{{ data.libelle_lien }}</a>
      </div>
    </div>
  `,
  styleUrls: ['./zone-liste-avantages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneListeAvantagesComponent {
  @Input() data: any;

  constructor(private readonly tagCommanderService: TagCommanderService) {}

  clickHandler(buttonLabel: string): void {
    this.tagCommanderService.trackClickNavigationEvent(buttonLabel);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { ModalConfig } from '../modal-config';
import { ModalEntryHostDirective } from '../modal-entry-host/modal-entry-host.directive';
import { ModalOutletInjector } from '../modal-outlet-injector';
import { ModalRef } from '../modal-ref';
import { ModalRouterOutletContext } from '../modal-router-outlet-context';
import { ModalContent } from '../modal.types';
import { OutletInjector } from '../outlet-injector';

@Component({
  selector: 'app-modal-container',
  template: `
    <div class="modal-content-wrapper">
      <div class="cross-button" (click)="closeModal()">
        <span class="cdk-visually-hidden">fermer la boite de dialogue</span>
      </div>
      <div class="modal-content">
        <ng-template [ngIf]="isTemplateRefContent">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-template>
        <ng-template [ngIf]="!isTemplateRefContent && !isModalRouterOutletContext">
          <ng-container *ngComponentOutlet="content"></ng-container>
        </ng-template>
        <ng-template modal-entry-host></ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContainerComponent<T = any> implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('attr.aria-modal')
  readonly attrAriaModal = true;

  @HostBinding('attr.role')
  readonly attrRole = 'dialog';

  get content(): Type<T> | TemplateRef<T> | undefined {
    return this._content;
  }

  get contentOverflow(): string | undefined {
    return this._contentOverflow;
  }

  @HostBinding('class.has-close-cross')
  get hasCloseCross(): boolean {
    return this._hasCloseCross;
  }

  @ViewChild(ModalEntryHostDirective, { static: true })
  _entryHost: ModalEntryHostDirective;

  get isTemplateRefContent(): boolean {
    return this._content instanceof TemplateRef;
  }

  get isModalRouterOutletContext(): boolean {
    return this.content instanceof ModalRouterOutletContext;
  }

  @HostBinding('attr.aria-labelledby')
  get ariaLabelledBy(): string | null | undefined {
    return this._ariaLabelledBy;
  }

  private _content?: Type<T> | TemplateRef<T>;
  private _config: ModalConfig;
  private _hasCloseCross = false;
  private _ariaLabelledBy: string | null | undefined;
  private _contentOverflow?: string;

  constructor(
    private readonly _modalRef: ModalRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    this._bindProperties();
  }

  @HostListener('document:keyup', ['$event'])
  handleKey(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this._hasCloseCross) {
      this.closeModal();
    }
  }

  getConfig(): ModalConfig {
    return this._config;
  }

  ngOnInit(): void {
    this._insertContent();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this._document.querySelector('app-root')?.removeAttribute('aria-hidden');
  }

  closeModal(): void {
    this._modalRef.close();
    if (this._config.callbackOnClose) {
      this._config.callbackOnClose();
    }
  }

  _insertContent(): void {
    const content: ModalContent<T> = this._modalRef.getContent();

    if (content instanceof ModalRouterOutletContext) {
      const { factory, componentIndex, outletInjector } = content;
      const modalOutletInjector = this._buildModalOutletInjector(outletInjector);

      this._entryHost.viewContainerRef.createComponent(factory, componentIndex, modalOutletInjector);
    } else {
      this._content = content;
    }
  }

  private _bindProperties(): void {
    this._config = this._modalRef.getConfig();
    this._hasCloseCross = this._config?.hasCloseCross || this._hasCloseCross;
    this._contentOverflow = this._config?.contentOverflow || this._contentOverflow;
  }

  private _buildModalOutletInjector(outletInjector: OutletInjector): ModalOutletInjector {
    return new ModalOutletInjector(
      outletInjector.get(ActivatedRoute),
      outletInjector.get(ChildrenOutletContexts),
      outletInjector.get(Injector),
      this._modalRef,
    );
  }
}

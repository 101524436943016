import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, streetRegex, Form } from './form';
import { Text } from '../index';
export interface QuestionForm extends Form {
  requestType: string;
  claimType?: string;
  clientFunction: string;
  emailNotClient: string;
  birthDate: string;
  street: string;
  city: string;
  postalCode: string;
  clientCategory?: string;
  businessName?: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
  clientType?: string;
  civilTitleClient?: string;
  firstNameClient?: string;
  lastNameClient?: string;
  emailClient?: string;
  birthDateClient?: string;
  phoneClient?: string;
  streetClient?: string;
  cityClient?: string;
  postalCodeClient?: string;
  recipt: Text[];
}

export interface QuestionFormGroup {
  identity: QuestionIdentityForm;
  claim: QuestionClaimForm;
  request: QuestionRequestForm;
}

export interface QuestionIdentityForm {
  requestType: string;
  claimType?: string;
  clientFunction: number;
}

export interface QuestionClaimForm {
  civilTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  phone: string;
  street: string;
  city: string;
  postalCode: string;
  clientCategory?: number;
  businessName?: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
  clientType?: number;
  civilTitleClient?: string;
  firstNameClient?: string;
  lastNameClient?: string;
  emailClient?: string;
  birthDateClient?: string;
  phoneClient?: string;
  streetClient?: string;
  cityClient?: string;
  postalCodeClient?: string;
}

export interface QuestionRequestForm {
  subject: number;
  message: string;
  recaptcha: string;
  files?: any;
}

export const CLIENT_COMPLAINT_CATEGORIES = [
  'Paiements (carte, chèque, virement, prélèvement)',
  'Fonctionnement de compte (tarification, relevés…)',
  'Epargne et placements',
  'Crédits',
  'Assurances',
  'Evénements (ouverture, clôture, incidents, succession…)',
  'Espace client en ligne et Appli LCL Mes Comptes',
  'Services (coffre, automates…)',
  'Accueil et conseil',
];
export const OPTIONS_CLIENT_CATEGORIES = ['Particulier', 'Professionnel', 'Banque privée'];
export const OPTIONS_CLIENT_TYPES = [
  'Mandataire par procuration',
  'Représentant légal',
  'Ayant-droit succession',
  'Autre',
];

export const CLIENT_FUNCTION = ['Je suis client', 'Je ne suis pas client', "J'interviens pour un client"]; // tslint:disable-line:quotemark

export const QUESTION_IDENTITY_FORM: JSONSchema7 = {
  properties: {
    clientFunction: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_FUNCTION.length - 1,
    },
  },
};

export const QUESTION_CLAIM_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
      pattern: streetRegex,
    },
    city: {
      type: 'string',
      minLength: 1,
      pattern: nameRegex,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    clientCategory: {
      type: 'integer',
      minimum: 0,
      maximum: OPTIONS_CLIENT_CATEGORIES.length - 1,
    },
    businessName: {
      type: 'string',
      pattern: streetRegex,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
    },
    accountNumber: {
      type: 'string',
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      maxLength: 7,
    },
    clientType: {
      type: 'integer',
      minimum: 0,
      maximum: OPTIONS_CLIENT_TYPES.length - 1,
    },
    civilTitleClient: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstNameClient: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastNameClient: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    emailClient: {
      type: 'string',
      pattern: emailRegex,
    },
    birthDateClient: {
      type: 'string',
    },
    phoneClient: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
    },
    streetClient: {
      type: 'string',
      pattern: streetRegex,
      minLength: 1,
    },
    cityClient: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    postalCodeClient: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'birthDate',
    'phone',
    'street',
    'city',
    'postal',
    'clientType',
    'agencyRef',
    'agencyQuery',
    'civilTitleClient',
    'firstNameClient',
    'lastNameClient',
    'clientCategory',
  ],
};
export const QUESTION_REQUEST_FORM: JSONSchema7 = {
  properties: {
    subject: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_COMPLAINT_CATEGORIES.length - 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    files: {
      type: 'array',
    },
  },
  required: ['subject', 'message', 'recaptcha'],
};

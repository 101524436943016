import { Component, HostListener, Input, ChangeDetectionStrategy } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { CarouselServicesSlice } from '@repo/shared';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TagCommanderService } from '../../../services/tag-commander.service';

const IC_ARROW = '/assets/images/arrow_links.svg';

@Component({
  selector: 'slice-carousel-services',
  template: `
    <button *ngIf="hasPreviousItem" (click)="goToPreviousItem()" class="arrow-btn prev">
      <span class="ic ic-arrow"></span>
    </button>
    <button *ngIf="hasNextItem" (click)="goToNextItem()" class="arrow-btn next">
      <span class="ic ic-arrow"></span>
    </button>
    <ng-container *ngFor="let item of data.carouselServicesItems; let index = index">
      <carousel-pane
        [@fadeInOut]="currentPanIndex === index ? 'in' : 'out'"
        [active]="currentPanIndex === index"
        [item]="item"
        [title]="data.title"
        [indicatorCount]="data.carouselServicesItems.length"
        [currentIndicator]="currentPanIndex"
      ></carousel-pane>
    </ng-container>
    <div
      class="in-viewport"
      inViewport
      [inViewportOptions]="{ threshold: 1 }"
      (inViewportAction)="onCarouselPaneIntersection($event)"
    ></div>
  `,
  styleUrls: ['./slice-carousel-services.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [animate('200ms')]),
      transition('out => in', [animate('300ms')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceCarouselServicesComponent implements SliceWithData {
  @Input() data: Partial<CarouselServicesSlice>;

  readonly icArrow = IC_ARROW;

  currentPanIndex = 0;

  private tagTriggeredList: number[] = [];

  constructor(private readonly tagCommanderService: TagCommanderService) {}

  get carouselLength(): number {
    return (this.data && this.data.carouselServicesItems && this.data.carouselServicesItems.length) || 0;
  }

  get hasPreviousItem(): boolean {
    return this.currentPanIndex > 0;
  }

  get hasNextItem(): boolean {
    return this.currentPanIndex < this.carouselLength - 1;
  }

  @HostListener('swiperight')
  goToPreviousItem(): void {
    if (this.hasPreviousItem) {
      this.currentPanIndex--;
      this.triggerTrackEvent();
    }
  }

  @HostListener('swipeleft')
  goToNextItem(): void {
    if (this.hasNextItem) {
      this.currentPanIndex++;
      this.triggerTrackEvent();
    }
  }

  onCarouselPaneIntersection({ visible }: { visible: boolean }): void {
    if (visible) {
      this.triggerTrackEvent();
    }
  }

  triggerTrackEvent(): void {
    const carouselServicesItems = this.data?.carouselServicesItems;
    const carouselServicesCurrentItem = carouselServicesItems && carouselServicesItems[this.currentPanIndex];
    const campaignCode = carouselServicesCurrentItem?.campaignCode;

    if (campaignCode && !this.tagTriggeredList.includes(this.currentPanIndex)) {
      this.tagCommanderService.trackEventPrint(campaignCode);
      this.tagTriggeredList.push(this.currentPanIndex);
    }
  }
}

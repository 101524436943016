import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex } from './form';
import { Text } from '../index';

export interface ResiliationForm {
  // identity
  productType: string;
  isOwner: boolean;
  productSelected: string;

  // claim
  civilTitle: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
  optionalIdentityField?: string;
  optionalIdentityFieldText?: string;

  // request
  deadline: string;
  resiliationDate?: string;
  recaptcha: string;

  recipt: Text[];
}

export interface ResiliationFormGroup {
  identity: ResiliationIdentityForm;
  claim: ResiliationClaimForm;
  request: ResiliationRequestForm;
}

export interface ResiliationIdentityForm {
  productType: string;
  isOwner: boolean;
  productSelected: string;
}

export interface ResiliationClaimForm {
  civilTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
  optionalIdentityField?: string;
  optionalIdentityFieldText?: string;
}

export interface ResiliationRequestForm {
  deadline: string;
  resiliationDate?: string;
  recaptcha: string;
}

// catégories à fournir pour les raisons de la plainte (c'est dans l'étape 3)
export const ECHEANCE_CATEGORIES = ["A l'échéance", 'Au plus tôt', 'A la date du'];

export const RESILIATION_IDENTITY_FORM: JSONSchema7 = {
  properties: {
    productType: {
      type: 'string',
      minLength: 1,
    },
    isOwner: {
      type: 'boolean',
    },
    productSelected: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['isOwner', 'productSelected', 'productType'],
};

export const RESILIATION_CLAIM_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      minLength: 1,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
      minLength: 1,
    },
    accountNumber: {
      type: 'string',
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      minLength: 1,
      maxLength: 7,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'birthDate',
    'agencyRef',
    'agencyID',
    'accountNumber',
    'agencyQuery',
  ],
};
export const RESILIATION_REQUEST_FORM: JSONSchema7 = {
  properties: {
    deadline: {
      type: 'string',
      minLength: 1,
    },
    resiliationDate: {
      type: 'string',
    },
  },
  required: ['deadline', 'recaptcha'],
};

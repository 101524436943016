import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';

import { UiSlideshowAnchorDirective } from './slideshow-anchor.directive';
import { UiSlideshowBlockComponent } from './slideshow-block.component';
import { UiSlideshowNavDirective } from './slideshow-nav.directive';
import { UiSlideshowSlideDirective } from './slideshow-slide.directive';

@NgModule({
  imports: [CommonModule, LayoutModule],
  declarations: [
    UiSlideshowAnchorDirective,
    UiSlideshowBlockComponent,
    UiSlideshowNavDirective,
    UiSlideshowSlideDirective,
  ],
  exports: [UiSlideshowAnchorDirective, UiSlideshowBlockComponent, UiSlideshowNavDirective, UiSlideshowSlideDirective],
})
export class UiSlideshowBlockModule {}

import { Component } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'cdk-stepper',
  template: `
    <section class="container">
      <div [ngTemplateOutlet]="selected?.content"></div>
    </section>
  `,
  styleUrls: ['./cdk-stepper.component.css'],
  providers: [{ provide: CdkStepper, useExisting: CdkStepperComponent }],
})
export class CdkStepperComponent extends CdkStepper {
  onClick(index: number): void {
    this.selectedIndex = index;
  }
}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Inject,
  forwardRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { HttpErrorResponse } from '@angular/common/http';
import { ECHEANCE_CATEGORIES, RESILIATION_REQUEST_FORM } from '@repo/shared/form-schemas/resiliation-client';
import { DOMService } from '../../../../../services/dom.service';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { SliceFormsService } from '../../slice-forms.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../logger/logger.service';
import { Logger } from '../../../../../logger/logger';
import { SLICE_TAGGING_NAME, SLICE_TAGGING_SUBPATH } from '../../../../../common/constants/tagging';

export const CONFIRMATION_PAGE_URL = 'confirmation-envoi-resiliation';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../../slice-forms.scss', './summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RESILIATION_REQUEST_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SummaryComponent),
    },
  ],
})
export class SummaryComponent extends AbstractFormComponent {
  @Input() regForm: any;
  @Input() informations: string;
  @Output() goToStep: EventEmitter<number> = new EventEmitter();

  logger: Logger;

  echeanceOptions = ECHEANCE_CATEGORIES;
  _buttonDisabled = false;
  _hasPostError = false;
  selectError = false;

  tagName = `${SLICE_TAGGING_NAME.resiliation1}_bt_envoyer`;
  tagSubPath = SLICE_TAGGING_SUBPATH.resiliation_confirmation;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _resiliationFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_resiliationFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SummaryComponent.name);
  }

  changeStepOfResilationForm(step: number): void {
    this.goToStep.emit(step);
  }

  submitForm(): void {
    if (!this._buttonDisabled) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendResiliationForm(this.regForm).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._hasPostError = true;
          this._buttonDisabled = false;
          this.changeDetectorRef.detectChanges();
        },
      );
    }
  }
}

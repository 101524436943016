import { Logger } from './logger';

export class SilentLogger implements Logger {
  info(_value: any, ..._rest: any[]): void {
    // does nothing
  }

  warn(_value: any, ..._rest: any[]): void {
    // does nothing
  }

  error(_value: any, ..._rest: any[]): void {
    // does nothing
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InterstitialChoicesSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { CTABtnLinkType } from '../../common-behaviors/button-link/button-link.component';

@Component({
  selector: 'slice-interstitial-choices',
  template: `
    <ng-container *ngIf="data">
      <div class="wrapper">
        <cb-fullwidth-background class="background desktop-only" [image]="data.image"></cb-fullwidth-background>
        <div class="content">
          <h1 class="title">{{ data.title }}</h1>
          <h2 class="subtitle">{{ data.subtitle }}</h2>
          <div class="btn-group">
            <cb-cta-btn-link
              *ngFor="let button of data.buttons; first as isFirst"
              [type]="getType(isFirst)"
              [button]="button"
              [sliceType]="data.type"
            >
            </cb-cta-btn-link>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-interstitial-choices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceInterstitialChoicesComponent implements ComponentWithData {
  @Input() data: InterstitialChoicesSlice;

  getType(isFirst: boolean): CTABtnLinkType | undefined {
    if (isFirst) {
      return CTABtnLinkType.Light;
    }
  }
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (!/[0-9]+/.test(phoneNumber) || phoneNumber.length % 2 !== 0) {
    return phoneNumber;
  }
  return phoneNumber
    .split('')
    .map((digit, index) => {
      if (index !== 0 && index % 2 === 0) {
        return ` ${digit}`;
      }
      return digit;
    })
    .join('');
}

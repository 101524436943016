import { Component } from '@angular/core';

const LCL_LOGO_BLEU_SIGNATURE = '/assets/images/lcl_logo_bleu_signature.svg';

@Component({
  selector: 'app-technical-error',
  template: `
    <header>
      <div class="logo-wrapper">
        <img [attr.src]="lclLogo" alt="logo LCL" class="logo" />
      </div>
    </header>
    <main>
      <h1>Cette page est momentanément indisponible</h1>
      <p>Nous mettons tout en œuvre pour la rétablir au plus vite. Veuillez nous excuser pour la gène occasionnée.</p>
      <section class="account-access">
        <p><strong>Accéder à mes comptes&nbsp;:</strong></p>
        <ul>
          <li>
            <a href="https://monespace.lcl.fr/connexion">Particulier</a>
          </li>
          <li>
            <a href="https://monespace.lcl.fr/connexion">Banque Privée</a>
          </li>
          <li>
            <a href="https://monespace.lcl.fr/connexion">Pro ACCESS</a>
          </li>
          <li>
            <a href="https://espacepro.secure.lcl.fr/outil/IQEN/Authentication/indexRedirect">LCL Espace Pro</a>
          </li>
          <li>
            <a href="https://entreprises.secure.lcl.fr/outil/IQEN/Authentication/indexRedirect">Entreprises</a>
          </li>
        </ul>
      </section>
    </main>
  `,
  styleUrls: ['./technical-error.component.scss'],
})
export class TechnicalErrorComponent {
  readonly lclLogo: string = LCL_LOGO_BLEU_SIGNATURE;
}

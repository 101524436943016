import { map } from 'rxjs/operators';

import { Component, ChangeDetectionStrategy, Input, Inject, ElementRef, AfterViewInit } from '@angular/core';

import { LandingPageBlockType1Slice, SliceType } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { ResponsiveService } from '../../../services/responsive.service';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';
import { CTABtnLinkType } from '../../common-behaviors/button-link/button-link.component';
import { extractTextContent } from '../../../helpers/rich-text.helpers';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { WINDOW } from '../../../services/window.provider';
import { triggerElementByClassName } from '../../../helpers/dom.helpers';
import { Observable } from 'rxjs';

@Component({
  selector: 'slice-landing-page-block-type1',
  template: `
    <div class="wrapper" [class.wrapper--default]="data.imageOnLeft" [class.wrapper--reverse]="!data.imageOnLeft">
      <div class="image">
        <img [src]="imageUrl$ | async" [alt]="data.image.alt" />
      </div>

      <div class="main">
        <h2 class="title" [innerHTML]="data.title"></h2>

        <div class="description" [innerHTML]="data.description"></div>

        <cb-cta-btn-link
          *ngIf="hasButtonLink"
          [button]="data.button"
          [type]="buttonType"
          [sliceType]="sliceType"
          [analyticsLabel]="analyticsLabel"
        >
        </cb-cta-btn-link>
      </div>
    </div>
  `,
  styleUrls: ['./slice-landing-page-block-type1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingPageBlockType1Component implements ComponentWithData, AfterViewInit {
  @Input() data: LandingPageBlockType1Slice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  sliceType: SliceType = 'landing_page_block_type1';

  buttonType = CTABtnLinkType.LandingPage;

  readonly imageUrl$: Observable<string>;

  get analyticsLabel(): string {
    return `${SLICE_TAGGING_NAME.landing_page_block_type1}_${extractTextContent(this.data.title)}_${
      this.data.button?.label
    }`;
  }

  get hasButtonLink(): boolean {
    return !!(this.data.button && this.data.button.url);
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly responsiveService: ResponsiveService,
    private readonly tagCommanderService: TagCommanderService,
    private elementRef: ElementRef,
  ) {
    this.imageUrl$ = this.responsiveService
      .isDesktopMatched()
      .pipe(
        map(isDesktop =>
          isDesktop ? this.data.image.url : this.data.image.mobile ? this.data.image.mobile.url : this.data.image.url,
        ),
      );
  }

  public ngAfterViewInit(): void {
    if (this.win) {
      triggerElementByClassName(this.win, this.elementRef, '.immo', this.handleAnchorClick);
    }
  }

  handleAnchorClick = event => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    // tslint:disable-next-line:max-line-length
    this.tagCommanderService.trackClickNavigationEvent(
      'LisereJaune_' + anchor.text.split(' ').join('_'),
      anchor.pathname,
    );
    window.location.href = anchor.href;
  };
}

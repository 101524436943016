import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[uiSlideshowSlide]',
})
export class UiSlideshowSlideDirective {
  constructor(readonly elementRef: ElementRef<HTMLElement>) {}

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get id(): string {
    return this.element.id;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { JsonLdModule } from '../../json-ld/json-ld.module';

import { AnalyticsModule } from '../../analytics/analytics.module';
import { AtomsModule } from '../../ui/atoms/atoms.module';

import { ArticleInfoBarComponent } from './article-info-bar/article-info-bar.component';
import { ArticleThumbnailComponent } from './article-thumbnail/article-thumbnail.component';
import { BlockWithBlurComponent } from './block-with-blur/block-with-blur.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CTAButtonLinkComponent } from './button-link/button-link.component';
import { DropDownComponent } from './dropdown/dropdown.component';
import { FullwidthBackgroundComponent } from './fullwidth-background/fullwidth-background.component';
import { LottieAnimationComponent } from './lottie-animation/lottie-animation.component';
import { MiniTitleComponent } from './mini-title/mini-title.component';
import { PhoneComponent } from './phone/phone.component';
import { PushBannerComponent } from './push-banner/push-banner.component';
import { ScalableImgComponent } from './scalable-img/scalable-img.component';
import { CTALinkComponent } from './link/link.component';
import { ResponsiveImgComponent } from './responsive-img/responsive-img.component';
import { OfferVignetteComponent } from './offer-vignette/offer-vignette.component';

@NgModule({
  imports: [CommonModule, AnalyticsModule, AtomsModule, InViewportModule, JsonLdModule],
  declarations: [
    FullwidthBackgroundComponent,
    MiniTitleComponent,
    BlockWithBlurComponent,
    CTAButtonLinkComponent,
    CTALinkComponent,
    ArticleInfoBarComponent,
    ArticleThumbnailComponent,
    BreadcrumbComponent,
    DropDownComponent,
    LottieAnimationComponent,
    PhoneComponent,
    PushBannerComponent,
    ScalableImgComponent,
    ResponsiveImgComponent,
    OfferVignetteComponent,
  ],
  exports: [
    ArticleInfoBarComponent,
    ArticleThumbnailComponent,
    BlockWithBlurComponent,
    BreadcrumbComponent,
    CTAButtonLinkComponent,
    CTALinkComponent,
    DropDownComponent,
    FullwidthBackgroundComponent,
    LottieAnimationComponent,
    MiniTitleComponent,
    PhoneComponent,
    PushBannerComponent,
    ScalableImgComponent,
    ResponsiveImgComponent,
    OfferVignetteComponent,
  ],
})
export class CommonBehaviorsModule {}

import { UiMoleculesModule } from '../../../ui/molecules/molecules.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SliceLandingPageEventHeroComponent } from './slice-landing-page-event-hero.component';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule, MatTabsModule, UiMoleculesModule],
  declarations: [SliceLandingPageEventHeroComponent],
  exports: [SliceLandingPageEventHeroComponent],
  entryComponents: [SliceLandingPageEventHeroComponent],
})
export class SliceLandingPageEventModule {}

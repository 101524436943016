import {
  Component,
  Input,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  forwardRef,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { DOMService } from '../../../../../services/dom.service';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { Subscription } from 'rxjs';
import { SliceFormsService } from '../../slice-forms.service';
import { AgenciesService } from '../../../../../services/agencies.service';
import { AgenciesData, AgenciesResult, Agency } from '../../../../../services/agencies.type';
import { AgencyFormComponent } from '../../../slice-agency/agency-form/agency-form.component';
import { RESILIATION_CLAIM_FORM } from '@repo/shared/form-schemas/resiliation-client';
import { Produit } from '@repo/shared';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SLICE_TAGGING_NAME, SLICE_TAGGING_SUBPATH } from '../../../../../common/constants/tagging';

@Component({
  selector: 'resiliation-claim',
  templateUrl: './resiliation-claim.component.html',
  styleUrls: ['../../slice-forms.scss', '../../slice-resiliation/resiliation-claim/resiliation-claim.component.scss'],
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RESILIATION_CLAIM_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ResiliationClaimComponent),
    },
  ],
})
export class ResiliationClaimComponent extends AbstractFormComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() regForm: FormGroup;
  @Input() iframeURL?: SafeResourceUrl | null;
  @Input() productSelected?: Produit;
  @Input() selectedIndex: number;

  _buttonDisabled = true;
  _hasPostError = false;
  selectError = false;

  agencies: Agency[] = [];
  showSearchAgenciesResults = false;
  @ViewChild(AgencyFormComponent) agencyFormComponent: AgencyFormComponent;
  selectedAgency: Agency;
  displayMandatoryAgencyMessage: boolean;
  private agencyQuerySubscription: Subscription;
  private identityProductSubscription: Subscription;

  tagName: string;
  tagSubPath = SLICE_TAGGING_SUBPATH.resiliation_form;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _resiliationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly agenciesService: AgenciesService,
    private readonly viewportScroller: ViewportScroller,
    private readonly sliceFormsService: SliceFormsService,
  ) {
    super(_resiliationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngAfterViewInit(): void {
    if (this.agencyFormComponent) this.setAgencyQueryFormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productSelected) {
      this.setOptionalReference(changes.productSelected.currentValue);
    }

    if (changes.selectedIndex) {
      this.tagName = `${SLICE_TAGGING_NAME.resiliation1}_bt_suivant_etape_${this.selectedIndex + 1}`;
    }
  }

  ngOnDestroy(): void {
    if (this.agencyQuerySubscription) {
      this.agencyQuerySubscription.unsubscribe();
    }

    if (this.identityProductSubscription) {
      this.identityProductSubscription.unsubscribe();
    }
  }

  previous(): void {
    this.myStepper.previous();
  }

  setAgencyQueryFormControl() {
    const claimGroup = this.regForm.get('claim') as FormGroup;
    const agencyQueryControl = this.agencyFormComponent.agencyForm.get('query') as FormControl;
    claimGroup.addControl('agencyQuery', this.agencyFormComponent.agencyForm.get('query') as AbstractControl);
    agencyQueryControl.setParent(claimGroup);
    agencyQueryControl.setValidators([
      this.sliceFormsService._getFieldValidator(
        (RESILIATION_CLAIM_FORM.properties as any)['agencyQuery'] as JSONSchema7,
        'agencyQuery',
      ),
    ]);

    this.regForm.get('claim')?.statusChanges.subscribe(status => {
      this._buttonDisabled = status === 'INVALID';
    });

    agencyQueryControl.updateValueAndValidity();

    this.agencyQuerySubscription = this.initAgencyQuerySubscription();
  }

  searchNearAgencies(agenciesData: AgenciesData): void {
    this.regForm.get('claim')?.patchValue({
      agencyQuery: this.agencyFormComponent.agencyForm.get('query')?.value,
    });

    // resetting selected agency value
    this.regForm.get('claim')?.patchValue({ agencyRef: '' });
    this.selectedAgency = (null as unknown) as Agency;

    this.agenciesService.searchNearAgencies(agenciesData.agenciesFilter).subscribe(agenciesResult => {
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult);
      this.showSearchAgenciesResults = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  initAgencyQuerySubscription(): Subscription {
    return this.agencyFormComponent.agencyForm.get('query')?.valueChanges.subscribe(value => {
      if (!value) {
        this.showSearchAgenciesResults = false;
      }
    }) as Subscription;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies
      .sort((a, b) => {
        if (a.distance > b.distance) {
          return 1;
        }

        if (a.distance < b.distance) {
          return -1;
        }

        return 0;
      })
      .slice(0, 10);
  }

  updateAgencyData(agency: Agency): void {
    this.selectedAgency = agency;
    this.displayMandatoryAgencyMessage = false;
    this.regForm.get('claim')?.patchValue({ agencyRef: ('0' + agency.id).slice(-5) });
    this.agencyFormComponent.agencyForm.patchValue({ query: agency.name.toUpperCase() });
  }

  setOptionalReference(product?: Produit): void {
    const claim = this.regForm.get('claim') as FormGroup;
    if (product && product.optional_reference) {
      claim.addControl('optionalIdentityField', new FormControl(null, [Validators.required]));
      claim.addControl('optionalIdentityFieldText', new FormControl(product.help_optional_reference));
    } else {
      if (claim.get('optionalIdentityField')) {
        claim.removeControl('optionalIdentityField');
        claim.removeControl('optionalIdentityFieldText');
      }
    }
    claim.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.regForm.get('claim')?.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm.get('claim') as FormGroup);
      if (!this.agencyFormComponent) {
        this.focusFirstInvalidFormField();
      } else {
        this.triggerFormFieldsStatus(this.agencyFormComponent.agencyForm as FormGroup);
        if (!this.regForm.get('claim.agencyRef')?.valid) {
          this.displayMandatoryAgencyMessage = true;
          const agencyQueryControl = this.elementRef.nativeElement.querySelector('app-agency-form');
          agencyQueryControl.focus({ preventScroll: true });
          this.domService.scrollTo(agencyQueryControl);
        } else {
          this.focusFirstInvalidFormField();
        }
      }
    }
  }
}

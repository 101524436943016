import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentService } from './cookie-consent.service';
import { AnalyticsOnDirective } from './analytics-on/analytics-on.directive';
import { AnalyticsOnNestedLinkDirective } from './analytics-on/analytics-on-nested-link.directive';

@NgModule({
  imports: [CommonModule],
  providers: [CookieConsentService],
  declarations: [AnalyticsOnDirective, AnalyticsOnNestedLinkDirective],
  exports: [AnalyticsOnDirective, AnalyticsOnNestedLinkDirective],
})
export class AnalyticsModule {}

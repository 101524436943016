import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AnalyticsModule } from './analytics/analytics.module';
import { CookieConsentService } from './analytics/cookie-consent.service';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { InteractService } from './services/interact.service';
import { PrismicService } from './services/prismic.service';
import { WindowLoadEventService } from './window-load-event.service';

@NgModule({
  imports: [AppModule, BrowserAnimationsModule, HttpClientModule, AnalyticsModule],
  providers: [PrismicService, InteractService, WindowLoadEventService],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor(cookieConsentService: CookieConsentService, prismicService: PrismicService) {
    cookieConsentService.cookieConsentRemover();
    prismicService.appendScripts();
  }
}

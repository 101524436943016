import { HeaderLoadScriptService } from './../headerLoadScript.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ButtonLinkData, Header, Link, Logos, MenuItem } from '@repo/shared';
import { LoggerService } from '../../../logger/logger.service';
import { Logger } from '../../../logger/logger';
import { HeaderButtonLink, HeaderButtonsLinks } from '../header';
import { HeaderService } from '../header.service';
import { ConfigService } from '../../../config.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-header',
  template: `
    <app-desktop-header
      class="desktop-only"
      [universes]="universes"
      [communications]="communications"
      [buttons]="buttons"
      [markets]="markets"
      [marketsMenuLabel]="marketsMenuLabel"
      [logo]="logo"
      [showH1Title]="showH1Title"
    >
    </app-desktop-header>
    <app-mobile-header
      class="mobile-only sticky"
      [universes]="universes"
      [communications]="communications"
      [buttons]="buttons"
      [markets]="markets"
      [marketsMenuLabel]="marketsMenuLabel"
      [mobileTitle]="mobileTitle"
      [logoMenuOpened]="logo && logo.mobileopen"
      [logoMenuClosed]="logo && logo.mobile"
    >
    </app-mobile-header>
  `,
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public buttons: HeaderButtonsLinks = {};
  public communications: MenuItem[] = [];
  public universes: MenuItem[] = [];
  public markets: Link[];
  public marketsMenuLabel: string;
  public mobileTitle: string;
  public logo: Logos | undefined;
  public showH1Title: Boolean;
  public colorButton3: string | undefined;

  private logger: Logger;

  constructor(
    private headerService: HeaderService,
    private loadScriptService: HeaderLoadScriptService,
    private loggerService: LoggerService,
    private configService: ConfigService,
    private readonly stateService: StateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.logger = this.loggerService.get(HeaderComponent.name);
  }

  ngOnInit(): void {
    this.headerService.getHeader().subscribe(
      (header: Header) => {
        this.communications = header.communications;
        this.universes = header.universes;
        this.markets = header.markets;
        this.marketsMenuLabel = header.marketsMenuLabel;
        this.mobileTitle = header.mobileTitle;
        this.logo = header.logo;
        const btnDataContact = {
          desktopBackgroundColor: header.colorButton1,
          desktopIcon: header.iconButton1,
          desktopLabelColor: header.labelColorButton1,
          mobileBackgroundColor: header.colorMobileButton1,
          mobileIcon: header.iconMobileButton1,
          mobileLabelColor: header.labelColorMobileButton1,
        } as ButtonLinkData;
        const btnDataOpenAccount = {
          desktopBackgroundColor: header.colorButton2,
          desktopIcon: header.iconButton2,
          desktopLabelColor: header.labelColorButton2,
          mobileBackgroundColor: header.colorMobileButton2,
          mobileIcon: header.iconMobileButton2,
          mobileLabelColor: header.labelColorMobileButton2,
        } as ButtonLinkData;
        const btnDataMyAccount = {
          desktopBackgroundColor: header.colorButton3,
          desktopIcon: header.iconButton3,
          desktopLabelColor: header.labelColorButton3,
          mobileBackgroundColor: header.colorMobileButton3,
          mobileIcon: header.iconMobileButton3,
          mobileLabelColor: header.labelColorMobileButton3,
        } as ButtonLinkData;

        if (header.contactButtonUrl) {
          header.contactButtonUrl.buttonLinkData = btnDataContact;
        }
        if (header.secondButtonUrl) {
          header.secondButtonUrl.buttonLinkData = btnDataOpenAccount;
        }
        const myAccount = this.getMyAccountLink(header.thirdButtonWording, header.marketUid as string);
        myAccount.buttonLinkData = btnDataMyAccount;
        this.buttons = {
          chat: header.contactButtonUrl,
          openAccount: header.secondButtonUrl,
          home: header.logoUrl as Link,
          myAccount: myAccount,
        };
        this.loadScriptService.loadScriptOnRouteChange();
      },
      error => {
        if (this.isFirstLoad()) {
          throw error;
        }
        this.logger.warn(`Could not get header: ${error}`);
      },
    );
  }

  ngAfterViewInit(): void {
    this.getHomeTitleDisplayCondition();
  }

  private getMyAccountLink(label: string, marketUid: string): HeaderButtonLink {
    const url = this.configService.getAccountUrl(marketUid);
    return { url, label };
  }

  private isFirstLoad(): boolean {
    return !this.communications.length && !this.universes.length && !this.markets;
  }

  private getHomeTitleDisplayCondition() {
    this.stateService.currentDisplayH1Value.subscribe(value => {
      this.showH1Title = value;
      this.changeDetectorRef.detectChanges();
    });
  }
}

import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

const LIGHT_BLUE = '#f5fcff';

@Directive({
  selector: '[fileDragDrop]',
})
export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private background = LIGHT_BLUE;
  @HostBinding('style.opacity') private opacity = '1';

  @HostListener('dragover', ['$event'])
  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = LIGHT_BLUE;
    this.opacity = '1';
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = LIGHT_BLUE;
    this.opacity = '1';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}

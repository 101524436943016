export function getCookie(cookies: string, name: string): string | null {
  const encodedName = encodeURIComponent(name);
  const regEx = getCookieRegEx(encodedName);
  const cookieParts = regEx.exec(cookies);

  if (cookieParts) {
    return decodeURIComponent(cookieParts[1]);
  }
  return null;
}

function getCookieRegEx(name: string): RegExp {
  // eslint-disable-next-line no-useless-escape
  const escapedName: string = name.replace(/([.\\+\-*:\/?!|^${}()\[\]])/g, '\\$1');
  return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
}

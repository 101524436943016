import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private homePageMarketStateSource = new BehaviorSubject<Boolean>(false);
  public currentDisplayH1Value = this.homePageMarketStateSource.asObservable();

  private state: State = {
    stickyHeaderBottomPosition: 0,
    homepageId: null,
    labelPage: null,
    pageUid: null,
    prevUid: null,
    pageUniverse: null,
    pageMarket: null,
    pushBannerClosedIds: [],
  };

  private state$ = new BehaviorSubject(this.state);

  get(): State {
    return this.state;
  }

  get$(): BehaviorSubject<State> {
    return this.state$;
  }

  update(update: Partial<State>): void {
    for (const key of Object.keys(update)) {
      this.state[key] = update[key];
    }

    this.state$.next(this.state);
  }

  updateIsHomePageMarketState(value: Boolean) {
    this.homePageMarketStateSource.next(value);
  }
}

export interface State {
  stickyHeaderBottomPosition: number;
  homepageId: string | null;
  labelPage: string | null;
  pageMarket: string | null;
  pageUid: string | null;
  prevUid: string | null;
  pageUniverse: string | null;
  pushBannerClosedIds: string[];
}

export enum PageMarket {
  Particulier = 'particulier',
  BanquePrivee = 'banque-privee',
}

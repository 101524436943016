import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';

import { LinkTarget, Page } from '@repo/shared';
import { SeoService } from '../../seo.service';
import { RouterService } from '../../../services/router.service';
import { TagCommanderService, TrackEventLabels } from '../../../services/tag-commander.service';
import { LoggerService } from '../../../logger/logger.service';
import { Logger } from '../../../logger/logger';
import { CookieService } from '../../../services/cookie.service';
import { StateService } from '../../../services/state.service';
import { MARKET_COOKIE_NAME } from '../../../common/constants/cookies';
import { PageService } from '../../page.service';
import { HttpStatusCode } from '../../../common/constants/http-status-code';
import { getMarketCookieFromMarketData } from '../../../common/constants/redirects';
import { WINDOW } from '../../../services/window.provider';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { Platform } from '@angular/cdk/platform';
import * as moment from 'moment';
import { triggerElementByClassName } from '../../../helpers/dom.helpers';

@Component({
  selector: 'cms-page',
  template: `
    <cb-breadcrumb *ngIf="breadcrumbShown" [breadcrumb]="page.data.breadcrumb"> </cb-breadcrumb>

    <dynamic-page *ngIf="page" [page]="currentPage"> </dynamic-page>
  `,
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements AfterViewInit, OnInit, OnDestroy, DoCheck {
  page: Page;
  pageUrl: string;
  private interstitialPage: Page;

  get breadcrumbShown(): boolean {
    return !!this.page && !this.interstitialPage && !this.pageService.isConversionFunnelPage(this.page);
  }

  get currentPage(): Page {
    return this.interstitialPage || this.page;
  }

  private routeData$: Subscription | null = null;
  private logger: Logger;

  private interstitialPage$: Observable<Page | null>;

  private readonly request?: Request;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly seoService: SeoService,
    private readonly routerService: RouterService,
    private readonly renderer: Renderer2,
    private readonly tagCommanderService: TagCommanderService,
    private readonly stateService: StateService,
    private readonly loggerService: LoggerService,
    private readonly cookieService: CookieService,
    private readonly pageService: PageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly platform: Platform,
    private elementRef: ElementRef,
    @Inject(WINDOW) private readonly win: Window,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.logger = this.loggerService.get(PageComponent.name);

    if (!platform.isBrowser) {
      this.request = this.injector.get(REQUEST);
    }
  }

  ngOnInit(): void {
    this.routeData$ = this.route.data.subscribe(
      async ({ page }: { page: Page }) => {
        if (!page) {
          this.logger.warn(`page: ${page} is falsy`);
          return;
        }

        const browserUrl = this.platform.isBrowser ? this.win.location.pathname : this.request?.path;
        this.pageUrl = browserUrl as string;

        if (!page.error && this.route.snapshot.params.uid && browserUrl !== page.url) {
          await this.routerService.redirect(page.url as string, HttpStatusCode.MovedPermanently);
        } else {
          this.setPage(page);
          this.changeDetectorRef.detectChanges();
        }
      },
      (error: Error) => {
        this.logger.error('route.data.subscribe threw exception', error);
      },
    );

    this.interstitialPage$ = this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.get('popin')),
      distinctUntilChanged(),
      concatMap(documentId => {
        return documentId ? this.pageService.getPageById(documentId) : observableOf(null);
      }),
    );
  }

  ngAfterViewInit(): void {
    this.interstitialPage$.subscribe(interstitialPage => {
      this.interstitialPage = (interstitialPage as unknown) as Page;
      this._handlePageSeo(this.page);
      this.changeDetectorRef.detectChanges();
      if (interstitialPage) {
        this.tagCommanderService.trackEventPage(TrackEventLabels.InterstitialStep1);
      }
    });
    if (this.win) {
      triggerElementByClassName(this.win, this.elementRef, '.hypertext', this.handleAnchorClick);
    }
  }

  ngOnDestroy(): void {
    if (this.routeData$) {
      this.routeData$.unsubscribe();
      this.routeData$ = null;
    }
  }

  ngDoCheck(): void {
    if (this.routerService.isIframePage()) {
      const nodes = this.document.getElementsByTagName('a');
      for (let i = 0; i < nodes.length; i++) {
        (nodes.item(i) as HTMLAnchorElement).target = LinkTarget.Blank;
      }
    }
  }

  private setPage(page: Page): void {
    this.page = page;
    this._handleTagCommanderScripts(page);
    if (!page.error) {
      this._storePagePath(page);
      // this._handleMarketCookie(page); RSI-1527 Remove HomePage Cookie
      this._handlePageSeo(page);
      this._handleIframePage();
    }
  }

  handleAnchorClick = event => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    this.tagCommanderService.trackClickActionEvent(anchor.innerHTML);
    if (anchor && anchor.target === '_blank') {
      window.open(anchor.href, anchor.target);
    } else {
      window.location.href = anchor.href;
    }
  };

  /*_handleMarketCookie(page: Page): void {  RSI-1527 Remove HomePage Cookie
    const cookieValue = getMarketCookieFromMarketData(page.data.market || page.uid);

    if (cookieValue) {
      const cookieOptions = {
        expires: moment().add(13, 'months').toDate(),
      };

      this.cookieService.set(MARKET_COOKIE_NAME, cookieValue, cookieOptions);
    } else {
      this.cookieService.delete(MARKET_COOKIE_NAME);
    }
  }*/

  _handlePageSeo(page: Page): void {
    if (page.data && page.data.seo) {
      if (
        this.routerService.isIframePage() ||
        this.routerService.isXtatcPage() ||
        this.routerService.isInterstitialPage()
      ) {
        page.data.seo.robots = 'noindex, nofollow';
        if (page.url) {
          page.data.seo.canonical = this.win.location.origin.concat(page.url);
        }
      } else {
        page.data.seo.robots = 'index, follow';
        page.data.seo.canonical = undefined;
      }

      this.seoService.addSeo(page.data.seo, this.renderer);
    }
  }

  _handleTagCommanderScripts(page: Page): void {
    if (this.platform.isBrowser) {
      this.tagCommanderService.updateConfig(page);
    }
  }

  _storePagePath(page: Page): void {
    this.stateService.update({
      labelPage:
        page.data.breadcrumb?.links && page.data.breadcrumb.links.length > 0
          ? page.data.breadcrumb.links[page.data.breadcrumb.links.length - 1].label
          : page.data.breadcrumb?.links[0].label,
      pageUniverse: page.data.universe,
      pageMarket: page.data.market,
      pageUid: page.uid,
      prevUid: this.stateService.get().pageUid,
    });
  }

  _handleIframePage(): void {
    if (this.routerService.isIframePage()) {
      this.document.body.classList.add('iframe');
    }
  }
}

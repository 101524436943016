import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import { Footer } from '@repo/shared';
import { BackUnavailableError } from '../../errors';

const footerQuery: DocumentNode = gql`
  query($lang: String!, $homepageId: String!) {
    footer(lang: $lang, homepageId: $homepageId) {
      socialNetworks {
        url
        label
        target
        image {
          dimensions {
            width
            height
          }
          alt
          url
        }
      }
      accordions {
        title
        accordionTextColor
        links {
          label
          url
          target
          image {
            dimensions {
              width
              height
            }
            alt
            url
          }
        }
      }
      bottomBarLinks {
        label
        url
        target
      }
      bottomBarTextColor
    }
  }
`;

@Injectable()
export class FooterService {
  private footer$: Subject<Footer> = new ReplaySubject(1);

  constructor(private apollo: Apollo) {}

  getFooter(): Observable<Footer> {
    return this.footer$.asObservable();
  }

  reload(homepageId: string): void {
    this.fetchFooter(homepageId).subscribe(
      (footer: Footer) => this.footer$.next(footer),
      error => this.footer$.error(error),
    );
  }

  private fetchFooter(homepageId: string): Observable<Footer> {
    return this.apollo
      .query<{ footer: Footer }>({
        query: footerQuery,
        variables: {
          lang: 'fr-fr',
          homepageId,
        },
      })
      .pipe(
        map(result => result.data.footer),
        catchError(error => throwError(new BackUnavailableError('Could not get footer', error))),
      );
  }
}

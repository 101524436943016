import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NewsroomItem, NewsroomSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { seeMoreAnimation } from '../../../common/constants/animations';
import { ResponsiveService } from '../../../services/responsive.service';
import { Subscription } from 'rxjs';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

export const NEWSROOM_ITEMS_MIN_COUNT_MOBILE = 4;
export const NEWSROOM_ITEMS_MAX_COUNT_MOBILE = 10;
export const NEWSROOM_ITEMS_MIN_COUNT_DESKTOP = 5;
export const NEWSROOM_ITEMS_MAX_COUNT_DESKTOP = NEWSROOM_ITEMS_MAX_COUNT_MOBILE;

@Component({
  selector: 'slice-newsroom',
  template: `
    <div class="content">
      <cb-mini-title [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <ul *ngIf="data.newsroomItems" class="thematic-container">
        <li
          class="thematic-item"
          *ngFor="let item of displayedItems; let i = index"
          [@enterIn]="getMoreItemsAnimationState(i)"
        >
          <a class="thematic-link" [attr.href]="item.link.url" [attr.target]="item.link.target">
            <div class="picture-wrapper">
              <cb-responsive-img class="picture" [mobileImage]="item.image?.mobile" [desktopImage]="item.image">
              </cb-responsive-img>
            </div>
            <span class="label">{{ item.title }}</span>
          </a>
        </li>
      </ul>
      <div *ngIf="isVisibleSeeMoreButton" class="see-more-container">
        <button class="btn--see-more" (click)="showAllItems()" analyticsOn [analyticsLabel]="analyticsLabel">
          <span class="label">{{ data.seeMoreLabel }}</span>
        </button>
      </div>
      <div *ngIf="isValidGoToNewsroomButton" class="go-to-newsroom-container">
        <cb-cta-btn-link class="go-to-newsroom-button" [button]="data.button" [sliceType]="data.type">
        </cb-cta-btn-link>
      </div>
    </div>
  `,
  styleUrls: ['./slice-newsroom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [seeMoreAnimation],
})
export class SliceNewsroomComponent implements SliceWithData<NewsroomSlice>, OnDestroy {
  @Input() data: NewsroomSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  _itemsMinCount: number;
  _itemsMaxCount: number;
  _itemCursor: number;

  get isValidGoToNewsroomButton(): boolean {
    return !!this.data.button && !!this.data.button.url && !!this.data.button.label;
  }

  get isVisibleSeeMoreButton(): boolean {
    const newsroomItems = this.data?.newsroomItems;

    return (
      !!newsroomItems && newsroomItems.length > this._itemsMinCount && this.displayedItems.length <= this._itemsMinCount
    );
  }

  get displayedItems(): NewsroomItem[] {
    return this.data?.newsroomItems?.slice(0, this._itemCursor) || [];
  }

  get analyticsLabel(): string {
    if (this.data.seeMoreLabel) {
      return `${SLICE_TAGGING_NAME[this.data.type]}_${this.data.seeMoreLabel}`;
    }
    return '';
  }

  private _subscription: Subscription;

  constructor(
    private readonly _responsiveService: ResponsiveService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
    this._subscription = this._responsiveService.isDesktopMatched().subscribe(matches => {
      this._renderOnDeviceTarget(matches);
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = (null as unknown) as Subscription;
    }
  }

  getMoreItemsAnimationState(itemIndex: number): 'visible' | 'new' {
    return itemIndex < this._itemsMinCount ? 'visible' : 'new';
  }

  showAllItems(): void {
    this._itemCursor = this._itemsMaxCount;
    this._changeDetectorRef.markForCheck();
  }

  private _renderOnDeviceTarget(isDesktop: boolean = false): void {
    if (isDesktop) {
      this._itemsMinCount = this._itemCursor = NEWSROOM_ITEMS_MIN_COUNT_DESKTOP;
      this._itemsMaxCount = NEWSROOM_ITEMS_MAX_COUNT_DESKTOP;
    } else {
      this._itemsMinCount = this._itemCursor = NEWSROOM_ITEMS_MIN_COUNT_MOBILE;
      this._itemsMaxCount = NEWSROOM_ITEMS_MAX_COUNT_MOBILE;
    }
  }
}

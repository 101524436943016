import { Component, Input } from '@angular/core';
import { QuoteSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

@Component({
  selector: 'slice-quote',
  template: `
    <ng-container *ngIf="data">
      <div class="wrapper">
        <div class="title" role="section-h1">{{ data.title }}</div>
        <div class="quote" [innerHTML]="data.richText"></div>
        <div class="signature">{{ data.signature }}</div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-quote.component.scss'],
})
export class SliceQuoteComponent implements SliceWithData<QuoteSlice> {
  @Input() data: QuoteSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;
}

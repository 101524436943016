import { map } from 'rxjs/operators';
import { ResponsiveService } from '../../../../services/responsive.service';
import { Component, Inject, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { WINDOW } from '../../../../services/window.provider';
import { LandingPageEventOffer } from '@repo/shared';
import { TagCommanderService } from '../../../../services/tag-commander.service';

@Component({
  selector: 'landing-page-event-offer',
  template: `
    <div *ngIf="content$ | async as content">
      <div class="credit-quote">
        {{ data.creditMention }}
      </div>
      <div class="offer-container">
        <div class="swiper-button-next desktop-only" (click)="onNext()"></div>
        <div class="swiper-button-prev desktop-only" (click)="onPrev()"></div>

        <img *ngIf="content.showImage" [src]="data.image.url" />

        <div class="offer-content">
          <h2 class="title" [innerHTML]="data.title"></h2>
          <h3 class="subtitle" [innerHTML]="data.subtitle"></h3>
          <div class="key-argument" *ngIf="data.description">
            <div class="description" [innerHTML]="data.description"></div>
          </div>
          <div class="contact-zone-container" *ngIf="data.contactArea">
            <div class="contact-zone">
              <div class="text" [innerHTML]="data.contactArea"></div>
              <a [routerLink]="" (click)="phoneClickHandler(data.contactAreaPhoneNumber)">
                <span class="numero">{{ data.contactAreaPhoneNumber }}*</span>
              </a>
            </div>
          </div>
          <div class="contact-quote" *ngIf="data.contactMention" [innerHTML]="data.contactMention"></div>
          <div class="contact-or" *ngIf="data.contactMention && (data.contactButtonLabel || data.contactLinkLabel)">
            ou
          </div>
          <div class="contact-button-link" *ngIf="data.contactButtonLabel">
            <cb-cta-btn-link
              [button]="{ label: data.contactButtonLabel, url: data.contactButtonURL }"
              (linkClicked)="contactButtonClickHandler(data.contactButtonLabel)"
            >
            </cb-cta-btn-link>
          </div>
          <div class="contact-link" *ngIf="data.contactLinkLabel">
            <a [routerLink]="" (click)="contactLinkClickHandler(data.contactLinkURL, data.contactLinkLabel)">{{
              data.contactLinkLabel
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="legal-text" *ngIf="data.exampleCreditTitle && data.exampleCreditText">
      <div class="legal-text-title">{{ data.exampleCreditTitle }}</div>
      <div class="legal-text-quote" [innerHTML]="data.exampleCreditText"></div>
    </div>
    <div class="legal-mention" [innerHTML]="data.legalMention"></div>
  `,
  styleUrls: ['./landing-page-event-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageEventOfferComponent {
  @Input()
  data: LandingPageEventOffer;

  @Input()
  index: number;

  @Output() triggerSwipe = new EventEmitter<number>();

  content$ = this.responsiveService.isDesktopMatched().pipe(
    map(isDesktop => ({
      showImage: !this.win || isDesktop,
    })),
  );

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly responsiveService: ResponsiveService,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  onNext(): void {
    this.triggerSwipe.emit(this.index + 1);
  }

  onPrev(): void {
    this.triggerSwipe.emit(this.index - 1);
  }

  contactButtonClickHandler(buttonLabel: string): void {
    this.tagCommanderService.trackClickActionEvent(this.data.title + '_CTA_' + buttonLabel);
  }

  phoneClickHandler(phoneNumber: string): void {
    this.tagCommanderService.trackClickActionEvent(this.data.title + '_PhoneNumber_' + phoneNumber);
    window.location.href = 'tel:+33' + phoneNumber.substring(1, phoneNumber.length + 1);
  }

  contactLinkClickHandler(url: string, label: string): void {
    this.tagCommanderService.trackClickActionEvent(this.data.title + '_CTA_' + label);
    window.location.href = url;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  GOOGLE_CHROME_DOWNLOAD_URL,
  MICROSOFT_EDGE_DOWNLOAD_URL,
  MOZILLA_FIREFOX_DOWNLOAD_URL,
} from './unsupported-browser.constants';

@Component({
  selector: 'unsupported-browser-modal',
  template: `
    <h2>Votre navigateur n’est pas compatible</h2>
    <p>Nous vous conseillons d’utiliser l’un de ces navigateurs pour profiter de toutes les fonctionnalités du site.</p>
    <ul>
      <li><a [href]="googleChromeUrl" class="google-chrome" target="_blank">Google Chrome</a></li>
      <li><a [href]="microsoftEdgeUrl" class="microsoft-edge" target="_blank">Microsoft Edge</a></li>
      <li><a [href]="mozillaFirefoxUrl" class="mozilla-firefox" target="_blank">Mozilla Firefox</a></li>
    </ul>
  `,
  styleUrls: ['./unsupported-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowserComponent {
  readonly googleChromeUrl = GOOGLE_CHROME_DOWNLOAD_URL;
  readonly microsoftEdgeUrl = MICROSOFT_EDGE_DOWNLOAD_URL;
  readonly mozillaFirefoxUrl = MOZILLA_FIREFOX_DOWNLOAD_URL;
}

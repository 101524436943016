import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobileHeaderClosedComponent } from './mobile-header-closed/mobile-header-closed.component';
import { MobileHeaderOpenedComponent } from './mobile-header-opened/mobile-header-opened.component';
import { MobileHeaderOpenedBottomMenuComponent } from './mobile-header-opened-bottom-menu/mobile-header-opened-bottom-menu.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { MobileHeaderMenuOpenedComponent } from './mobile-header-menu-opened/mobile-header-menu-opened.component';
import { AnalyticsModule } from '../../../analytics/analytics.module';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';

@NgModule({
  declarations: [
    MobileHeaderComponent,
    MobileHeaderClosedComponent,
    MobileHeaderOpenedComponent,
    MobileHeaderOpenedBottomMenuComponent,
    MobileHeaderMenuOpenedComponent,
  ],
  imports: [CommonModule, AnalyticsModule, CommonBehaviorsModule],
  exports: [MobileHeaderComponent],
})
export class MobileHeaderModule {}

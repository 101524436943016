export enum HomePageId {
  // Prismic RCT
  HOME_PAGE_BP_UID_RCT = 'XDyylRAAACoAjvJP',
  HOME_PAGE_PART_UID_RCT = 'W9MtZhIAACYAC1Nn',
  HOME_PAGE_PRO_UID_RCT = 'XDyyIBAAAC4Aju__',
  HOME_PAGE_ENT_UID_RCT = 'XNvTnDEAAEMActKn',
  HOME_PAGE_INSTIT_UID_RCT = 'W1nr0CEAAHeePrSA',
  // Prismic Pilote/Production
  HOME_PAGE_BP_UID_PRD = 'XHlbohEAACIADFrR',
  HOME_PAGE_PART_UID_PRD = 'XBj5qxEAACoAVabb',
  HOME_PAGE_PRO_UID_PRD = 'XH46eBEAACMAIcfN',
  HOME_PAGE_ENT_UID_PRD = 'XK9NnhEAACAAD2M5',
  HOME_PAGE_INSTIT_UID_PRD = 'W1nr0CEAAHeePrSA',
}

export enum PageMarketUid {
  PAGE_MARKET_BP_UID = 'banque-privee',
  PAGE_MARKET_PART_UID = 'particulier',
  PAGE_MARKET_PRO_UID = 'professionnel',
  PAGE_MARKET_ENT_UID = 'entreprise',
  PAGE_MARKET_INSTIT_UID = 'decouvrir-lcl',
}

export const HOMEPAGE_MARKET_UID = new Map<string, string>([
  [HomePageId.HOME_PAGE_BP_UID_RCT, PageMarketUid.PAGE_MARKET_BP_UID],
  [HomePageId.HOME_PAGE_PART_UID_RCT, PageMarketUid.PAGE_MARKET_PART_UID],
  [HomePageId.HOME_PAGE_PRO_UID_RCT, PageMarketUid.PAGE_MARKET_PRO_UID],
  [HomePageId.HOME_PAGE_ENT_UID_RCT, PageMarketUid.PAGE_MARKET_ENT_UID],
  [HomePageId.HOME_PAGE_INSTIT_UID_RCT, PageMarketUid.PAGE_MARKET_INSTIT_UID],
  [HomePageId.HOME_PAGE_BP_UID_PRD, PageMarketUid.PAGE_MARKET_BP_UID],
  [HomePageId.HOME_PAGE_PART_UID_PRD, PageMarketUid.PAGE_MARKET_PART_UID],
  [HomePageId.HOME_PAGE_PRO_UID_PRD, PageMarketUid.PAGE_MARKET_PRO_UID],
  [HomePageId.HOME_PAGE_ENT_UID_PRD, PageMarketUid.PAGE_MARKET_ENT_UID],
  [HomePageId.HOME_PAGE_INSTIT_UID_PRD, PageMarketUid.PAGE_MARKET_INSTIT_UID],
]);

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, ChangeDetectionStrategy, Input, OnInit, AfterViewInit } from '@angular/core';

import { LandingPageHeroSlice, Link, SliceType } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { ResponsiveService } from '../../../services/responsive.service';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';
import { CTABtnLinkType } from '../../common-behaviors/button-link/button-link.component';
import { HeaderService } from '../../header/header.service';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { CookieService } from '../../../services/cookie.service';
import { ConfigService } from '../../../config.service';

const PRET_IMMO_GEN_TAG = 'medgen';
const PRET_IMMO_GEN_PATH = 'pret-immobilier-media-offres-du-moment';

const PRET_IMMO_PRIMO_TAG = 'medprimo';
const PRET_IMMO_PRIMO_PATH = 'pret-immobilier-media-offre-taux-0';

const BLANK_COOKIE = 'blnk_landing_page_parameters';
const BLANK_PATH = 'compte-essentiel-pro';

@Component({
  selector: 'slice-landing-page-hero',
  template: `
    <div *ngIf="content$ | async as content" class="wrapper" [ngStyle]="{ backgroundImage: content.backgroundImage }">
      <div class="logo">
        <img [src]="content.logoUrl" />
      </div>

      <div class="main">
        <h1 class="title">{{ data.title }}</h1>

        <h2 class="subtitle" [innerHTML]="data.subtitle"></h2>

        <cb-cta-btn-link
          *ngIf="hasSecondaryButtonLink"
          class="btn"
          [button]="data.buttonSecondary"
          [type]="buttonSecondaryType"
          [sliceType]="sliceType"
          [analyticsLabel]="getAnalyticsLabel(data.buttonSecondary)"
          (linkClicked)="clickHandler($event, data.buttonSecondary.url)"
        >
        </cb-cta-btn-link>

        <cb-cta-btn-link
          class="btn"
          [button]="data.buttonPrimary"
          [type]="buttonPrimaryType"
          [sliceType]="sliceType"
          [analyticsLabel]="getAnalyticsLabel(data.buttonPrimary)"
          (linkClicked)="clickHandler($event, data.buttonPrimary.url)"
        >
        </cb-cta-btn-link>
      </div>
    </div>
  `,
  styleUrls: ['./slice-landing-page-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingPageHeroComponent implements ComponentWithData, AfterViewInit {
  @Input() data: LandingPageHeroSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  sliceType: SliceType = 'landing_page_hero';
  cookieDomain: string;

  buttonSecondaryType = CTABtnLinkType.Light;
  buttonPrimaryType = CTABtnLinkType.LandingPage;

  readonly content$: Observable<{
    isMobile: boolean;
    backgroundImage: string;
    logoUrl?: string;
  }>;

  get hasSecondaryButtonLink(): boolean {
    return !!(this.data.buttonSecondary && this.data.buttonSecondary.url);
  }

  constructor(
    private readonly headerService: HeaderService,
    private readonly responsiveService: ResponsiveService,
    private readonly configService: ConfigService,
    public readonly tagCommanderService: TagCommanderService,
    public readonly cookieService: CookieService,
  ) {
    this.cookieDomain = this.configService.get<string>('COOKIE_DOMAIN');

    this.content$ = combineLatest([this.responsiveService.isDesktopMatched(), this.headerService.getHeader()]).pipe(
      map(([isDesktop, header]) => ({
        isMobile: !isDesktop,
        backgroundImage: `url("${isDesktop ? this.data.image.url : this.data.image.mobile?.url}")`,
        logoUrl: isDesktop ? header.logo?.url : header.logo?.mobile?.url,
      })),
    );
  }
  ngAfterViewInit(): void {
    if (typeof window !== 'undefined' && this.isBlank(window)) {
      this.initCookieBlank();
    }
  }

  getAnalyticsLabel(link: Link): string {
    return `${SLICE_TAGGING_NAME.landing_page_hero}_${link.label}`;
  }

  clickHandler(_event: Event, buttonUrl: string): void {
    if (this.isTelButton(buttonUrl)) {
      if (this.isImmoGenerique()) {
        this.tagCommanderService.trackHavasClickTag(PRET_IMMO_GEN_TAG);
      } else if (this.isImmoPrimo()) {
        this.tagCommanderService.trackHavasClickTag(PRET_IMMO_PRIMO_TAG);
      }
    }
    if (this.isBlank(window)) {
      this.initCookieBlank();
    }
  }

  private isTelButton(buttonUrl: string): boolean {
    return buttonUrl.includes('tel');
  }

  private isImmoGenerique(): boolean {
    return window.location.pathname.includes(PRET_IMMO_GEN_PATH);
  }

  private isImmoPrimo(): boolean {
    return window.location.pathname.includes(PRET_IMMO_PRIMO_PATH);
  }

  isBlank(window: Window): boolean {
    return window.location.pathname.includes(BLANK_PATH);
  }

  initCookieBlank(): void {
    const urltoget = new URL(window.location.href);
    const cookie = this.cookieService.get(BLANK_COOKIE);
    const parameters = cookie ? JSON.parse(cookie) : {};

    urltoget.searchParams.forEach((value, key) => {
      parameters[key] = value;
    });
    parameters.referrer = document.referrer;
    this.setCookieBlank(BLANK_COOKIE, parameters);
  }

  setCookieBlank(cookieName: string, parameters: any): void {
    this.cookieService.set(cookieName, JSON.stringify(parameters), {
      domain: this.cookieDomain,
    });
  }
}

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[uiSlideshowAnchor]',
})
export class UiSlideshowAnchorDirective {
  @Output() clicked = new EventEmitter<UiSlideshowAnchorDirective>();

  get element(): HTMLAnchorElement {
    return this.elementRef.nativeElement;
  }

  constructor(readonly elementRef: ElementRef<HTMLAnchorElement>) {}

  getTargetId(): string | null {
    const hashIndex = this.element.href ? this.element.href.lastIndexOf('#') : -1;

    return hashIndex !== -1 ? this.element.href.slice(hashIndex + 1) : null;
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    event.preventDefault();

    this.clicked.emit(this);
  }

  onClick(callback: (anchor: UiSlideshowAnchorDirective) => void): Subscription {
    return this.clicked.subscribe(callback);
  }
}

import { Component, Input } from '@angular/core';
import { StepperSlice } from '@repo/shared';

@Component({
  selector: 'slice-stepper',
  template: `
    <div class="wrapper content">
      <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <h2 *ngIf="data.subtitle">{{ data.subtitle }}</h2>
      <div class="steps">
        <div class="step" *ngFor="let step of data.steps; let index = index">
          <div>
            <span class="number">{{ index + 1 }}</span>
          </div>
          <div>
            <span class="description">{{ step }}</span>
          </div>
        </div>
      </div>
      <cb-cta-btn-link class="cta" *ngIf="data.button" [button]="data.button" [sliceType]="data.type"></cb-cta-btn-link>
    </div>
  `,
  styleUrls: ['./slice-stepper.component.scss'],
})
export class SliceStepperComponent {
  @Input() data: StepperSlice = {
    type: 'stepper',
    steps: [],
  };
  @Input() isFirstSlice: boolean;
}

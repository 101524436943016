import { NestedError } from './nested-error';

export enum ErrorType {
  PageNotFound = 'PageNotFoundError',
  BackUnavailable = 'BackUnavailableError',
}

export class BaseError extends NestedError {
  type: ErrorType;

  constructor(message: string, errorType: ErrorType, cause: Error) {
    super(message, cause);
    this.name = errorType as string;
    this.type = errorType;
  }
}

export class PageNotFoundError extends BaseError {
  constructor(cause?: Error) {
    super('Page not found', ErrorType.PageNotFound, (cause as unknown) as Error);
  }
}

export class BackUnavailableError extends BaseError {
  constructor(message: string, cause?: Error) {
    super(message, ErrorType.BackUnavailable, (cause as unknown) as Error);
  }
}

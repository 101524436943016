import { OverlayRef } from '@angular/cdk/overlay';
import { ModalConfig } from './modal-config';
import { ModalContent } from './modal.types';

export class ModalRef<T = any> {
  private readonly _config: ModalConfig;

  constructor(private readonly _overlayRef: OverlayRef, private readonly _content: ModalContent<T>) {
    const config: ModalConfig = this._overlayRef.getConfig();
    if (config) {
      this._config = config;

      if (config.disposeOnBackdropClick) {
        this._overlayRef.backdropClick().subscribe(() => this.close());
      }
    }
  }

  getOverlay(): OverlayRef {
    return this._overlayRef;
  }

  getContent(): ModalContent<T> {
    return this._content;
  }

  getConfig(): ModalConfig {
    return this._config;
  }

  close(): void {
    this._overlayRef.dispose();
  }
}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  Inject,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import {
  ASSISTANCE_EQUIPEMENTS_FORM,
  BROWSER_TYPES_OPTIONS,
  DEVICE_TYPES_OPTIONS,
  OS_TYPES_OPTIONS,
} from '@repo/shared/form-schemas/assistance-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'assistance-equipements',
  template: `
    <form class="form" [formGroup]="regForm" novalidate>
      <div id="assistance-equipements">
        <h2>Votre équipement</h2>
        <div formFieldControl class="form-field-control--large-size">
          <label>
            Matériel utilisé*
            <ng-select
              formControlName="deviceType"
              [clearable]="false"
              [searchable]="false"
              (change)="handleDeviceType()"
            >
              <ng-option *ngFor="let option of deviceTypesOptions; index as i" [value]="i">{{ option }} </ng-option>
            </ng-select>
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--large-size">
          <label>
            Système d'exploitation*
            <ng-select formControlName="osType" [clearable]="false" [searchable]="false">
              <ng-option *ngFor="let option of osTypesOptions; index as i" [disabled]="option.disabled" [value]="i"
                >{{ option.label }}
              </ng-option>
            </ng-select>
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--large-size">
          <label>
            Navigateur*
            <ng-select formControlName="browserType" [clearable]="false" [searchable]="false">
              <ng-option *ngFor="let option of browserTypesOptions; index as i" [value]="i">{{ option }} </ng-option>
            </ng-select>
          </label>
          <form-field-error></form-field-error>
        </div>

        <div formFieldControl class="form-field-control--half-size">
          <label>
            Version du navigateur
            <input formControlName="browserVersion" formFieldInput type="text" />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label>
            Fournisseur d'accès
            <input formControlName="accessProvider" formFieldInput type="text" />
          </label>
          <form-field-error></form-field-error>
        </div>

        <p class="mandatory-field-text">*Champs obligatoires</p>
        <p>
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
          disponible sur la page d'accueil du site.
        </p>
      </div>
      <div class="button-wrapper">
        <cb-cta-btn-link
          class="previous"
          (click)="previous()"
          [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
          [buttonWithoutLink]="true"
          [sliceType]="'assistance_form'"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <cb-cta-btn-link
          class="next"
          (click)="onSubmit()"
          [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
          [buttonWithoutLink]="true"
          [sliceType]="'assistance_form'"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: ASSISTANCE_EQUIPEMENTS_FORM,
    },
  ],
})
export class AssistanceEquipementsComponent extends AbstractFormComponent implements OnInit {
  @Input() regForm: FormGroup;

  browserTypesOptions = BROWSER_TYPES_OPTIONS;
  deviceTypesOptions = DEVICE_TYPES_OPTIONS;
  osTypesOptions = OS_TYPES_OPTIONS;
  _buttonDisabled = false;
  _hasPostError = false;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _assistanceFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_assistanceFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngOnInit(): void {
    if (this.regForm) {
      this.regForm.patchValue({ browserType: 0, deviceType: 0, osType: 0 });
    }
  }

  handleDeviceType(): void {
    const isDesktopDevice = this.regForm.value.deviceType === 0;
    this.regForm.get('osType')?.setValue(isDesktopDevice ? 0 : 2);
    this.osTypesOptions[0].disabled = this.osTypesOptions[1].disabled = !isDesktopDevice;
    this.osTypesOptions[2].disabled = this.osTypesOptions[3].disabled = isDesktopDevice;
  }

  previous(): void {
    this.myStepper.previous();
  }

  onSubmit(): void {
    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';

import { UiExpansionPanelComponent, UiExpansionPanelState } from './expansion-panel.component';
import { uiExpansionPanelAnimations } from './expansion-panel.animations';

/**
 * `<ui-expansion-header>`
 *
 * This component can be used to display expansion panels header.
 *
 * See expansion-panel.md for usage details.
 */
@Component({
  selector: 'ui-expansion-header',
  exportAs: 'uiExpansionHeader',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [uiExpansionPanelAnimations.indicatorRotate],
  template: `
    <div class="ui-expansion-header-title">
      <ng-content></ng-content>
    </div>
    <div class="ui-expansion-header-indicator" [@indicatorRotate]="panel && panel.expandedState">
      <i class="ui-expansion-header-indicator-arrow"></i>
    </div>
  `,
})
export class UiExpansionHeaderComponent implements OnDestroy {
  @HostBinding('class.ui-expansion-header') hostClass = true;
  @HostBinding('attr.role') hostRole = 'button';
  @HostBinding('attr.id') get id(): string {
    return this.panel && this.panel._headerId;
  }
  @HostBinding('attr.aria-controls') get ariaControls(): string {
    return this.panel && this.panel.id;
  }
  @HostBinding('attr.aria-expanded') get ariaExpanded(): any {
    return this.panel && this.panel.expanded;
  }
  @HostBinding('style.cursor') get styleCursor(): 'pointer' | 'inherit' {
    return this.panel ? 'pointer' : 'inherit';
  }

  get expanded(): any {
    return this.panel.expanded;
  }

  get expandedState(): UiExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  private readonly _parentChangeSubscription = Subscription.EMPTY;

  constructor(
    public panel: UiExpansionPanelComponent,
    private _element: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    // Since the toggle state depends on an @Input on the panel, we
    // need to  subscribe and trigger change detection manually.
    this._parentChangeSubscription = merge(panel.opened, panel.closed).subscribe(() =>
      this._changeDetectorRef.markForCheck(),
    );
  }

  ngOnDestroy(): void {
    if (this._parentChangeSubscription) {
      this._parentChangeSubscription.unsubscribe();
    }
  }

  @HostListener('click')
  onClick(): void {
    this.panel.toggle();
  }
}

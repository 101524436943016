import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Inject, Injectable, Injector, Optional } from '@angular/core';

import { ModalConfig } from './modal-config';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { ModalRef } from './modal-ref';
import { MODAL_CONFIG_DEFAULT } from './modal.providers';
import { ModalContent } from './modal.types';

@Injectable()
export class ModalService {
  constructor(
    @Optional() @Inject(MODAL_CONFIG_DEFAULT) private readonly _modalConfigDefault: ModalConfig,
    private readonly _overlay: Overlay,
    private readonly _injector: Injector,
  ) {}

  open<T>(content: ModalContent<T>, config?: ModalConfig, injectorTokens?: WeakMap<any, any>): OverlayRef {
    const modalConfig: ModalConfig = this._computeConfig(config);
    const overlayRef: OverlayRef = this._overlay.create(modalConfig);
    const containerInjector = this._createInjector(content, overlayRef, injectorTokens);
    const containerPortal = new ComponentPortal(ModalContainerComponent, null, containerInjector);

    overlayRef.attach(containerPortal);

    return overlayRef;
  }

  private _createInjector<T>(
    content: ModalContent<T>,
    overlayRef: OverlayRef,
    injectorTokens: WeakMap<any, any> = new WeakMap(),
  ): Injector {
    const modalRef: ModalRef = new ModalRef(overlayRef, content);

    injectorTokens.set(ModalRef, modalRef);

    return new PortalInjector(this._injector, injectorTokens);
  }

  private _computeConfig(config?: ModalConfig): ModalConfig {
    return {
      disposeOnNavigation: true,
      hasBackdrop: true,
      hasCloseCross: false,
      panelClass: 'cdk-modal-pane',
      positionStrategy: this._overlay.position().global().centerHorizontally(),
      scrollStrategy: this._overlay.scrollStrategies.block(),
      ...this._modalConfigDefault,
      ...config,
    };
  }
}

import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Self,
  HostListener,
  Directive,
  OnInit,
  OnDestroy,
  Input,
  Inject,
  HostBinding,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../helpers/date.adapter';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JSONSchema7 } from 'json-schema';
import { AbstractFormComponent } from '../../../cms/slices/slice-forms/abstract-form-component';
import { FORM_COMPONENT } from '../../../../tokens';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

const IC_CALENDAR = '/assets/images/ic_calendar_miniature.svg';

@Directive({
  selector: 'datepicker[formFieldDatepicker]',
  exportAs: 'formFieldDatepicker',
})
export class DatepickerDirective implements OnInit, OnDestroy {
  @Input() formControlName: string;

  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blurred: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') class = 'datepicker';

  get minlength(): number {
    return this._minlength;
  }

  // See HTML5 spec
  @Input('minlength')
  @HostBinding('attr.minlength')
  set minlength(value: number) {
    if (value) {
      this._minlength = coerceNumberProperty(value);
    }
  }

  get maxlength(): number {
    return this._maxlength;
  }

  @Input('maxlength')
  @HostBinding('attr.maxlength')
  set maxlength(value: number) {
    if (value) {
      this._maxlength = coerceNumberProperty(value);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input('required')
  @HostBinding('attr.required')
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  get model(): string {
    return this.ngControl.value;
  }

  private _subscription: Subscription = new Subscription();
  private _minlength: number;
  private _maxlength: number;
  private _required = false;
  private _validationSchema: JSONSchema7;

  constructor(
    @Inject(FORM_COMPONENT) public readonly _parentSliceFormComponent: AbstractFormComponent,
    @Self() public readonly ngControl: NgControl,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('focus') onFocusEvent(): void {
    this.focused.emit(true);
  }

  @HostListener('blur') onBlurEvent(): void {
    this.onTouched();
    this.blurred.emit(true);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    if (this._parentSliceFormComponent) {
      this._validationSchema = this._parentSliceFormComponent.getFormFieldValidationSchema(this.formControlName);
    } else {
      throw Error(
        'You must to create a token injection to define which' +
          ' class implements the abstract SliceFormComponent class.',
      );
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }

  _bindToAttributes(): void {
    const {
      maxLength,
      minLength,
      // pattern,
    } = this.validationSchema;
    this.maxlength = maxLength as number;
    if ((minLength as number) > 0) {
      this.minlength = minLength as number;
      this.required = true;
    }

    this._changeDetectorRef.detectChanges();
  }
} // tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'datepicker',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="form-group">
      <div class="input_container">
        <ng-container *ngIf="timeDirection === 'before'">
          <input
            class="datepicker-before"
            matInput
            readonly
            [matDatepicker]="myDatepicker"
            name="date"
            placeholder="Choisir une date"
            (dateChange)="setValue($event.target.value)"
            [max]="futureDays"
          />
        </ng-container>
        <ng-container *ngIf="timeDirection === 'after'">
          <input
            class="datepicker-after"
            matInput
            readonly
            [matDatepicker]="myDatepicker"
            name="date"
            placeholder="Choisir une date"
            (dateChange)="setValue($event.target.value)"
            [min]="futureDays"
          />
        </ng-container>
        <ng-container *ngIf="timeDirection === 'any'">
          <input
            class="datepicker-any"
            matInput
            readonly
            [matDatepicker]="myDatepicker"
            name="date"
            placeholder="Choisir une date"
            (dateChange)="setValue($event.target.value)"
          />
        </ng-container>
        <div class="datepicker-container">
          <mat-datepicker-toggle matSuffix [for]="myDatepicker">
            <mat-icon matDatepickerToggleIcon>
              <img [src]="calendarIcon" />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #myDatepicker [startAt]="date"> </mat-datepicker>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./_datepicker.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit {
  constructor(private _dateAdapter: DateAdapter<any>) {}
  futureDays: Date | null = null;
  set value(val: string) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  date = new Date(new Date().getTime());

  // before => toutes les dates avant ajuourd'hui
  // after =>  toutes les dates avant aujourd'hui
  // any =>  toutes les dates possibles
  @Input() timeDirection?: 'before' | 'after' | 'any' = 'before';

  @Output()
  valueChanged = new EventEmitter<string>();

  calendarIcon: any = IC_CALENDAR;
  val = '';

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit() {
    this.futureDays = this.timeDirection === 'any' ? null : new Date();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setValue(value: Date): void {
    const date = this._dateAdapter.format(value, 'input');
    this.onTouch();
    this.onChange(date);
    this.valueChanged.emit(date);
  }
}

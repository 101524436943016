import {
  Component,
  Output,
  EventEmitter,
  Directive,
  Inject,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Self,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { JSONSchema7 } from 'json-schema';
import { Subscription } from 'rxjs';

import { AbstractFormComponent } from '../../../cms/slices/slice-forms/abstract-form-component';
import { FORM_COMPONENT } from '../../../../tokens';
import { to2digit } from '../../../helpers/date.adapter';

import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

export function getTimepickerConfig(): TimepickerConfig {
  return Object.assign(new TimepickerConfig(), {
    hourStep: 1,
    minuteStep: 1,
    showMeridian: false,
    readonlyInput: false,
    mousewheel: true,
    showMinutes: true,
    showSeconds: false,
    allowArrowKeys: false,
    showSpinners: false,
  });
}

@Directive({
  selector: 'form-field-timepicker[formFieldTimepicker]',
  exportAs: 'formFieldTimepicker',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-timepicker',
  },
})
export class FormFieldTimepickerDirective implements OnInit, OnDestroy {
  @Input() formControlName: string;

  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blurred: EventEmitter<boolean> = new EventEmitter<boolean>();

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  get model(): string {
    return this.ngControl.value;
  }

  private _subscription: Subscription = new Subscription();

  private _validationSchema: JSONSchema7;

  constructor(
    @Inject(FORM_COMPONENT) public readonly _parentSliceFormComponent: AbstractFormComponent,
    @Self() public readonly ngControl: NgControl,
  ) {}

  @HostListener('focus') onFocusEvent(): void {
    this.focused.emit(true);
  }

  @HostListener('blur') onBlurEvent(): void {
    this.onTouched();
    this.blurred.emit(true);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    if (this._parentSliceFormComponent) {
      this._validationSchema = this._parentSliceFormComponent.getFormFieldValidationSchema(this.formControlName);
    } else {
      throw Error(
        'You must to create a token injection to define which' +
          ' class implements the abstract SliceFormComponent class.',
      );
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }
}

@Component({
  selector: 'form-field-timepicker',
  template: `
    <div class="form-group">
      <timepicker [(ngModel)]="value" (keydown)="detectTextErasure($event)" (select)="detectTextSelection()">
      </timepicker>
    </div>
  `,
  styleUrls: ['./form-field-timepicker.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormFieldTimepickerComponent,
    },
    {
      provide: TimepickerConfig,
      useFactory: getTimepickerConfig,
    },
  ],
})
export class FormFieldTimepickerComponent implements ControlValueAccessor {
  private _value: Date = (null as unknown) as Date;
  private _deletingKeystrokes = 0;
  private _textSelection = 0;
  private _emittedValue: string = (null as unknown) as string;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  change = new EventEmitter<string>();

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;

    value
      ? (this._emittedValue = `${to2digit(this._value.getHours())}:${to2digit(this._value.getMinutes())}`)
      : (this._emittedValue = (null as unknown) as string);

    if (this._deletingKeystrokes === 4) {
      this.resetComponentValue();
    } else {
      if (this._textSelection === 1 && this._deletingKeystrokes === 1) {
        this.resetComponentValue();
      } else if (this._textSelection === 2 && this._deletingKeystrokes === 2) {
        this.resetComponentValue();
      } else if (this._textSelection === 0 && this._deletingKeystrokes === 2) {
        this.resetComponentValue();
      } else if (this._textSelection === 1 && this._deletingKeystrokes === 3) {
        this.resetComponentValue();
      } else if (this._textSelection === 2 && this._deletingKeystrokes === 3) {
        this.resetComponentValue();
      } else if (this._textSelection === 3 && this._deletingKeystrokes === 2) {
        this.resetComponentValue();
      }
    }

    this.onTouch(this._emittedValue);
    this.onChange(this._emittedValue);
    this.change.emit(this._emittedValue as string);
  }

  detectTextErasure(event: any): void {
    // Explanation : the ngx-bootstrap timepicker component always return 'null' when
    // the input is empty AND when the input is incorrect
    // ==> just relying on the input value, there is no way of knowing what error message to display
    // ('Champ obligatoire' or 'Format invalide').
    // Detecting if the user is deleting data in the input zone was the only way I could think of to make a distinction
    // (keycode 46 is for Delete and 8 is for Backspace)
    if (event.keyCode === 8 || event.keyCode === 46) {
      this._deletingKeystrokes++;
    } else {
      this.resetUserActions();
    }
  }

  detectTextSelection(): void {
    this._textSelection++;
  }

  resetUserActions(): void {
    this._deletingKeystrokes = 0;
    this._textSelection = 0;
  }

  resetComponentValue(): void {
    this.resetUserActions();
    this._emittedValue = '';
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

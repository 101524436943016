import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'zone-promo',
  template: `
    <div class="promo-container" [ngStyle]="{ 'background-color': data.couleur_fond_zone_2 }">
      <div class="titre-promo clamp-single-line">
        {{ data.titre_promo }}
      </div>
      <div class="texte-promo clamp-single-line">
        {{ data.texte_promo }}
      </div>
    </div>
  `,
  styleUrls: ['./zone-promo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZonePromoComponent {
  @Input() data: any;
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  distanceTo(point2: Point): number {
    return Math.sqrt(Math.pow(point2.x - this.x, 2) + Math.pow(point2.y - this.y, 2));
  }

  angleBetween(point2: Point): number {
    return Math.atan2(point2.x - this.x, point2.y - this.y);
  }
}

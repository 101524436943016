import { Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { PdfLinksSlice } from '@repo/shared';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

@Component({
  selector: 'slice-pdf-links',
  template: `
    <ng-container *ngIf="data && data.items && data.items.length !== 0">
      <div class="pdf-links-group" [ngClass]="data.alignment">
        <div *ngFor="let item of data.items">
          <span *ngIf="item.type === 'document'" class="ic-pdf"></span>
          <a
            [attr.href]="item.url"
            [attr.target]="item.target"
            analyticsOn
            [analyticsLabel]="getAnalyticsLabel(item.label)"
            >{{ item.label }}</a
          >
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-pdf-links.component.scss'],
})
export class SlicePdfLinksComponent implements SliceWithData<PdfLinksSlice> {
  @Input() data: PdfLinksSlice;

  getAnalyticsLabel(linkLabel: string): string {
    return SLICE_TAGGING_NAME[this.data.type] + '_' + linkLabel;
  }
}

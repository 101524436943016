import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Page } from '@repo/shared';
import { ConfigService } from '../../../config.service';
import { StateService } from '../../../services/state.service';
import { HeaderService } from '../../header/header.service';
import { FooterService } from '../../footer/footer.service';

export const INSTERSTITAL_SLICE_BACKGROUND_IMAGE = '/assets/images/professionals-connection-interstitial-hero.jpg';

export const PROFESSIONAL_HOMEPAGE_ID = 'PROFESSIONAL_HOMEPAGE_ID';
export const LCLA_CONNECTION_URL = 'CONNECTION_URL_PROFESSIONNEL';
export const ESPACEPRO_CONNECTION_URL = 'CONNECTION_URL_ESPACEPRO';

export const INSTERSTITIAL_SLICE_TITLE = 'ESPACE CLIENT EN LIGNE';
export const INSTERSTITIAL_SLICE_SUBTITLE = 'Choisir mon espace dédié';
export const ESPACEPRO_BUTTON_LABEL = 'Accéder à LCL Espace Pro';
export const PRO_BUTTON_LABEL = 'Accéder à mes comptes';

@Injectable()
export class ProfessionalsConnectionResolverService implements Resolve<Page> {
  private homepageId: string;

  constructor(
    private readonly configService: ConfigService,
    private readonly stateService: StateService,
    private readonly headerService: HeaderService,
    private readonly footerService: FooterService,
  ) {
    this.homepageId = this.configService.get<string>(PROFESSIONAL_HOMEPAGE_ID);
  }

  resolve(): Page {
    this.loadHeaderFooterWhenFirstVisit(this.homepageId);
    return ({
      id: '',
      uid: 'choisir-espace',
      type: 'interstitial',
      data: {
        market: 'professionnel',
        homepageId: this.homepageId,
        seo: {
          robots: 'noindex, nofollow',
        },
        pageContent: {
          type: 'DynamicPage',
          body: [
            {
              type: 'interstitial_choices',
              title: INSTERSTITIAL_SLICE_TITLE,
              subtitle: INSTERSTITIAL_SLICE_SUBTITLE,
              buttons: [
                { label: ESPACEPRO_BUTTON_LABEL, url: this.configService.get<string>(ESPACEPRO_CONNECTION_URL) },
                { label: PRO_BUTTON_LABEL, url: this.configService.get<string>(LCLA_CONNECTION_URL) },
              ],
              image: { url: INSTERSTITAL_SLICE_BACKGROUND_IMAGE },
            },
          ],
        },
      },
    } as {}) as Page;
  }

  private loadHeaderFooterWhenFirstVisit(homepageId: string): void {
    if (homepageId !== this.stateService.get().homepageId) {
      this.headerService.reload(homepageId);
      this.footerService.reload(homepageId);
      this.stateService.update({ homepageId });
    }
  }
}

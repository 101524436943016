import {
  Component,
  Input,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  Inject,
  forwardRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { RECLAMATION_CLAIM_FORM } from '@repo/shared/form-schemas/reclamation-client';
import { DOMService } from '../../../../../services/dom.service';
import { AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { Subscription } from 'rxjs';
import { SliceFormsService } from '../../slice-forms.service';
import { AgenciesService } from '../../../../../services/agencies.service';
import { AgenciesData, AgenciesResult, Agency } from '../../../../../services/agencies.type';
import { AgencyFormComponent } from '../../../slice-agency/agency-form/agency-form.component';
import { ReclamationFormSlice } from '@repo/shared';

@Component({
  selector: 'reclamation-claim',
  templateUrl: './reclamation-claim.component.html',
  styleUrls: ['../../slice-forms.scss', './reclamation-claim.component.scss'],
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_CLAIM_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ReclamationClaimComponent),
    },
  ],
})
export class ReclamationClaimComponent extends AbstractFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() regForm: FormGroup;
  @Input() data: ReclamationFormSlice;

  get cssDisplayForAgencyForm(): 'none' | null {
    const identityValue = this.regForm.get('identity.clientFunction')?.value;

    return identityValue === 0 || identityValue === 2 ? null : 'none';
  }

  _clientFunctionSubscription: Subscription;
  clientCategoryOptions;
  clientTypeOptions;
  _buttonDisabled = false;
  _hasPostError = false;
  selectError = false;

  agencies: Agency[] = [];
  showSearchAgenciesResults = false;
  @ViewChild(AgencyFormComponent, { static: true }) agencyFormComponent: AgencyFormComponent;
  selectedAgency: Agency;
  displayMandatoryAgencyMessage: boolean;
  private agencyQuerySubscription: Subscription;

  get cssDisplayForCompanyName(): 'hidden' | 'visible' {
    return this.selectedClientCategoryValue === 1 ? 'visible' : 'hidden';
  }

  selectedClientCategoryValue: number;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly agenciesService: AgenciesService,
    private readonly viewportScroller: ViewportScroller,
    private readonly sliceFormsService: SliceFormsService,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngOnInit(): void {
    this._clientFunctionSubscription = this.initClientFunctionSubscription();
    this.clientTypeOptions = this.data.lien_client;
    this.clientCategoryOptions = this.data.select_type;

    if (this.regForm) {
      this.regForm.patchValue({
        claim: { clientType: 0 },
      });
      this.regForm.get('claim.clientType')?.disable();
      this.regForm.get('claim.civilTitleClient')?.disable();
      this.regForm.get('claim.firstNameClient')?.disable();
      this.regForm.get('claim.lastNameClient')?.disable();
      this.regForm.get('claim.emailClient')?.disable();
      this.regForm.get('claim.birthDateClient')?.disable();
      this.regForm.get('claim.phoneClient')?.disable();
      this.regForm.get('claim.streetClient')?.disable();
      this.regForm.get('claim.cityClient')?.disable();
      this.regForm.get('claim.postalCodeClient')?.disable();
    }
  }

  ngAfterViewInit(): void {
    const claimGroup = this.regForm.get('claim') as FormGroup;
    const agencyQueryControl = this.agencyFormComponent.agencyForm.get('query') as FormControl;
    claimGroup.addControl('agencyQuery', this.agencyFormComponent.agencyForm.get('query') as AbstractControl);
    agencyQueryControl.setParent(claimGroup);

    agencyQueryControl.setValidators([
      this.sliceFormsService._getFieldValidator(
        (RECLAMATION_CLAIM_FORM.properties as any)['agencyQuery'] as JSONSchema7,
        'agencyQuery',
      ),
    ]);
    agencyQueryControl.updateValueAndValidity();

    this.agencyQuerySubscription = this.initAgencyQuerySubscription();
  }

  ngOnDestroy(): void {
    if (this._clientFunctionSubscription) {
      this._clientFunctionSubscription.unsubscribe();
    }
    if (this.agencyQuerySubscription) {
      this.agencyQuerySubscription.unsubscribe();
    }
  }

  initClientFunctionSubscription(): Subscription {
    return this.regForm.get('identity.clientFunction')?.valueChanges.subscribe((value: 0 | 1 | 2) => {
      const agencyRefControl = this.regForm.get('claim.agencyRef') as AbstractControl;
      const agencyQueryControl = this.regForm.get('claim.agencyQuery') as AbstractControl;
      const agencyIDControl = this.regForm.get('claim.agencyID') as AbstractControl;
      const accountNumberControl = this.regForm.get('claim.accountNumber') as AbstractControl;
      const clientTypeControl = this.regForm.get('claim.clientType') as AbstractControl;
      const civilTitleClientControl = this.regForm.get('claim.civilTitleClient') as AbstractControl;
      const firstNameClientControl = this.regForm.get('claim.firstNameClient') as AbstractControl;
      const lastNameClientControl = this.regForm.get('claim.lastNameClient') as AbstractControl;
      const emailClientControl = this.regForm.get('claim.emailClient') as AbstractControl;
      const birthDateClientControl = this.regForm.get('claim.birthDateClient') as AbstractControl;
      const phoneClientControl = this.regForm.get('claim.phoneClient') as AbstractControl;
      const streetClientControl = this.regForm.get('claim.streetClient') as AbstractControl;
      const cityClientControl = this.regForm.get('claim.cityClient') as AbstractControl;
      const clientCategoryClientControl = this.regForm.get('claim.clientCategory') as AbstractControl;
      const postalCodeClientControl = this.regForm.get('claim.postalCodeClient') as AbstractControl;

      if (value === 0) {
        clientCategoryClientControl.enable();
        agencyRefControl.enable();
        agencyIDControl.enable();
        agencyQueryControl.enable();
        accountNumberControl.enable();
        clientTypeControl.disable();
        civilTitleClientControl.disable();
        firstNameClientControl.disable();
        lastNameClientControl.disable();
        emailClientControl.disable();
        birthDateClientControl.disable();
        phoneClientControl.disable();
        streetClientControl.disable();
        cityClientControl.disable();
        postalCodeClientControl.disable();
      } else if (value === 1) {
        clientCategoryClientControl.disable();
        agencyRefControl.disable();
        agencyIDControl.disable();
        agencyQueryControl.disable();
        accountNumberControl.disable();
        clientTypeControl.disable();
        civilTitleClientControl.disable();
        firstNameClientControl.disable();
        lastNameClientControl.disable();
        emailClientControl.disable();
        birthDateClientControl.disable();
        phoneClientControl.disable();
        streetClientControl.disable();
        cityClientControl.disable();
        postalCodeClientControl.disable();
      } else {
        clientCategoryClientControl.enable();
        agencyRefControl.enable();
        agencyIDControl.enable();
        agencyQueryControl.enable();
        accountNumberControl.enable();
        clientTypeControl.enable();
        civilTitleClientControl.enable();
        firstNameClientControl.enable();
        lastNameClientControl.enable();
        emailClientControl.enable();
        birthDateClientControl.enable();
        phoneClientControl.enable();
        streetClientControl.enable();
        cityClientControl.enable();
        postalCodeClientControl.enable();
      }
    }) as Subscription;
  }

  previous(): void {
    this.myStepper.previous();
  }

  searchNearAgencies(agenciesData: AgenciesData): void {
    this.regForm.get('claim')?.patchValue({
      agencyQuery: this.agencyFormComponent.agencyForm.get('query')?.value,
    });

    // resetting selected agency value
    this.regForm.get('claim')?.patchValue({ agencyRef: '' });
    this.selectedAgency = (null as unknown) as Agency;

    this.agenciesService.searchNearAgencies(agenciesData.agenciesFilter).subscribe(agenciesResult => {
      // RSI-1623 Hide Profesionals Agencies
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult).filter(agency => agency.agencyType !== 'PRO');
      this.showSearchAgenciesResults = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  initAgencyQuerySubscription(): Subscription {
    return this.agencyFormComponent.agencyForm.get('query')?.valueChanges.subscribe(value => {
      if (!value) {
        this.showSearchAgenciesResults = false;
      }
    }) as Subscription;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies
      .sort((a, b) => (a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0))
      .slice(0, 10);
  }

  updateAgencyData(agency: Agency): void {
    this.selectedAgency = agency;
    this.displayMandatoryAgencyMessage = false;
    this.regForm.get('claim')?.patchValue({ agencyRef: ('0' + agency.id).slice(-5) });
    this.agencyFormComponent.agencyForm.patchValue({ query: agency.name.toUpperCase() });
  }

  onSubmit(): void {
    if (!this.regForm.get('claim.clientCategory')?.valid) {
      this.selectError = true;
    }
    if (this.regForm.get('claim')?.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm.get('claim') as FormGroup);
      if (!this.agencyFormComponent) {
        this.focusFirstInvalidFormField();
      } else {
        this.triggerFormFieldsStatus(this.agencyFormComponent.agencyForm as FormGroup);
        const errorsCount = super.getErrorCount(this.regForm.get('claim') as FormGroup);
        if (errorsCount === 1 && !this.regForm.get('claim.agencyRef')?.valid) {
          this.displayMandatoryAgencyMessage = true;
          const agencyQueryControl = this.elementRef.nativeElement.querySelector('app-agency-form');
          agencyQueryControl.focus({ preventScroll: true });
          this.domService.scrollTo(agencyQueryControl);
        } else {
          this.focusFirstInvalidFormField();
        }
      }
    }
  }
}

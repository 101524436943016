import { Directive } from '@angular/core';

@Directive({
  selector: '[formFieldHint]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-hint',
  },
})
export class FormFieldHintDirective {}

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as Url from 'url-parse';
import { WithContext, Article, BreadcrumbList, ListItem, Organization, WebPage, FAQPage } from 'schema-dts';

import { ArticleHead, Breadcrumb, FaqQuestion } from '@repo/shared';
import { ConfigService } from '../config.service';
import { extractTextContent } from '../helpers/rich-text.helpers';

export const LCL_LOGO_SIGNATURE = '/assets/images/lcl_logo_bleu_signature.jpg';
export const LCL_LOGO_SIGNATURE_SIZE = { height: '278', width: '340' };

@Injectable({
  providedIn: 'root',
})
export class SchemaOrgService {
  private readonly baseUrl: string;

  private readonly publisherSchema: Organization;

  constructor(private readonly location: Location, private readonly configService: ConfigService) {
    this.baseUrl = this.configService.get<string>('FRONT_URL');
    this.publisherSchema = {
      '@type': 'Organization',
      name: 'LCL',
      logo: {
        '@type': 'ImageObject',
        url: this.getLogoUrl(),
        height: LCL_LOGO_SIGNATURE_SIZE.height,
        width: LCL_LOGO_SIGNATURE_SIZE.width,
      },
    };
  }

  getOrganizationSchema(): WithContext<Organization> {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'LCL',
      description: 'Banque et assurance',
      logo: this.getLogoUrl(),
      url: this.baseUrl,
      sameAs: [
        'https://www.facebook.com/LCL',
        'https://www.instagram.com/lcl/',
        'https://www.linkedin.com/company/lcl/',
        'https://twitter.com/lcl',
        'https://www.youtube.com/c/lcl',
      ],
    };
  }

  getArticleSchema(article: ArticleHead): WithContext<Article> | undefined {
    if (
      !article ||
      !article.title ||
      !article.description ||
      !article.image ||
      !article.image.url ||
      !article.publishOn
    ) {
      return;
    }

    // HACK: force type `any` on this property in order to bypass
    // an linter issue on the `Article` type definition.
    const mainEntityOfPage = {
      '@type': 'WebPage',
      '@id': this.getCurrentPageUrl(),
    } as WebPage;

    const hasImageDimensions =
      article.image.dimensions && article.image.dimensions.width && article.image.dimensions.height;

    const imageDimensions = hasImageDimensions
      ? {
          width: article.image.dimensions?.width + '',
          height: article.image.dimensions?.height + '',
        }
      : {};

    return {
      '@context': 'https://schema.org',
      '@type': 'Article',
      author: this.publisherSchema,
      publisher: this.publisherSchema,
      mainEntityOfPage,
      headline: article.title,
      description: extractTextContent(article.description),
      image: {
        '@type': 'ImageObject',
        url: article.image.url,
        ...imageDimensions,
      },
      datePublished: article.publishOn,
      keywords: [''],
    };
  }

  getBreadcrumbSchema(breadcrumb: Breadcrumb): WithContext<BreadcrumbList> | undefined {
    if (!breadcrumb || !breadcrumb.links || !breadcrumb.links.length) {
      return;
    }

    const itemListElement = this.getBreadcrumbList(breadcrumb);

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  }

  getFAQPageSchema(FaqQuestions: FaqQuestion[]): WithContext<FAQPage> | undefined {
    if (!FaqQuestions.length) {
      return;
    }

    const structuredQuestion: any[] = [];

    for (const question of FaqQuestions) {
      structuredQuestion.push({
        '@type': 'Question',
        name: question.text,
        acceptedAnswer: {
          '@type': 'Answer',
          text: question.answer,
        },
      });
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: structuredQuestion,
    };
  }

  private getBreadcrumbList(breadcrumb: Breadcrumb): ListItem[] {
    return breadcrumb.links.map(
      (link, index) =>
        ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'Thing',
            '@id': this.getAbsoluteUrl(link.url as string),
            name: link.label,
          },
        } as ListItem),
    );
  }

  private getLogoUrl(): string {
    return new Url(LCL_LOGO_SIGNATURE, this.baseUrl).toString();
  }

  private getCurrentPageUrl(): string {
    return new Url(this.location.path(), this.baseUrl).toString();
  }

  private getAbsoluteUrl(path: string): string {
    return new Url(path, this.baseUrl).toString();
  }
}

import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { agenciesQueryString, agencyDetailsQueryString } from '@repo/shared/dynamicQuery.graphql';
import { AgenciesFilter, AgenciesResult, AgenciesQueryResult, AgencyDetailsQueryResult, Agency } from './agencies.type';

const agenciesQuery: DocumentNode = gql(agenciesQueryString);
const agencyDetailsQuery: DocumentNode = gql(agencyDetailsQueryString);

@Injectable()
export class AgenciesService {
  constructor(private apollo: Apollo) {}

  searchNearAgencies(agenciesFilter: AgenciesFilter): Observable<AgenciesResult> {
    return this.apollo
      .query<AgenciesQueryResult>({
        query: agenciesQuery,
        variables: agenciesFilter,
      })
      .pipe(map(apolloQueryResult => apolloQueryResult.data.agencies));
  }

  getAgencyDetails(agencyId: string): Observable<Agency | null> {
    return this.apollo
      .query<AgencyDetailsQueryResult>({
        query: agencyDetailsQuery,
        variables: { agencyId },
      })
      .pipe(map(apolloQueryResult => apolloQueryResult.data.agency));
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agency } from '../../../../services/agencies.type';

const DISTANCE = '/assets/images/agency-map-pin.svg';
const CALENDAR = '/assets/images/agency-calendar.svg';
const VAULT = '/assets/images/agency-vault.svg';

@Component({
  selector: 'app-agency-feature',
  template: `
    <div class="feature" [class.feature-vertical]="vertical">
      <div class="item" *ngIf="!hideDistance">
        <img class="item-picto" [src]="distance" />
        <div class="item-text">{{ agency.distance | number: '1.0-2' }} km</div>
      </div>
      <div class="item">
        <ng-container *ngIf="agency?.hours?.saturday?.isOpen">
          <img class="item-picto" [src]="calendar" />
          <div class="item-text">Ouvert samedi</div>
        </ng-container>
      </div>
      <div class="item">
        <ng-container *ngIf="agency.hasVault">
          <img class="item-picto" [src]="vault" />
          <div class="item-text">Coffre fort</div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./agency-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyFeatureComponent {
  @Input()
  agency: Agency;

  @Input()
  hideDistance = false;

  @Input()
  vertical = false;

  readonly distance = DISTANCE;
  readonly calendar = CALENDAR;
  readonly vault = VAULT;
}

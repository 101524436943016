import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { LandingUniversGalSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { isContentEmpty } from '../../../helpers/rich-text.helpers';
import { ResponsiveService } from '../../../services/responsive.service';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { WINDOW } from '../../../services/window.provider';
import { FullwidthBackgroundComponent } from '../../common-behaviors/fullwidth-background/fullwidth-background.component';
import { HeroComponent } from '../slice-hero/slice-hero.component';

@Component({
  selector: 'slice-landing-univers-hero',
  template: `
    <ng-template #contentTemplate>
      <div class="content" #contentRef>
        <h1 class="title" #titleRef>{{ data.title }}</h1>
        <h2 class="subtitle" #subtitleRef>{{ data.subtitle }}</h2>
        <div class="description" [innerHTML]="data.richText"></div>
        <ng-container *ngFor="let button of data.buttons">
          <cb-cta-btn-link
            class="cta-button"
            *ngIf="button"
            [button]="button"
            [sliceType]="data.type"
          ></cb-cta-btn-link>
        </ng-container>
      </div>
    </ng-template>
    <ng-container *ngIf="isDataValid && isReady">
      <cb-fullwidth-background [class]="fullwidthBackgroundClass" [image]="data.image">
        <div class="spacer" #spacerRef></div>
        <ng-container *ngIf="isDesktopMode">
          <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </ng-container>
      </cb-fullwidth-background>
      <ng-container *ngIf="!isDesktopMode">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./slice-landing-univers-gal-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingUniverseGalComponent
  implements HeroComponent, SliceWithData<LandingUniversGalSlice>, AfterViewInit, OnDestroy {
  @Input() data: LandingUniversGalSlice = {} as LandingUniversGalSlice;

  @ViewChild(FullwidthBackgroundComponent, { read: ElementRef }) backgroundRef: ElementRef;
  @ViewChild('contentRef') contentRef: ElementRef | undefined;
  @ViewChild('titleRef') titleRef: ElementRef | undefined;
  @ViewChild('subtitleRef') subtitleRef: ElementRef | undefined;
  @ViewChild('spacerRef') spacerRef: ElementRef | undefined;

  isDesktopMode: boolean;

  isReady = true;

  private subscription$: Subscription = new Subscription();

  constructor(
    private readonly renderer: Renderer2,
    private readonly responsiveService: ResponsiveService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    public readonly tagCommanderService: TagCommanderService,
    @Inject(WINDOW) private readonly win: Window,
  ) {}

  @HostBinding('class.hero--with-cta')
  get hasCTA(): boolean {
    return !!this.data.buttons && this.data.buttons.length > 0;
  }

  @HostBinding('class.mega-hero')
  get isMegaHero(): boolean {
    return this.data.megaHero;
  }

  @HostBinding('class.with-anim')
  get isAnimated(): boolean {
    return this.isReady;
  }

  get isDataValid(): boolean {
    return !isContentEmpty(this.data.title as string) || !isContentEmpty(this.data.richText as string);
  }

  get fullwidthBackgroundClass(): string {
    return this.isDesktopMode ? 'wrapper' : 'background';
  }

  ngAfterViewInit(): void {
    if (this.win) {
      this.ngZone.runOutsideAngular(() => {
        this.subscription$.add(this.applyOnceResizeEventReached());
      });
    }

    this.subscription$.add(this.applyOnceBreakpointEventReached());
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  applyOnceBreakpointEventReached(): Subscription {
    return this.responsiveService.subscribeOnDesktop(isDesktopMode => {
      if (this.isDesktopMode !== isDesktopMode) {
        this.isDesktopMode = isDesktopMode;
        this.changeDetectorRef.detectChanges();
        this.updateView();
      }
    });
  }

  applyOnceResizeEventReached(): Subscription {
    return fromEvent(this.win, 'resize')
      .pipe(throttleTime(200))
      .subscribe(() => {
        this.ngZone.run(() => {
          if (!this.isDesktopMode) {
            this.updateView();
          }
        });
      });
  }

  renderOnMobile(): void {
    if (this.titleRef && this.backgroundRef) {
      const { offsetHeight: backgroundHeight } = this.backgroundRef.nativeElement as HTMLElement;
      const { offsetHeight: titleHeight } = this.titleRef.nativeElement as HTMLElement;
      let newMarginTop = backgroundHeight - titleHeight / 2;

      if (this.isMegaHero && this.subtitleRef) {
        const { offsetTop: subtitleTop, offsetHeight: subtitleHeight } = this.subtitleRef.nativeElement as HTMLElement;
        newMarginTop = backgroundHeight - subtitleTop - subtitleHeight / 2;
      }

      if (!isNaN(newMarginTop)) {
        this.renderer.setStyle(this.contentRef?.nativeElement, 'margin-top', `${newMarginTop}px`);
      }
    }
  }

  renderOnDesktop(): void {
    this.renderer.removeStyle(this.contentRef?.nativeElement, 'margin-top');

    if (this.spacerRef && this.isContentOversized()) {
      this.renderer.addClass(this.contentRef?.nativeElement, 'content--oversized');
    } else {
      this.renderer.removeClass(this.contentRef?.nativeElement, 'content--oversized');
    }
  }

  updateView(): void {
    if (this.contentRef && this.isDesktopMode !== undefined) {
      this.isDesktopMode ? this.renderOnDesktop() : this.renderOnMobile();
    }
  }

  private isContentOversized(): boolean {
    const { offsetHeight: breadcrumbHeight } = this.spacerRef?.nativeElement as HTMLElement;
    const { offsetTop } = this.contentRef?.nativeElement as HTMLElement;
    return offsetTop - breadcrumbHeight < 50;
  }
}

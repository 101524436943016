import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Agency, AgencyDetailsSlice } from '@repo/shared';
import { SliceWithData } from '../../../../../typings';
import { isContentEmpty } from '../../../../helpers/rich-text.helpers';
import { formatPhoneNumber } from '../slice-agency.helper';

const AGENCY_INFO = '/assets/images/agency-info.svg';
const PUBLIC_BANK_PIN = '/assets/images/agency-map-pin-public-bank.svg';
const PRIVATE_BANK_PIN = '/assets/images/agency-map-pin-private-bank.svg';
const PRO_BANK_PIN = '/assets/images/agency-map-pin-pro.svg';

@Component({
  selector: 'slice-agency-details',
  template: `
    <ng-container *ngIf="isDataValid">
      <cb-fullwidth-background class="wrapper">
        <div class="content">
          <h2 class="subtitle">{{ data.title }}</h2>
        </div>
      </cb-fullwidth-background>
      <div class="result">
        <div class="result-content">
          <div class="result-infos">
            <h3 class="section">Informations pratiques</h3>
            <div class="infos-container">
              <div class="agency-details">
                <div class="info-address">{{ agency.address }}<br />{{ agency.postalCode }} {{ agency.town }}</div>
                <div class="info-phone" *ngIf="agency.phoneNumber">
                  <span class="info-title">Tél :</span>
                  {{ formatPhoneNumber(agency.phoneNumber) }}
                </div>
                <div class="info-fax" *ngIf="agency.fax">
                  <span class="info-title">Fax :</span>
                  {{ formatPhoneNumber(agency.fax) }}
                </div>
              </div>
              <div>
                <div class="legend-item" *ngIf="isPrivateBank">
                  <img class="legend-picto" [src]="privateBankPin" />
                  <span>Pôle banque privée</span>
                </div>
                <app-agency-feature
                  *ngIf="isPrivateBank"
                  [agency]="agency"
                  [hideDistance]="true"
                  [vertical]="true"
                ></app-agency-feature>
              </div>
              <div>
                <div class="legend-item" *ngIf="isProBank">
                  <img class="legend-picto" [src]="proBankPin" />
                  <span>Agence des Professionnels</span>
                </div>
                <app-agency-feature
                  *ngIf="isProBank"
                  [agency]="agency"
                  [hideDistance]="true"
                  [vertical]="true"
                ></app-agency-feature>
              </div>
            </div>
            <br />
            <div class="info-com-area" *ngIf="agency.communicationArea">
              <h3 class="section"><img class="legend-picto" [src]="agencyInfo" /> Information sur votre agence</h3>
              {{ agency.communicationArea }}
            </div>
            <h3 class="section">Horaires d'ouvertures</h3>
            <app-agency-countdown [agencyHours]="agency?.hours"></app-agency-countdown>
            <app-agency-schedule [agencyHours]="agency?.hours"></app-agency-schedule>
            <br />
            <h3 class="section">Contactez un conseiller</h3>
            <app-agency-contact></app-agency-contact>
          </div>
          <app-agency-map class="result-map" [agencies]="[agency]" [showPinNumber]="false"></app-agency-map>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-agency-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceAgencyDetailsComponent implements SliceWithData<AgencyDetailsSlice>, OnInit {
  constructor() {}

  @Input() data: AgencyDetailsSlice;

  agency: Agency;

  readonly agencyInfo = AGENCY_INFO;
  readonly publicBankPin = PUBLIC_BANK_PIN;
  readonly privateBankPin = PRIVATE_BANK_PIN;
  readonly proBankPin = PRO_BANK_PIN;

  get isPrivateBank(): boolean {
    return this.agency.agencyType === 'BP';
  }

  get isProBank(): boolean {
    return this.agency.agencyType === 'PRO';
  }

  ngOnInit(): void {
    this.agency = this.data.agencyDetails as Agency;
  }

  get isDataValid(): boolean {
    return !isContentEmpty(this.data.title as string) && !!this.data.agencyDetails;
  }

  formatPhoneNumber(phoneNumber: string): string {
    return formatPhoneNumber(phoneNumber);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tableau-comparatif',
  template: `<div class="cadre-tableau">
    <zone1-tableau-comparatif [data]="data"></zone1-tableau-comparatif>
  </div>`,
  styleUrls: ['./tableau-comparatif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauComparatifComponent {
  @Input() data: any;
}

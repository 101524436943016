import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreadcrumbList, WithContext } from 'schema-dts';

import { Breadcrumb } from '@repo/shared';
import { SchemaOrgService } from '../../schema-org.service';

@Component({
  selector: 'cb-breadcrumb',
  template: `
    <nav role="navigation" aria-label="Vous êtes ici :" class="breadcrumb">
      <ul class="container" *ngIf="breadcrumb">
        <li class="link-block" *ngFor="let link of breadcrumb.links">
          <a class="link-item" [attr.data-label]="link.label" [attr.href]="link.url">
            <span class="label">{{ link.label }}</span>
          </a>
        </li>
      </ul>
    </nav>

    <app-json-ld *ngIf="schema" [json]="schema"></app-json-ld>
  `,
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input() breadcrumb: Breadcrumb;

  constructor(private readonly schemaOrgService: SchemaOrgService) {}

  get schema(): WithContext<BreadcrumbList> | undefined {
    return this.schemaOrgService.getBreadcrumbSchema(this.breadcrumb);
  }
}

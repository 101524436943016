import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-push-block-item',
  template: ` <ng-content></ng-content> `,
  exportAs: 'uiPushBlockItem',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPushBlockItemComponent {
  @HostBinding('class') class = 'ui-push-block-item';
}

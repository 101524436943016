import { Component, Input, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { RECLAMATION_IDENTITY_FORM } from '@repo/shared/form-schemas/reclamation-connected-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

@Component({
  selector: 'reclamation-connected-customer-identity',
  template: `
    <form class="form" [formGroup]="regForm" (ngSubmit)="onSubmit()" novalidate>
      <div id="identity">
        <h2>Votre demande</h2>
        <label>Faire une réclamation*</label>
        <div formFieldRadioGroup class="form-field-control">
          <form-field-radio displayMode="asideLabel" name="claimType" formControlName="claimType" value="firstClaim">
            Il s'agit de ma 1ère réclamation
          </form-field-radio>
          <form-field-radio displayMode="asideLabel" name="claimType" formControlName="claimType" value="noSatisfied">
            J'ai obtenu une réponse mais je ne suis pas satisfait
          </form-field-radio>
        </div>
        <form-field-error></form-field-error>
      </div>

      <p class="mandatory-field-text">*Champs obligatoires</p>
      <p>
        La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
        disponible sur la page d'accueil du site.
      </p>

      <div class="button-wrapper">
        <cb-cta-btn-link
          class="button"
          [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
          [buttonWithoutLink]="true"
          [sliceType]="'reclamation_connected_customer_form'"
          [disabled]="_buttonDisabled"
        >
        </cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_IDENTITY_FORM,
    },
  ],
})
export class ReclamationConnectedCustomerIdentityComponent extends AbstractFormComponent {
  @Input() regForm: FormGroup;

  _buttonDisabled = false;
  _hasPostError = false;

  selectError = false;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  onSubmit(): void {
    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';

import { AgencyMapHeroSlice } from '@repo/shared/index';
import { SliceWithData } from '../../../../../typings';
import { isContentEmpty } from '../../../../helpers/rich-text.helpers';
import { AgenciesService } from '../../../../services/agencies.service';
import { AgenciesData, AgenciesFilter, AgenciesResult, Agency, TagSources } from '../../../../services/agencies.type';
import { WINDOW } from '../../../../services/window.provider';
import { TagCommanderService } from '../../../../services/tag-commander.service';

const PUBLIC_BANK_PIN = '/assets/images/agency-map-pin-public-bank.svg';
const PRIVATE_BANK_PIN = '/assets/images/agency-map-pin-private-bank.svg';
const PRO_BANK_PIN = '/assets/images/agency-map-pin-pro.svg';

const STORE_LOCATOR_TAG = 'particulier::Recherche_';
const STORE_LOCATOR_HAVAS_TAG = 'agencyloc';

@Component({
  selector: 'slice-agency-map-hero',
  template: `
    <ng-container *ngIf="isDataValid">
      <cb-fullwidth-background class="wrapper" [image]="data.image">
        <div class="content">
          <h1 class="title">{{ data.title }}</h1>
          <h2 class="subtitle">{{ data.subtitle }}</h2>
          <div class="search-box">
            <cb-cta-btn-link
              *ngIf="shouldShowGeolocationButton"
              [button]="{ label: 'Me localiser' }"
              [buttonWithoutLink]="true"
              (click)="getPositionAndSearchNearAgencies()"
            >
            </cb-cta-btn-link>
            <div class="search-text-sep" *ngIf="shouldShowGeolocationButton">ou</div>
            <app-agency-form
              (optionSelected)="searchNearAgencies($event)"
              (filterSelected)="getAgenciesFilter($event)"
              [isChildForm]="false"
              [displayNoAgencyMessage]="false"
            >
            </app-agency-form>
          </div>
        </div>
      </cb-fullwidth-background>
      <ng-container *ngIf="showMapResult; else landingText">
        <div id="result-anchor" [ngSwitch]="agencies.length">
          <div class="count count-no-result" *ngSwitchCase="0">Aucune agence LCL trouvée</div>
          <h2 class="count" *ngSwitchCase="1">1 agence LCL trouvée</h2>
          <h2 class="count" *ngSwitchDefault>{{ agencies.length }} agences LCL trouvées</h2>
        </div>
        <div class="result" *ngIf="agencies.length">
          <div class="result-content">
            <app-agency-list class="result-infos" [agencies]="agencies"></app-agency-list>
            <app-agency-map class="result-map" [agencies]="agencies"></app-agency-map>
          </div>
        </div>
        <div class="legend">
          <div class="legend-item legend-item-title">Légende :</div>
          <div class="legend-item">
            <img class="legend-picto" [src]="publicBankPin" />
            <span>Pôle particulier</span>
          </div>
          <div class="legend-item">
            <img class="legend-picto" [src]="privateBankPin" />
            <span>Pôle banque privée</span>
          </div>
          <div class="legend-item">
            <img class="legend-picto" [src]="proBankPin" />
            <span>Agence des Professionnels</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./slice-agency-map-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceAgencyMapHeroComponent implements SliceWithData<AgencyMapHeroSlice> {
  @Input() data: AgencyMapHeroSlice;

  agencies: Agency[] = [];

  agenciesFilters: AgenciesFilter;

  showMapResult = false;

  readonly publicBankPin = PUBLIC_BANK_PIN;
  readonly privateBankPin = PRIVATE_BANK_PIN;
  readonly proBankPin = PRO_BANK_PIN;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly agenciesService: AgenciesService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly viewportScroller: ViewportScroller,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  get isDataValid(): boolean {
    return !isContentEmpty(this.data.title as string) && !isContentEmpty(this.data.subtitle as string);
  }

  get shouldShowGeolocationButton(): boolean {
    return !this.win || this.hasGeolocation;
  }

  get hasGeolocation(): boolean {
    return !!(this.win && this.win.navigator && this.win.navigator.geolocation);
  }

  searchNearAgencies({ agenciesFilter, tagSource }: AgenciesData): void {
    this.agenciesService.searchNearAgencies(agenciesFilter).subscribe(agenciesResult => {
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult);
      this.showMapResult = true;
      this.changeDetectorRef.detectChanges();
      this.viewportScroller.scrollToAnchor('result-anchor');
      this.tagCommanderService.trackClickNavigationEvent(`${STORE_LOCATOR_TAG}${tagSource}`);
      this.tagCommanderService.trackHavasClickTag(STORE_LOCATOR_HAVAS_TAG);
    });
  }

  getAgenciesFilter(agenciesFilters: AgenciesFilter): void {
    this.agenciesFilters = agenciesFilters;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies.sort((a, b) => (a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0));
  }

  getPositionAndSearchNearAgencies(): void {
    if (!this.hasGeolocation) {
      return;
    }
    this.win.navigator.geolocation.getCurrentPosition(position => {
      const agenciesFilter = { ...this.agenciesFilters, ...this.getAgenciesFilterFromPosition(position) };
      const agenciesData: AgenciesData = { agenciesFilter, tagSource: TagSources.Localize };
      this.searchNearAgencies(agenciesData);
    });
  }

  private getAgenciesFilterFromPosition(position: GeolocationPosition): AgenciesFilter {
    const {
      coords: { latitude, longitude },
    } = position;
    return { latitude, longitude };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { CmsFaqModule } from './faq/faq.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SlicesModule } from '../../slices/slices.module';
import { ArticleModule } from './article/article.module';

@NgModule({
  imports: [CommonModule, SlicesModule, PipesModule, CmsFaqModule, ArticleModule],
  declarations: [SimplePageComponent],
  entryComponents: [SimplePageComponent],
})
export class PagesModule {}

import { Component, ViewEncapsulation, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-contacts-item',
  encapsulation: ViewEncapsulation.None,
  template: `
    <ui-contacts-item-title *ngIf="title">
      {{ title }}
    </ui-contacts-item-title>
    <ng-content select="[uiContactsItemTitle], [ui-contacts-item-title], ui-contacts-item-title"> </ng-content>
    <ng-content> </ng-content>
  `,
})
export class UiContactsItemComponent {
  @HostBinding('class.ui-contacts-item') hostClass = true;

  @Input() title: string;
}

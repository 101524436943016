import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('WINDOW', {
  providedIn: 'root',
  factory: (windowFactory as unknown) as () => Window,
});

export function windowFactory(): Window | null {
  return typeof window !== 'undefined' ? window : null;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TableComponent } from './table-desktop/table.component';
import { SliceTableComponent } from './slice-table.component';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AnalyticsModule } from '../../../analytics/analytics.module';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule, SwiperModule, PipesModule, AnalyticsModule],
  declarations: [TableComponent, SliceTableComponent],
  exports: [SliceTableComponent],
})
export class SliceTableModule {}

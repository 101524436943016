import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, Form, streetRegex } from './form';

export interface Category {
  key: string;
  label: string;
}

export const FUNCTION_OPTIONS = [
  'Président',
  'Directeur Général',
  'Gérant',
  'Entrepreneur individuel',
  'Directeur administratif et financier',
];

export const REVENUES_OPTIONS = ['Entre 0 et 1,5 millions d’euros', "De 1,5 à 7 millions d'euros"]; // tslint:disable-line:quotemark

export const WORKERS_NUMBER_OPTIONS = [
  '0 salarié',
  '1 à 5 salariés',
  '6 à 10 salariés',
  '11 à 20 salariés',
  '21 à 50 salariés',
  '+ 50 salariés',
];

export const PRO_CONTACT_SUBJECT_CATEGORIES: Category[] = [
  { key: 'account', label: 'Devenir client' },
  { key: 'blank', label: 'Essentiel Pro : Besoins complémentaires' },
  { key: 'payment', label: 'Solutions d’encaissement et de paiement' },
  { key: 'treasury', label: 'Besoin de trésorerie' },
  { key: 'financial', label: 'Besoin de financement des investissements' },
  { key: 'property', label: 'Acquisition immobilière' },
  { key: 'protection', label: `Protection de l’entreprise et du dirigeant` },
  { key: 'excess', label: 'Placer vos excédents de trésorerie' },
  { key: 'retirement', label: 'Préparer votre retraite et celle de vos salariés' },
  { key: 'develoment', label: 'Développement à l’international' },
  { key: 'monem', label: 'Monem mobile' },
  { key: 'franchise', label: 'Franchise' },
  { key: 'liberalProfession', label: 'Professions Libérales' },
  { key: 'others', label: 'Autre' },
];

export interface ProContactForm extends Form {
  function: string;
  hasFirm: string;
  date?: string;
  socialReason: string;
  siren?: string;
  postal: string;
  naf?: string;
  activity: string;
  revenues: string;
  numberWorkers: string;
}

export const PRO_CONTACT_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    function: {
      type: 'integer',
      minimum: 0,
      maximum: FUNCTION_OPTIONS.length - 1,
    },
    hasFirm: {
      type: 'string',
      enum: ['Oui', 'Non'],
      minLength: 1,
    },
    date: {
      type: 'string',
    },
    socialReason: {
      type: 'string',
      minLength: 1,
    },
    siren: {
      type: 'string',
      pattern: '^$|^[0-9]{9}$',
      minLength: 1,
    },
    postal: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    naf: {
      type: 'string',
      pattern: '^$|^[0-9]{4}[A-Z]$',
      maxLength: 5,
      minLength: 1,
    },
    activity: {
      type: 'string',
      pattern: streetRegex,
      minLength: 1,
    },
    revenues: {
      type: 'integer',
      minimum: 0,
      maximum: REVENUES_OPTIONS.length - 1,
    },
    numberWorkers: {
      type: 'integer',
      minimum: 0,
      maximum: WORKERS_NUMBER_OPTIONS.length - 1,
    },
    subject: {
      type: 'string',
      enum: PRO_CONTACT_SUBJECT_CATEGORIES.map((category: Category) => category.key),
      minLength: 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'date',
    'phone',
    'function',
    'hasFirm',
    'postal',
    'socialReason',
    'siren',
    'naf',
    'activity',
    'revenues',
    'subject',
    'message',
    'recaptcha',
  ],
};

export const PRO_CONTACT_FORM_BACKEND_VALIDATION_SCHEMA: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    function: {
      type: 'integer',
      minimum: 0,
      maximum: FUNCTION_OPTIONS.length - 1,
    },
    hasFirm: {
      type: 'string',
      enum: ['Oui', 'Non'],
      minLength: 1,
    },
    date: {
      type: 'string',
    },
    socialReason: {
      type: 'string',
      minLength: 1,
    },
    postal: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    activity: {
      type: 'string',
      pattern: streetRegex,
      minLength: 1,
    },
    revenues: {
      type: 'integer',
      minimum: 0,
      maximum: REVENUES_OPTIONS.length - 1,
    },
    numberWorkers: {
      type: 'integer',
      minimum: 0,
      maximum: WORKERS_NUMBER_OPTIONS.length - 1,
    },
    subject: {
      type: 'string',
      enum: PRO_CONTACT_SUBJECT_CATEGORIES.map((category: Category) => category.key),
      minLength: 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'date',
    'phone',
    'function',
    'hasFirm',
    'postal',
    'socialReason',
    'activity',
    'revenues',
    'subject',
    'message',
    'recaptcha',
  ],
  if: { properties: { hasFirm: { enum: ['Oui'] } } },
  then: { required: ['siren', 'naf'] },
};

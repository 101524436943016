import { Injector } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { ModalRef } from './modal-ref';

export class ModalOutletInjector implements Injector {
  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _childContexts: ChildrenOutletContexts,
    private readonly _parent: Injector,
    private readonly _modalRef: ModalRef,
  ) {}

  get(token: any, notFoundValue?: any): any {
    if (token === ModalRef) {
      return this._modalRef;
    }

    if (token === ActivatedRoute) {
      return this._route;
    }

    if (token === ChildrenOutletContexts) {
      return this._childContexts;
    }

    return this._parent.get<any>(token, notFoundValue);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyBooleanValue',
})
export class PrettyBooleanValuePipe implements PipeTransform {
  static TAB_CHECK_ICON = '<span class="ic ic-tab-check"></span>';

  transform(value: any): string {
    switch (value) {
      case null:
      case undefined:
      case false:
        return '-';
      case true:
        return PrettyBooleanValuePipe.TAB_CHECK_ICON;
      default:
        return value;
    }
  }
}

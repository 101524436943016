import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectionStrategy,
  forwardRef,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JSONSchema7 } from 'json-schema';

import { AbstractFormComponent } from '../abstract-form-component';
import { SliceWithData } from '../../../../../typings';
import { EntrepriseContactFormSlice, Link } from '@repo/shared';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../tokens';
import {
  ENTREPRISE_CONTACT_FORM,
  ENTREPRISE_CONTACT_SUBJECT_CATEGORIES,
  TURNOVER_OPTIONS,
  FUNCTION_OPTIONS,
} from '@repo/shared/form-schemas/entreprise-contact';
import { SliceFormsService } from '../slice-forms.service';
import { DOMService } from '../../../../services/dom.service';
import { ConfigService } from '../../../../config.service';
import { LoggerService } from '../../../../logger/logger.service';
import { Logger } from '../../../../logger/logger';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StateService } from '../../../../services/state.service';
import { RECAPTCHA_LANGUAGE, ReCaptchaV3Service } from 'ng-recaptcha';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'slice-entreprise-contact-form',
  template: `
    <cb-mini-title
      class="mini-title"
      *ngIf="data.title"
      [title]="data.title"
      [withFrame]="!isFirstSlice"
    ></cb-mini-title>
    <form class="form" [formGroup]="form" (ngSubmit)="checkRecaptcha()" novalidate>
      <h2>Identité</h2>
      <div formFieldControl>
        <label>Civilité*</label>
        <div formFieldRadioGroup class="form-field-control--half-size">
          <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
            <span class="ic ic-woman civil-icon"></span>
            Madame
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
          <form-field-radio name="civilTitle" formControlName="civilTitle" value="M" class="form-field-radio--right">
            <span class="ic ic-man civil-icon"></span>
            Monsieur
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
        </div>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          Nom*
          <input formControlName="lastName" type="text" placeholder="Dupont" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label
          >Prénom*
          <input placeholder="Antoine" type="text" formControlName="firstName" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          E-mail*
          <input formControlName="email" type="email" formFieldInput placeholder="exemple@mail.com" />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label>
          Numéro de téléphone*
          <input
            formControlName="phone"
            type="text"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00 00 00 00 00"
            placeholder="01 23 45 67 89"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--large-size">
        <label>
          Fonction*
          <ng-select formControlName="function" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of functionOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
        <form-field-error></form-field-error>
      </div>

      <h2>Société</h2>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          Raison sociale*
          <input formControlName="companyName" formFieldInput type="text" placeholder="MaSociété SAS" />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label>
          SIREN*
          <input
            formControlName="siret"
            formFieldInput
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            mask="000 000 000"
            placeholder="123 456 789"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--large-size">
        <label>
          Chiffre d'affaires*
          <form-field-select
            formFieldSelect
            [options]="turnoverOptions"
            [formControlName]="'turnover'"
            [selectError]="_selectError"
          ></form-field-select>
        </label>
        <form-field-error></form-field-error>
      </div>

      <h2>Message</h2>
      <div formFieldControl class="form-field-control">
        <label>
          Votre demande concerne : *
          <form-field-select
            formFieldSelect
            [selectedValue]="_selectedValue"
            [options]="subjectCategories"
            [formControlName]="'subject'"
            [selectError]="_subjectError"
          ></form-field-select>
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl>
        <textarea
          formControlName="message"
          placeholder="Ecrivez votre message ici..."
          #messageField="formFieldInput"
          formFieldInput
        ></textarea>
        <div formFieldHint>
          {{ messageField.charCountLabel }}
        </div>
        <form-field-error></form-field-error>
      </div>
      <p class="mandatory-field-text">*Champs obligatoires</p>

      <p>
        La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
        disponible sur la page d'accueil du site.
      </p>

      <div class="button-wrapper">
        <cb-cta-btn-link
          class="button"
          [button]="button"
          [buttonWithoutLink]="true"
          [sliceType]="data.type"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
      </div>
      <p class="recaptcha-mentions">
        Ce site est protégé par reCAPTCHA. Les
        <a href="https://policies.google.com/privacy">règles de confidentialité</a>
        et les
        <a href="https://policies.google.com/terms">conditions d'utilisation</a>
        de Google s'appliquent.
      </p>
    </form>
  `,
  styleUrls: ['../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: ENTREPRISE_CONTACT_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceEntrepriseContactFormComponent),
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr',
    },
  ],
})
export class SliceEntrepriseContactFormComponent
  extends AbstractFormComponent
  implements SliceWithData<EntrepriseContactFormSlice>, OnInit, AfterViewInit {
  @Input() data: EntrepriseContactFormSlice;

  logger: Logger;

  subjectCategories = ENTREPRISE_CONTACT_SUBJECT_CATEGORIES;
  turnoverOptions = TURNOVER_OPTIONS;
  functionOptions = FUNCTION_OPTIONS;
  _buttonDisabled = false;
  _hasPostError = false;
  _selectError = false;
  _subjectError = false;
  _selectedValue: number | null = null;

  form: FormGroup;

  get button(): Link {
    return { label: 'Envoyer le formulaire' };
  }

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _entrepriseContactFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly configService: ConfigService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly stateService: StateService,
    readonly domService: DOMService,
    readonly elementRef: ElementRef,
    readonly loggerService: LoggerService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_entrepriseContactFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceEntrepriseContactFormComponent.name);
  }

  ngOnInit(): void {
    this.form = this.sliceFormsService.buildForm(this.getFormValidationSchema());
    this.form.patchValue({ subject: Validators.required });
    this.form.patchValue({ turnover: Validators.required });
    this.form.patchValue({ function: 0 });
    if (this.stateService.get().prevUid === 'reclamation-entreprise') {
      this._selectedValue = 12; // Réclamation
      this.form.patchValue({ subject: 12 });
    }
  }

  ngAfterViewInit(): void {
    this.form.addControl('recaptcha', new FormControl('', Validators.required));
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('entrepriseContactForm').subscribe(
      token => {
        this.form.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  submitForm(): void {
    const { valid, value } = this.form;
    if (!this.form.get('turnover')?.valid) {
      this._selectError = true;
    }
    if (!this.form.get('subject')?.valid) {
      this._subjectError = true;
    }
    if (valid) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendEntrepriseContactForm(value).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._buttonDisabled = false;
          this._hasPostError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
    } else {
      this.triggerFormFieldsStatus(this.form);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

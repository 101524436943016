import { Component, Input, ViewChild } from '@angular/core';
import { CarouselServicesItem } from '@repo/shared';
import {
  LottieAnimationComponent,
  AnimationConfig,
} from '../../../common-behaviors/lottie-animation/lottie-animation.component';

@Component({
  selector: 'carousel-pane',
  template: `
    <div class="wrapper">
      <cb-lottie-animation [active]="active" lottie [animationConfig]="animationConfig"></cb-lottie-animation>
      <div class="info">
        <h2>{{ title }}</h2>
        <div class="indicators-wrapper">
          <div class="title rich-text" [innerHTML]="item.title"></div>
          <div class="rich-text" [innerHTML]="item.description"></div>
          <div class="indicators" *ngIf="indicatorCount > 1">
            <span *ngFor="let index of indicatorArray" class="dot" [class.active]="index === currentIndicator">·</span>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./carousel-pane.component.scss'],
})
export class CarouselPaneComponent {
  @Input() title: string;
  @Input() item: CarouselServicesItem;
  @Input() active: boolean;
  @Input() indicatorCount = 1;
  @Input() currentIndicator = 0;

  @ViewChild(LottieAnimationComponent, { static: true }) lottie: LottieAnimationComponent;

  get animationConfig(): AnimationConfig {
    return {
      desktop: {
        path: this.item.animation ? this.item.animation.url : undefined,
        loop: true,
        inViewportPlay: true,
        placeholder: this.item.image.url,
      },
      mobile: {
        path: this.item.animation ? this.item.animation.mobile.url : undefined,
        height: 250,
        width: 320,
        loop: true,
        inViewportPlay: true,
        placeholder: this.item.image.mobile?.url,
      },
    };
  }

  get indicatorArray(): number[] {
    return Array.from(Array(this.indicatorCount).keys());
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * This component implements a mini title with css styles defined by UX/UI, example:
 * ---------------- ZONE 1 --------------
 * it takes 100% width, compatible mobile/desktop.
 * @param {string} title  The title to be showed
 */
@Component({
  selector: 'cb-mini-title',
  template: `
    <div class="mini-title" [class.withFrame]="withFrame">
      <span class="cb-mini-title_title">{{ title }}</span>
    </div>
  `,
  styleUrls: ['./mini-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniTitleComponent {
  @Input() title: string;
  @Input() withFrame = true;
}

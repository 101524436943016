import { Component, Input } from '@angular/core';
import { CharacteristicsSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

@Component({
  selector: 'slice-characteristics',
  template: `
    <ng-container *ngIf="data && data.description">
      <cb-mini-title *ngIf="data.title" [title]="data.title"></cb-mini-title>
      <cb-block-with-blur class="feature-description" [innerHtml]="data.description"></cb-block-with-blur>
    </ng-container>
  `,
  styleUrls: ['./slice-characteristics.component.scss'],
})
export class SliceCharacteristicsComponent implements SliceWithData<CharacteristicsSlice> {
  @Input() data: CharacteristicsSlice;
}

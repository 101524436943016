import * as moment from 'moment';
import { Request } from 'express';
import { APP_NAME, FRONT_NAME, IP_HEADER } from '@repo/shared/properties';
import { ConsoleLogger } from './console.logger';
import { Logger } from './logger';
import { LogLevel, LogLevelType } from '@repo/shared';

export class FormattedConsoleLogger implements Logger {
  private logger: ConsoleLogger;

  private logLevel: number;

  constructor(namespace: string, logLevelType: LogLevelType, private request?: Request & { correlationId?: string }) {
    this.logger = new ConsoleLogger(namespace);
    this.logLevel = LogLevel[logLevelType];
  }

  info(value: any, ...rest: any[]): void {
    if (this.shouldIgnoreLevel(LogLevel.INFO)) {
      return;
    }
    this.logger.info(this.formatMetadata('INFO'), '|msg=' + value, ...rest);
  }

  warn(value: any, ...rest: any[]): void {
    if (this.shouldIgnoreLevel(LogLevel.WARN)) {
      return;
    }
    this.logger.warn(this.formatMetadata('WARN'), '|msg=' + value, ...rest);
  }

  error(value: any, ...rest: any[]): void {
    if (this.shouldIgnoreLevel(LogLevel.ERROR)) {
      return;
    }
    this.logger.error(this.formatMetadata('ERROR'), '|msg=' + value, ...rest);
  }

  private formatMetadata(level: LogLevelType): string {
    const fields = [
      'time=' + moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      'level=' + level,
      'app_id=' + APP_NAME,
      'component_id=' + FRONT_NAME,
      'corr_id=' + this.getCorrelationId(),
      'src_client_id=' + this.getClientId(),
    ];
    return fields.join('|');
  }

  private getClientId(): string | undefined {
    return (this.request && this.request.header(IP_HEADER)) || undefined;
  }

  private getCorrelationId(): string {
    return (this.request && this.request.correlationId) || '';
  }

  private shouldIgnoreLevel(levelTested: LogLevel): boolean {
    return this.logLevel < levelTested;
  }
}

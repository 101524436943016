import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, streetRegex, Form } from './form';

export interface LoanInsuranceForm extends Form {
  birthDate: string;
  street: string;
  city: string;
  postalCode: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID: string;
  accountNumber?: string;
  loansQuantity: number;
  loanNumber1: string;
  loanNumber2?: string;
  loanNumber3?: string;
  loanNumber4?: string;
  loanNumber5?: string;
  filesNames?: string[];
}

export interface LoanInsuranceFormGroup {
  identity: LoanInsuranceIdentityForm;
  request: LoanInsuranceRequestForm;
}

export interface LoanInsuranceIdentityForm {
  civilTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  phone: string;
  street: string;
  city: string;
  postalCode: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID: string;
  accountNumber?: string;
  loansQuantity: number;
  loanNumber1: string;
  loanNumber2?: string;
  loanNumber3?: string;
  loanNumber4?: string;
  loanNumber5?: string;
}

export interface LoanInsuranceRequestForm {
  message: string;
  recaptcha: string;
  files?: any;
}

export const OPTIONS_LOANS_QUANTITIES = [1, 2, 3, 4, 5];

export const LOAN_INSURANCE_IDENTITY_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
      pattern: streetRegex,
    },
    city: {
      type: 'string',
      minLength: 1,
      pattern: nameRegex,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
    },
    accountNumber: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      maxLength: 7,
    },
    loansQuantity: {
      type: 'integer',
      maximum: OPTIONS_LOANS_QUANTITIES.length - 1,
    },
    loanNumber1: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber2: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber3: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber4: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber5: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'birthDate',
    'phone',
    'street',
    'city',
    'postalCode',
    'agencyRef',
    'agencyID',
    'accountNumber',
    'loansQuantity',
    'loanNumber1',
  ],
};

export const LOAN_INSURANCE_REQUEST_FORM: JSONSchema7 = {
  properties: {
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    files: {
      type: 'array',
      minLength: 1,
    },
  },
  required: ['message', 'recaptcha'],
};

export const LOAN_INSURANCE_IDENTITY_FORM_BACKEND_VALIDATION_SCHEMA: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
      pattern: streetRegex,
    },
    city: {
      type: 'string',
      minLength: 1,
      pattern: nameRegex,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
    },
    agencyID: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
    },
    accountNumber: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      maxLength: 7,
    },
    loansQuantity: {
      type: 'integer',
      maximum: OPTIONS_LOANS_QUANTITIES.length - 1,
    },
    loanNumber1: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber2: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber3: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber4: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
    loanNumber5: {
      type: 'string',
      pattern: '^$|^[a-zA-Z0-9]{16}$',
      maxLength: 16,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'birthDate',
    'phone',
    'street',
    'city',
    'postalCode',
    'agencyRef',
    'agencyID',
    'accountNumber',
    'loansQuantity',
    'loanNumber1',
  ],
};

export const LOAN_INSURANCE_REQUEST_FORM_BACKEND_VALIDATION_SCHEMA: JSONSchema7 = {
  properties: {
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    files: {
      type: 'array',
      minLength: 1,
    },
  },
  required: ['message', 'recaptcha'],
};

import { Component, Input } from '@angular/core';
import { ArticleHead, ImageWithMobile } from '@repo/shared';

export const ARTICLE_IMAGE_DEFAULT = '/assets/images/article_image_default.png';

@Component({
  selector: 'cb-article-thumbnail',
  template: `
    <div class="article">
      <a [attr.href]="article.url" class="link-with-image">
        <div class="frame">
          <img class="picture" [attr.alt]="image.alt" [attr.src]="image.url" />
        </div>
        <span class="article-title">{{ article.title }}</span>
      </a>
      <cb-article-info-bar [publishOn]="article.publishOn" [readingTime]="article.readingTime" [type]="article.type">
      </cb-article-info-bar>
    </div>
  `,
  styleUrls: ['./article-thumbnail.component.scss'],
})
export class ArticleThumbnailComponent {
  @Input() article: ArticleHead;

  get image(): ImageWithMobile {
    if (this.article.image && this.article.image.url) {
      return this.article.image;
    }
    return this.defaultImage;
  }

  private defaultImage: ImageWithMobile = {
    alt: '',
    url: ARTICLE_IMAGE_DEFAULT,
  };
}

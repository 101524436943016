import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'cb-push-banner',
  template: `
    <div class="wrapper">
      <div class="content">
        <ng-content></ng-content>
      </div>
      <button (click)="handleCloseClick($event)" class="close-button">
        <i aria-hidden="true" class="ic ic-bt-close"></i>
      </button>
    </div>
  `,
  styleUrls: ['push-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PushBannerComponent {
  @Input()
  @HostBinding('class.sticky-bottom--stickied')
  sticky = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<PushBannerComponent>();

  handleCloseClick(event: MouseEvent): void {
    event.preventDefault();

    this.close.emit(this);
  }
}

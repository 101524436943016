<div class="summary">
  <h2>Récapitulatif</h2>

  <div class="summary-bloc">
    <div class="bloc-title">
      <p class="title">1 - Mon Contrat</p>
      <div class="edit-link" (click)="changeStepOfResilationForm(0)">modifier</div>
    </div>
    <p class="summary-content">{{ regForm.identity.productSelected }}</p>
  </div>

  <div class="summary-bloc">
    <div class="bloc-title">
      <p class="title">2 - Mes informations</p>
      <div class="edit-link" (click)="changeStepOfResilationForm(1)">modifier</div>
    </div>

    <p class="summary-content">{{ regForm.claim?.firstName }} {{ regForm.claim?.lastName }}</p>
    <p class="summary-content">{{ regForm.claim?.birthDate }}</p>
    <p class="summary-content">{{ regForm.claim?.email }}</p>

    <p class="summary-content">Mon agence : {{ regForm.claim?.agencyRef }}</p>

    <p class="summary-content">Référence compte : {{ regForm.claim?.agencyID }}/{{ regForm.claim?.accountNumber }}</p>
    <p
      class="summary-content"
      id="optionalIdentityField"
      *ngIf="regForm.claim?.optionalIdentityField && regForm.claim?.optionalIdentityFieldText"
    >
      {{ regForm.claim?.optionalIdentityFieldText }} : {{ regForm.claim?.optionalIdentityField }}
    </p>
  </div>

  <div class="summary-bloc">
    <div class="bloc-title">
      <p class="title">3 - Date de résiliation souhaitée</p>
      <div class="edit-link" (click)="changeStepOfResilationForm(2)">modifier</div>
    </div>
    <p class="summary-content" id="date" *ngIf="regForm.request?.deadline && !regForm.request?.resiliationDate">
      {{ regForm.request?.deadline }}
    </p>
    <p class="summary-content" id="date" *ngIf="regForm.request?.resiliationDate">
      {{ regForm.request?.deadline + ' ' + regForm.request?.resiliationDate }}
    </p>
  </div>

  <div>
    <p class="info">POUR INFORMATION</p>
    <p [innerHtml]="informations"></p>
  </div>

  <div class="confirm-button">
    <cb-cta-btn-link
      class="next confirm-recap"
      (click)="submitForm()"
      [button]="{ label: 'Confirmer', type: 'multi-step-form-next' }"
      [buttonWithoutLink]="true"
      [sliceType]="'summary'"
      [analyticsLabel]="tagName"
      [analyticsSubPath]="tagSubPath"
      analyticsOn
    ></cb-cta-btn-link>
  </div>
</div>

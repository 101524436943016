<form class="form form-width" [formGroup]="regForm.get('claim')" novalidate>
  <div id="claim">
    <h2>Informations</h2>
    <div class="row">
      <div class="column">
        <h3>Identité</h3>
        <div formFieldControl id="civilite">
          <label>Civilité*</label>
          <div formFieldRadioGroup class="form-field-control--half-size--block">
            <form-field-radio id="civilTitle" name="civilTitle" formControlName="civilTitle" value="Mme">
              <span class="ic ic-woman civil-icon"></span>
              Madame
              <div class="check-icon">
                <span class="ic-check"></span>
              </div>
            </form-field-radio>
            <form-field-radio name="civilTitle" formControlName="civilTitle" value="M" class="form-field-radio--right">
              <span class="ic ic-man civil-icon"></span>
              Monsieur
              <div class="check-icon">
                <span class="ic-check"></span>
              </div>
            </form-field-radio>
          </div>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="lastName" class="form-field-control--half-size--block">
          <label>
            Nom*
            <input formControlName="lastName" type="text" placeholder="Dupont" formFieldInput />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="firstName" class="form-field-control--half-size--block">
          <label
            >Prénom*
            <input placeholder="Antoine" type="text" formControlName="firstName" formFieldInput />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size--block">
          <label>
            E-mail*
            <input formControlName="email" id="email" type="email" formFieldInput placeholder="exemple@mail.com" />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="birthDate" class="form-field-control--half-size--block">
          <label>
            Date de naissance*
            <datepicker formFieldDatepicker formControlName="birthDate"></datepicker>
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="phone" class="form-field-control--half-size--block">
          <label>
            Numéro de téléphone*
            <input
              formControlName="phone"
              type="text"
              formFieldInput
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00 00 00 00 00"
              placeholder="01 23 45 67 89"
            />
          </label>
          <form-field-error></form-field-error>
        </div>
      </div>
      <div class="column">
        <h3>Adresse</h3>
        <div formFieldControl id="street" class="form-field-control--half-size--block">
          <label
            >Rue*
            <input placeholder="25 rue Saint Louis" type="text" formControlName="street" formFieldInput />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="city" class="form-field-control--half-size--block">
          <label
            >Ville*
            <input placeholder="Paris" type="text" formControlName="city" formFieldInput />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="postalCode" class="form-field-control--half-size--block">
          <label
            >Code postal*
            <input
              placeholder="75011"
              formControlName="postalCode"
              formFieldInput
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00000"
            />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div
          formFieldControl
          id="clientCategory"
          class="form-field-control--half-size--block"
          *ngIf="regForm.get('identity.clientFunction')?.value === 0"
        >
          <label>
            Je suis un client : *
            <form-field-select
              formFieldSelect
              [options]="clientCategoryOptions"
              [formControlName]="'clientCategory'"
              [selectError]="selectError"
              [placeholder]="placeholder_client_type"
              [(ngModel)]="selectedClientCategoryValue"
            ></form-field-select>
          </label>
          <form-field-error></form-field-error>
        </div>
        <div
          formFieldControl
          id="businessName"
          class="form-field-control--half-size--block"
          [style.visibility]="cssDisplayForCompanyName"
        >
          <label
            >Raison sociale (si personne morale)
            <input placeholder="MaSociété SAS" type="text" formControlName="businessName" formFieldInput />
          </label>
          <form-field-error></form-field-error>
        </div>
      </div>
    </div>
    <div [style.display]="cssDisplayForAgencyForm">
      <h2>Agence LCL</h2>
      <div class="agencies-search-box">
        <div>
          <div formFieldControl class="form-field-control--half-size">
            <app-agency-form
              (optionSelected)="searchNearAgencies($event)"
              [isChildForm]="true"
              [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
            >
            </app-agency-form>
          </div>
        </div>
        <div
          *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
          formFieldControl
          class="agencies-result-container"
        >
          <label>Sélectionnez une agence dans la liste de résultats.</label>
          <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
          <div *ngIf="agencies.length === 0" class="agency-count">
            Aucune agence LCL trouvée. Veuillez refaire une recherche
          </div>
          <div class="agencies-container">
            <div
              *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
              class="agency"
              [ngClass]="{ 'agency-odd': odd }"
              (click)="updateAgencyData(agency)"
            >
              <div class="agency-info-name">{{ agency.name }}</div>
              <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
            </div>
          </div>
        </div>
        <div formFieldControl class="hide-item">
          <label
            >Agence
            <input formControlName="agencyRef" formFieldInput type="text" />
          </label>
        </div>
      </div>
      <h2>Référence compte</h2>
      <h3>Champs non obligatoires mais recommandés</h3>
      <div formFieldControl id="agencyID" class="form-field-control--half-size">
        <label
          >Indicatif
          <input
            placeholder="01234"
            formControlName="agencyID"
            formFieldInput
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00000"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl id="accountNumber" class="form-field-control--half-size form-field-control--right">
        <label
          >Compte
          <input placeholder="012345 + clé" formControlName="accountNumber" formFieldInput type="text" />
        </label>
        <form-field-error></form-field-error>
      </div>
    </div>
    <div *ngIf="regForm.get('identity.clientFunction')?.value === 2">
      <div formFieldControl id="clientType" class="form-field-control--half-size subject-form-field-control">
        <label>
          Lien avec le client pour lequel vous intervenez
          <ng-select formFieldInput formControlName="clientType" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of clientTypeOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
      </div>
      <h2>Identité du client pour lequel vous intervenez</h2>
      <div class="row">
        <div class="column">
          <h3>Identité</h3>
          <div formFieldControl id="civilTitleClient">
            <label>Civilité*</label>
            <div formFieldRadioGroup class="form-field-control--half-size--block">
              <form-field-radio name="civilTitleClient" formControlName="civilTitleClient" value="Mme">
                <span class="ic ic-woman civil-icon"></span>
                Madame
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
              <form-field-radio
                name="civilTitleClient"
                formControlName="civilTitleClient"
                value="M"
                class="form-field-radio--right"
              >
                <span class="ic ic-man civil-icon"></span>
                Monsieur
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
            </div>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="lastNameClient" class="form-field-control--half-size--block">
            <label>
              Nom*
              <input formControlName="lastNameClient" type="text" placeholder="Dupont" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="firstNameClient" class="form-field-control--half-size--block">
            <label
              >Prénom*
              <input placeholder="Antoine" type="text" formControlName="firstNameClient" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="emailClient" class="form-field-control--half-size--block">
            <label>
              E-mail
              <input formControlName="emailClient" type="email" formFieldInput placeholder="exemple@mail.com" />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="birthDateClient" class="form-field-control--half-size--block">
            <label>
              Date de naissance
              <datepicker [formControlName]="'birthDateClient'"></datepicker>
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="phoneClient" class="form-field-control--half-size--block">
            <label>
              Numéro de téléphone
              <input
                formControlName="phoneClient"
                type="text"
                formFieldInput
                inputmode="numeric"
                pattern="[0-9]*"
                mask="00 00 00 00 00"
                placeholder="01 23 45 67 89"
              />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div
            formFieldControl
            id="clientCategory"
            class="form-field-control--half-size--block"
            *ngIf="regForm.get('identity.clientFunction')?.value === 2"
          >
            <label>
              Je suis un client : *
              <form-field-select
                formFieldSelect
                [options]="clientCategoryOptions"
                [formControlName]="'clientCategory'"
                [selectError]="selectError"
              ></form-field-select>
            </label>
            <form-field-error></form-field-error>
          </div>
        </div>
        <div class="column">
          <h3>Adresse</h3>
          <div formFieldControl id="streetClient" class="form-field-control--half-size--block">
            <label
              >Rue*
              <input placeholder="25 rue Saint Louis" type="text" formControlName="streetClient" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="cityClient" class="form-field-control--half-size--block">
            <label
              >Ville*
              <input placeholder="Paris" type="text" formControlName="cityClient" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="postalCodeClient" class="form-field-control--half-size--block">
            <label
              >Code postal*
              <input
                placeholder="75011"
                formControlName="postalCodeClient"
                formFieldInput
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                mask="00000"
              />
            </label>
            <form-field-error></form-field-error>
          </div>
        </div>
      </div>
    </div>
    <p class="mandatory-field-text">*Champs obligatoires</p>
    <p>
      {{ this.text_rgpd }}
    </p>
  </div>
  <div class="button-wrapper">
    <cb-cta-btn-link
      class="previous"
      (click)="previous()"
      [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <cb-cta-btn-link
      class="next"
      (click)="onSubmit()"
      [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
  </div>
</form>

import { ElementRef } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

export interface ElementOffsets {
  top: number;
  left: number;
}

export interface ElementSize {
  width: number;
  height: number;
}

export interface ElementRect extends ElementOffsets, ElementSize {}

export function getAbsoluteOffsets(element: HTMLElement): ElementOffsets {
  const offsets = { left: 0, top: 0 };

  while (element) {
    offsets.left += element.offsetLeft || 0;
    offsets.top += element.offsetTop || 0;

    element = element.offsetParent as HTMLElement;
  }

  return offsets;
}

export function getAbsoluteRect(element: HTMLElement): ElementRect {
  const size = getElementSize(element);
  const offsets = getAbsoluteOffsets(element);

  return {
    width: size.width,
    height: size.height,
    left: offsets.left,
    top: offsets.top,
  };
}

export function getElementSize(element: HTMLElement): ElementSize {
  return {
    width: Math.max(element.offsetWidth || 0, element.clientWidth || 0, element.scrollWidth || 0),
    height: Math.max(element.offsetHeight || 0, element.clientHeight || 0, element.scrollHeight || 0),
  };
}

export function getDocumentHeight(doc: Document): number {
  if (doc.body && doc.body.clientHeight && doc.documentElement.clientHeight && doc.documentElement.scrollHeight) {
    return Math.max(
      doc.body.clientHeight || 0,
      doc.documentElement.clientHeight || 0,
      doc.documentElement.scrollHeight || 0,
    );
  }
  return 0;
}

export function getRelativeOffsets(win: Window, element: HTMLElement): ElementOffsets {
  let top = 0;
  let left = 0;

  let currentElement = element;
  let currentElementStyle: CSSStyleDeclaration;

  do {
    currentElementStyle =
      currentElement !== element ? win.getComputedStyle(currentElement) : ({} as CSSStyleDeclaration);

    if (currentElementStyle.position === 'relative') {
      break;
    }

    if (currentElementStyle.position !== 'absolute') {
      top += currentElement.offsetTop || 0;
      left += currentElement.offsetLeft || 0;
    }

    currentElement = currentElement.offsetParent as HTMLElement;
  } while (currentElement);

  return {
    top,
    left,
  };
}

export function getRelativeRect(win: Window, element: HTMLElement): ElementRect {
  const size = getElementSize(element);
  const offsets = getRelativeOffsets(win, element);

  return {
    width: size.width,
    height: size.height,
    left: offsets.left,
    top: offsets.top,
  };
}

export function getViewportSize(win: Window): { width: number; height: number } {
  if (!win) {
    return { width: 0, height: 0 };
  }

  return {
    width: win.innerWidth,
    height: win.innerHeight,
  };
}

export function getWindowScrollPosition(win: Window): ElementOffsets {
  let left = 0;
  let top = 0;

  if (win) {
    if (win.pageXOffset !== undefined && win.pageYOffset !== undefined) {
      left = win.pageXOffset;
      top = win.pageYOffset;
    } else {
      const htmlElement = win.document.documentElement || win.document.body.parentElement;

      left = htmlElement.scrollLeft;
      top = htmlElement.scrollTop;
    }
  }

  return {
    left,
    top,
  };
}

export function isInternetExplorer(win: Window | undefined | null): boolean {
  if (!win) {
    return false;
  }

  return win.navigator.userAgent.indexOf('MSIE ') !== -1 || win.navigator.userAgent.indexOf('Trident/') !== -1;
}

export function observeReadyStateChanges(
  win: Window,
  fakeReadyStateChangeDelay: number = 200,
): Observable<DocumentReadyState> {
  // simulate loading with a delay for IE only because ready state "complete" is reached too early
  if (isInternetExplorer(win)) {
    return of<DocumentReadyState>('complete').pipe(delay(fakeReadyStateChangeDelay));
  }

  // no need to add listener on server or when ready state is already "complete" on browser
  if (!win || win.document.readyState === 'complete') {
    return of<DocumentReadyState>('complete');
  }

  // observe ready state changes on browser when needed (except for IE)
  return fromEvent<ProgressEvent>(win.document, 'readystatechange').pipe(
    map<ProgressEvent, DocumentReadyState>(() => win.document.readyState),
  );
}

export function triggerElementByClassName(win: Window, elementRef: ElementRef, className: string, callback: any): void {
  const anchors = elementRef.nativeElement.querySelectorAll(className);
  Array.prototype.forEach.call(anchors, (anchor: HTMLAnchorElement) => {
    anchor.addEventListener('click', callback, false);
  });
}

export async function loadImage(src: string, alt?: string, crossOrigin?: boolean): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    if (crossOrigin) {
      image.crossOrigin = 'Anonymous';
    }
    if (alt) {
      image.alt = alt;
    }
    image.src = src;
    image.onerror = () => {
      const error = new Error(`Image ${src} is not loaded.`);
      reject(error);
    };
  });
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fromEvent, Subscription } from 'rxjs';

import { TableItem, TableTitle } from '@repo/shared';
import { StateService } from '../../../../services/state.service';
import { WINDOW } from '../../../../services/window.provider';
import * as fileHelper from '../../../../helpers/file.helpers';
import { SLICE_TAGGING_NAME } from '../../../../common/constants/tagging';

@Component({
  selector: 'slice-table-desktop',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template #tableHeader>
      <thead>
        <tr>
          <th class="first-column"></th>
          <th *ngFor="let item of content">
            <div class="headline">
              <cb-responsive-img *ngIf="item.image && item.image.url" [desktopImage]="item.image"> </cb-responsive-img>
              <a
                *ngIf="item.link && item.link.url; else titleOnly"
                [attr.href]="item.link.url"
                [attr.target]="isPdf(item.link.url) ? '_blank' : item.link.target"
                analyticsOn
                [analyticsLabel]="getAnalyticsLabel(item.title)"
              >
                <span *ngIf="isPdf(item.link.url)" class="ic-pdf"></span>
                <span class="label line-break">{{ item.title }}</span>
              </a>
              <ng-template #titleOnly>
                <span class="line-break">{{ item.title }}</span>
              </ng-template>
            </div>
          </th>
        </tr>
      </thead>
    </ng-template>
    <!-- Fixed Table Header -->
    <table
      class="sticky"
      [@enterIn]="animationState"
      [style.width.px]="tableDesktopWidth"
      [style.top.px]="stickyPosition"
    >
      <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
    </table>
    <table>
      <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
      <tbody>
        <tr *ngFor="let title of titles">
          <th class="first-column" [innerHTML]="title.label"></th>
          <td *ngFor="let item of content" [innerHTML]="item[title.id] | prettyBooleanValue"></td>
        </tr>
      </tbody>
    </table>
  `,
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('enterIn', [
      state(
        'inTop',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        }),
      ),
      state(
        'outTop',
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
      ),
      state(
        'outDown',
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
      ),
      state(
        'inDown',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        }),
      ),
      transition('inDown => outDown', animate('0.3s ease-out')),
      transition('outDown => inDown', animate('0.3s ease-out')),
    ]),
  ],
})
export class TableComponent implements AfterViewInit, OnDestroy {
  @Input() content: Array<Partial<TableItem>>;
  @Input() titles: TableTitle[];
  @Input() selectedCategory: string;

  animationState = 'outTop';
  direction: string;

  get tableDesktopWidth(): number {
    return this.elementRef.nativeElement.offsetWidth;
  }

  get stickyPosition(): number {
    return this.stateService.get().stickyHeaderBottomPosition;
  }

  private stateServiceSubscription: Subscription | null = null;
  private windowSubscription: Subscription = new Subscription();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly stateService: StateService,
    @Inject(WINDOW) private readonly win: Window,
    private readonly ngZone: NgZone,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.stateServiceSubscription = this.stateService.get$().subscribe(() => this.changeDetectorRef.markForCheck());

    if (this.win) {
      this.ngZone.runOutsideAngular(() => {
        this.windowSubscription.add(
          fromEvent(this.win, 'scroll').subscribe(() => {
            this.onScroll();
          }),
        );
        this.windowSubscription.add(
          fromEvent(this.win, 'resize').subscribe(() => {
            this.onResize();
          }),
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (this.stateServiceSubscription) {
      this.stateServiceSubscription.unsubscribe();
      this.stateServiceSubscription = null;
    }

    if (this.windowSubscription) {
      this.windowSubscription.unsubscribe();
      this.windowSubscription = (null as unknown) as Subscription;
    }
  }

  isPdf(filePath: string): boolean {
    return fileHelper.isPdf(filePath);
  }

  onScroll(): void {
    const scrollPosition = this.win ? this.win.pageYOffset : 0;
    const element = this.elementRef.nativeElement;
    const componentTopPosition = element.offsetParent
      ? element.offsetTop + (element.offsetParent as HTMLElement).offsetTop
      : element.offsetTop;
    const componentHeight = element.offsetHeight;
    const componentDownPosition = componentTopPosition + componentHeight;
    const animationState = this.getAnimationState(scrollPosition, componentTopPosition, componentDownPosition);

    if (animationState && animationState !== this.animationState) {
      this.animationState = animationState;

      this.changeDetectorRef.detectChanges();
    }
  }

  getAnimationState(
    scrollPosition: number,
    componentTopPosition: number,
    componentDownPosition: number,
    heightForSmoothDetach: number = 180,
  ): string | undefined {
    const scrollPositionWithOffset = scrollPosition + this.stickyPosition;

    if (scrollPositionWithOffset < componentTopPosition) {
      return 'outTop';
    }
    if (scrollPositionWithOffset > componentDownPosition - heightForSmoothDetach) {
      return 'outDown';
    }
    if (scrollPositionWithOffset < componentDownPosition / 2) {
      return 'inTop';
    }
    if (scrollPositionWithOffset > componentDownPosition / 2) {
      return 'inDown';
    }
  }

  getAnalyticsLabel(linkLabel: string): string {
    return SLICE_TAGGING_NAME['table'] + '_' + linkLabel;
  }

  private onResize(): void {
    this.changeDetectorRef.detectChanges();
  }
}

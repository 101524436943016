import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFooterComponent } from './ui-footer/ui-footer.component';
import { FooterComponent } from './footer/footer.component';
import { MenuToggleComponent } from './menu-toggle/menu-toggle.component';
import { FooterService } from './footer.service';
import { AnalyticsModule } from '../../analytics/analytics.module';

@NgModule({
  imports: [CommonModule, AnalyticsModule],
  declarations: [MenuToggleComponent, FooterComponent, UiFooterComponent],
  exports: [FooterComponent],
  providers: [FooterService],
})
export class FooterModule {}

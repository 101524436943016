import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { PageMarket, StateService } from './state.service';
import { Injectable, OnDestroy } from '@angular/core';

export const ThemeClassNames: Record<string, string> = {
  [PageMarket.Particulier]: 'lcl--part',
  [PageMarket.BanquePrivee]: 'lcl--bp',
};
export const ThemeClassNamesMap = new Map(Object.entries(ThemeClassNames));
export const DEFAULT_THEME_CLASSNAME = ThemeClassNames.particulier;

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private _themeClass: Subject<string> = new BehaviorSubject(PageMarket.Particulier);
  private _subscription: Subscription | null;

  constructor(private readonly _stateService: StateService) {
    this._subscription = this._stateService.get$().subscribe(({ pageMarket, pageUid }) => {
      const checkPageMarket = !!pageMarket;
      const checkPageUid = !pageMarket && pageUid;

      let themeClass = ThemeClassNamesMap.get(PageMarket.Particulier) as string;
      if (checkPageMarket && ThemeClassNamesMap.has(pageMarket as string)) {
        themeClass = ThemeClassNamesMap.get(pageMarket as string) as string;
      } else if (checkPageUid && ThemeClassNamesMap.has(pageUid as string)) {
        themeClass = ThemeClassNamesMap.get(pageUid as string) as string;
      }

      this.setThemeClass(themeClass);
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this._subscription = null;
  }

  setThemeClass(className: string): void {
    this._themeClass.next(className);
  }

  getThemeClass$(): Observable<string> {
    return this._themeClass.asObservable();
  }
}

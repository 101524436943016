import { Subscription } from 'rxjs';
import { Component, Input, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { QUESTION_IDENTITY_FORM } from '@repo/shared/form-schemas/question-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'question-form-identity',
  templateUrl: './question-form-identity.component.html',
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: QUESTION_IDENTITY_FORM,
    },
  ],
})
export class QuestionFormIdentityComponent extends AbstractFormComponent {
  @Input() regForm: FormGroup;
  @Input() placeholder_relation: string;
  @Input() clientFunctionOptions: string[];
  @Input() text_rgpd: string;

  _requestTypeSubscription: Subscription;

  _buttonDisabled = false;
  _hasPostError = false;

  selectError = false;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _questionFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_questionFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  onSubmit(): void {
    if (!this.regForm.get('clientFunction')?.valid) {
      this.selectError = true;
    }
    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

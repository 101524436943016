import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { ButtonSlice } from '@repo/shared';

@Component({
  selector: 'slice-buttons',
  template: `
    <div class="zone-buttons">
      <ng-container *ngFor="let button of data.buttons">
        <cb-cta-btn-link class="cta-button" *ngIf="button" [button]="button" [sliceType]="data.type"></cb-cta-btn-link>
      </ng-container>
    </div>
  `,
  styleUrls: ['./slice-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceButtonsComponent implements SliceWithData<ButtonSlice> {
  @Input() data: ButtonSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;
}

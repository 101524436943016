import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ImageWithMobile, OpenGraph, Seo } from '@repo/shared';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../config.service';

enum Constants {
  TAG_LINK = 'link',
  ATTR_REL = 'rel',
  ATTR_HREF = 'href',
  ATTR_VAL = 'canonical',
  OG_TITLE = 'og:title',
  OG_DESCRIPTION = 'og:description',
  OG_URL = 'og:url',
  OG_TYPE = 'og:type',
  OG_IMAGE = 'og:image',
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  addSeo(seo: Seo, renderer: Renderer2): void {
    if (seo) {
      const { title, description, robots, canonical, openGraph } = seo;
      if (title) {
        this.title.setTitle(title);
      }
      if (description) {
        this.meta.updateTag({ name: 'description', content: description });
      }
      if (robots) {
        this.meta.updateTag({ name: 'robots', content: robots });
      }
      this.updateOpenGraph(openGraph);
      this.updateCanonicalLink(canonical as string, renderer);
    }
  }

  private updateOpenGraph(openGraph: OpenGraph): void {
    const ogMetas: { [key: string]: string | null } = {};
    ogMetas[Constants.OG_TITLE] = null;
    ogMetas[Constants.OG_DESCRIPTION] = null;
    ogMetas[Constants.OG_URL] = null;
    ogMetas[Constants.OG_TYPE] = null;
    ogMetas[Constants.OG_IMAGE] = null;
    if (openGraph) {
      const { title, description, type, url, image } = openGraph;
      ogMetas[Constants.OG_TITLE] = title as string;
      ogMetas[Constants.OG_DESCRIPTION] = description as string;
      ogMetas[Constants.OG_URL] = url as string;
      ogMetas[Constants.OG_TYPE] = type as string;
      if (image && image.url) {
        ogMetas[Constants.OG_IMAGE] = this.getUrl(image);
      }
    }
    Object.keys(ogMetas).forEach(property => {
      const content = ogMetas[property];
      if (content) {
        this.meta.updateTag({ property, content });
      } else {
        this.meta.removeTag(`property="${property}"`);
      }
    });
  }

  private getUrl(image: ImageWithMobile): string {
    if (!image.url.startsWith('http')) {
      return `${this.configService.get<string>('FRONT_URL')}/${image.url}`;
    }
    return image.url;
  }

  private updateCanonicalLink(canonical: string, renderer: Renderer2): void {
    if (canonical) {
      this.addCanonicalLink(renderer, canonical);
    } else {
      this.removeCanonicalLink(renderer);
    }
  }

  private addCanonicalLink(renderer: Renderer2, canonical: string): void {
    let canonicalLink = this.getCanonicalLink();

    if (!canonicalLink) {
      canonicalLink = renderer.createElement(Constants.TAG_LINK) as Element;
      canonicalLink.setAttribute(Constants.ATTR_REL, Constants.ATTR_VAL);
      renderer.appendChild(this.document.head, canonicalLink);
    }

    canonicalLink.setAttribute(Constants.ATTR_HREF, canonical);
  }

  private removeCanonicalLink(renderer: Renderer2): void {
    const canonicalLink = this.getCanonicalLink();
    if (canonicalLink) {
      renderer.removeChild(this.document.head, canonicalLink);
    }
  }

  private getCanonicalLink(): Element | undefined {
    const elements = this.document.head.getElementsByTagName(Constants.TAG_LINK);
    return Array.from(elements).find(
      (element: Element) => element.getAttribute(Constants.ATTR_REL) === Constants.ATTR_VAL,
    );
  }
}

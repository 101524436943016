import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmbedProvider, SocialEmbedsSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { SocialEmbedsService } from './social-embeds.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'slice-social-embeds',
  template: `
    <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"> </cb-mini-title>
    <div *ngFor="let embed of data.socialEmbeds" class="embed" [innerHtml]="sanitizeHtml(embed.html)"></div>
  `,
  styleUrls: ['./slice-social-embeds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceSocialEmbedsComponent implements SliceWithData<SocialEmbedsSlice>, AfterViewInit {
  @Input() data: SocialEmbedsSlice = {
    type: 'social_embeds',
    socialEmbeds: [],
  };

  constructor(
    private readonly platform: Platform,
    private readonly sliceSocialEmbedsService: SocialEmbedsService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit(): void {
    if (!this.platform.isBrowser) {
      return;
    }
    if (this.hasTwitterEmbeds()) {
      this.sliceSocialEmbedsService.initTwitterEmbeds();
    }

    if (this.hasTikTokEmbeds()) {
      this.sliceSocialEmbedsService.initTikTokEmbeds();
    }
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private hasTwitterEmbeds(): boolean {
    return this.data.socialEmbeds.some(embed => embed.provider === EmbedProvider.Twitter);
  }

  private hasTikTokEmbeds(): boolean {
    return this.data.socialEmbeds.some(embed => embed.provider === EmbedProvider.TikTok);
  }
}

import {
  Component,
  Input,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  forwardRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import {
  RECLAMATION_CLAIM_FORM,
  OPTIONS_CLIENT_CATEGORIES,
} from '@repo/shared/form-schemas/reclamation-connected-client';
import { DOMService } from '../../../../../services/dom.service';
import { AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { Subscription } from 'rxjs';
import { SliceFormsService } from '../../slice-forms.service';
import { AgenciesService } from '../../../../../services/agencies.service';
import { AgenciesData, AgenciesResult, Agency } from '../../../../../services/agencies.type';
import { AgencyFormComponent } from '../../../slice-agency/agency-form/agency-form.component';

@Component({
  selector: 'reclamation-connected-customer-claim',
  template: `
    <form class="form form-width" [formGroup]="regForm.get('claim')" novalidate>
      <div id="claim">
        <h2>Informations</h2>
        <div class="row">
          <div class="column">
            <h3>Identité</h3>
            <div formFieldControl>
              <label>Civilité*</label>
              <div formFieldRadioGroup class="form-field-control--half-size--block">
                <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
                  <span class="ic ic-woman civil-icon"></span>
                  Madame
                  <div class="check-icon">
                    <span class="ic-check"></span>
                  </div>
                </form-field-radio>
                <form-field-radio
                  name="civilTitle"
                  formControlName="civilTitle"
                  value="M"
                  class="form-field-radio--right"
                >
                  <span class="ic ic-man civil-icon"></span>
                  Monsieur
                  <div class="check-icon">
                    <span class="ic-check"></span>
                  </div>
                </form-field-radio>
              </div>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Nom*
                <input formControlName="lastName" type="text" placeholder="Dupont" formFieldInput />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Prénom*
                <input placeholder="Antoine" type="text" formControlName="firstName" formFieldInput />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                E-mail*
                <input formControlName="email" type="email" formFieldInput placeholder="exemple@mail.com" />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Date de naissance*
                <datepicker formFieldDatepicker formControlName="birthDate"></datepicker>
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Numéro de téléphone*
                <input
                  formControlName="phone"
                  type="text"
                  formFieldInput
                  inputmode="numeric"
                  pattern="[0-9]*"
                  mask="00 00 00 00 00"
                  placeholder="01 23 45 67 89"
                />
              </label>
              <form-field-error></form-field-error>
            </div>
          </div>
          <div class="column">
            <h3>Adresse</h3>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Rue*
                <input placeholder="25 rue Saint Louis" type="text" formControlName="street" formFieldInput />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Ville*
                <input placeholder="Paris" type="text" formControlName="city" formFieldInput />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Code postal*
                <input
                  placeholder="75011"
                  formControlName="postalCode"
                  formFieldInput
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  mask="00000"
                />
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Je suis un client : *
                <form-field-select
                  formFieldSelect
                  [options]="clientCategoryOptions"
                  [formControlName]="'clientCategory'"
                  [selectError]="selectError"
                ></form-field-select>
              </label>
              <form-field-error></form-field-error>
            </div>
            <div formFieldControl class="form-field-control--half-size--block">
              <label>
                Raison sociale (si personne morale)
                <input placeholder="MaSociété SAS" type="text" formControlName="businessName" formFieldInput />
              </label>
              <form-field-error></form-field-error>
            </div>
          </div>
        </div>
        <div>
          <h2>Agence LCL</h2>
          <div class="agencies-search-box">
            <div>
              <div formFieldControl class="form-field-control--half-size">
                <app-agency-form
                  (optionSelected)="searchNearAgencies($event)"
                  [isChildForm]="true"
                  [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
                >
                </app-agency-form>
              </div>
            </div>
            <div
              *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
              formFieldControl
              class="agencies-result-container"
            >
              <label>Sélectionnez une agence dans la liste de résultats.</label>
              <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
              <div *ngIf="agencies.length === 0" class="agency-count">
                Aucune agence LCL trouvée. Veuillez refaire une recherche
              </div>
              <div class="agencies-container">
                <div
                  *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
                  class="agency"
                  [ngClass]="{ 'agency-odd': odd }"
                  (click)="updateAgencyData(agency)"
                >
                  <div class="agency-info-name">{{ agency.name }}</div>
                  <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
                </div>
              </div>
            </div>
            <div formFieldControl class="hide-item">
              <label
                >Agence
                <input formControlName="agencyRef" formFieldInput type="text" />
              </label>
            </div>
          </div>
          <h2>Référence compte</h2>
          <h3>Champs non obligatoires mais recommandés</h3>
          <div formFieldControl class="form-field-control--half-size">
            <label
              >Indicatif
              <input
                placeholder="01234"
                formControlName="agencyID"
                formFieldInput
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                mask="00000"
              />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl class="form-field-control--half-size form-field-control--right">
            <label
              >Compte
              <input placeholder="012345 + clé" formControlName="accountNumber" formFieldInput type="text" />
            </label>
            <form-field-error></form-field-error>
          </div>
        </div>
        <p class="mandatory-field-text">*Champs obligatoires</p>
        <p>
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
          disponible sur la page d'accueil du site.
        </p>
      </div>
      <div class="button-wrapper">
        <cb-cta-btn-link
          class="previous"
          (click)="previous()"
          [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
          [buttonWithoutLink]="true"
          [sliceType]="'reclamation_connected_client_form'"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <cb-cta-btn-link
          class="next"
          (click)="onSubmit()"
          [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
          [buttonWithoutLink]="true"
          [sliceType]="'reclamation_connected_client_form'"
          [disabled]="_buttonDisabled"
        >
        </cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss', '../../slice-reclamation/reclamation-claim/reclamation-claim.component.scss'],
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_CLAIM_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ReclamationConnectedCustomerClaimComponent),
    },
  ],
})
export class ReclamationConnectedCustomerClaimComponent
  extends AbstractFormComponent
  implements OnDestroy, AfterViewInit {
  @Input() regForm: FormGroup;
  clientCategoryOptions = OPTIONS_CLIENT_CATEGORIES;

  _buttonDisabled = false;
  _hasPostError = false;
  selectError = false;

  agencies: Agency[] = [];
  showSearchAgenciesResults = false;
  @ViewChild(AgencyFormComponent) agencyFormComponent: AgencyFormComponent;
  selectedAgency: Agency;
  displayMandatoryAgencyMessage: boolean;
  private agencyQuerySubscription: Subscription;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly agenciesService: AgenciesService,
    private readonly viewportScroller: ViewportScroller,
    private readonly sliceFormsService: SliceFormsService,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngAfterViewInit(): void {
    const claimGroup = this.regForm.get('claim') as FormGroup;
    const agencyQueryControl = this.agencyFormComponent.agencyForm.get('query') as FormControl;
    claimGroup.addControl('agencyQuery', this.agencyFormComponent.agencyForm.get('query') as AbstractControl);
    agencyQueryControl.setParent(claimGroup);

    agencyQueryControl.setValidators([
      this.sliceFormsService._getFieldValidator(
        (RECLAMATION_CLAIM_FORM.properties as any)['agencyQuery'] as JSONSchema7,
        'agencyQuery',
      ),
    ]);
    agencyQueryControl.updateValueAndValidity();

    this.agencyQuerySubscription = this.initAgencyQuerySubscription();
  }

  ngOnDestroy(): void {
    if (this.agencyQuerySubscription) {
      this.agencyQuerySubscription.unsubscribe();
    }
  }

  previous(): void {
    this.myStepper.previous();
  }

  searchNearAgencies(agenciesData: AgenciesData): void {
    this.regForm.get('claim')?.patchValue({
      agencyQuery: this.agencyFormComponent.agencyForm.get('query')?.value,
    });

    // resetting selected agency value
    this.regForm.get('claim')?.patchValue({ agencyRef: '' });
    this.selectedAgency = (null as unknown) as Agency;

    this.agenciesService.searchNearAgencies(agenciesData.agenciesFilter).subscribe(agenciesResult => {
      // RSI-1623 Hide Profesionals Agencies
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult).filter(agency => agency.agencyType !== 'PRO');
      this.showSearchAgenciesResults = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  initAgencyQuerySubscription(): Subscription {
    return this.agencyFormComponent.agencyForm.get('query')?.valueChanges.subscribe(value => {
      if (!value) {
        this.showSearchAgenciesResults = false;
      }
    }) as Subscription;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies
      .sort((a, b) => (a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0))
      .slice(0, 10);
  }

  updateAgencyData(agency: Agency): void {
    this.selectedAgency = agency;
    this.displayMandatoryAgencyMessage = false;
    this.regForm.get('claim')?.patchValue({ agencyRef: ('0' + agency.id).slice(-5) });
    this.agencyFormComponent.agencyForm.patchValue({ query: agency.name.toUpperCase() });
  }

  onSubmit(): void {
    if (!this.regForm.get('claim.clientCategory')?.valid) {
      this.selectError = true;
    }
    if (this.regForm.get('claim')?.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm.get('claim') as FormGroup);
      if (!this.agencyFormComponent) {
        this.focusFirstInvalidFormField();
      } else {
        this.triggerFormFieldsStatus(this.agencyFormComponent.agencyForm as FormGroup);
        const errorsCount = super.getErrorCount(this.regForm.get('claim') as FormGroup);
        if (errorsCount === 1 && !this.regForm.get('claim.agencyRef')?.valid) {
          this.displayMandatoryAgencyMessage = true;
          const agencyQueryControl = this.elementRef.nativeElement.querySelector('app-agency-form');
          agencyQueryControl.focus({ preventScroll: true });
          this.domService.scrollTo(agencyQueryControl);
        } else {
          this.focusFirstInvalidFormField();
        }
      }
    }
  }
}

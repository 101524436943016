import { Logger } from './logger';

export class ConsoleLogger implements Logger {
  constructor(private namespace: string) {}

  info(value: any, ...rest: any[]): void {
    // tslint:disable-next-line:no-console
    this.log(console.info.bind(console), value, ...rest);
  }

  warn(value: any, ...rest: any[]): void {
    this.log(console.warn.bind(console), value, ...rest);
  }

  error(value: any, ...rest: any[]): void {
    this.log(console.error.bind(console), value, ...rest);
  }

  private log(method: (message?: any, ...optionalParams: any[]) => void, value: any, ...rest: any[]): void {
    method(`${value}[${this.namespace}]`, ...rest);
  }
}

import { Component, Input } from '@angular/core';
import { WithContext, FAQPage } from 'schema-dts';
import { SchemaOrgService } from '../../../schema-org.service';

import { FaqPage, FaqQuestion } from '@repo/shared';

type FaqPageType = FaqPage;

@Component({
  selector: 'cms-faq-landing',
  template: `
    <ui-faq-page *ngIf="data" [headerBackground]="background">
      <h1 uiFaqTitle>{{ data.title }}</h1>
      <div uiFaqDescription [innerHTML]="data.text"></div>
      <ui-faq-section *ngIf="questionsShown">
        <div role="section-h1" [innerHTML]="data.questionsTitle"></div>
        <ui-expansion-list>
          <ui-expansion-panel *ngFor="let question of data.questions" [title]="question.text">
            <div [innerHTML]="question.answer"></div>
          </ui-expansion-panel>
        </ui-expansion-list>
      </ui-faq-section>

      <cb-slices [data]="data.body"></cb-slices>
    </ui-faq-page>
    <app-json-ld *ngIf="schema" [json]="schema"></app-json-ld>
  `,
})
export class CmsFaqLandingComponent {
  @Input() data: FaqPageType;

  constructor(private readonly schemaOrgService: SchemaOrgService) {}

  get background(): string {
    return this.data?.image?.url as string;
  }

  get questionsShown(): boolean {
    return !!(this.data && this.data.questionsTitle && this.data.questions && this.data.questions.length);
  }

  public get schema(): WithContext<FAQPage> | undefined {
    return this.schemaOrgService.getFAQPageSchema(this.data?.questions as FaqQuestion[]);
  }
}

import { Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { MentionCreditSlice } from '@repo/shared';

@Component({
  selector: 'slice-mention-credit',
  template: `
    <ng-container *ngIf="data && data.description">
      <div class="mention-credit" [innerHTML]="data.description"></div>
    </ng-container>
  `,
  styleUrls: ['./slice-mention-credit.component.scss'],
})
export class SliceMentionCreditComponent implements SliceWithData<MentionCreditSlice> {
  @Input() data: MentionCreditSlice;
}

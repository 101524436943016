import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Link, PushLinksSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';

@Component({
  selector: 'slice-push-links',
  template: `
    <ui-push-block *ngIf="isShown">
      <ui-push-block-header [backgroundImage]="backgroundImage" [backgroundImageDesktop]="backgroundImageDesktop">
        <h2>{{ data.title }}</h2>
        <p>{{ data.description }}</p>
      </ui-push-block-header>
      <ui-push-block-links>
        <cb-cta-link *ngFor="let link of links" class="push-link" type="product-page" [link]="link"></cb-cta-link>
      </ui-push-block-links>
    </ui-push-block>
  `,
  styleUrls: ['./slice-push-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlicePushLinksComponent implements ComponentWithData {
  @Input() data: PushLinksSlice;

  get backgroundImage(): string {
    return this.data?.image?.mobile?.url as string;
  }

  get backgroundImageDesktop(): string {
    return this.data?.image?.url as string;
  }

  get links(): Link[] {
    return (this.data && this.data.links) || [];
  }

  get isShown(): boolean {
    return !!this.data;
  }
}

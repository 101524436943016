import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { ProjectBlockComponent } from './project-block/project-block.component';
import { SliceProjectBlockCtaComponent } from './slice-project-block-cta.component';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { UiSlideshowBlockModule } from '../../../ui/molecules/slideshow-block/slideshow-block.module';

@NgModule({
  imports: [CommonModule, InViewportModule, CommonBehaviorsModule, UiSlideshowBlockModule],
  declarations: [ProjectBlockComponent, SliceProjectBlockCtaComponent],
  exports: [SliceProjectBlockCtaComponent],
})
export class SliceProjectBlockCtaModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiContactsBlockModule } from '../../../ui/molecules/contacts-block/contacts-block.module';
import { SliceContactsPresseComponent } from './slice-contacts-presse.component';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { AtomsModule } from '../../../ui/atoms/atoms.module';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule, UiContactsBlockModule, AtomsModule],
  declarations: [SliceContactsPresseComponent],
  exports: [SliceContactsPresseComponent],
  entryComponents: [SliceContactsPresseComponent],
})
export class SliceContactsPresseModule {}

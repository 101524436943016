import { Link, SliceType } from '@repo/shared';
import { SLICE_TAGGING_NAME } from '../common/constants/tagging';
import { AbstractCTAComponent } from '../cms/common-behaviors/link/abstract-cta-component';
import { Input, Directive } from '@angular/core';

export interface Analyzable {
  sliceType: SliceType;
  analyticsLabel: string;
  analyticsActive?: boolean;
  CTA: Link;
}

@Directive()
export abstract class AbstractAnalyticsCTADirective extends AbstractCTAComponent implements Analyzable {
  @Input() sliceType: SliceType;
  @Input() analyticsSubPath: string;

  get analyticsLabel(): string {
    if (this._analyticsLabel) {
      return this._analyticsLabel;
    }
    if (this.CTA && this.CTA.label) {
      return `${SLICE_TAGGING_NAME[this.sliceType]}_${this.CTA.label}`;
    }
    return '';
  }

  @Input()
  set analyticsLabel(value: string) {
    this._analyticsLabel = value;
  }

  private _analyticsLabel;
}

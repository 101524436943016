import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersBlocksService {
  private filterToggledSubject = new Subject<number>();
  readonly filterToggled$ = this.filterToggledSubject.asObservable();

  // keeping track of the last toggled filter
  updateToggle(filterNumber: number): void {
    this.filterToggledSubject.next(filterNumber);
  }

  closeDropDowns(): void {
    this.filterToggledSubject.next(0);
  }
}

import { ElementRef, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { JSONSchema7 } from 'json-schema';
import { DOMService } from '../../../services/dom.service';

export abstract class AbstractFormComponent {
  protected constructor(
    protected readonly _validationSchema: JSONSchema7,
    private readonly _elementRef: ElementRef,
    private readonly _domService: DOMService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  getFormValidationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  getFormFieldValidationSchema(field: string): JSONSchema7 {
    const validationSchema = this.getFormValidationSchema();

    return ((validationSchema &&
      validationSchema.properties &&
      validationSchema.properties[field]) as unknown) as JSONSchema7;
  }

  triggerFormFieldsStatus(form: FormGroup): void {
    Object.keys(form.controls).forEach((controlName: string) => {
      const control = form.get(controlName) as AbstractControl;
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    });
    this._changeDetectorRef.detectChanges();
  }

  focusFirstInvalidFormField(): void {
    const invalidFormControlSelector = '.form-field-control--invalid';
    const firstInvalidFormInput = this._elementRef.nativeElement.querySelector(`${invalidFormControlSelector} input`);
    if (firstInvalidFormInput) {
      firstInvalidFormInput.focus({ preventScroll: true });
    }
    const firstInvalidFormControl = this._elementRef.nativeElement.querySelector(invalidFormControlSelector);
    this._domService.scrollTo(firstInvalidFormControl);
  }

  focusFirstFormField(): void {
    const firstFormControlSelector = '.form-field-control';
    const firstFormControl = this._elementRef.nativeElement.querySelector(firstFormControlSelector);
    this._domService.scrollTo(firstFormControl, { offset: -150 });
  }

  getErrorCount(container: FormGroup): number {
    let errorCount = 0;
    for (const controlKey in container.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (container.controls.hasOwnProperty(controlKey)) {
        const controlErrors = container.controls[controlKey].errors;
        if (controlErrors) {
          errorCount += Object.keys(controlErrors).length;
        }
      }
    }
    return errorCount;
  }
}

import { Component, Input } from '@angular/core';
import { NoteSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

@Component({
  selector: 'slice-note',
  template: `
    <ng-container *ngIf="data && data.notes && data.notes.length">
      <div class="slice-note-group" *ngFor="let note of data.notes">
        <div *ngIf="note.title" class="slice-note-title">{{ note.title }}</div>
        <div *ngIf="note.richText" class="slice-note-content" [innerHTML]="note.richText"></div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-note.component.scss'],
})
export class SliceNoteComponent implements SliceWithData<NoteSlice> {
  @Input() data: NoteSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;
}

import { InjectionToken } from '@angular/core';

export const NEC_UNSUPPORTED_BROWSER = new InjectionToken('NEC_UNSUPPORTED_BROWSER', {
  providedIn: 'root',
  factory: necUnsupportedBrowserFactory,
});

export function necUnsupportedBrowserFactory(): boolean {
  const win = typeof window !== 'undefined' ? window : null;
  const userAgent = win?.navigator?.userAgent;

  return userAgent ? isIe(userAgent) : false;
}

export function isIe(userAgent: string): boolean {
  return /MSIE \d|Trident.*rv:/.test(userAgent);
}

import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { CookieOptions, Request, Response } from 'express';
import { getCookie } from '../helpers/cookie.helpers';
import { ConfigService } from '../config.service';
import { COOKIE_DOMAIN, COOKIE_PATH } from '../common/constants/cookies';

/**
 * @see https://github.com/7leads/ngx-cookie-service/blob/master/lib/cookie-service/cookie.service.ts
 */
@Injectable({
  providedIn: 'root',
})
export class CookieService {
  platformBrowser: boolean;
  request: Request;
  response: Response;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly configService: ConfigService,
    private injector: Injector,
  ) {
    this.platformBrowser = isPlatformBrowser(platformId);
    if (isPlatformServer(platformId)) {
      this.request = this.injector.get(REQUEST);
      this.response = this.injector.get(RESPONSE);
    }
  }

  get(name: string): string | null {
    return getCookie(this.getRawCookies(), name);
  }

  set(name: string, value: string, cookieOptions: CookieOptions = {}): void {
    const defaultDomain = this.configService.get<boolean>('PROD') ? COOKIE_DOMAIN : null;
    const { path = COOKIE_PATH, domain = defaultDomain } = cookieOptions;

    const mergedCookieOptions: CookieOptions = {
      ...cookieOptions,
      path,
      domain: domain as string,
    };

    if (this.platformBrowser) {
      this.createCookieForBrowser(name, value, mergedCookieOptions);
    } else if (this.request) {
      this.response.cookie(name, value, mergedCookieOptions);
    }
  }

  createCookieForBrowser(name: string, value: string, cookieOptions?: CookieOptions): void {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (cookieOptions) {
      const { expires, path, domain, secure, sameSite } = cookieOptions;

      if (expires instanceof Date) {
        cookie += `expires=${(expires as Date).toUTCString()};`;
      }

      if (path) {
        cookie += `path=${path};`;
      }

      if (domain) {
        cookie += `domain=${domain};`;
      }

      if (secure) {
        cookie += 'secure;';
      }

      if (sameSite) {
        cookie += `sameSite=${sameSite};`;
      }
    }

    this.document.cookie = cookie;
  }

  delete(name: string, path?: string, domain?: string): void {
    this.set(name, '', { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain });
  }

  private getRawCookies(): string {
    if (this.platformBrowser) {
      return this.document.cookie;
    } else if (this.request) {
      return (this.request.headers.cookie as string) || '';
    }
    return '';
  }
}

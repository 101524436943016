import { Injectable } from '@angular/core';

import { ConfigService } from '../config.service';

@Injectable()
export class HereMapService {
  private readonly HERE_APP_ID: string;
  private readonly HERE_APP_CODE: string;

  constructor(private configService: ConfigService) {
    this.HERE_APP_ID = this.configService.get('HERE_APP_ID');
    this.HERE_APP_CODE = this.configService.get('HERE_APP_CODE');
  }

  getMapLayer(
    tileType: 'base' | 'aerial',
    scheme: 'normal' | 'terrain' | 'satellite' | 'hybrid',
    highResolution: boolean = false,
  ): string {
    const loadBalancing: 1 | 2 | 3 | 4 = 1;
    const baseUrl = `https://${loadBalancing}.${tileType}.maps.api.here.com/maptile/2.1/maptile/newest/`;
    const format: 'png' | 'png8' | 'jpg' = scheme === 'satellite' || scheme === 'hybrid' ? 'jpg' : 'png8';
    const authent = `?app_id=${this.HERE_APP_ID}&app_code=${this.HERE_APP_CODE}`;
    const ppi = highResolution ? '&ppi=320' : '';

    return `${baseUrl}${scheme}.day/{z}/{x}/{y}/512/${format}${authent}${ppi}`;
  }
}

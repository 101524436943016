import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  // noinspection JSUnusedGlobalSymbols
  overrides = {
    swipe: { direction: 6 },
    pan: { enable: false },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PushProductsSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

@Component({
  selector: 'slice-push-products',
  template: `
    <ui-push-block *ngIf="isShown">
      <ui-push-block-header [backgroundImage]="backgroundImage" [backgroundImageDesktop]="backgroundImageDesktop">
        <h2>{{ data.title }}</h2>
        <p>{{ data.description }}</p>
      </ui-push-block-header>
      <ui-push-block-group *ngFor="let group of data.pushProductsGroups" [title]="group.title">
        <ui-push-block-item *ngFor="let product of group.products">
          <cb-cta-link type="product-page" [link]="product" [analyticsLabel]="getAnalyticsLabel(product.label)">
            <div [innerHTML]="product.description"></div>
          </cb-cta-link>
        </ui-push-block-item>
      </ui-push-block-group>
    </ui-push-block>
  `,
  styleUrls: ['./slice-push-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlicePushProductsComponent implements ComponentWithData {
  @Input() data: PushProductsSlice;

  get backgroundImage(): string {
    return this.data?.image?.mobile?.url as string;
  }

  get backgroundImageDesktop(): string {
    return this.data?.image?.url as string;
  }

  get isShown(): boolean {
    return !!this.data;
  }

  getAnalyticsLabel(linkLabel: string): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${linkLabel}`;
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const seeMoreAnimation = trigger('enterIn', [
  state('visible', style({ height: '*', overflow: 'hidden' })),
  state('new', style({ height: '*', overflow: 'hidden' })),
  transition('* => new', [
    style({ height: '0', overflow: 'hidden' }),
    animate('0.5s ease-in-out', style({ height: '*' })),
  ]),
]);

import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectionStrategy,
  forwardRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { JSONSchema7 } from 'json-schema';

import { AbstractFormComponent } from '../abstract-form-component';
import { SliceWithData } from '../../../../../typings';
import { QuestionFormSlice } from '@repo/shared';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../tokens';
import {
  QUESTION_CLAIM_FORM,
  QUESTION_IDENTITY_FORM,
  QUESTION_REQUEST_FORM,
} from '@repo/shared/form-schemas/question-client';
import { SliceFormsService } from '../slice-forms.service';
import { DOMService } from '../../../../services/dom.service';
import { LoggerService } from '../../../../logger/logger.service';
import { Logger } from '../../../../logger/logger';
import { FormGroup } from '@angular/forms';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'slice-question-form',
  template: `
    <div id="reclamation">
      <form id="regForm" [formGroup]="form">
        <cb-mini-title class="mini-title" *ngIf="data.title" [title]="data.title" [withFrame]="false"></cb-mini-title>
        <cdk-stepper #stepper linear>
          <cdk-step formGroupName="identity" [stepControl]="form.get('identity')">
            <question-form-identity
              [regForm]="form.get('identity')"
              [placeholder_relation]="data.titre_placeholder_relation"
              [clientFunctionOptions]="data.select_relation"
              [text_rgpd]="data.text_rgpd"
            ></question-form-identity>
          </cdk-step>
          <cdk-step formGroupName="claim" [stepControl]="form.get('claim')">
            <question-form-claim
              [regForm]="form"
              [placeholder_client_type]="data.titre_placeholder_client_type"
              [clientCategoryOptions]="data.select_client_type"
              [clientTypeOptions]="data.lien_client"
              [text_rgpd]="data.text_rgpd"
            ></question-form-claim>
          </cdk-step>
          <cdk-step formGroupName="request" [stepControl]="form.get('request')">
            <question-form-request
              [regForm]="form"
              [placeholder_message]="data.title_placeholder_message"
              [clientRequestCategoriesOptions]="data.liste_message"
              [text_rgpd]="data.text_rgpd"
              [text_under_jointpiece]="data.under_jointpiece"
              [receipt]="data.recipt"
              [email_redirection]="data.email_redirection"
            ></question-form-request>
          </cdk-step>
        </cdk-stepper>
      </form>
    </div>
  `,
  styleUrls: ['../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: QUESTION_IDENTITY_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceQuestionFormComponent),
    },
  ],
})
export class SliceQuestionFormComponent
  extends AbstractFormComponent
  implements SliceWithData<QuestionFormSlice>, OnInit {
  @Input() data: QuestionFormSlice;

  form: FormGroup;

  logger: Logger;

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _questionFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    readonly domService: DOMService,
    readonly elementRef: ElementRef,
    readonly loggerService: LoggerService,
  ) {
    super(_questionFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceQuestionFormComponent.name);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      identity: this.sliceFormsService.buildForm(QUESTION_IDENTITY_FORM),
      claim: this.sliceFormsService.buildForm(QUESTION_CLAIM_FORM),
      request: this.sliceFormsService.buildForm(QUESTION_REQUEST_FORM),
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { ComponentWithData } from '../../../../typings';
import { TableItem, TableSlice, TableTitle } from '@repo/shared';
import * as fileHelper from '../../../helpers/file.helpers';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

const MAX_COLUMN_SIZE = 4;
const DROPDOWN_MENU_TAGGING_PREFIX = 'ComparateurFiltre_';

@Component({
  selector: 'slice-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"> </cb-mini-title>
    <h2 class="subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</h2>
    <cb-dropdown *ngIf="categories" [items]="categories" (selectItem)="onSelectCategory($event)"> </cb-dropdown>
    <div class="mobile" *ngIf="content" [@fadeOut]="selectedCategory">
      <swiper [config]="config" (indexChange)="onMove($event)">
        <div *ngFor="let item of content; let i = index" [@fadeIn]="isCurrent(i) ? 'in' : 'out'">
          <div class="headline">
            <cb-responsive-img
              *ngIf="item.image && item.image.mobile && item.image.mobile.url"
              [mobileImage]="item.image.mobile"
            >
            </cb-responsive-img>

            <a
              *ngIf="item.link && item.link.url; else titleOnly"
              analyticsOn
              [analyticsLabel]="getAnalyticsLabel(item.title)"
              [attr.href]="item.link.url"
              [attr.target]="item.link.target"
            >
              <span *ngIf="isPdf(item.link.url)" class="ic-pdf"></span>
              <span class="label line-break">{{ item.title }}</span>
            </a>

            <ng-template #titleOnly>
              <span class="line-break">{{ item.title }}</span>
            </ng-template>
          </div>
          <dl>
            <ng-container *ngFor="let title of rowTitles">
              <dt class="title" [innerHTML]="title.label"></dt>
              <dd [innerHTML]="item[title.id] | prettyBooleanValue"></dd>
            </ng-container>
          </dl>
        </div>
      </swiper>
      <div class="indicators" [class.hasImage]="hasContentWithImage">
        <span *ngFor="let item of content; let i = index" class="dot" [class.active]="i === currentIndex">·</span>
      </div>
    </div>
    <div class="desktop" *ngIf="content" [@fadeOut]="selectedCategory">
      <slice-table-desktop [content]="content" [titles]="rowTitles" [selectedCategory]="selectedCategory">
      </slice-table-desktop>
    </div>
  `,
  styleUrls: ['./slice-table.component.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'in',
        style({
          opacity: 1,
        }),
      ),
      state(
        'out',
        style({
          opacity: 0,
        }),
      ),
      transition('out => in', [animate('0.5s')]),
    ]),
    trigger('fadeOut', [
      transition('* => *', [style({ opacity: 0 }), animate('0.5s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class SliceTableComponent implements ComponentWithData, OnInit {
  @Input() data: TableSlice;
  @Input() isFirstSlice: boolean;

  @ViewChild(SwiperComponent) swipper: SwiperComponent;

  @HostBinding('attr.id')
  get anchor(): string | null {
    return this.data?.anchor || null;
  }

  @HostBinding('class.with-one-item')
  get classWithOneItem(): boolean {
    const items = this.content;

    return items ? items.length === 1 : false;
  }

  readonly config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    watchOverflow: true,
  };

  currentIndex = 0;
  selectedCategory = '';

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  ngOnInit(): void {
    this.selectedCategory = this.categories && this.categories[0];
  }

  get hasContentWithImage(): string {
    return (
      (this.data.tableContent &&
        this.data.tableContent[0] &&
        this.data.tableContent[0].image &&
        this.data.tableContent[0].image.url) ||
      ''
    );
  }

  get content(): Partial<TableItem>[] {
    if (!this.data.tableContent || !this.data.tableContent.length) {
      return (null as unknown) as Partial<TableItem>[];
    }
    let filteredTableContent = this.data.tableContent;
    if (this.selectedCategory) {
      filteredTableContent = this.data.tableContent.filter(content =>
        content.categories?.includes(this.selectedCategory),
      );
    }
    return filteredTableContent.slice(0, MAX_COLUMN_SIZE);
  }

  get rowTitles(): TableTitle[] {
    return this.data?.tableTitles as TableTitle[];
  }

  get categories(): string[] {
    return this.data?.tableCategories as string[];
  }

  isPdf(filePath: string): boolean {
    return fileHelper.isPdf(filePath);
  }

  onSelectCategory(item: string): void {
    this.selectedCategory = item;
    this.swipper.directiveRef?.setIndex(0);
    this.changeDetectorRef.detectChanges();
    this.trackDropdownMenuClickEvent(item);
  }

  onMove(index: number): void {
    this.currentIndex = index;
  }

  isCurrent(index: number): boolean {
    return index === this.currentIndex;
  }

  trackDropdownMenuClickEvent(item: string): void {
    const label = DROPDOWN_MENU_TAGGING_PREFIX + item;
    this.tagCommanderService.trackClickActionEvent(label);
  }

  getAnalyticsLabel(linkLabel: string): string {
    return SLICE_TAGGING_NAME[this.data.type] + '_' + linkLabel;
  }
}

import { PageType } from '.';

export const HOME_PAGE_TYPES: string[] = ['home_page_particuliers', 'home_page_institutionnel'];

export const HOME_PAGE_DOCUMENT = {
  uid: 'particulier',
  type: 'home_page_particuliers',
};

export const AGENCY_DETAILS_DOCUMENT = {
  uid: 'agence-details',
};

export const ERROR_PAGE = {
  uid: 'erreur',
  type: 'page_simple',
};

export const CMS_PAGES: Record<string, PageType> = {
  article: 'article',
  produit: 'product',
  home_agence: 'home_agence',
  home_agence_details: 'home_agence_details',
  home_page_institutionnel: 'homepage_institutional',
  landing_univers: 'landing_universe',
  newsroom_institutionnel: 'newsroom_institutional',
  page_simple: 'page_simple',
  page_simple_institutionnelle: 'page_simple_institutional',
  pagefaq: 'pagefaq',
  simulateurs_et_devis: 'simulators_and_estimations',
  simulateur_encapsule: 'simulator_encapsulated',
  home_page_particuliers: 'homepage_particuliers',
  contact: 'contact',
  plan_du_site: 'sitemap',
  page_thematique_faq: 'thematic_faq',
  page_thematique_faq_free: 'thematic_faq_free',
  formulaire: 'form',
  lp_etape: 'landing_page_step',
  home_mag: 'homepage_mag',
  page_timeline: 'page_timeline',
  mag_page_thematique: 'mag_thematic_page',
  lp_campagne: 'landing_page_campaign',
  lp_event: 'landing_page_event',
  page_produit_gal: 'page_produit_gal',
  landing_univers_gal: 'landing_univers_gal',
};

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import { BackUnavailableError } from '../../errors';
import { Header } from '@repo/shared';

export enum ActionButtonLabels {
  ContactUs = 'Nous contacter',
  OpenAccount = "J'ouvre un compte",
  MyAccount = 'Mon espace',
}

export const imageWithMobileFragment = `
fragment imageWithMobileFragment on ImageWithMobile {
  url
  alt
  mobile {
    url
    alt
 }
}
`;

const headerQuery: DocumentNode = gql`
  ${imageWithMobileFragment}
  fragment menuItemFragment on MenuItem {
    link {
      label
      url
      target
    }
    megamenu {
      offerSize
      items {
        label
        url
        target
        description
        type
      }
      promoDoc {
        label
        url
        target
        description
        type
      }
    }
  }

  query HeaderQuery($lang: String!, $homepageId: String!) {
    header(lang: $lang, homepageId: $homepageId) {
      universes {
        ...menuItemFragment
      }
      communications {
        ...menuItemFragment
      }
      market
      pageId
      markets {
        label
        url
        target
      }
      marketsMenuLabel
      mobileTitle
      exceptionalInformation
      statutSmartApp
      codeCampagneSmartApp
      descriptionSmartApp
      imageBanner {
        dimensions {
          width
          height
        }
        alt
        url
        sticky {
          dimensions {
            width
            height
          }
          alt
          url
        }
        mobileopen {
          dimensions {
            width
            height
          }
          alt
          url
        }
        mobile {
          dimensions {
            width
            height
          }
          alt
          url
        }
      }
      smartButtonLabel
      lienBouton {
        link_type
        url
        target
        kind
        name
        size
      }
      appearsOnPage {
        linked_page {
          id
          type
          tags
          slug
          lang
          uid
          link_type
          isBroken
        }
      }
      logo {
        dimensions {
          width
          height
        }
        alt
        url
        sticky {
          dimensions {
            width
            height
          }
          alt
          url
        }
        mobileopen {
          dimensions {
            width
            height
          }
          alt
          url
        }
        mobile {
          dimensions {
            width
            height
          }
          alt
          url
        }
      }
      logoUrl {
        label
        url
        target
        type
      }
      contactButtonUrl {
        label
        url
        target
        type
      }
      colorButton1
      iconButton1 {
        ...imageWithMobileFragment
      }
      labelColorButton1
      colorMobileButton1
      iconMobileButton1 {
        ...imageWithMobileFragment
      }
      labelColorMobileButton1
      secondButtonUrl {
        label
        url
        target
        type
      }
      colorButton2
      iconButton2 {
        ...imageWithMobileFragment
      }
      labelColorButton2
      colorMobileButton2
      iconMobileButton2 {
        ...imageWithMobileFragment
      }
      labelColorMobileButton2
      thirdButtonWording
      colorButton3
      iconButton3 {
        ...imageWithMobileFragment
      }
      labelColorButton3
      colorMobileButton3
      iconMobileButton3 {
        ...imageWithMobileFragment
      }
      labelColorMobileButton3
      marketUid
      couleurPicker
    }
  }
`;
@Injectable()
export class HeaderService {
  private header$: Subject<Header> = new ReplaySubject(1);

  constructor(private readonly apollo: Apollo) {}

  getHeader(): Observable<Header> {
    return this.header$;
  }

  reload(homepageId: string): void {
    this.fetchHeader(homepageId).subscribe(
      (header: Header) => this.header$.next(header),
      error => this.header$.error(error),
    );
  }

  private fetchHeader(homepageId: string): Observable<Header> {
    return this.apollo
      .query<{ header: Header }>({
        query: headerQuery,
        variables: {
          lang: 'fr-fr',
          homepageId,
        },
      })
      .pipe(
        map(result => result.data.header),
        catchError(error => throwError(new BackUnavailableError('Could not get header', error))),
      );
  }
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiContactsItemTitle], [ui-contacts-item-title], ui-contacts-item-title',
  exportAs: 'uiContactsItemTitle',
})
export class UiContactsItemTitleDirective {
  @HostBinding('class.ui-contacts-item-title') hostClass = true;
  @HostBinding('attr.role') hostRole = 'h3';
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AgencyHours, HoursDetails } from '../../../../services/agencies.type';

const IC_CLOCK = '/assets/images/ic_clock.svg';

@Component({
  selector: 'app-agency-countdown',
  template: ` <p><img class="legend-picto" [src]="icClock" />Votre agence {{ remainTime }}</p> `,
  styleUrls: ['./agency-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyCountdownComponent implements OnInit {
  @Input()
  agencyHours: AgencyHours;

  readonly icClock = IC_CLOCK;
  readonly days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  remainTime = '';

  ngOnInit(): void {
    this.getRemainTime(this.agencyHours);
  }

  getRemainTime(agencyHours: AgencyHours): void {
    const current = new Date();
    const dayNumber: number = current.getDay() - 1;
    const hours = current.getHours();
    const minutes: string = ('0' + current.getMinutes()).slice(-2);

    const currentHour = Number(hours + '.' + minutes);

    const hoursDetails: HoursDetails = agencyHours[this.days[dayNumber]];

    if (!hoursDetails || !hoursDetails.openingHours) {
      this.remainTime = 'est actuellement fermée';
      return;
    }

    const { amOpen, amClose, pmOpen, pmClose } = hoursDetails.openingHours;

    const amOpenNumber = Number(hoursSubstr(amOpen as string));
    const amCloseNumber = Number(hoursSubstr(amClose as string));
    const pmOpenNumber = Number(hoursSubstr(pmOpen as string));
    const pmCloseNumber = Number(hoursSubstr(pmClose as string));

    if (isBetweenPeriod(currentHour, amOpenNumber, amCloseNumber)) {
      this.remainTime = this.getDurationMessage(currentHour, amCloseNumber);
    } else if (isBetweenPeriod(currentHour, pmOpenNumber, pmCloseNumber)) {
      this.remainTime = this.getDurationMessage(currentHour, pmCloseNumber);
    } else if (isBetweenPeriod(currentHour, amOpenNumber, pmCloseNumber)) {
      this.remainTime = this.getDurationMessage(currentHour, pmCloseNumber);
    } else {
      this.remainTime = 'est actuellement fermée';
    }
  }

  getDurationMessage(hour: number, hour2: any): string {
    const diffHour = this.diffBetweenTwoHours(hour, hour2);
    return 'ferme dans ' + diffHour.toFixed(2).replace('.', 'h');
  }

  diffBetweenTwoHours(currentHour: number, closedHour: number): number {
    const currentMinutes = currentHour - Math.floor(currentHour);
    let closedMinutes = closedHour - Math.floor(closedHour);

    if (closedMinutes < currentMinutes) {
      closedMinutes += 0.6;
      closedHour--;
    }

    return Math.trunc(closedHour) - Math.trunc(currentHour) + (closedMinutes - currentMinutes);
  }
}

function hoursSubstr(hoursOpening: string): string {
  if (hoursOpening && hoursOpening.length >= 5) {
    hoursOpening = hoursOpening.substr(0, 5);
  }
  return hoursOpening;
}

function isBetweenPeriod(hour: number, begin: number, end: number): boolean {
  return begin > 0 && end > 0 && begin < hour && end > hour;
}

<form class="form" [formGroup]="regForm" (ngSubmit)="onSubmit()" novalidate>
  <div id="identity">
    <div formFieldControl id="claimType">
      <label>Faire une réclamation*</label>
      <div formFieldRadioGroup class="form-field-control">
        <form-field-radio displayMode="asideLabel" name="claimType" formControlName="claimType" value="firstClaim">
          {{ data.checkbox_reclamation_1 }}
        </form-field-radio>
        <form-field-radio displayMode="asideLabel" name="claimType" formControlName="claimType" value="noSatisfied">
          {{ data.checkbox_reclamation_2 }}
        </form-field-radio>
      </div>
      <form-field-error></form-field-error>
    </div>
    <div formFieldControl id="clientFunction" class="form-field-control--large-size">
      <label>
        Votre relation avec LCL : *
        <form-field-select
          formFieldSelect
          [options]="clientFunctionOptions"
          [formControlName]="'clientFunction'"
          [selectError]="selectError"
          [placeholder]="data.title_placeholder_client_type"
        ></form-field-select>
      </label>
      <form-field-error></form-field-error>
    </div>
  </div>

  <p class="mandatory-field-text">*Champs obligatoires</p>
  <p>
    La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
    disponible sur la page d'accueil du site.
  </p>

  <div class="button-wrapper">
    <cb-cta-btn-link
      class="button"
      [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
  </div>
</form>

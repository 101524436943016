import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

const CLOSE_BUTTON_ICON = '/assets/images/ic_bt_close.svg';

@Component({
  selector: 'app-alert-banner',
  template: `
    <div class="wrapper" [style.background-color]="hasCouleurPicker() ? this.couleurPicker : ''">
      <div class="message" [innerHTML]="message"></div>
      <img
        class="close-button"
        (click)="closeAlertBanner.emit()"
        [attr.src]="closeButtonIcon"
        alt="icone de fermeture"
      />
    </div>
  `,
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBannerComponent {
  closeButtonIcon: string = CLOSE_BUTTON_ICON;

  @Input() message: string;
  @Input() couleurPicker: string;

  @Output() closeAlertBanner = new EventEmitter<any>();

  hasCouleurPicker(): boolean {
    return this.couleurPicker !== null;
  }
}

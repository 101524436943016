import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { HeaderButtonsLinks } from '../../header';
import { Image, Link, MenuItem } from '@repo/shared';
import { AnalyticsSubPaths } from '../../../../services/tag-commander.service';

const IC_BT_CLOSE = '/assets/images/ic_bt_close.svg';
const LCL_LOGO_SIGNATURE = '/assets/images/lcl_logo_blanc_signature.svg';

@Component({
  selector: 'app-mobile-header-opened',
  template: `
    <div>
      <div class="header" role="banner">
        <div class="cross">
          <img (click)="triggerToggleMenu()" [attr.src]="icBtClose" height="16" width="16" alt="close menu icon" />
        </div>
        <div class="lcl-icon">
          <a
            [attr.href]="buttons.home && buttons.home.url"
            [attr.target]="buttons.home && buttons.home.target"
            (click)="triggerToggleMenu()"
          >
            <img
              *ngIf="logo && logo.dimensions; else defaultLogo"
              [attr.src]="logo.url"
              [attr.alt]="logo.alt"
              [attr.height]="logo.dimensions.height"
              [attr.width]="logo.dimensions.width"
            />
            <ng-template #defaultLogo>
              <img [attr.src]="lclLogoSignature" alt="LCL" height="55" width="136" />
            </ng-template>
          </a>
        </div>
        <div class="right-icons"></div>
      </div>
    </div>
    <div *ngIf="!isSubmenuOpened" class="content">
      <div *ngIf="markets && marketsMenuLabel" class="market" [ngClass]="marketListExpansionState">
        <label class="market-list-label" (click)="toggleMarketList($event)">
          <button>{{ marketsMenuLabel }}</button>
          <i [@marketListArrowRotation]="marketListArrowRotationState" class="arrow"></i>
        </label>
        <ul [@marketListExpansion]="marketListExpansionState" class="market-list">
          <li *ngFor="let market of markets">
            <a
              analyticsOn
              [analyticsLabel]="market.label"
              [analyticsSubPath]="analyticsSubPath"
              [attr.href]="market.url"
              [attr.target]="market.target"
              (click)="triggerToggleMenu()"
              >{{ market.label }}</a
            >
          </li>
        </ul>
      </div>
      <div class="title" *ngIf="mobileTitle">{{ mobileTitle }}</div>
      <div class="main text-uppercase">
        <div *ngFor="let universe of universes">
          <a
            *ngIf="!isLinkToSubMenu(universe)"
            [attr.href]="universe.link.url"
            [attr.target]="universe.link.target"
            (click)="triggerToggleMenu()"
            >{{ universe.link.label }}</a
          >
          <span *ngIf="isLinkToSubMenu(universe)" class="to-submenu" (click)="openSubmenu(universe)">{{
            universe.link.label
          }}</span>
        </div>
      </div>
      <div class="other">
        <div *ngFor="let communication of communications">
          <a
            *ngIf="!isLinkToSubMenu(communication)"
            [attr.href]="communication.link.url"
            [attr.target]="communication.link.target"
            (click)="triggerToggleMenu()"
            >{{ communication.link.label }}</a
          >
          <span *ngIf="isLinkToSubMenu(communication)" class="to-submenu" (click)="openSubmenu(communication)">{{
            communication.link.label
          }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="isSubmenuOpened" class="submenu">
      <app-mobile-header-menu-opened [submenu]="activeSubmenu"> </app-mobile-header-menu-opened>
    </div>
    <div class="sticky bottom">
      <app-mobile-header-opened-bottom-menu [buttonsLinks]="buttons" (clicked)="triggerToggleMenu()">
      </app-mobile-header-opened-bottom-menu>
    </div>
  `,
  animations: [
    trigger('marketListExpansion', [
      state('open', style({ height: '*' })),
      state('close', style({ height: '0' })),
      transition('open <=> close', animate('0.3s cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('marketListArrowRotation', [
      state('up', style({ transform: 'rotate(225deg)' })),
      state('down', style({ transform: 'rotate(45deg)' })),
      transition('up <=> down', animate('0.3s cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./mobile-header-opened.component.scss'],
})
export class MobileHeaderOpenedComponent {
  @Input() universes: MenuItem[] = [];
  @Input() communications: MenuItem[] = [];
  @Input() buttons: HeaderButtonsLinks = {};
  @Input() markets: Link[] = [];
  @Input() marketsMenuLabel: string;
  @Input() mobileTitle: string;
  @Input() logo: Image;

  @Output() toggleMenu = new EventEmitter<any>();

  icBtClose: string = IC_BT_CLOSE;
  lclLogoSignature: string = LCL_LOGO_SIGNATURE;
  isSubmenuOpened = false;
  activeSubmenu = {} as MenuItem;
  marketListOpened = false;
  analyticsSubPath = AnalyticsSubPaths.HeaderMenuMarket;

  get marketListExpansionState(): 'open' | 'close' {
    return this.marketListOpened ? 'open' : 'close';
  }

  get marketListArrowRotationState(): 'up' | 'down' {
    return this.marketListOpened ? 'up' : 'down';
  }

  isLinkToSubMenu(menuItem: MenuItem): boolean {
    return !(menuItem.link && menuItem.link.url) && !!menuItem.megamenu;
  }

  triggerToggleMenu(): void {
    this.toggleMenu.emit();
    this.isSubmenuOpened = false;
  }

  openSubmenu(menuItem: MenuItem): void {
    const hasSubMenu = menuItem.megamenu && menuItem.megamenu.items && menuItem.megamenu.items.length !== 0;

    if (hasSubMenu) {
      this.activeSubmenu = menuItem;
      this.isSubmenuOpened = true;
    }
  }

  closeSubmenu(): void {
    this.isSubmenuOpened = false;
  }

  toggleMarketList(event: MouseEvent): void {
    event.preventDefault();

    this.marketListOpened = !this.marketListOpened;
  }
}

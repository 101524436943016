import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { UiPushBlockItemComponent } from './push-block-item.component';

@Component({
  selector: 'ui-push-block',
  template: `
    <ng-content select="ui-push-block-header"></ng-content>
    <div class="ui-push-block-content">
      <ng-content></ng-content>
    </div>
  `,
  exportAs: 'uiPushBlock',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPushBlockComponent {
  @ContentChildren(UiPushBlockItemComponent, { descendants: true }) items: QueryList<UiPushBlockItemComponent>;

  @HostBinding('class') class = 'ui-push-block';

  get itemsCount(): number {
    return this.items ? this.items.length : 0;
  }
}

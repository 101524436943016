import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiContactsBlockComponent } from './contacts-block.component';
import { UiContactsItemComponent } from './contacts-item.component';
import { UiContactsBlockTitleDirective } from './contacts-block-title.directive';
import { UiContactsItemTitleDirective } from './contacts-item-title.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    UiContactsBlockComponent,
    UiContactsBlockTitleDirective,
    UiContactsItemComponent,
    UiContactsItemTitleDirective,
  ],
  exports: [
    UiContactsBlockComponent,
    UiContactsBlockTitleDirective,
    UiContactsItemComponent,
    UiContactsItemTitleDirective,
  ],
})
export class UiContactsBlockModule {}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  forwardRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { JSONSchema7 } from 'json-schema';
import { HttpErrorResponse } from '@angular/common/http';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { LOAN_INSURANCE_REQUEST_FORM } from '@repo/shared/form-schemas/loan-insurance';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { FileUploadComponent } from '../../../../../ui/molecules/form-upload-file/form-upload-file.component';
import { RECAPTCHA_LANGUAGE, ReCaptchaV3Service } from 'ng-recaptcha';
import { ConfigService } from '../../../../../config.service';
import { SliceFormsService } from '../../slice-forms.service';
import { DOMService } from '../../../../../services/dom.service';
import { LoggerService } from '../../../../../logger/logger.service';
import { Logger } from '../../../../../logger/logger';
import { FILE_UPLOAD_ERRORS } from '../../../../../ui/molecules/form-upload-file/form-upload-file.type';

export const CONFIRMATION_PAGE_URL = 'confirmation-envoi-ade';

@Component({
  selector: 'loan-insurance-request',
  template: `
    <form class="form" [formGroup]="regForm.get('request')" novalidate>
      <div id="request">
        <h2>Message</h2>
        <div formFieldControl>
          <label>Précisions à apporter: *</label>
          <textarea
            formControlName="message"
            placeholder="Ecrivez votre message ici..."
            #messageField="formFieldInput"
            formFieldInput
          ></textarea>
          <div formFieldHint>
            {{ messageField.charCountLabel }}
          </div>
          <form-field-error></form-field-error>
        </div>

        <h2>Pièces jointes</h2>
        <p class="files-needed-instructions">Documents nécessaires au bon traitement de votre demande:</p>
        <p>- Notice d'information ou Conditions Générales du contrat proposé en substitution*</p>
        <p>- Certificat d'adhésion du contrat proposé en substitution ou proposition d'assurance engageante</p>
        <p>
          (Pour plus d'information sur les modalités de substitution, veuillez-vous référer au guide Assurance
          Emprunteur)
        </p>
        <div formFieldControl class="form-field-control-file-upload">
          <file-upload-form
            formControlName="files"
            fileUploadForm
            [minFiles]="this.minFiles"
            [minFilesErrorMessage]="this.minFilesErrorMessage"
          >
          </file-upload-form>
          <form-field-error></form-field-error>
        </div>

        <p class="mandatory-field-text">*Champs obligatoires</p>
        <p>
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
          disponible sur la page d'accueil du site.
        </p>
        <div class="button-wrapper">
          <cb-cta-btn-link
            class="previous"
            (click)="previous()"
            [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
            [buttonWithoutLink]="true"
            [sliceType]="'loan_insurance_form'"
            [disabled]="_buttonDisabled"
          ></cb-cta-btn-link>
          <cb-cta-btn-link
            class="next"
            (click)="checkRecaptcha()"
            [button]="{ label: 'Envoyer', type: 'multi-step-form-next' }"
            [buttonWithoutLink]="true"
            [sliceType]="'loan_insurance_form'"
            [disabled]="_buttonDisabled"
          ></cb-cta-btn-link>
          <form-field-error
            class="post-error"
            [class.shown]="_hasPostError"
            message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
          >
          </form-field-error>
        </div>
        <p class="recaptcha-mentions">
          Ce site est protégé par reCAPTCHA. Les
          <a href="https://policies.google.com/privacy">règles de confidentialité</a>
          et les
          <a href="https://policies.google.com/terms">conditions d'utilisation</a>
          de Google s'appliquent.
        </p>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss', './loan-insurance-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: LOAN_INSURANCE_REQUEST_FORM,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr',
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => LoanInsuranceRequestFormComponent),
    },
  ],
})
export class LoanInsuranceRequestFormComponent extends AbstractFormComponent implements AfterViewInit {
  @Input() regForm: FormGroup;

  logger: Logger;

  _buttonDisabled = false;
  _hasPostError = false;

  minFiles = 1;
  minFilesErrorMessage = FILE_UPLOAD_ERRORS.MIN_FILES_LOAN_INSURANCE_ERROR;

  @ViewChild(FileUploadComponent, { static: true }) fileUploadComponent;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _loanInsuranceFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly configService: ConfigService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_loanInsuranceFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(LoanInsuranceRequestFormComponent.name);
  }

  ngAfterViewInit(): void {
    const requestGroup = this.regForm.get('request') as FormGroup;
    requestGroup.addControl('recaptcha', new FormControl('', Validators.required));
  }

  previous(): void {
    this.myStepper.previous();
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('loanInsuranceRequestForm').subscribe(
      token => {
        this.regForm.get('request')?.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  submitForm(): void {
    if (this.regForm.get('files')) {
      this.regForm.get('files')?.updateValueAndValidity();
    }
    const { valid, value } = this.regForm;
    if (valid) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendLoanInsuranceForm(value).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._buttonDisabled = false;
          this._hasPostError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
    } else {
      this.triggerFormFieldsStatus(this.regForm.get('request') as FormGroup);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

import { NgModule } from '@angular/core';

import { SliceAgencyModule } from '../slice-agency.module';
import { SliceAgencyDetailsComponent } from './slice-agency-details.component';

@NgModule({
  imports: [SliceAgencyModule],
  declarations: [SliceAgencyDetailsComponent],
  exports: [SliceAgencyDetailsComponent],
  entryComponents: [SliceAgencyDetailsComponent],
})
export class SliceAgencyDetailsModule {}

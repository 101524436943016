import disableScroll from 'disable-scroll';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { HereAutocompleteResponse, HereAutocompleteSuggestion } from '@repo/shared/store-locator-schemas/suggestion';
import { AgenciesData, AgenciesFilter, TagSources } from '../../../../services/agencies.type';
import { HereAutocompleteService } from '../../../../services/here-autocomplete.service';
import { AgencyFormSuggestionDirective } from './agency-form-suggestion.directive';

@Component({
  selector: 'app-agency-form',
  template: `
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="agencyForm" novalidate>
      <div class="container">
        <div formFieldControl>
          <label *ngIf="isChildForm">
            <div>
              Agence ou Pôle Banque Privée*
              <span *ngIf="displayNoAgencyMessage" class="agency-none">
                Aucune agence LCL trouvée. Veuillez refaire une recherche.
              </span>
            </div>
            <div formFieldControl>
              <input
                #queryRef
                type="text"
                class="query form-field-input customForm"
                placeholder="Rechercher une adresse, ville ou code postal"
                formControlName="query"
                formFieldInput
                (keyup.enter)="emitAgenciesFilter(tagSources.Keyboard)"
              />
              <button class="searchButton" (click)="emitAgenciesFilter(tagSources.Button)">
                <img class="searchButtonImage" [src]="SEARCH_BUTTON_ICON" />
              </button>
              <form-field-error></form-field-error>
            </div>
          </label>
          <input
            *ngIf="!isChildForm"
            #queryRef
            type="text"
            class="query form-field-input"
            placeholder="Rechercher une adresse, ville ou code postal"
            formControlName="query"
            (keyup.enter)="emitAgenciesFilter(tagSources.Keyboard)"
          />
          <div
            (clickOutside)="onClickOutsideSuggestions($event)"
            [delayClickOutsideInit]="true"
            [clickOutsideEvents]="'click,keydown'"
          >
            <ng-container *ngIf="suggestions$ | async as suggestions">
              <div
                [ngClass]="{
                  'result-container': isChildForm === false,
                  'result-container-form': isChildForm === true
                }"
                *ngIf="shouldShowSuggestions"
              >
                <button
                  *ngFor="let suggestion of suggestions"
                  agencyFormSuggestion
                  class="result"
                  [innerHTML]="suggestion.label"
                  (click)="selectSuggestionHandler(suggestion, tagSources.MouseClick)"
                ></button>
              </div>
            </ng-container>
          </div>
          <div class="filter-container" *ngIf="!isChildForm" formGroupName="filters">
            <form-field-checkbox class="filter is-open-saturday-checkbox" formControlName="isOpenSaturday">
              Ouvert le samedi
            </form-field-checkbox>
            <form-field-checkbox class="filter has-vault-checkbox" formControlName="hasVault">
              Coffre-Fort
            </form-field-checkbox>
            <form-field-checkbox class="filter is-private-checkbox" formControlName="isPrivateBank">
              Pôle Banque Privée
            </form-field-checkbox>
            <form-field-checkbox class="filter is-pro-checkbox" formControlName="isProBank">
              Agence des Professionnels
            </form-field-checkbox>
          </div>
        </div>
        &nbsp;&nbsp;
        <cb-cta-btn-link
          *ngIf="!isChildForm"
          [button]="{ label: 'Rechercher' }"
          [buttonWithoutLink]="true"
          (click)="emitAgenciesFilter(tagSources.Button, $event)"
        >
        </cb-cta-btn-link>
      </div>
    </form>
  `,
  styleUrls: ['./agency-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  isChildForm: boolean;

  @Input()
  displayNoAgencyMessage: boolean;

  @Output()
  optionSelected: EventEmitter<AgenciesData> = new EventEmitter();

  @Output()
  filterSelected: EventEmitter<AgenciesFilter> = new EventEmitter();

  @ViewChild('queryRef')
  private queryRef: ElementRef<HTMLInputElement>;

  @ViewChildren(AgencyFormSuggestionDirective)
  domSuggestionsQueryList: QueryList<AgencyFormSuggestionDirective>;
  suggestions$: Observable<HereAutocompleteSuggestion[]>;

  domSuggestions: HTMLButtonElement[] = [];
  domSuggestionsIndex: number = (null as unknown) as number;
  domSuggestionsSubscription: Subscription;

  private query$ = new Subject<string>();
  queryChangeSubscription: Subscription;

  agenciesFilter: AgenciesFilter = {};
  filtersChangeSubscription: Subscription;

  private readonly ADDRESS_MIN_LENGTH = 3;
  private readonly SUGGESTIONS_DEBOUNCE_TIME = 500;
  private readonly SUGGESTION_HIGHLIGHT_REGEX = '<.?b>';
  SEARCH_BUTTON_ICON = '../../../../assets/images/03_icones_icons_Search.svg';
  private isSearchInProgress = false;

  shouldShowSuggestions = false;
  stopQuerying = false;
  tagSources = TagSources;

  agencyForm: FormGroup;

  constructor(
    private readonly autocompleteService: HereAutocompleteService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.agencyForm = new FormGroup({
      query: new FormControl('', Validators.required),
      filters: new FormGroup({
        isOpenSaturday: new FormControl(false),
        hasVault: new FormControl(false),
        isPrivateBank: new FormControl(false),
        isProBank: new FormControl(false),
      }),
    });

    // this.suggestions$ = this.getSuggestionsFromQuery$();
  }

  onClickOutsideSuggestions(_event: any): void {
    this.stopQuerying = false;
  }

  ngAfterViewInit(): void {
    this.queryChangeSubscription = this.agencyForm.get('query')?.valueChanges.subscribe(() => {
      if (!this.stopQuerying) {
        this.emitQuery();
      }
    }) as Subscription;

    this.filtersChangeSubscription = this.agencyForm
      .get('filters')
      ?.valueChanges.subscribe(({ hasVault, isOpenSaturday, isPrivateBank, isProBank }) => {
        this.agenciesFilter = {
          hasVault,
          isOpenSaturday,
          isPrivateBank,
          isProBank,
          query: this.agencyForm.get('query')?.value,
        };
        this.filterSelected.emit(this.agenciesFilter);
      }) as Subscription;

    this.domSuggestionsSubscription = this.domSuggestionsQueryList.changes.subscribe(() => {
      this.domSuggestions = [];
      this.domSuggestionsIndex = (null as unknown) as number;

      this.domSuggestions = this.domSuggestionsQueryList
        .toArray()
        .map(suggestionDirective => suggestionDirective.elementRef.nativeElement);
    });
  }

  ngOnDestroy(): void {
    if (this.queryChangeSubscription) {
      this.queryChangeSubscription.unsubscribe();
    }
    if (this.filtersChangeSubscription) {
      this.filtersChangeSubscription.unsubscribe();
    }
    if (this.domSuggestionsSubscription) {
      this.domSuggestionsSubscription.unsubscribe();
    }
  }

  private get validatedQueryInput(): string {
    const query = (this.agencyForm.get('query')?.value || '').trim().replace(/\s+/g, ' ');
    if (query.length >= this.ADDRESS_MIN_LENGTH) {
      return query;
    }
    return '';
  }

  emitQuery(): void {
    this.agenciesFilter.query = this.validatedQueryInput;
    if (this.agenciesFilter.query) {
      this.query$.next(this.agenciesFilter.query);
      this.stopQuerying = true;
    } else {
      this.shouldShowSuggestions = false;
      this.isSearchInProgress = false;
    }
  }

  private getSuggestionsFromQuery$(): Observable<HereAutocompleteSuggestion[]> {
    return this.query$.pipe(
      debounceTime(this.SUGGESTIONS_DEBOUNCE_TIME),
      switchMap(query => {
        this.isSearchInProgress = true;
        return this.autocompleteService.autocompleteAddress(query).pipe(
          catchError(() => of({ suggestions: [] } as HereAutocompleteResponse)),
          map(result => {
            if (!this.isSearchInProgress) {
              return [];
            }
            this.isSearchInProgress = false;
            this.shouldShowSuggestions = result.suggestions.length > 0;
            return result.suggestions;
          }),
        );
      }),
      tap(() => this.changeDetectorRef.detectChanges()),
    );
  }

  @HostListener('document:click', ['$event'])
  leaveSuggestionHandler(event?: MouseEvent): void {
    if (event && event.target === this.queryRef.nativeElement) {
      return;
    }
    this.shouldShowSuggestions = false;
    disableScroll.off();
  }

  focusSuggestionHandler(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Up': // For IE11
      case 'ArrowUp': {
        this.focusSuggestion(-1);
        break;
      }
      case 'Down': // For IE11
      case 'ArrowDown': {
        this.focusSuggestion(1);
        break;
      }
      case 'Esc': // For IE11
      case 'Escape': {
        this.leaveSuggestionHandler();
        break;
      }
    }
  }

  private focusSuggestion(offset: number): void {
    if (!this.domSuggestions.length) {
      return;
    }
    if (this.domSuggestionsIndex === null) {
      this.domSuggestionsIndex = 0;
    } else {
      this.domSuggestionsIndex = (this.domSuggestionsIndex + offset) % this.domSuggestions.length;
      if (this.domSuggestionsIndex < 0) {
        this.domSuggestionsIndex = this.domSuggestions.length - 1;
      }
    }
    this.domSuggestions[this.domSuggestionsIndex].focus();
    disableScroll.on((null as unknown) as undefined);
  }

  selectSuggestionHandler(suggestion: HereAutocompleteSuggestion, tagSource: string): void {
    const suggestionHighlightRegex = new RegExp(this.SUGGESTION_HIGHLIGHT_REGEX, 'g');
    const newAgenciesQuery = (suggestion.label || '').replace(suggestionHighlightRegex, '');
    this.agencyForm.patchValue({ query: newAgenciesQuery });
    this.agenciesFilter.query = this.validatedQueryInput;
    this.emitAgenciesFilter(tagSource);
  }

  emitAgenciesFilter(tagSource: string): void {
    if (this.agenciesFilter.query) {
      this.shouldShowSuggestions = false;
      this.isSearchInProgress = false;
      const agenciesData: AgenciesData = { agenciesFilter: this.agenciesFilter, tagSource };
      this.optionSelected.emit(agenciesData);
    }
  }
}

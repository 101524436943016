import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmsFaqLandingComponent } from './faq-landing.component';
import { UiExpansionPanelModule } from '../../../../ui/molecules/expansion-panel/expansion-panel.module';
import { UiFaqModule } from '../../../../ui/templates/faq/faq.module';
import { SlicesModule } from '../../../slices/slices.module';
import { JsonLdModule } from '../../../../json-ld/json-ld.module';

@NgModule({
  imports: [CommonModule, UiExpansionPanelModule, UiFaqModule, SlicesModule, JsonLdModule],
  declarations: [CmsFaqLandingComponent],
  entryComponents: [CmsFaqLandingComponent],
})
export class CmsFaqModule {}

import { Component, Input } from '@angular/core';
import { ParagraphSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

@Component({
  selector: 'slice-paragraph',
  template: `<ng-container *ngIf="valid">
    <div [class]="data.alignment" [innerHTML]="data.richText | trustHtml"></div>
  </ng-container>`,
  styleUrls: ['./slice-paragraph.component.scss'],
})
export class SliceParagraphComponent implements SliceWithData<ParagraphSlice> {
  @Input() data: ParagraphSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  get valid(): boolean {
    return !!(this.data && this.data.richText);
  }
}

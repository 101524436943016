import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { BREAKPOINT_MOBILE } from '../../../common/constants/breakpoints';
@Component({
  selector: 'ui-push-block-header',
  template: '<ng-content></ng-content>',
  exportAs: 'uiPushBlockHeader',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPushBlockHeaderComponent {
  @Input() backgroundImage: string;
  @Input() backgroundImageDesktop: string;
  @Input() backgroundGradient;

  @HostBinding('class') class = 'ui-push-block-header';

  @HostBinding('style.background')
  get hostStyleBackground(): string {
    return this.responsiveBackgroundImage && `url(${this.responsiveBackgroundImage})`;
  }

  get responsiveBackgroundImage(): string {
    return (
      (this.viewportWidth > BREAKPOINT_MOBILE && this.backgroundImageDesktop) ||
      this.backgroundImage ||
      this.backgroundGradient
    );
  }

  @HostBinding('attr.aria-bg-gradient')
  get isBackgroundGradient(): boolean {
    return this.responsiveBackgroundImage === this.backgroundGradient;
  }

  private viewportWidth = BREAKPOINT_MOBILE;

  constructor(@Inject(PLATFORM_ID) platformId?: any) {
    if (isPlatformBrowser(platformId)) {
      this.onResize(({ target: window } as {}) as Event);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const win = event.target as Window;

    if (win.innerWidth) {
      this.viewportWidth = win.innerWidth;
    }
  }
}

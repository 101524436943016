import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliceEntrepriseContactFormComponent } from './slice-entreprise-contact/slice-entreprise-contact.component';
import { SliceProfessionalContactFormComponent } from './slice-professional-contact/slice-professional-contact.component';
import { SliceNonCustomerComplaintComponent } from './slice-non-customer-complaint/slice-non-customer-complaint.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { UiMoleculesModule } from '../../../ui/molecules/molecules.module';
import { SliceFormsService } from './slice-forms.service';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkStepperComponent } from './cdk-stepper/cdk-stepper.component';
import { SliceAssistanceFormComponent } from './slice-assistance/slice-assistance.component';
import { AssistanceIdentityComponent } from './slice-assistance/assistance-identity/assistance-identity.component';
// tslint:disable-next-line:max-line-length
import { AssistanceEquipementsComponent } from './slice-assistance/assistance-equipements/assistance-equipements.component';
import { AssistanceRequestComponent } from './slice-assistance/assistance-request/assistance-request.component';
import { SliceReclamationFormComponent } from './slice-reclamation/slice-reclamation.component';
import { ReclamationIdentityComponent } from './slice-reclamation/reclamation-identity/reclamation-identity.component';
import { ReclamationClaimComponent } from './slice-reclamation/reclamation-claim/reclamation-claim.component';
import { ReclamationRequestComponent } from './slice-reclamation/reclamation-request/reclamation-request.component';
import { SliceAgencyModule } from '../slice-agency/slice-agency.module';
import { ConfigService } from '../../../config.service';
import { LoanInsuranceIdentityFormComponent } from './slice-loan-insurance-form/loan-insurance-identity/loan-insurance-identity.component';
import { LoanInsuranceRequestFormComponent } from './slice-loan-insurance-form/loan-insurance-request/loan-insurance-request.component';
import { SliceLoanInsuranceFormComponent } from './slice-loan-insurance-form/slice-loan-insurance.component';
import { SliceReclamationConnectedCustomerFormComponent } from './slice-reclamation-connected-customer/slice-reclamation-connected-customer.component';
import { ReclamationConnectedCustomerIdentityComponent } from './slice-reclamation-connected-customer/reclamation-connected-customer-identity/reclamation-connected-customer-identity.component';
import { ReclamationConnectedCustomerClaimComponent } from './slice-reclamation-connected-customer/reclamation-connected-customer-claim/reclamation-connected-customer-claim.component';
import { ReclamationConnectedCustomerRequestComponent } from './slice-reclamation-connected-customer/reclamation-connected-customer-request/reclamation-connected-customer-request.component';
import { SliceResiliationFormComponent } from './slice-resiliation/slice-resiliation.component';
import { ResiliationIdentityComponent } from './slice-resiliation/resiliation-identity/resiliation-identity.component';
import { ResiliationClaimComponent } from './slice-resiliation/resiliation-claim/resiliation-claim.component';
import { ResiliationRequestComponent } from './slice-resiliation/resiliation-request/resiliation-request.component';
import { SliceQuestionFormComponent } from './slice-question-form/slice-question-form.component';
import { QuestionFormIdentityComponent } from './slice-question-form/question-form-identity/question-form-identity.component';
import { QuestionFormClaimComponent } from './slice-question-form/question-form-claim/question-form-claim.component';
import { QuestionFormRequestComponent } from './slice-question-form/question-form-request/question-form-request.component';
import { SummaryComponent } from './slice-resiliation/summary/summary.component';

@NgModule({
  declarations: [
    SliceNonCustomerComplaintComponent,
    SliceEntrepriseContactFormComponent,
    SliceProfessionalContactFormComponent,
    SliceReclamationFormComponent,
    SliceAssistanceFormComponent,
    AssistanceIdentityComponent,
    AssistanceRequestComponent,
    AssistanceEquipementsComponent,
    SliceLoanInsuranceFormComponent,
    LoanInsuranceIdentityFormComponent,
    LoanInsuranceRequestFormComponent,
    CdkStepperComponent,
    ReclamationIdentityComponent,
    ReclamationClaimComponent,
    ReclamationRequestComponent,
    SliceReclamationConnectedCustomerFormComponent,
    ReclamationConnectedCustomerIdentityComponent,
    ReclamationConnectedCustomerClaimComponent,
    ReclamationConnectedCustomerRequestComponent,
    SliceResiliationFormComponent,
    ResiliationIdentityComponent,
    ResiliationClaimComponent,
    ResiliationRequestComponent,
    SliceQuestionFormComponent,
    QuestionFormIdentityComponent,
    QuestionFormClaimComponent,
    QuestionFormRequestComponent,
    SummaryComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    CommonBehaviorsModule,
    UiMoleculesModule,
    CdkStepperModule,
    SliceAgencyModule,
  ],
  providers: [
    SliceFormsService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (configService: ConfigService) => {
        return configService.get('RECAPTCHA_SITE_KEY');
      },
      deps: [ConfigService],
    },
  ],
  exports: [
    SliceNonCustomerComplaintComponent,
    SliceEntrepriseContactFormComponent,
    SliceProfessionalContactFormComponent,
    SliceAssistanceFormComponent,
    SliceReclamationFormComponent,
    SliceLoanInsuranceFormComponent,
    SliceReclamationConnectedCustomerFormComponent,
    SliceResiliationFormComponent,
    SliceQuestionFormComponent,
  ],
  entryComponents: [
    SliceNonCustomerComplaintComponent,
    SliceEntrepriseContactFormComponent,
    SliceProfessionalContactFormComponent,
    SliceAssistanceFormComponent,
    SliceReclamationFormComponent,
    SliceLoanInsuranceFormComponent,
    SliceReclamationConnectedCustomerFormComponent,
    SliceResiliationFormComponent,
    SliceQuestionFormComponent,
  ],
})
export class SliceFormsModule {}

import { Component, Input } from '@angular/core';
import { LegalMentionsSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';

@Component({
  selector: 'slice-legal-mentions',
  template: ` <div class="legal-mentions" *ngIf="data && data.richText">
    <div [innerHTML]="data.richText"></div>
  </div>`,
  styleUrls: ['./slice-legal-mentions.component.scss'],
})
export class SliceLegalMentionsComponent implements ComponentWithData {
  @Input() data: LegalMentionsSlice;
}

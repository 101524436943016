import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBehaviorsModule } from '../cms/common-behaviors/common-behaviors.module';

import { SmartBannerComponent } from './smart-banner.component';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule],
  declarations: [SmartBannerComponent],
  exports: [SmartBannerComponent],
})
export class SmartBannerModule {}

import { Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { CharacteristicsKeysSlice } from '@repo/shared';

@Component({
  selector: 'slice-characteristics-keys',
  template: `
    <ng-container *ngIf="data && data.characteristicsKeys && data.characteristicsKeys.length !== 0">
      <cb-mini-title class="mini-title" *ngIf="data.title" [title]="data.title"></cb-mini-title>
      <div class="list-features">
        <div *ngFor="let key of data.characteristicsKeys" class="feature">
          <div class="title">{{ key.title }}</div>
          <div class="text" [innerHTML]="key.text"></div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-characteristics-keys.component.scss'],
})
export class SliceCharacteristicsKeysComponent implements SliceWithData<CharacteristicsKeysSlice> {
  @Input() data: CharacteristicsKeysSlice;
}

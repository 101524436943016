import { NgModule } from '@angular/core';

import { SliceAgencyModule } from '../slice-agency.module';
import { SliceAgencyMapHeroComponent } from './slice-agency-map-hero.component';

@NgModule({
  imports: [SliceAgencyModule],
  declarations: [SliceAgencyMapHeroComponent],
  exports: [SliceAgencyMapHeroComponent],
  entryComponents: [SliceAgencyMapHeroComponent],
})
export class SliceAgencyMapHeroModule {}

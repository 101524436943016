import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DOMScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public updateInlineScript(id: string, code: string): void {
    this.removeScriptIfItExists(id);
    const script = this.document.createElement('script');
    script.id = id;
    script.innerHTML = code;
    this.document.body.appendChild(script);
  }

  public updateExternalScript(id: string, url: string, isAsync?: boolean): void {
    this.removeScriptIfItExists(id);

    const script = this.document.createElement('script');
    script.id = id;
    script.setAttribute('src', url);
    isAsync ? script.setAttribute('async', '') : '';
    this.document.body.appendChild(script);
  }

  private removeScriptIfItExists(id: string): void {
    const elem = this.document.getElementById(id);
    if (elem) {
      elem.remove();
    }
  }
}

import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, streetRegex, Form } from './form';

export interface ConnectedCustomerComplaintForm extends Form {
  claimType: string;
  birthDate: string;
  street: string;
  city: string;
  postalCode: string;
  clientCategory: string;
  businessName?: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
}

export interface ConnectedCustomerComplaintFormGroup {
  identity: ConnectedCustomerComplaintIdentityForm;
  claim: ConnectedCustomerComplaintClaimForm;
  request: ConnectedCustomerComplaintRequestForm;
}

export interface ConnectedCustomerComplaintIdentityForm {
  claimType: string;
}

export interface ConnectedCustomerComplaintClaimForm {
  civilTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  phone: string;
  street: string;
  city: string;
  postalCode: string;
  clientCategory: number;
  businessName?: string;
  agencyRef?: string;
  agencyQuery?: string;
  agencyID?: string;
  accountNumber?: string;
}

export interface ConnectedCustomerComplaintRequestForm {
  subject: number;
  message: string;
  recaptcha: string;
  files?: any;
}

export const CLIENT_COMPLAINT_CATEGORIES = [
  'Paiements (carte, chèque, virement, prélèvement)',
  'Fonctionnement de compte (tarification, relevés…)',
  'Epargne et placements',
  'Crédits',
  'Assurances',
  'Evénements (ouverture, clôture, incidents, succession…)',
  'Espace client en ligne et Appli LCL Mes Comptes',
  'Services (coffre, automates…)',
  'Accueil et conseil',
];

export const OPTIONS_CLIENT_CATEGORIES = ['Particulier', 'Professionnel', 'Banque privée'];

export const RECLAMATION_IDENTITY_FORM: JSONSchema7 = {
  properties: {
    claimType: {
      type: 'string',
      enum: ['firstClaim', 'noSatisfied'],
    },
  },
};
export const RECLAMATION_CLAIM_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
      pattern: streetRegex,
    },
    city: {
      type: 'string',
      minLength: 1,
      pattern: nameRegex,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    clientCategory: {
      type: 'integer',
      minimum: 0,
      maximum: OPTIONS_CLIENT_CATEGORIES.length - 1,
    },
    businessName: {
      type: 'string',
      pattern: streetRegex,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
    },
    accountNumber: {
      type: 'string',
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      maxLength: 7,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'date',
    'phone',
    'street',
    'city',
    'postal',
    'agencyRef',
    'agencyQuery',
    'clientCategory',
  ],
};
export const RECLAMATION_REQUEST_FORM: JSONSchema7 = {
  properties: {
    subject: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_COMPLAINT_CATEGORIES.length - 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    files: {
      type: 'array',
    },
  },
  required: ['subject', 'message', 'recaptcha'],
};

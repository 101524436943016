import { Inject, Injectable } from '@angular/core';

import { DOMScriptService } from './dom-script.service';
import { TAG_COMMANDER_CONTAINER_SCRIPT, TAG_COMMANDER_VARS_SCRIPT } from '../analytics/analytics.tokens';
import { ConfigService } from '../config.service';
import { StateService } from './state.service';
import { TagCommanderEventOptions, TagCommanderVars, WindowWithTagCommander } from '../analytics/tag-commander.types';
import { Logger } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { WINDOW } from './window.provider';
import { Page, PageType } from '@repo/shared';
import { XtPage } from '../analytics/xtpage';
import { RouterService } from './router.service';

export enum AnalyticsSubPaths {
  HeaderMenuMarket = 'Header::MenuMarches',
  HeaderRightButtons = 'Header::BoutonsDroits',
  Footer = 'Footer',
  HeaderMegaMenu = 'Header::MegaMenu',
}

export enum TrackEventLabels {
  SolutionProduct = 'BlocSolutionsProduits_',
  InterstitialStep1 = 'InterstitielEtape1',
  LPEvent = 'LPEvent::offre-',
}

const PAGE_TYPE_TO_TAG_COMMANDER_PAGE_TEMPLATE = new Map<PageType, string>([
  ['landing_universe', 'Page_univers'],
  ['landing_univers_gal', 'Landing_univers_gal'],
  ['product', 'Page_produit'],
  ['page_simple', 'Page_simple'],
  ['page_simple_institutional', 'Page_simple_instit'],
  ['pagefaq', 'HP_FAQ'],
  ['homepage_institutional', 'HP_instit'],
  ['article', 'Actu_article'],
  ['newsroom_institutional', 'Newsroom'],
  ['simulators_and_estimations', 'Page_simulateurs_devis'],
  ['simulator_encapsulated', 'Page_simulateur_encapsule'],
  ['homepage_particuliers', 'HP'],
  ['contact', 'Page_contacts'],
  ['sitemap', 'Plan_site'],
  ['thematic_faq', 'Thematique_FAQ'],
  ['thematic_faq_free', 'Thematique_FAQ_Free'],
  ['form', 'Page_formulaire'],
  ['landing_page_step', 'LP_etape'],
  ['homepage_mag', 'HP_Mag'],
  ['page_timeline', 'Page_timeline'],
  ['mag_thematic_page', 'Thematique_Mag'],
  ['landing_page_campaign', 'LP_Campagne'],
  ['landing_page_event_offer_list', 'LPevent'],
  ['landing_page_block_type1', 'LPimmo'],
  ['landing_page_block_type2', 'LPimmo'],
]);

const PAGE_FORM_TO_TAG_COMMANDER_PAGE_TEMPLATE = new Map<string, string>([['resiliation', 'formulaire_etape_1']]);

export enum TagClickType {
  Navigation = 'Navigation',
  Action = 'Action',
}

@Injectable({
  providedIn: 'root',
})
export class TagCommanderService {
  private readonly defaultTagCommanderVars: TagCommanderVars = {
    site_environnement: '',
    site_parcours: 'site_vitrine_lcl',
    url_log: 'http://logc409',
    url_slog: 'https://logs1409',
    page_s1: '',
    page_s2: '',
    page_xtpage: '',
    page_chapitre: '',
    page_sous_chapitre: '',
    page_sous_sous_chapitre: '',
    page_page: '',
    page_template: '',
    user_id: '',
    user_id_AT: '',
    src: '',
    type: '',
    cat: '',
    num_dossier: '',
    offre_souscrite: '',
    maturite_projet: '',
    appetence_immo: '',
    type_page: '',
  };

  private readonly tagCommanderUrl: string;

  private logger: Logger;

  constructor(
    private readonly configService: ConfigService,
    private readonly domScriptService: DOMScriptService,
    private readonly stateService: StateService,
    private readonly routerService: RouterService,
    @Inject(WINDOW) private win: WindowWithTagCommander,
    loggerService: LoggerService,
  ) {
    this.tagCommanderUrl = this.configService.get<string>('TAG_COMMANDER_CONTAINER_URL');
    this.defaultTagCommanderVars = { ...this.defaultTagCommanderVars, ...this.getVarsByEnvironment() };
    this.logger = loggerService.get(TagCommanderService.name);
  }

  public updateConfig(page: Page): void {
    const tagCommanderVars = this._buildTagCommanderVars(page);
    this._updateScripts(tagCommanderVars);
  }

  public trackClickActionEvent(label: string, subPath?: string, fullFormatPath?: boolean): void {
    this.trackEventClick(label, TagClickType.Action, subPath, fullFormatPath);
  }

  public trackClickNavigationEvent(label: string, subPath?: string): void {
    this.trackEventClick(label, TagClickType.Navigation, subPath);
  }

  public trackDMPClickNavigationEvent(label: string): void {
    this.trackEvent('DMP_CLICK', {
      xt_clic: `${label}`,
      clic: '',
    });
  }

  public trackHavasClickTag(tagName: string): void {
    this.trackEvent(tagName, {});
  }

  private trackEventClick(label: string, type: string, subPath?: string, fullFormatPath?: boolean): void {
    if (!label || !type) {
      return;
    }
    const xtPage = this.getXtPage();
    const formattedPagePath = subPath && !fullFormatPath ? xtPage.shortFormat() : xtPage.fullFormat();
    const formattedSubPath = subPath ? `::${subPath}` : '';
    this.trackEvent('CLICK', {
      type_clic: type,
      xt_clic: `${formattedPagePath}${formattedSubPath}::${label}`,
      clic: label,
    });
  }

  public trackEventPage(label: string): void {
    this.trackEvent('PAGE', {
      xt_clic: '',
      clic: '',
      page_xtpage: `${this.getXtPage().fullFormat()}::${label}`,
    });
  }

  public trackEventPrint(campaignCode: string): void {
    this.trackEvent('IMPRESSION', { CAMPAGNE: `INT-${campaignCode}` });
  }

  public _buildTagCommanderVars(page: Page): TagCommanderVars {
    const pagePrefix = this.getPagePrefix();
    const market = page?.data?.market ?? '';
    const universe = page?.data?.universe ?? '';
    const uid = page?.uid ?? '';

    const pageUid = PAGE_FORM_TO_TAG_COMMANDER_PAGE_TEMPLATE.get(uid) ?? pagePrefix + uid;

    return {
      page_xtpage: new XtPage(
        market,
        PAGE_FORM_TO_TAG_COMMANDER_PAGE_TEMPLATE.get(uid) ? uid : universe,
        pageUid,
      ).fullFormat(),
      page_chapitre: market,
      page_sous_chapitre: PAGE_FORM_TO_TAG_COMMANDER_PAGE_TEMPLATE.get(uid) ? uid : universe,
      page_page: pageUid || '',
      page_template: this.getPageTemplateVar(page.type),
      type_page: this.getTypePageVar(page.type),
    };
  }

  public _updateScripts(tagCommanderVars: TagCommanderVars): void {
    const mergedVars = { ...this.defaultTagCommanderVars, ...tagCommanderVars };
    this.domScriptService.updateInlineScript(TAG_COMMANDER_VARS_SCRIPT, this.buildTagVarsSnippet(mergedVars));
    this.domScriptService.updateExternalScript(TAG_COMMANDER_CONTAINER_SCRIPT, this.tagCommanderUrl);
  }

  private getVarsByEnvironment(): TagCommanderVars {
    return {
      site_environnement: this.configService.get<string>('TAG_COMMANDER_ENVIRONMENT') || '',
      page_s1: this.configService.get<string>('TAG_COMMANDER_PAGE_S1') || '',
    };
  }

  private buildTagVarsSnippet(tagCommanderVars: TagCommanderVars): string {
    const vars = Object.keys(tagCommanderVars)
      .map(key => `tc_vars['${key}'] = ${JSON.stringify(tagCommanderVars[key])};`)
      .join('\n      ');

    return `
      tc_vars = {};
      ${vars}
    `;
  }

  private trackEvent(event: string, options: TagCommanderEventOptions): void {
    if (!this.win) {
      return;
    }
    if (!this.win.tc_events_26) {
      this.logger.warn(`Failed to track event "${event}": tc_events_26 function is undefined`);
      return;
    }
    try {
      this.win.tc_events_26(this.win, event, options);
    } catch (err) {
      this.logger.warn(err);
    }
  }

  private getXtPage(): XtPage {
    const { pageMarket, pageUid, pageUniverse } = this.stateService.get();
    const pagePrefix = this.getPagePrefix();
    return new XtPage(pageMarket as string, pageUniverse as string, pagePrefix + pageUid);
  }

  private getPageTemplateVar(type: PageType): string {
    return PAGE_TYPE_TO_TAG_COMMANDER_PAGE_TEMPLATE.get(type) || '';
  }

  private getTypePageVar(type: PageType): string {
    switch (type) {
      case 'homepage_particuliers':
        return 'hp';
      case 'landing_universe':
      case 'landing_univers_gal':
      case 'product':
        return 'product';
      default:
        return '';
    }
  }

  private getPagePrefix(): string {
    return this.routerService.isIframePage() ? 'iframe_' : '';
  }
}

import { AriaDescriber } from '@angular/cdk/a11y';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions, Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UiTooltipMessageComponent } from './tooltip-message.component';
import { connectedPositionMap } from './tooltip.helper';
import { TooltipPosition } from './tooltip.types';

export const LONGPRESS_DELAY = 100;

const passiveListenerOptions = normalizePassiveListenerOptions({ passive: true });

@Directive({
  selector: '[uiTooltip]',
  exportAs: 'uiTooltip',
})
export class UiTooltipDirective implements OnDestroy, OnInit {
  @Input('uiTooltip')
  set message(value: string) {
    this._message = value != null ? value.trim() : '';
    this.updateTooltipMessage();
  }
  get message(): string {
    return this._message;
  }

  @Input('tooltipPosition')
  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;
      if (this._overlayRef) {
        this.updatePosition();
        this._overlayRef.updatePosition();
      }
    }
  }
  get position(): TooltipPosition {
    return this._position;
  }

  @HostBinding('attr.tabindex')
  tabindex = 0;

  _overlayRef: OverlayRef;
  private _message: string;
  private _position: TooltipPosition = 'bottom';
  private _isVisible = false;
  private _tooltipInstance: UiTooltipMessageComponent | null;
  private readonly _destroyed = new Subject<void>();
  private _passiveListeners = new Map<string, EventListenerOrEventListenerObject>();
  private _showStartTimeout: any;
  private _scrollSubscription: Subscription;

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly ngZone: NgZone,
    private readonly platform: Platform,
    private readonly ariaDescriber: AriaDescriber,
    private readonly scrollDispatcher: ScrollDispatcher,
  ) {}

  ngOnInit(): void {
    this.setupScrollSubscription();
    this.setupEventListener();
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._tooltipInstance = null;
    }

    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe();
      this._scrollSubscription = (null as unknown) as Subscription;
    }

    this.clearEventListeners();
    this.removeAriaDescription();

    this._destroyed.next();
    this._destroyed.complete();
  }

  private setupScrollSubscription(): void {
    this._scrollSubscription = this.scrollDispatcher.scrolled().subscribe(() => {
      if (this._isVisible) {
        this.hideTip();
      }
    });
  }

  private setupEventListener(): void {
    if (!this.platform.IOS && !this.platform.ANDROID) {
      this._passiveListeners.set('mouseenter', () => this.showTip()).set('mouseleave', () => this.hideTip());
      this._passiveListeners.set('focus', () => this.showOnFocus()).set('blur', () => this.hideTip());
      this._passiveListeners.set('click', () => this.toggleOverClick());
      this._passiveListeners.set('keydown', (event: KeyboardEvent) => {
        if (event && (event.key === 'Escape' || event.key === 'Esc')) {
          return this.hideTip();
        }
      });
    } else {
      this._passiveListeners
        .set('click', () => this.toggleOverTouch())
        .set('touch', () => this.toggleOverTouch())
        .set('blur', () => this.hideOverTouch());
    }

    this._passiveListeners.forEach((listener, event) => {
      this.elementRef.nativeElement.addEventListener(event, listener, passiveListenerOptions);
    });
  }

  private clearEventListeners(): void {
    this._passiveListeners.forEach((listener, event) => {
      this.elementRef.nativeElement.removeEventListener(event, listener, passiveListenerOptions);
    });
    this._passiveListeners.clear();
  }

  private showTip(): void {
    this.createModal();

    const native = this.elementRef.nativeElement as HTMLElement;

    if (this._position === 'bottom') {
      const offsetLeft = native.offsetLeft + native.offsetWidth / 2 - 7;
      this._overlayRef.overlayElement.style.setProperty('--caret-offset-left', `${offsetLeft}px`);
    }

    if (!this._tooltipInstance) {
      this._tooltipInstance = this._overlayRef.attach(
        new ComponentPortal(UiTooltipMessageComponent, this.viewContainerRef),
      ).instance;
    }
    this.updateTooltipMessage();
    if (this._message) {
      this._tooltipInstance.show(this.position);
      this._isVisible = true;
    }

    this.setAriaDescription();
  }

  private hideTip(): void {
    if (this._tooltipInstance) {
      this._tooltipInstance.hide();
      this._isVisible = false;
    }
    this.closeModal();
    this.removeAriaDescription();
  }

  private hideOverTouch(): void {
    clearTimeout(this._showStartTimeout);
    this.hideTip();
  }

  private toggleOverTouch(): void {
    if (!this._isVisible) {
      this._showStartTimeout = setTimeout(() => {
        this.showTip();
      }, LONGPRESS_DELAY);
    } else {
      this.hideOverTouch();
    }
  }

  private toggleOverClick(): void {
    if (!this._isVisible) {
      this.showTip();
    } else {
      this.hideTip();
    }
  }

  private showOnFocus(): void {
    // Accessibility: NVDA provokes focus and click events at the same time
    // which will show and hide immediately the tooltip
    setTimeout(() => {
      this.showTip();
    }, 100);
  }

  private updateTooltipMessage(): void {
    if (this._tooltipInstance) {
      this._tooltipInstance.message = this._message;
    }
  }

  private updatePosition(): void {
    if (this._overlayRef) {
      const position = this._overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
      position.withPositions([connectedPositionMap[this.position]]);
    }
  }

  private createModal(): void {
    if (this._overlayRef) {
      return;
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([connectedPositionMap[this.position]]);
    this._overlayRef = this.overlay.create({ positionStrategy });
    this._overlayRef
      .detachments()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.closeModal());
  }

  private closeModal(): void {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }

    this._tooltipInstance = null;
  }

  private setAriaDescription(): void {
    this.ngZone.runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.ariaDescriber.describe(this.elementRef.nativeElement, this._message);
      });
    });
  }

  private removeAriaDescription(): void {
    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this._message);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InViewportModule } from 'ng-in-viewport';

import { AnalyticsModule } from '../../../analytics/analytics.module';
import { SliceSolutionProductsComponent } from './slice-solution-products.component';
import { SolutionProductComponent } from './solution-product/solution-product.component';
import { UiSlideshowBlockModule } from '../../../ui/molecules/slideshow-block/slideshow-block.module';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';

@NgModule({
  imports: [CommonModule, InViewportModule, UiSlideshowBlockModule, AnalyticsModule, CommonBehaviorsModule],
  declarations: [SliceSolutionProductsComponent, SolutionProductComponent],
  exports: [SliceSolutionProductsComponent, SolutionProductComponent],
})
export class SliceSolutionProductsModule {}

import { Component, Input, HostBinding, Inject } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { ProjectBlockCtaCSlice, ProjectBlock } from '@repo/shared';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StateService } from '../../../services/state.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'slice-project-block-cta',
  template: `
    <div class="content">
      <h2 role="section-h2" class="title" *ngIf="data && data.title">{{ data.title }}</h2>
      <ui-slideshow-block
        *ngIf="data"
        [fixTopOffset]="fixTopOffsetDesktop"
        [fixTopOffsetDesktop]="fixTopOffsetDesktop"
        inViewport
        (inViewportAction)="onSlidesContainerIntersection()"
      >
        <nav *ngIf="isNavShown()" uiSlideshowNav>
          <a *ngFor="let projectBlock of projectBlocksWithAnchor" [href]="'#' + projectBlock.anchor" uiSlideshowAnchor>
            {{ projectBlock.anchorLabel }}
          </a>
        </nav>
        <spbc-project-block
          *ngFor="let projectBlock of data.projectBlocks; let odd = odd"
          uiSlideshowSlide
          [block]="projectBlock"
          [right]="odd"
          [parentSliceType]="data.type"
        >
        </spbc-project-block>
      </ui-slideshow-block>
    </div>
  `,
  animations: [
    trigger('enterInOut', [
      state('out', style({ opacity: 0.25 })),
      state('in', style({ opacity: 1 })),
      transition('out -> in', animate('0.4s ease-in-out')),
    ]),
  ],
  styleUrls: ['./slice-project-block-cta.component.scss'],
})
export class SliceProjectBlockCtaComponent implements SliceWithData<ProjectBlockCtaCSlice> {
  @Input() data: ProjectBlockCtaCSlice;

  @HostBinding('@enterInOut') get enterInOutState(): 'in' | 'out' {
    if (this.isNavShown()) {
      return this.isInViewport ? 'in' : 'out';
    } else {
      return 'in';
    }
  }

  @HostBinding('class.with-nav') get cssClassWithNav(): boolean {
    return this.isNavShown();
  }

  isInViewport = false;

  constructor(private readonly stateService: StateService, @Inject(DOCUMENT) private readonly document: Document) {}

  get projectBlocksWithAnchor(): ProjectBlock[] {
    return this.data && this.data.projectBlocks
      ? this.data.projectBlocks.filter(projectBlock => projectBlock.anchor && projectBlock.anchorLabel)
      : [];
  }

  isNavShown(): boolean {
    return this.projectBlocksWithAnchor.length > 1;
  }

  onSlidesContainerIntersection(): void {
    const blocCtaNode = this.document.getElementsByTagName('ui-slideshow-block');
    if (!this.isInViewport && blocCtaNode.length !== 0) {
      this.isInViewport = true;
    }
  }

  get fixTopOffsetDesktop(): number {
    return this.stateService.get().stickyHeaderBottomPosition || 0;
  }
}

import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { WINDOW } from './services/window.provider';

@Injectable()
export class WindowLoadEventService {
  load$ = new ReplaySubject<void>(1);

  constructor(@Inject(WINDOW) private readonly win: Window) {
    if (this.win) {
      this.win.addEventListener('load', () => this.load$.next());
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  Subscription,
  animationFrameScheduler,
  fromEvent,
  merge,
} from 'rxjs';
import { mapTo, takeUntil, throttleTime, share } from 'rxjs/operators';

import { WINDOW } from '../../../services/window.provider';
import {
  getWindowScrollPosition,
  getAbsoluteRect,
  getViewportSize,
  getRelativeRect,
} from '../../../helpers/dom.helpers';

export enum UiStickyState {
  Normal = 'normal',
  Prestickied = 'prestickied',
  Stickied = 'stickied',
}

function nextAnimationFrame<T>(): MonoTypeOperatorFunction<T> {
  return throttleTime(0, animationFrameScheduler);
}

@Directive({
  selector: '[uiStickyBottom]',
  exportAs: 'uiStickyBottom',
})
export class UiStickyBottomDirective implements AfterViewInit, OnDestroy {
  get enable(): boolean {
    return this.enable$.getValue();
  }
  @Input()
  set enable(value: boolean) {
    value = coerceBooleanProperty(value);

    if (value !== this.enable) {
      this.enable$.next(value);
    }
  }
  private enable$ = new BehaviorSubject<boolean>(true);

  get spot(): HTMLElement | null {
    return this.spot$.getValue();
  }
  @Input()
  set spot(value: HTMLElement | null) {
    if (value !== this.spot) {
      this.spot$.next(value);
    }
  }
  private spot$ = new BehaviorSubject<HTMLElement | null>(null);

  get state(): UiStickyState {
    return this.state$.getValue();
  }
  set state(value: UiStickyState) {
    this.state$.next(value);
  }
  private state$ = new BehaviorSubject<UiStickyState>((null as unknown) as UiStickyState);

  @HostBinding('class.sticky-bottom')
  get cssClassSticky(): boolean {
    return this.enable;
  }

  @HostBinding('class.sticky-bottom--normal')
  get cssClassStickyNormal(): boolean {
    return this.enable && this.state === UiStickyState.Normal;
  }

  @HostBinding('class.sticky-bottom--prestickied')
  get cssClassStickyPrestickied(): boolean {
    return this.enable && this.state === UiStickyState.Prestickied;
  }

  @HostBinding('class.sticky-bottom--stickied')
  get cssClassStickyStickied(): boolean {
    return this.enable && this.state === UiStickyState.Stickied;
  }

  get hidden(): boolean {
    return !(this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.offsetHeight);
  }

  private ghost: HTMLDivElement;
  private styleOriginal: { [key: string]: string };

  readonly destroyed$ = new Subject<void>();

  private monitoring$: Observable<boolean>;
  private monitoringSubscription: Subscription;

  constructor(
    readonly elementRef: ElementRef<HTMLElement>,
    @Inject(WINDOW) readonly win: Window,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly renderer: Renderer2,
    readonly ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    this.state$.subscribe(_state =>
      this.ngZone.run(() => {
        this.changeDetectorRef.markForCheck();
      }),
    );

    this.ngZone.runOutsideAngular(() => {
      this._initMonitoring();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    if (this.monitoringSubscription) {
      this.monitoringSubscription.unsubscribe();
      this.monitoringSubscription = (null as unknown) as Subscription;
    }

    if (this.ghost) {
      this.renderer.removeChild(this.elementRef.nativeElement.parentElement, this.ghost);
      this.ghost = (null as unknown) as HTMLDivElement;
    }
  }

  _initMonitoring(): void {
    if (!this.win) {
      return;
    }

    if (!this.monitoring$) {
      const mapToShouldReset = mapTo(true);
      const mapToShouldNotReset = mapTo(false);

      this.monitoring$ = merge(
        fromEvent(this.win, 'load').pipe(mapToShouldReset),
        fromEvent(this.win, 'orientationchange').pipe(mapToShouldReset),
        fromEvent(this.win, 'resize').pipe(mapToShouldReset),
        fromEvent(this.win, 'scroll').pipe(mapToShouldNotReset),
        this.enable$.pipe(mapToShouldReset),
        this.spot$.pipe(mapToShouldReset),
      ).pipe(takeUntil(this.destroyed$), nextAnimationFrame(), share());
    }

    if (!this.monitoringSubscription) {
      this.monitoringSubscription = this.monitoring$.subscribe(shouldReset => {
        this._refresh(shouldReset);
      });
    }
  }

  _refresh(shouldReset: boolean): void {
    if (!this.win) {
      return;
    }

    if (this.hidden) {
      this._refreshElement((null as unknown) as UiStickyState);

      return;
    }

    if (!this.enable) {
      this._refreshElement((null as unknown) as UiStickyState);

      if (this.state) {
        this.state = (null as unknown) as UiStickyState;
      }

      return;
    }

    let setStickyNormal = false;

    if (shouldReset) {
      this._refreshElement((null as unknown) as UiStickyState);
      this._refreshElement(UiStickyState.Normal);

      setStickyNormal = true;
    } else if (!this.state) {
      this._refreshElement(UiStickyState.Normal);

      setStickyNormal = true;
    }

    const previousState = this.state;
    const scrollTop = getWindowScrollPosition(this.win).top;
    const state = this._determineStickyState(scrollTop);
    const stateChanged = state !== previousState;

    if (
      this.spot ||
      (!stateChanged && setStickyNormal && state !== UiStickyState.Normal) ||
      (stateChanged && (state !== UiStickyState.Normal || !setStickyNormal))
    ) {
      this._refreshElement(state);
    }

    if (stateChanged) {
      this.state = state;
    }
  }

  _determineStickyState(scrollTop: number): UiStickyState {
    if (!this.win) {
      return (null as unknown) as UiStickyState;
    }

    const ghostRect = getAbsoluteRect(this.ghost);
    const viewportSize = getViewportSize(this.win);
    let state: UiStickyState = UiStickyState.Normal;

    if (this.spot) {
      const spotRect = getAbsoluteRect(this.spot);

      const stickiedStart = scrollTop - spotRect.top - spotRect.height;
      const stickiedEnd = ghostRect.top - scrollTop - viewportSize.height + ghostRect.height;

      if (stickiedStart > 0 && stickiedEnd > 0) {
        state = UiStickyState.Stickied;
      } else if (stickiedStart <= 0 && stickiedEnd > 0) {
        state = UiStickyState.Prestickied;
      }
    } else {
      const stickiedStart = ghostRect.top - scrollTop - viewportSize.height + ghostRect.height;

      if (stickiedStart > 0) {
        state = UiStickyState.Stickied;
      }
    }

    return state;
  }

  _refreshElement(state: UiStickyState): void {
    if (!this.win) {
      return;
    }

    if (!state) {
      if (this.ghost) {
        this.renderer.setStyle(this.ghost, 'display', 'none');
      }

      this._restoreStyleOriginal();

      return;
    }

    this._saveStyleOriginal();

    if (state === UiStickyState.Normal) {
      this._refreshGhost();
    }

    const elementStyle = this._getElementStyle(state);

    Object.keys(elementStyle).forEach(key => {
      this.renderer.setStyle(this.elementRef.nativeElement, key, elementStyle[key]);
    });
  }

  _getElementStyle(state: UiStickyState): { [key: string]: string } {
    if (!this.win || !state) {
      return {};
    }

    const stickied = state === UiStickyState.Stickied;
    let styles: { [key: string]: string };

    if (stickied || state === UiStickyState.Prestickied) {
      const ghostRect = getAbsoluteRect(this.ghost);

      styles = {
        position: 'fixed',
        top: '',
        bottom: '0',
        left: `${ghostRect.left}px`,
        transform: `translateY(${stickied ? '0' : '100%'})`,
      };
    } else {
      const ghostRect = getRelativeRect(this.win, this.ghost);
      const ghostStyle = this.win.getComputedStyle(this.ghost);

      const elementWidth =
        ghostRect.width -
        ((parseFloat(ghostStyle.borderLeftWidth) || 0) + (parseFloat(ghostStyle.borderRightWidth) || 0)) -
        ((parseFloat(ghostStyle.paddingLeft) || 0) + (parseFloat(ghostStyle.paddingRight) || 0));

      styles = {
        position: 'absolute',
        width: `${elementWidth}px`,
        top: `${ghostRect.top}px`,
        bottom: '',
        left: `${ghostRect.left}px`,
        margin: '0px',
      };
    }

    return styles;
  }

  _refreshGhost(): void {
    if (!this.win) {
      return;
    }

    let styleRefreshNeeded = false;

    if (!this.ghost) {
      this.ghost = this.renderer.createElement('div');
      this.renderer.addClass(this.ghost, 'sticky-ghost');
      this.renderer.setStyle(this.ghost, 'borderStyle', 'solid');
      this.renderer.setStyle(this.ghost, 'borderColor', 'transparent');
      this.renderer.insertBefore(
        this.elementRef.nativeElement.parentElement,
        this.ghost,
        this.elementRef.nativeElement,
      );

      styleRefreshNeeded = true;
    } else if (this.ghost.style.display === 'none') {
      this.renderer.setStyle(this.ghost, 'display', 'block');

      styleRefreshNeeded = true;
    }

    if (!styleRefreshNeeded) {
      return;
    }

    const elementStyle = this.win.getComputedStyle(this.elementRef.nativeElement);

    const ghostHeight =
      this.elementRef.nativeElement.offsetHeight -
      (parseFloat(elementStyle.borderTopWidth) || 0) -
      (parseFloat(elementStyle.borderBottomWidth) || 0) -
      (parseFloat(elementStyle.paddingTop) || 0) -
      (parseFloat(elementStyle.paddingBottom) || 0);

    this.renderer.setStyle(this.ghost, 'width', this.elementRef.nativeElement.style.width);
    this.renderer.setStyle(this.ghost, 'height', `${ghostHeight}px`);
    this.renderer.setStyle(this.ghost, 'borderTopWidth', elementStyle.borderTopWidth);
    this.renderer.setStyle(this.ghost, 'borderBottomWidth', elementStyle.borderBottomWidth);
    this.renderer.setStyle(this.ghost, 'borderLeftWidth', elementStyle.borderLeftWidth);
    this.renderer.setStyle(this.ghost, 'borderRightWidth', elementStyle.borderRightWidth);
    this.renderer.setStyle(this.ghost, 'marginTop', elementStyle.marginTop);
    this.renderer.setStyle(this.ghost, 'marginBottom', elementStyle.marginBottom);
    this.renderer.setStyle(this.ghost, 'marginLeft', elementStyle.marginLeft);
    this.renderer.setStyle(this.ghost, 'marginRight', elementStyle.marginRight);
    this.renderer.setStyle(this.ghost, 'paddingTop', elementStyle.paddingTop);
    this.renderer.setStyle(this.ghost, 'paddingBottom', elementStyle.paddingBottom);
    this.renderer.setStyle(this.ghost, 'paddingLeft', elementStyle.paddingLeft);
    this.renderer.setStyle(this.ghost, 'paddingRight', elementStyle.paddingRight);
  }

  _restoreStyleOriginal(): void {
    if (this.styleOriginal) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', this.styleOriginal.position);
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', this.styleOriginal.width);
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', this.styleOriginal.top);
      this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', this.styleOriginal.bottom);
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', this.styleOriginal.left);
      this.renderer.setStyle(this.elementRef.nativeElement, 'margin', this.styleOriginal.margin);
      this.renderer.setStyle(this.elementRef.nativeElement, 'marginTop', this.styleOriginal.marginTop);
      this.renderer.setStyle(this.elementRef.nativeElement, 'marginRight', this.styleOriginal.marginRight);
      this.renderer.setStyle(this.elementRef.nativeElement, 'marginBottom', this.styleOriginal.marginBottom);
      this.renderer.setStyle(this.elementRef.nativeElement, 'marginLeft', this.styleOriginal.marginLeft);

      this.styleOriginal = (null as unknown) as { [key: string]: string };
    }
  }

  _saveStyleOriginal(): void {
    if (!this.styleOriginal) {
      this.styleOriginal = {
        position: this.elementRef.nativeElement.style.position,
        width: this.elementRef.nativeElement.style.width,
        top: this.elementRef.nativeElement.style.top,
        bottom: this.elementRef.nativeElement.style.bottom,
        left: this.elementRef.nativeElement.style.left,
        margin: this.elementRef.nativeElement.style.margin,
        marginTop: this.elementRef.nativeElement.style.marginTop,
        marginRight: this.elementRef.nativeElement.style.marginRight,
        marginBottom: this.elementRef.nativeElement.style.marginBottom,
        marginLeft: this.elementRef.nativeElement.style.marginLeft,
      };
    }
  }
}

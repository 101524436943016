import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  HostBinding,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
  InteractiveQuestion,
  InteractiveQuestionAnswer,
  InteractiveQuestionsBlock,
  InteractiveQuestionsSlice,
} from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { ResponsiveService } from '../../../services/responsive.service';
import { RouterService } from '../../../services/router.service';
import { TagCommanderService } from '../../../services/tag-commander.service';

@Component({
  selector: 'slice-interactive-questions-block',
  template: `
    <div class="content">
      <cb-mini-title *ngIf="title" [title]="title" [withFrame]="!isFirstSlice"></cb-mini-title>
    </div>
    <div *ngIf="currentQuestion">
      <div class="question content">
        {{ currentQuestion.label }}
      </div>
      <div *ngIf="currentQuestion.answers" class="answers">
        <cb-cta-btn-link
          class="answer-button"
          *ngFor="let answer of currentQuestion.answers"
          [buttonWithoutLink]="true"
          [button]="{ label: answer.label, type: 'light' }"
          (click)="onAnswerClicked(answer)"
        >
        </cb-cta-btn-link>
      </div>
    </div>
  `,
  styleUrls: ['./slice-interactive-questions-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceInteractiveQuestionsBlockComponent
  implements ComponentWithData<InteractiveQuestionsSlice>, OnDestroy, OnInit {
  @Input() sliceIndex: number;
  @Input() isFirstSlice: boolean;

  _data: InteractiveQuestionsSlice;
  _block: InteractiveQuestionsBlock;
  _subscription = new Subscription();
  _isDesktop = false;

  currentQuestionId: string;
  currentQuestion: InteractiveQuestion;

  get data(): InteractiveQuestionsSlice {
    return this._data;
  }

  @Input()
  set data(value: InteractiveQuestionsSlice) {
    if (value !== this._data) {
      this._data = value;
      this.load(value && value.interactiveQuestionsBlock);
    }
  }

  @HostBinding('style.backgroundImage')
  get styleBackgroundImageUrl(): string | null {
    const backgroundImageUrl = this._block?.backgroundUrl;

    return (this._isDesktop && backgroundImageUrl && `url(${backgroundImageUrl})`) || null;
  }

  get backgroundImageUrl(): string {
    return (this._block?.backgroundUrl as unknown) as string;
  }

  get title(): string {
    return (this._block?.title as unknown) as string;
  }

  constructor(
    private readonly routerService: RouterService,
    private readonly responsiveService: ResponsiveService,
    private readonly tagCommanderService: TagCommanderService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._subscription.add(
      this.routerService.queryParams.subscribe(queryParams => {
        const questionIdKey = this.getQuestionIdQueryParamKey();
        const questionId = queryParams[questionIdKey] || this._block.firstQuestionId;

        this.changeCurrentQuestion(questionId);

        this.changeDetectorRef.markForCheck();
      }),
    );

    this._subscription.add(
      this.responsiveService.subscribeOnDesktop(matches => {
        this._isDesktop = matches;
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  load(block: InteractiveQuestionsBlock): void {
    if (block === this._block) {
      return;
    }

    if (!block) {
      this._block = (null as unknown) as InteractiveQuestionsBlock;
      this.changeCurrentQuestion((null as unknown) as string);
      return;
    }

    this._block = block;
    this.changeCurrentQuestion(block.firstQuestionId);
  }

  changeCurrentQuestion(questionId: string): void {
    if (questionId === this.currentQuestionId) {
      return;
    }

    const question = questionId && this._block?.questions.find(_question => _question.id === questionId);

    this.currentQuestionId = questionId;
    this.currentQuestion = (question as unknown) as InteractiveQuestion;
  }

  onAnswerClicked(answer: InteractiveQuestionAnswer): void {
    this.tagCommanderService.trackClickNavigationEvent(this.getAnswerAnalyticsLabel(this.currentQuestion, answer));

    if (answer.action === 'changePage') {
      this.routerService.navigateByUrl('/' + answer.value);
    } else if (answer.action === 'changeQuestion') {
      this.changeCurrentQuestion(answer.value);
      this.routerService.replaceQueryParam(this.getQuestionIdQueryParamKey(), answer.value, true);
    }
  }

  getAnswerAnalyticsLabel(question: InteractiveQuestion, answer: InteractiveQuestionAnswer): string {
    return `Questionnaire_${question.label}_${answer.label}`;
  }

  getQuestionIdQueryParamKey(): string {
    return `question-${this.sliceIndex || 0}`;
  }
}

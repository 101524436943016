import {
  ContentChildren,
  Directive,
  QueryList,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { UiSlideshowAnchorDirective } from './slideshow-anchor.directive';

@Directive({
  selector: '[uiSlideshowNav]',
})
export class UiSlideshowNavDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(UiSlideshowAnchorDirective) anchors: QueryList<UiSlideshowAnchorDirective>;
  @Output() anchorClicked = new EventEmitter<UiSlideshowAnchorDirective>();

  anchorClickedSubscriptions: Subscription[];

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.anchorClickedSubscriptions = this.anchors.map(anchor =>
      anchor.onClick((anchorClicked: UiSlideshowAnchorDirective) => this.handleAnchorClick(anchorClicked)),
    );
  }

  ngOnDestroy(): void {
    if (this.anchorClickedSubscriptions) {
      this.anchorClickedSubscriptions.forEach(anchorClickedSubscription => anchorClickedSubscription.unsubscribe());
      this.anchorClickedSubscriptions = (null as unknown) as Subscription[];
    }
  }

  getAnchorById(id: string): UiSlideshowAnchorDirective {
    return this.anchors.find(anchor => anchor.getTargetId() === id) as UiSlideshowAnchorDirective;
  }

  handleAnchorClick(anchor: UiSlideshowAnchorDirective): void {
    this.anchorClicked.emit(anchor);
  }

  onAnchorClick(callback: (anchor: UiSlideshowAnchorDirective) => void): Subscription {
    return this.anchorClicked.subscribe(callback);
  }

  setAnchorActive(anchorActive: UiSlideshowAnchorDirective | null): void {
    this.anchors.forEach(anchor => {
      if (anchor === anchorActive) {
        this.renderer.addClass(anchor.element, 'activate');
      } else {
        this.renderer.removeClass(anchor.element, 'activate');
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { ImageWithMobile } from '@repo/shared';

@Component({
  selector: 'cb-phone',
  template: ` <a [appHrefPhone]="phone" [class.with-image]="!!imageUrl">
    <ng-container *ngIf="!imageUrl">{{ phone }}</ng-container>
    <img height="30" *ngIf="imageUrl" [attr.alt]="image.alt" [attr.src]="imageUrl" />
  </a>`,
})
export class PhoneComponent {
  @Input() phone: string | null;
  @Input() image: ImageWithMobile | null;

  get imageUrl(): string | null {
    return (this.image && this.image.url) || null;
  }
}

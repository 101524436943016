export class NestedError extends Error {
  constructor(readonly message: string, readonly nested: Error) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
      // eslint-disable-next-line no-prototype-builtins
    } else if (!this.hasOwnProperty('stack')) {
      Object.defineProperty(this, 'stack', {
        configurable: true,
        value: this.stack || new Error().stack,
      });
    }

    const oldStackDescriptor = Object.getOwnPropertyDescriptor(this, 'stack') as PropertyDescriptor;
    const stackDescriptor = buildStackDescriptor(oldStackDescriptor, nested);

    Object.defineProperty(this, 'stack', stackDescriptor);
  }
}

export function buildStackDescriptor(oldStackDescriptor: PropertyDescriptor, nested?: Error): PropertyDescriptor {
  const oldStackDescriptorGet = oldStackDescriptor.get;
  if (oldStackDescriptorGet) {
    return {
      get(): string {
        const stack = oldStackDescriptorGet.call(this);

        return buildCombinedStacks(stack, nested);
      },
    };
  } else {
    const stack = oldStackDescriptor.value;

    return {
      value: buildCombinedStacks(stack, nested),
    };
  }
}

export function buildCombinedStacks(stack: string, nested?: Error): string {
  if (nested) {
    stack += '\nCaused By: ' + nested.stack;
  }

  return stack;
}

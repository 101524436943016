export interface Swipe {
  prev(): void;
  next(): void;
  getPos(): number;
  getNumSlides(): number;
  slide(index: number, duration: number): void;
  restart(): void;
  stop(): void;
  setup(options?: SwipeOptions): void;
  kill(): void;
  disable(): void;
  enable(): void;
}

export interface SwipeOptions {
  startSlide?: number;
  speed?: number;
  auto?: number;
  draggable?: boolean;
  continuous?: boolean;
  autoRestart?: boolean;
  disableScroll?: boolean;
  stopPropagation?: boolean;
  callback?: (index: number, elem: HTMLElement, dir: number) => void;
  transitionEnd?: (index: number, elem: HTMLElement) => void;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _Swipe: any = require('swipejs');

export function newSwipe(container: HTMLElement, options?: SwipeOptions): Swipe {
  return options ? new _Swipe(container, options) : new _Swipe(container);
}

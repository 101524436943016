import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ComponentWithData } from '../../../../typings';
import { SimulatorSlice, SimulatorType } from '@repo/shared';
import { iframeResizer, IFrameComponent } from 'iframe-resizer';
import { WINDOW } from '../../../services/window.provider';
import { ConfigService } from '../../../config.service';
import { DOMScriptService } from '../../../services/dom-script.service';
import { DOMService } from '../../../services/dom.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'slice-simulator',
  template: `
    <ng-container *ngIf="data">
      <div class="iframe-wrapper">
        <div *ngIf="!isNexecur">
          <div *ngIf="!loaded" class="loader desktop-only">Loading...</div>
          <iframe
            [id]="iframeId"
            [src]="data.url | trustResourceUrl"
            [class.desktop-only]="data.mobileIsDifferent"
            [attr.width]="iframeWidth"
            frameborder="0"
            [attr.height]="data.height"
            (load)="iframeLoaded()"
          >
          </iframe>
        </div>
        <div *ngIf="isNexecur">
          <div id="iframe-nexecur-container" #iframeNexecurContainer></div>
          <label id="iframe-nexecur-origin" hidden>{{ iframeNexecurOrigin }}</label>
        </div>
      </div>
      <div
        *ngIf="data.mobileIsDifferent"
        class="mobile-simulator-unavailable-message mobile-only"
        [innerHTML]="data.description"
      ></div>
    </ng-container>
  `,
  styleUrls: ['./slice-simulator.component.scss'],
})
export class SliceSimulatorComponent implements OnInit, AfterViewInit, ComponentWithData, OnDestroy {
  @Input() data: SimulatorSlice;
  @Input() sliceIndex: number;

  loaded = false;
  nni = false;

  @ViewChild('iframeNexecurContainer', { static: false }) divNexecur: ElementRef<HTMLDivElement>;
  isNexecur = false;
  iframeNexecurOrigin: string;
  nexecurScriptContent: any;

  private iFrameComponent: IFrameComponent;

  readonly IFRAME_DEFAULT_WIDTH = '100%';

  pageUid: string;

  get iframeWidth(): string {
    return this.IFRAME_DEFAULT_WIDTH;
  }

  get iframeId(): string {
    return 'slice' + this.sliceIndex;
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef,
    private readonly _domService: DOMService,
    private readonly configService: ConfigService,
    private readonly domScriptService: DOMScriptService,
    private readonly stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.pageUid = this.stateService.get().pageUid || ' ';
    this.isNexecur = this.data.simulatorType === SimulatorType.Nexecur;
    this.iframeNexecurOrigin = this.configService.get<string>('HOST');
  }

  ngAfterViewInit(): void {
    if (!this.win) {
      return;
    }
    if (this.win.addEventListener) {
      this.win.addEventListener(
        'message',
        _event => {
          this.receiveMessage(_event);
        },
        true,
      );
    }

    if (this.isNexecur) {
      this.nexecurScriptContent = require('./nexecur-script.js');
      this.domScriptService.updateInlineScript('nexecurScript', this.nexecurScriptContent);
    }
  }

  receiveMessage(_event: MessageEvent): void {
    try {
      if (_event.data) {
        const eventData = _event.data;
        if (eventData.messageType === 'IFrameResize') {
          if (eventData.productorFrameHeight) {
            this.data.height = eventData.productorFrameHeight;
            this.nni = true;
          }
          if (eventData.retourTop) {
            const iframeElement = this._elementRef.nativeElement.parentNode;
            this._domService.scrollTo(iframeElement);
          }
          this.changeDetectorRef.detectChanges();
        }
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  iframeLoaded(): void {
    this.loaded = true;
    if (!this.iFrameComponent && this.data.simulatorType === SimulatorType.Harvest) {
      this.iFrameComponent = iframeResizer(
        {
          checkOrigin: false,
          heightCalculationMethod: 'documentElementOffset',
          log: false,
        },
        '#' + this.iframeId,
      )[0];
    }
  }

  ngOnDestroy(): void {
    if (this.iFrameComponent) {
      this.iFrameComponent.iFrameResizer.close();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';

import { HeaderComponent } from './header/header.component';
import { DesktopHeaderComponent } from './desktop-header/desktop-header.component';
import { MobileHeaderModule } from './mobile-header/mobile-header.module';
import { HeaderService } from './header.service';
import { HeaderLoadScriptService } from './headerLoadScript.service';
import { DesktopHeaderMenuItemComponent } from './desktop-header/desktop-header-menu-item/desktop-header-menu-item.component';
import { DesktopHeaderSecondLineComponent } from './desktop-header/desktop-header-second-line/desktop-header-second-line.component';
import { DesktopHeaderActionButtonComponent } from './desktop-header/desktop-header-action-button/desktop-header-action-button.component';
import { AnalyticsModule } from '../../analytics/analytics.module';
import { CommonBehaviorsModule } from '../common-behaviors/common-behaviors.module';

@NgModule({
  declarations: [
    HeaderComponent,
    DesktopHeaderComponent,
    DesktopHeaderMenuItemComponent,
    DesktopHeaderSecondLineComponent,
    DesktopHeaderActionButtonComponent,
  ],
  imports: [CommonModule, InViewportModule, MobileHeaderModule, AnalyticsModule, CommonBehaviorsModule],
  exports: [HeaderComponent],
  providers: [HeaderService, HeaderLoadScriptService],
})
export class HeaderModule {}

import { trigger, state, transition, style, animate } from '@angular/animations';

/** Time and timing curve for expansion panel animations. */
export const UI_EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const uiExpansionPanelAnimations = {
  /** Animation that expands and collapses the panel content. */
  bodyExpansion: trigger('bodyExpansion', [
    state('collapsed', style({ height: '0px' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate(UI_EXPANSION_PANEL_ANIMATION_TIMING)),
  ]),
  /** Animation that rotates the indicator arrow. */
  indicatorRotate: trigger('indicatorRotate', [
    state('collapsed', style({ transform: 'rotate(0)' })),
    state('expanded', style({ transform: 'rotate(180deg)' })),
    transition('expanded <=> collapsed', animate(UI_EXPANSION_PANEL_ANIMATION_TIMING)),
  ]),
};

import { Directive, Output, EventEmitter, HostBinding } from '@angular/core';

// tslint:disable-next-line: no-submodule-imports
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';

@Directive({
  selector: 'ui-expansion-list',
  exportAs: 'uiExpansionList',
})
export class UiExpansionListDirective extends CdkAccordion {
  @HostBinding('attr.class') class = 'ui-expansion-list';

  @Output() closed: EventEmitter<CdkAccordionItem> = new EventEmitter<CdkAccordionItem>();
  @Output() opened: EventEmitter<CdkAccordionItem> = new EventEmitter<CdkAccordionItem>();
  @Output() destroyed: EventEmitter<CdkAccordionItem> = new EventEmitter<CdkAccordionItem>();
}

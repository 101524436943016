import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'liste-pictos',
  template: `
    <div class="picto-liste">
      <ng-container *ngFor="let picto of data; let index = index">
        <div class="picto-container">
          <div class="image" [ngStyle]="{ 'background-image': 'url(' + picto.picto_zone.url + ')' }"></div>
          <div class="text">{{ picto.picto_texte }}</div>
        </div>
        <div class="plus" *ngIf="index !== data.length - 1">+</div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./liste-pictos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListePictosComponent {
  @Input() data: any;
}

import { OverlayConfig } from '@angular/cdk/overlay';

export class ModalConfig extends OverlayConfig {
  readonly disposeOnBackdropClick?: boolean;
  readonly hasCloseCross?: boolean;
  readonly contentOverflow?: string;
  // Used to focus element when modal is destroyed
  readonly openedByElement?: HTMLElement;
  readonly callbackOnClose?: () => void;

  constructor(readonly config?: ModalConfig) {
    super(config);

    if (config) {
      this.disposeOnBackdropClick = config.disposeOnBackdropClick;
      this.hasCloseCross = config.hasCloseCross;
      this.contentOverflow = config.contentOverflow;
      this.openedByElement = config.openedByElement;
      this.callbackOnClose = config.callbackOnClose;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { SliceOfferBlockComponent } from './slice-offer-block.component';
import { OfferBlockComponent } from './offer-block/offer-block.component';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';

@NgModule({
  imports: [CommonModule, InViewportModule, CommonBehaviorsModule],
  declarations: [SliceOfferBlockComponent, OfferBlockComponent],
  exports: [SliceOfferBlockComponent],
  entryComponents: [SliceOfferBlockComponent],
})
export class SliceOfferBlockModule {}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OfferVignette, ValueUnit } from '@repo/shared';

@Component({
  selector: 'cb-offer-vignette',
  template: `
    <div class="wrapper">
      <div class="name">{{ vignette.name }}</div>
      <div class="value">
        <div class="number">{{ vignette.valueNumber }}</div>
        <div class="unit">{{ valueUnit }}</div>
      </div>
      <div class="subtext" *ngIf="vignette.valueSubtext" [innerHTML]="vignette.valueSubtext"></div>
    </div>
  `,
  styleUrls: ['./offer-vignette.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferVignetteComponent {
  @Input() vignette: OfferVignette;

  get valueUnit(): string {
    if (this.vignette.valueUnit === ValueUnit.Month) {
      return this.vignette.valueNumber === '1' ? 'mois\noffert' : 'mois\nofferts';
    }
    return this.vignette.valueUnit;
  }
}

import { NgModule } from '@angular/core';

import { PageRoutingModule } from './page.routing';
import { PageResolverService } from './page-resolver.service';
import { NotFoundService } from './not-found.service';
import { ProfessionalsConnectionResolverService } from './professionals-connection/professionals-connection-resolver.service';
import { AgencyDetailsResolverService } from './agency-details/agency-details-resolver.service';

@NgModule({
  imports: [PageRoutingModule],
  providers: [
    PageResolverService,
    ProfessionalsConnectionResolverService,
    AgencyDetailsResolverService,
    NotFoundService,
  ],
})
export class PageModule {}

import { Component, Input } from '@angular/core';
import { ContactUsBlock, ContactUsTab } from '@repo/shared';

@Component({
  selector: 'contact-us-tab',
  template: `
    <ng-template #blocksRef let-blocks="blocks">
      <div class="block-content" *ngFor="let block of blocks">
        <div [innerHTML]="block.title"></div>
        <ng-container *ngFor="let element of block.elements">
          <div class="block-wrapper">
            <img [attr.src]="element.image.url" [attr.alt]="element.image.alt" />
            <div
              analyticsOnNestedLink
              [analyticsSubPath]="title"
              [analyticsFullFormatPath]="analyticsFullFormatPath"
              [innerHTML]="element.text"
            ></div>
            <p *ngIf="element.quote" class="quote">{{ element.quote }}</p>
          </div>
          <div class="item-container" *ngFor="let item of element.items">
            <div *ngIf="item.text" [innerHTML]="item.text"></div>
            <cb-phone
              analyticsOnNestedLink
              [analyticsSubPath]="title"
              [analyticsFullFormatPath]="analyticsFullFormatPath"
              [image]="item.image"
              [phone]="item.phone"
            ></cb-phone>
          </div>
        </ng-container>
      </div>
    </ng-template>

    <div class="left-col">
      <ng-container *ngTemplateOutlet="blocksRef; context: leftBlocks"></ng-container>
    </div>

    <div class="right-col">
      <ng-container *ngTemplateOutlet="blocksRef; context: rightBlocks"></ng-container>
    </div>
  `,
  styleUrls: ['./contact-us-tab.component.scss'],
})
export class ContactUsTabComponent {
  @Input() tab: ContactUsTab;

  analyticsFullFormatPath = true;

  get title(): string {
    return this.tab.title;
  }

  get leftBlocks(): { blocks: ContactUsBlock[] } {
    return { blocks: this.tab.leftBlocks || [] };
  }

  get rightBlocks(): { blocks: ContactUsBlock[] } {
    return { blocks: this.tab.rightBlocks || [] };
  }
}

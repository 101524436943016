import { HttpErrorResponse } from '@angular/common/http';
import {
  PRO_CONTACT_FORM,
  FUNCTION_OPTIONS,
  PRO_CONTACT_SUBJECT_CATEGORIES,
  REVENUES_OPTIONS,
  WORKERS_NUMBER_OPTIONS,
} from '@repo/shared/form-schemas/pro-contact';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggerService } from '../../../../logger/logger.service';
import { Router } from '@angular/router';
import { ConfigService } from '../../../../config.service';
import { SliceFormsService } from '../slice-forms.service';
import { DOMService } from '../../../../services/dom.service';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../tokens';
import { Logger } from '../../../../logger/logger';
import { ProContactFormSlice, Link } from '@repo/shared/index';
import {
  Component,
  OnInit,
  Input,
  Inject,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  forwardRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { AbstractFormComponent } from '../abstract-form-component';
import { SliceWithData } from '../../../../../typings';
import { Subscription } from 'rxjs';
import { RECAPTCHA_LANGUAGE, ReCaptchaV3Service } from 'ng-recaptcha';
import { StateService } from '../../../../services/state.service';

export const CONFIRMATION_PAGE_URL = 'confirmation-contact-pro';

@Component({
  selector: 'slice-professional-contact-form',
  template: `
    <cb-mini-title
      class="mini-title"
      *ngIf="data.title"
      [title]="data.title"
      [withFrame]="!isFirstSlice"
    ></cb-mini-title>
    <form class="form" [formGroup]="form" (ngSubmit)="checkRecaptcha()" novalidate>
      <h2>Identité</h2>
      <div formFieldControl>
        <label>Civilité*</label>
        <div formFieldRadioGroup class="form-field-control--half-size">
          <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
            <span class="ic ic-woman civil-icon"></span>
            Madame
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
          <form-field-radio name="civilTitle" formControlName="civilTitle" value="M" class="form-field-radio--right">
            <span class="ic ic-man civil-icon"></span>
            Monsieur
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
        </div>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          Nom*
          <input formControlName="lastName" type="text" placeholder="Dupont" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label
          >Prénom*
          <input placeholder="Antoine" type="text" formControlName="firstName" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          E-mail*
          <input formControlName="email" type="email" formFieldInput placeholder="exemple@mail.com" />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label>
          Numéro de téléphone*
          <input
            formControlName="phone"
            type="text"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00 00 00 00 00"
            placeholder="01 23 45 67 89"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--large-size">
        <label>
          Fonction*
          <ng-select formControlName="function" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of functionOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
        <form-field-error></form-field-error>
      </div>

      <h2>Société</h2>
      <div formFieldControl>
        <label>Votre entreprise est-elle créée ?*</label>
        <div formFieldRadioGroup class="form-field-control--half-size">
          <form-field-radio class="form-field-radio--small" name="hasFirm" formControlName="hasFirm" value="Oui">
            Oui
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
          <form-field-radio
            name="hasFirm"
            formControlName="hasFirm"
            value="Non"
            class="form-field-radio--right form-field-radio--small"
          >
            Non
            <div class="check-icon">
              <span class="ic-check"></span>
            </div>
          </form-field-radio>
        </div>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--large-size" *ngIf="form.get('hasFirm')?.value === 'Oui'">
        <label>
          Date de création*
          <datepicker [formControlName]="'date'"></datepicker>
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          Raison sociale*
          <input formControlName="socialReason" type="text" placeholder="MaSociété SAS" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label
          >SIREN*
          <input
            placeholder="123 456 789"
            type="text"
            formControlName="siren"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="000 000 000"
            [(ngModel)]="inputSiren"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size">
        <label>
          Code postal*
          <input formControlName="postal" type="text" placeholder="94800" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control--half-size form-field-control--right">
        <label
          >Code NAF*
          <input
            placeholder="0000Z"
            type="text"
            formControlName="naf"
            formFieldInput
            (ngModelChange)="inputNafUppercase = $event.toUpperCase()"
            [ngModel]="inputNafUppercase"
          />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl class="form-field-control">
        <label>
          Activité*
          <input formControlName="activity" type="text" placeholder="Commerce de détail" formFieldInput />
        </label>
        <form-field-error></form-field-error>
      </div>
      <div class="form-field-control--half-size form-field-control">
        <label>
          Chiffre d'affaires*
          <ng-select formControlName="revenues" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of revenuesOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
      </div>
      <div class="form-field-control--half-size form-field-control form-field-control--right">
        <label>
          Nombre de salariés
          <ng-select formControlName="numberWorkers" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of workersNumberOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
      </div>

      <h2>Message</h2>
      <div class="form-field-control subject-form-field-control">
        <label>
          Motif de la demande : *
          <ng-select
            formControlName="subject"
            [items]="subjectCategories"
            bindValue="key"
            bindLabel="label"
            [clearable]="false"
            [searchable]="false"
          >
          </ng-select>
        </label>
      </div>
      <div formFieldControl>
        <textarea
          formControlName="message"
          placeholder="Ecrivez votre message ici..."
          #messageField="formFieldInput"
          formFieldInput
        ></textarea>
        <div formFieldHint>
          {{ messageField.charCountLabel }}
        </div>
        <form-field-error></form-field-error>
      </div>

      <p class="mandatory-field-text">*Champs obligatoires</p>

      <p>
        La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
        disponible sur la page d'accueil du site.
      </p>

      <div class="button-wrapper">
        <cb-cta-btn-link
          class="button"
          [button]="button"
          [buttonWithoutLink]="true"
          [sliceType]="data.type"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
        <p class="recaptcha-mentions">
          Ce site est protégé par reCAPTCHA. Les
          <a href="https://policies.google.com/privacy">règles de confidentialité</a>
          et les
          <a href="https://policies.google.com/terms">conditions d'utilisation</a>
          de Google s'appliquent.
        </p>
      </div>
    </form>
  `,
  styleUrls: ['../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: PRO_CONTACT_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceProfessionalContactFormComponent),
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr',
    },
  ],
})
export class SliceProfessionalContactFormComponent
  extends AbstractFormComponent
  implements SliceWithData<ProContactFormSlice>, OnInit, OnDestroy, AfterViewInit {
  @Input() data: ProContactFormSlice;

  _hasFirmSubscription: Subscription;
  inputNafUppercase = '';
  inputSiren = '';
  subjectCategories = PRO_CONTACT_SUBJECT_CATEGORIES;
  functionOptions = FUNCTION_OPTIONS;
  revenuesOptions = REVENUES_OPTIONS;
  workersNumberOptions = WORKERS_NUMBER_OPTIONS;

  logger: Logger;

  form: FormGroup;

  get button(): Link {
    return { label: 'Envoyer le formulaire' };
  }

  _buttonDisabled = false;
  _hasPostError = false;

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _proContactFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly configService: ConfigService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly loggerService: LoggerService,
    private readonly stateService: StateService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_proContactFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceProfessionalContactFormComponent.name);
  }

  ngOnInit(): void {
    this.form = this.sliceFormsService.buildForm(this.getFormValidationSchema());
    this.prefillSubjectCategory();
    this._hasFirmSubscription = this.inithasFirmSubscription();
  }

  ngAfterViewInit(): void {
    this.form.addControl('recaptcha', new FormControl('', Validators.required));
  }

  ngOnDestroy(): void {
    this._hasFirmSubscription.unsubscribe();
  }

  inithasFirmSubscription(): Subscription {
    return this.form.get('hasFirm')?.valueChanges.subscribe((value: 'Oui' | 'Non') => {
      const sirenControl = this.form.get('siren');
      const nafControl = this.form.get('naf');
      if (value === 'Non') {
        sirenControl?.disable();
        nafControl?.disable();
        this.inputNafUppercase = '';
        this.inputSiren = '';
      } else {
        sirenControl?.enable();
        nafControl?.enable();
      }
    }) as Subscription;
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('professionalContactForm').subscribe(
      token => {
        this.form.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  prefillSubjectCategory(): void {
    const subjectMonemMobile = this.subjectCategories.find(subject => subject.key === 'monem')?.key;
    const subjectFranchise = this.subjectCategories.find(subject => subject.key === 'franchise')?.key;
    const subjectLiberalProfession = this.subjectCategories.find(subject => subject.key === 'liberalProfession')?.key;
    const subject = this.hasPrevUidMonemMobile()
      ? subjectMonemMobile
      : this.hasPageUidFranchise()
      ? subjectFranchise
      : this.hasPageUidLiberaleProfession()
      ? subjectLiberalProfession
      : this.subjectCategories.find(subject => subject.key === 'account')?.key;

    this.form.patchValue({
      subject: subject,
      function: 0,
      revenues: 0,
      numberWorkers: 2,
    });
  }

  hasPrevUidMonemMobile(): boolean {
    return this.stateService.get().prevUid ? this.stateService.get().prevUid === 'monem-mobile' : false;
  }

  hasPageUidFranchise(): boolean {
    return this.stateService.get().pageUid ? this.stateService.get().pageUid === 'formulaire-franchise' : false;
  }

  hasPageUidLiberaleProfession(): boolean {
    return this.stateService.get().pageUid
      ? this.stateService.get().pageUid === 'formulaire-professions-liberales'
      : false;
  }

  submitForm(): void {
    const { valid, value } = this.form;
    if (valid) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendProfessionalContactForm(value).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._buttonDisabled = false;
          this._hasPostError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
    } else {
      this.triggerFormFieldsStatus(this.form);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

<form class="form" [formGroup]="regForm" (ngSubmit)="onSubmit()" novalidate>
  <div id="identity" *ngIf="formSlice">
    <h2>Mon contrat</h2>
    <label>Ma demande concerne...</label>
    <div id="productType" formFieldRadioGroup class="form-field-control">
      <ng-container *ngFor="let radio of formSlice.product_types">
        <form-field-radio
          displayMode="asideLabel"
          name="productTypes"
          formControlName="productType"
          class="productTypes"
          [value]="radio.type_name"
        >
          {{ radio.type_name }}
        </form-field-radio>
        <form-field-error></form-field-error>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!hasLink">
    <div id="productSelection" formFieldControl class="form-field-control" *ngIf="productList">
      <label>
        Plus précisément...
        <form-field-select
          formFieldSelect
          [options]="productList"
          [sendValue]="'string'"
          formControlName="productSelected"
          [selectError]="selectError"
        ></form-field-select>
      </label>
      <form-field-error></form-field-error>
    </div>

    <div id="owner" *ngIf="regForm.get('productSelected').dirty">
      <h2>Je confirme que...</h2>
      <div formFieldRadioGroup class="form-field-control">
        <form-field-radio displayMode="asideLabel" name="owner" formControlName="isOwner" [value]="true">
          Je suis le souscripteur du contrat
        </form-field-radio>
        <form-field-radio displayMode="asideLabel" name="owner" formControlName="isOwner" [value]="false">
          Je ne suis pas le souscripteur du contrat
        </form-field-radio>
        <form-field-error></form-field-error>
      </div>
    </div>

    <div id="informations" *ngIf="informations!">
      <h2>Pour information</h2>
      <p class="informations" [innerHtml]="informations"></p>
    </div>
  </ng-container>

  <div class="button-wrapper">
    <ng-container *ngIf="!checkEstTitulaire()">
      <cb-cta-btn-link
        class="button"
        [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
        [buttonWithoutLink]="true"
        [sliceType]="'resiliation1'"
        [disabled]="_buttonDisabled"
        [analyticsLabel]="tagName"
        [analyticsSubPath]="tagSubPath"
        analyticsOn
      >
      </cb-cta-btn-link>
    </ng-container>
    <ng-container *ngIf="checkEstTitulaire()">
      <cb-cta-btn-link
        class="button"
        [button]="{ label: 'Terminer', type: 'multi-step-form-next', url: '/' }"
        [buttonWithoutLink]="false"
        [sliceType]="'resiliation1'"
        [disabled]="_buttonDisabled"
      >
      </cb-cta-btn-link>
    </ng-container>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
  </div>
</form>

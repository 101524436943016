import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OfferBlock, ValueUnit } from '@repo/shared';

@Component({
  selector: 'sob-offer-block',
  template: `
    <div class="wrapper">
      <div class="first-pane">
        <div class="name">{{ offerBlock.name }}</div>
        <h3>{{ offerBlock.longTitle }}</h3>
        <div class="conditions inner-html" *ngIf="offerBlock.conditions" [innerHTML]="offerBlock.conditions"></div>
      </div>
      <div class="second-pane">
        <div class="date-pre-text">{{ offerBlock.datePreText }}</div>
        <div class="date-text-wrapper" *ngIf="offerBlock.dateText">
          <div class="date-text">{{ offerBlock.dateText }}</div>
        </div>
        <div class="value" [class.one-char]="offerBlock.valueNumber.length === 1" [class.unit-month]="isUnitMonth()">
          <span class="number">{{ offerBlock.valueNumber }}</span>
          <div class="unit">{{ valueUnit }}</div>
        </div>
        <div
          class="value-subtext inner-html"
          *ngIf="offerBlock.valueSubtext"
          [innerHTML]="offerBlock.valueSubtext"
        ></div>
      </div>
    </div>
  `,
  styleUrls: ['./offer-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBlockComponent {
  @Input() offerBlock: OfferBlock;

  get valueUnit(): string {
    if (this.isUnitMonth()) {
      if (this.offerBlock.valueNumber === '1') {
        return 'mois\noffert';
      }
      return 'mois\nofferts';
    }
    return this.offerBlock.valueUnit;
  }

  isUnitMonth(): boolean {
    return this.offerBlock.valueUnit === ValueUnit.Month;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { SummarySlice } from '@repo/shared';

@Component({
  selector: 'slice-summary',
  template: `
    <div class="wrapper">
      <h3 *ngIf="data.title" class="title">{{ data.title }}</h3>
      <div class="summary" [innerHTML]="data.richText"></div>
    </div>
  `,
  styleUrls: ['./slice-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceSummaryComponent implements SliceWithData<SummarySlice> {
  @Input() data: SummarySlice = {} as SummarySlice;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Link } from '@repo/shared';
import { AbstractAnalyticsCTADirective } from '../../../analytics/abstract-analytics-cta-component';

export enum CTALinkType {
  Secondary = 'secondary',
  ProductPage = 'product-page',
  LandingUniverse = 'landing-universe',
}

@Component({
  selector: 'cb-cta-link',
  template: `
    <a
      class="cta-link"
      [ngClass]="className"
      [attr.href]="link?.url"
      [attr.aria-label]="'Menu ' + link?.label"
      [attr.target]="target"
      analyticsOn
      [analyticsLabel]="analyticsLabel"
      [analyticsSubPath]="analyticsSubPath"
    >
      <div class="header">
        <span *ngIf="isPrismicDocument()" class="ic-pdf"></span>
        <div class="label-wrapper">
          <span class="label">{{ link?.label }}</span>
        </div>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </a>
  `,
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CTALinkComponent extends AbstractAnalyticsCTADirective {
  static CLASSNAME_PREFIX = 'cta-link--';

  @Input() link: Link;
  @Input() type: CTALinkType = CTALinkType.Secondary;

  get CTA(): Link {
    return this.link;
  }

  get className(): string {
    return CTALinkComponent.computeClassName(this.type);
  }

  static computeClassName(type?: CTALinkType): string {
    switch (type) {
      case CTALinkType.Secondary:
      case CTALinkType.ProductPage:
      case CTALinkType.LandingUniverse:
        return CTALinkComponent.CLASSNAME_PREFIX + type;
      default:
        return CTALinkComponent.CLASSNAME_PREFIX + CTALinkType.Secondary;
    }
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 * `<ui-faq-page>`
 *
 * This component can be used to display a FAQ page template.
 *
 * See faq.md for usage details.
 */
@Component({
  selector: 'ui-faq-page',
  exportAs: 'uiFaqPage',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <header [ngClass]="headerClasses" [ngStyle]="headerStyles">
      <div class="ui-faq-header-content content">
        <ng-content select="[uiFaqTitle], [ui-faq-title], ui-faq-title"> </ng-content>
        <ng-content select="[uiFaqDescription], [ui-faq-description], ui-faq-description"> </ng-content>
      </div>
    </header>
    <div class="ui-faq-content content">
      <ng-content></ng-content>
    </div>
  `,
})
export class UiFaqPageComponent {
  @HostBinding('class.ui-faq-page') hostClass = true;

  get headerDark(): boolean {
    return this._headerDark;
  }

  @Input()
  set headerDark(value: boolean) {
    this._headerDark = coerceBooleanProperty(value);
  }

  get headerBackground(): string {
    return this._headerBackground;
  }

  @Input()
  set headerBackground(value: string) {
    this._headerBackground = value && `url(${value})`;
  }

  get headerClasses(): { 'ui-faq-header': boolean; 'ui-header-dark': boolean; 'ui-header-background': string } {
    return {
      'ui-faq-header': true,
      'ui-header-background': this.headerBackground,
      'ui-header-dark': this.headerDark,
    };
  }

  get headerStyles(): { 'background-image': string } {
    return {
      'background-image': this.headerBackground,
    };
  }

  protected _headerDark: boolean;
  protected _headerBackground: string;
}

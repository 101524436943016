import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrettyBooleanValuePipe } from './prettyBooleanValue/prettyBooleanValue';
import { TrustHtmlPipe } from './trustHtml/trust-html.pipe';
import { TrustResourceUrlPipe } from './trustResourceUrl/trust-resource-url.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TrustHtmlPipe, TrustResourceUrlPipe, PrettyBooleanValuePipe],
  exports: [TrustHtmlPipe, TrustResourceUrlPipe, PrettyBooleanValuePipe],
})
export class PipesModule {}

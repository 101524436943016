import { Location } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Subscription, Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HttpResponseService } from '../http-response.service';
import { WINDOW } from './window.provider';
import { observeReadyStateChanges } from '../helpers/dom.helpers';
import { HttpStatusCode } from '../common/constants/http-status-code';
import { DOMService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService implements OnDestroy {
  readonly queryParams: Observable<Params>;

  private _subscriptions = new Subscription();

  get historyState(): any {
    return this.win ? this.win.history.state : {};
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly httpResponseService: HttpResponseService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly domService: DOMService,
  ) {
    this.queryParams = this._createQueryParamsObservable();

    if (this.win) {
      this._subscriptions.add(
        combineLatest([this.activatedRoute.fragment, observeReadyStateChanges(win)])
          .pipe(filter(([hash, readyState]) => !!hash && readyState === 'complete'))
          .subscribe(([hash]) => this.scrollById(hash as string)),
      );
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  getActivatedRoute(): ActivatedRoute {
    return this.activatedRoute;
  }

  getHash(): string | undefined {
    return this.location.path(true).split('#')[1];
  }

  async navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  async redirect(url: string, status: number = HttpStatusCode.Found): Promise<boolean | undefined> {
    if (this.win) {
      return this.router.navigateByUrl(url);
    } else {
      this.httpResponseService.redirect(status, url);
    }
  }

  replaceHash(hash: string): void {
    let hashParsed = hash || '';

    if (hashParsed.length && hashParsed[0] !== '#') {
      hashParsed = `#${hashParsed}`;
    }

    const url = this.location.path(false) + hashParsed;

    this.location.replaceState(url, '', this.historyState);
  }

  replaceQueryParam(key: string, value: any, pushState?: boolean): void {
    this.replaceQueryParams({ [key]: value }, pushState);
  }

  replaceQueryParams(queryParams: Params, pushState?: boolean): void {
    const url = this.router
      .createUrlTree([], {
        queryParams,
        queryParamsHandling: 'merge',
      })
      .toString();

    if (pushState) {
      this.location.go(url, '', this.historyState);
    } else {
      this.location.replaceState(url, '', this.historyState);
    }
  }

  scrollById(id: string): void {
    this.replaceHash(id);

    const element = this.win && this.win.document.getElementById(id);

    if (element) {
      this.domService.scrollTo(element);
    }
  }

  _createQueryParamsObservable(): Observable<Params> {
    const queryParams$ = new BehaviorSubject<Params>(this._parseQueryParams(this.location.path()));

    this._subscriptions.add(
      this.location.subscribe(popStateEvent => {
        queryParams$.next(this._parseQueryParams(popStateEvent.url as string));
      }),
    );

    return queryParams$;
  }

  _parseQueryParams(url: string): Params {
    return this.router.parseUrl(url).queryParams;
  }

  isIframePage(): boolean {
    return this.activatedRoute.snapshot.queryParams.iframe !== undefined;
  }

  isXtatcPage(): boolean {
    return this.activatedRoute.snapshot.queryParams.xtatc !== undefined;
  }

  // test created to be used in _handlePageSeo method,
  // a method called before detection of interstitial in ngAfterViewInit méthod
  isInterstitialPage(): boolean {
    return this.activatedRoute.snapshot.queryParams.popin !== undefined;
  }
}

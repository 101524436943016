import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { LandingPageKeyInfoSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';

@Component({
  selector: 'slice-landing-page-key-info',
  template: ` <div class="content" [innerHTML]="data.content"></div> `,
  styleUrls: ['./slice-landing-page-key-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingPageKeyInfoComponent implements ComponentWithData {
  @Input() data: LandingPageKeyInfoSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;
}

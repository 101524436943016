import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex } from './form';

export const CLIENT_TYPES_OPTIONS = ['Particulier', 'Professionel', 'Banque privée'];

export const BROWSER_TYPES_OPTIONS = ['Mozilla Firefox', 'Chrome', 'Internet Explorer', 'Safari', 'Microsoft Edge'];

export const DEVICE_TYPES_OPTIONS = ['Ordinateur', 'Téléphone mobile', 'Tablette'];

export const OS_TYPES_OPTIONS = [
  { label: 'Windows', disabled: false },
  { label: 'Mac OS', disabled: false },
  { label: 'Android', disabled: true },
  { label: 'IOS', disabled: true },
];
export const ASSISTANCE_CONTACT_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    clientType: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_TYPES_OPTIONS.length - 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
      minLength: 1,
    },
    accountNumber: {
      type: 'string',
      pattern: '^$|^[0-9]{6}[a-zA-Z]$',
      minLength: 1,
      maxLength: 7,
    },
  },
  required: ['civilTitle', 'firstName', 'lastName', 'email', 'phone', 'clientType', 'agencyID', 'accountNumber'],
};

export const ASSISTANCE_EQUIPEMENTS_FORM: JSONSchema7 = {
  properties: {
    deviceType: {
      type: 'integer',
      minimum: 0,
      maximum: DEVICE_TYPES_OPTIONS.length - 1,
    },
    osType: {
      type: 'integer',
      minimum: 0,
      maximum: OS_TYPES_OPTIONS.length - 1,
    },
    browserType: {
      type: 'integer',
      minimum: 0,
      maximum: BROWSER_TYPES_OPTIONS.length - 1,
    },
    browserVersion: {
      type: 'string',
      pattern: '^[a-zA-Z0-9\\-`.,_/"]*$',
    },
    accessProvider: {
      type: 'string',
      pattern: '^[a-zA-Z\\-`.,_/"]*$',
    },
  },
  required: ['deviceType', 'osType', 'browserType'],
};

export const ASSISTANCE_REQUEST_FORM: JSONSchema7 = {
  properties: {
    time: {
      type: 'string',
      pattern: '^$|([01]?[0-9]|2[0-3]):([0-5][0-9])$',
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1200,
    },
    date: {
      type: 'string',
      minLength: 1,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['message', 'recaptcha'],
};

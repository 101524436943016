import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { PushParcoursSlice } from '@repo/shared';

@Component({
  selector: 'slice-push-parcours',
  template: `
    <section class="wrapper" [style.background-image]="'url(' + data.image?.url + ')'">
      <cb-fullwidth-background [image]="data.image" class="bg"></cb-fullwidth-background>
      <div class="content">
        <h3 class="title text-uppercase">{{ data.title }}</h3>
        <h2 class="subtitle">{{ data.subtitle }}</h2>
        <div *ngIf="data.richText" class="description" [innerHTML]="data.richText | trustHtml"></div>
        <cb-cta-btn-link
          *ngIf="data.button"
          class="button"
          [button]="data.button"
          [sliceType]="data.type"
        ></cb-cta-btn-link>
      </div>
    </section>
  `,
  styleUrls: ['./slice-push-parcours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlicePushParcoursComponent implements SliceWithData<PushParcoursSlice> {
  @Input() data: PushParcoursSlice = {} as PushParcoursSlice;
}

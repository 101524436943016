import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiFaqSection], [ui-faq-section], ui-faq-section',
  exportAs: 'uiFaqSection',
})
export class UiFaqSectionDirective {
  @HostBinding('class.ui-faq-section') hostClass = true;
  @HostBinding('attr.role') hostRole = 'section';
}

import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ImageWithMobile } from '@repo/shared/index';
import { ResponsiveService } from '../../../services/responsive.service';
import { WINDOW } from '../../../services/window.provider';

@Component({
  selector: 'cb-fullwidth-background',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./fullwidth-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullwidthBackgroundComponent implements OnInit, OnDestroy {
  @Input() image: ImageWithMobile;

  @HostBinding('style.background-image')
  get backgroundImageStyle(): string | null {
    const imageUrl = this.getImageUrl();

    return imageUrl ? `url(${imageUrl})` : null;
  }

  private isDesktop = false;
  private responsiveServiceSubscription: Subscription | null = null;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    if (this.win) {
      this.responsiveServiceSubscription = this.responsiveService.subscribeOnDesktop(isDesktop => {
        this.isDesktop = isDesktop;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.responsiveServiceSubscription) {
      this.responsiveServiceSubscription.unsubscribe();
      this.responsiveServiceSubscription = null;
    }
  }

  getImageUrl(): string | null {
    if (!this.win || !this.image) {
      return null;
    }

    if (this.isDesktop) {
      return this.image.url || null;
    }

    return (this.image.mobile && this.image.mobile.url) || null;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { LinksProductsBlockSlice } from '@repo/shared';

@Component({
  selector: 'slice-links-products-block',
  template: `
    <div class="content">
      <cb-mini-title *ngIf="data.title" class="title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <div class="links-products">
        <div class="link-product-block" *ngFor="let productLink of data.linkProductBlockItems">
          <cb-cta-link class="link-product" [link]="productLink" type="product-page">
            <div class="link-product-description" [innerHTML]="productLink.description"></div>
          </cb-cta-link>
        </div>
      </div>
      <cb-cta-link *ngIf="data.link" class="link" [sliceType]="data.type" [link]="data.link"></cb-cta-link>
      <cb-cta-btn-link
        *ngIf="data.button"
        class="button"
        [sliceType]="data.type"
        [button]="data.button"
      ></cb-cta-btn-link>
    </div>
  `,
  styleUrls: ['./slice-links-products-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLinksProductsBlockComponent implements SliceWithData<LinksProductsBlockSlice> {
  @Input() data: LinksProductsBlockSlice;
}

import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '../logger/logger.service';
import { Logger } from '../logger/logger';
import { HttpResponseService } from '../http-response.service';
import { HttpStatusCode } from '../common/constants/http-status-code';
import { TECHNICAL_ERROR_ROUTE } from '../common/constants/routes';

@Injectable()
export class ObservableErrorHandler implements ErrorHandler {
  private logger: Logger;

  constructor(
    private readonly loggerService: LoggerService,
    private readonly httpResponseService: HttpResponseService,
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
  ) {
    this.logger = this.loggerService.get(ObservableErrorHandler.name);
  }

  handleError(error: any): void {
    this.ngZone.run(() => {
      this.logger.error(error);
      this.httpResponseService.setStatus(HttpStatusCode.InternalServerError);
      this.injector.get(Router).navigateByUrl(TECHNICAL_ERROR_ROUTE, { replaceUrl: false, skipLocationChange: true });
    });
  }
}

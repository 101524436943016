import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { OfferBlockSlice, OfferPhone } from '@repo/shared';
import { TagCommanderService } from '../../../services/tag-commander.service';

@Component({
  selector: 'slice-offer-block',
  template: `
    <sob-offer-block
      [offerBlock]="data.offerBlock"
      inViewport
      [inViewportOptions]="{ threshold: 1, partial: false }"
      (inViewportAction)="onOfferBlockIntersection($event)"
    ></sob-offer-block>
    <cb-cta-btn-link class="cta-button" *ngIf="data.button" [button]="data.button"></cb-cta-btn-link>
    <div class="phone" *ngIf="offerPhone.phone" [innerHTML]="offerPhone.phone"></div>
    <div class="phone-subtext" *ngIf="offerPhone.subtext">{{ offerPhone.subtext }}</div>
  `,
  styleUrls: ['./slice-offer-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceOfferBlockComponent {
  constructor(private readonly tagCommanderService: TagCommanderService) {}

  @Input()
  data: OfferBlockSlice;

  private trackingHasTriggered: boolean;

  @HostBinding('attr.id')
  public get id(): string | null {
    return this.data.anchor;
  }

  get offerPhone(): OfferPhone {
    return this.data.offerPhone;
  }

  onOfferBlockIntersection({ visible }: { visible: boolean }): void {
    if (!this.trackingHasTriggered && visible && this.data.offerBlock.campaignCode) {
      this.trackingHasTriggered = true;
      this.tagCommanderService.trackEventPrint(this.data.offerBlock.campaignCode);
    }
  }
}

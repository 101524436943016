import { Component, Input } from '@angular/core';

import { ContactsBlockIem, ContactsBlockSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

export const SLICE_CONTACTS_PRESSE_BACKGROUND_URL_DEFAULT = '/assets/images/bg_contacts_presse.jpg';

@Component({
  selector: 'slice-contacts-presse',
  template: `
    <ui-contacts-block *ngIf="hasContactItems()" [background]="backgroundImage" class="content">
      <ui-contacts-block-title>{{ data.title }}</ui-contacts-block-title>
      <ui-contacts-item *ngFor="let contact of contacts">
        <ui-contacts-item-title>{{ contact.name }}</ui-contacts-item-title>
        <ul>
          <li *ngIf="contact.phone">
            <span><span class="ic-phone"></span></span>
            <a [appHrefPhone]="contact.phone">{{ contact.phone }}</a>
          </li>
          <li *ngIf="contact.email">
            <span><span class="ic-mail"></span></span>
            <a [href]="getEmailHref(contact.email)">{{ contact.email }}</a>
          </li>
        </ul>
      </ui-contacts-item>
    </ui-contacts-block>
  `,
  styleUrls: ['./slice-contacts-presse.component.scss'],
})
export class SliceContactsPresseComponent implements SliceWithData<ContactsBlockSlice> {
  @Input() data: ContactsBlockSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  get backgroundImage(): string {
    return (this.data && this.data.image && this.data.image.url) || SLICE_CONTACTS_PRESSE_BACKGROUND_URL_DEFAULT;
  }

  get contacts(): ContactsBlockIem[] {
    return this.data?.contacts?.filter(this.isContactValid).slice(0, 3) || [];
  }

  hasContactItems(): boolean {
    return this.contacts ? this.contacts.length > 0 : false;
  }

  isContactValid(contact: ContactsBlockIem): boolean {
    return !!contact && !!(contact.email || contact.phone);
  }

  getEmailHref(emailAddress: string): string {
    return 'mailto:' + emailAddress;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { LifeMomentSlice } from '@repo/shared';

type TitleTypography = {
  [key: string]: string | number | undefined;
};

@Component({
  selector: 'slice-life-moment',
  template: `
    <ng-template #content>
      <div class="content content--oversized">
        <h1 [style.color]="data.color_title" class="title">{{ data.title_bloc.text }}</h1>
        <div class="cta-buttons">
          <ng-container *ngFor="let link of data.blocLinks">
            <cb-cta-btn-link
              class="cta-button"
              [button]="{ label: link.label, url: link.link.url, type: 'life-moment' }"
              [labelStyles]="labelStyles"
              [buttonBgColor]="buttonBgColor"
            ></cb-cta-btn-link>
          </ng-container>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="data">
      <cb-fullwidth-background [class]="fullwidthBackgroundClass" [image]="data.image">
        <ng-container *ngIf="isDesktopMode">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-container>
      </cb-fullwidth-background>
      <ng-container *ngIf="!isDesktopMode">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./slice-life-moment.component.scss'],
})
export class SliceLifeMomentComponent implements OnInit {
  @Input() data: LifeMomentSlice;
  isDesktopMode: boolean;
  labelStyles: TitleTypography = {};
  buttonBgColor = '';

  get fullwidthBackgroundClass(): string {
    return this.isDesktopMode ? 'wrapper' : 'background';
  }

  ngOnInit(): void {
    this.initLabelStyles();
  }

  initLabelStyles(): void {
    if (this.data) {
      this.labelStyles['color'] = this.data.color_label;
      this.labelStyles['btnColor'] = this.data.color_icon;
      this.buttonBgColor = this.data.color_button ? this.data.color_button : '#FFFFFF';
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { SliceWithData } from '../../../../typings';
import { OfferCarouselItem, OffersCarouselSlice } from '@repo/shared';
import { WINDOW } from '../../../services/window.provider';
import { newSwipe, Swipe } from '../../../vendors/swipe';
import { TagCommanderService } from '../../../services/tag-commander.service';

const SLIDE_TRANSITION_DURATION = 400;

@Component({
  selector: 'slice-offers-carousel',
  template: `
    <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
    <div
      class="swiper-wrapper"
      *ngIf="offers"
      inViewport
      [inViewportOptions]="{ threshold: 1, partial: false }"
      (inViewportAction)="onIntersection($event)"
    >
      <div class="swiper-button-next desktop-only" (click)="onNext()"></div>
      <div class="swiper-button-prev desktop-only" (click)="onPrev()"></div>
      <div #swiper class="swipe">
        <div
          class="swipe-wrap"
          (mousedown)="onMouseDown($event)"
          (mouseup)="onMouseUp($event)"
          (click)="onClick($event)"
        >
          <div class="offer" *ngFor="let offer of offers">
            <a class="link-with-image" [attr.href]="offer.link.url" [attr.target]="offer.link.target">
              <div class="frame">
                <span class="subtitle" [innerHTML]="offer.title"></span>
                <cb-responsive-img class="picture" [mobileImage]="offer.image?.mobile" [desktopImage]="offer.image">
                </cb-responsive-img>
                <cb-offer-vignette *ngIf="offer.vignette" [vignette]="offer.vignette"></cb-offer-vignette>
              </div>
            </a>
          </div>
        </div>
        <div class="indicators">
          <span
            class="dot"
            *ngFor="let offer of offers; let i = index"
            [class.active]="i === currentIndex"
            (click)="goToSlide(i)"
          >
            ·
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./slice-offers-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceOffersCarouselComponent implements SliceWithData, AfterViewInit, OnDestroy {
  @Input() data: OffersCarouselSlice;
  @Input() isFirstSlice: boolean;

  @ViewChild('swiper') swiperRef: ElementRef<HTMLDivElement>;

  _isValidClick: boolean;
  currentIndex = 0;

  get offers(): OfferCarouselItem[] {
    return this.data.offerItems;
  }

  private swiper: Swipe;
  private mouseDownPosition: string;
  private tagTriggeredList: number[] = [];

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly tagCommanderService: TagCommanderService,
  ) {
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
  }

  ngAfterViewInit(): void {
    if (this.win) {
      this.swiper = newSwipe(this.swiperRef.nativeElement, {
        startSlide: 0,
        speed: SLIDE_TRANSITION_DURATION,
        draggable: true,
        continuous: true,
        disableScroll: false,
        auto: 4000,
        autoRestart: true,
        stopPropagation: true,
        transitionEnd: this.onTransitionEnd,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.swiper) {
      this.swiper.kill();
    }
  }

  onMouseDown(e: MouseEvent): void {
    this.mouseDownPosition = `X${e.clientX}Y${e.clientY}`;
  }

  onMouseUp(e: MouseEvent): void {
    const mouseUpPosition = `X${e.clientX}Y${e.clientY}`;
    this._isValidClick = this.mouseDownPosition === mouseUpPosition;
  }

  onClick(e: UIEvent): void {
    if (!this._isValidClick) {
      e.preventDefault();
    }
  }

  onNext(): void {
    this.swiper.next();
  }

  onPrev(): void {
    this.swiper.prev();
  }

  goToSlide(index: number): void {
    this.swiper.slide(index, SLIDE_TRANSITION_DURATION);
  }

  onIntersection({ visible }: { visible: boolean }): void {
    if (visible) {
      this.triggerTrackEvent();
    }
  }

  triggerTrackEvent(): void {
    const campaignCode = this.offers[this.currentIndex].campaignCode as string;
    if (!this.tagTriggeredList.includes(this.currentIndex) && campaignCode) {
      this.tagCommanderService.trackEventPrint(campaignCode);
      this.tagTriggeredList.push(this.currentIndex);
    }
  }

  private onTransitionEnd(index: number): void {
    this.currentIndex = index;
    this.changeDetector.detectChanges();
    this.triggerTrackEvent();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderButtonsLinks } from '../../header';
import { AnalyticsSubPaths } from '../../../../services/tag-commander.service';
import { Image } from '@repo/shared';

const IC_MOBILE_MENU = '/assets/images/ic_mobile_menu.svg';
const LCL_LOGO_BLANC = '/assets/images/lcl_logo_blanc.svg';
const IC_CHAT = '/assets/images/ic_chat.svg';
const IC_LOCK = '/assets/images/ic_lock.svg';

@Component({
  selector: 'app-mobile-header-closed',
  template: `
    <div class="wrapper">
      <div class="menu-icon">
        <img
          (click)="triggerToggleMenu()"
          [attr.src]="icMobileMenuImage"
          alt="Menu mobile LCL banque et assurance"
          width="19"
          height="14"
        />
      </div>
      <div class="lcl-icon">
        <a [attr.href]="buttons.home && buttons.home.url" [attr.target]="buttons.home && buttons.home.target">
          <img
            *ngIf="logo && logo.dimensions; else defaultLogo"
            [attr.src]="logo.url"
            [attr.alt]="logo.alt"
            [attr.height]="logo.dimensions.height"
            [attr.width]="logo.dimensions.width"
          />
          <ng-template #defaultLogo>
            <img [attr.src]="lclLogo" alt="lcl logo" height="34" width="48" />
          </ng-template>
        </a>
      </div>
      <div class="right-icons">
        <div>
          <a
            *ngIf="buttons.chat"
            [attr.href]="buttons.chat.url"
            [attr.target]="buttons.chat.target"
            [style.background-color]="colorBtnContact"
            [style.color]="labelColorBtnContact"
            analyticsOn
            [analyticsLabel]="buttons.chat.label"
            [analyticsSubPath]="analyticsSubPath"
          >
            <img [attr.src]="iconBtnContact" width="26" height="26" alt="chat icon" />
          </a>
          <a
            *ngIf="buttons.myAccount"
            [attr.href]="buttons.myAccount.url"
            [style.background-color]="colorBtnMyAccount"
            [style.color]="labelColorBtnMyAccount"
            analyticsOn
            [analyticsLabel]="buttons.myAccount.label"
            [analyticsSubPath]="analyticsSubPath"
          >
            <img [attr.src]="iconBtnMyAccount" width="15" height="20" alt="lock icon" />
          </a>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./mobile-header-closed.component.scss'],
})
export class MobileHeaderClosedComponent {
  @Input() buttons: HeaderButtonsLinks = {};
  @Input() logo: Image;

  @Output() toggleMenu = new EventEmitter<any>();

  icMobileMenuImage: string = IC_MOBILE_MENU;
  lclLogo: string = LCL_LOGO_BLANC;
  icChat: string = IC_CHAT;
  icLock: string = IC_LOCK;
  analyticsSubPath = AnalyticsSubPaths.HeaderRightButtons;

  triggerToggleMenu(): void {
    this.toggleMenu.emit();
  }

  get colorBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.mobileBackgroundColor;
  }

  get colorBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.mobileBackgroundColor;
  }

  get labelColorBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.mobileLabelColor;
  }

  get labelColorBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.mobileLabelColor;
  }

  get iconBtnContact(): string | undefined {
    return this.buttons.chat?.buttonLinkData?.mobileIcon?.url;
  }

  get iconBtnMyAccount(): string | undefined {
    return this.buttons.myAccount?.buttonLinkData?.mobileIcon?.url;
  }
}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Inject,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { ResiliationFormSlice, FamilleProduit } from '@repo/shared';
import { RESILIATION_IDENTITY_FORM } from '@repo/shared/form-schemas/resiliation-client';
import { Router } from '@angular/router';
import { SLICE_TAGGING_NAME, SLICE_TAGGING_SUBPATH } from '../../../../../common/constants/tagging';

@Component({
  selector: 'resiliation-identity',
  templateUrl: './resiliation-identity.component.html',
  styleUrls: ['../../slice-forms.scss', './resiliation-identity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RESILIATION_IDENTITY_FORM,
    },
  ],
})
export class ResiliationIdentityComponent extends AbstractFormComponent implements OnInit, OnChanges {
  @Input() regForm: FormGroup;
  @Input() formSlice: ResiliationFormSlice;
  @Input() informations: string;
  @Input() selectedIndex: number;

  _buttonDisabled = true;
  _hasPostError = false;

  hasLink? = false;

  productType?: FamilleProduit;
  productList?: string[];

  selectError = false;

  tagSubPath = SLICE_TAGGING_SUBPATH.resiliation_form;
  tagName: string;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _resiliationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly router: Router,
  ) {
    super(_resiliationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngOnInit() {
    if (this.formSlice.product_types) {
      this.regForm.valueChanges.subscribe(values => {
        this.productType = this.formSlice.product_types?.find(
          productSelected => productSelected.type_name === values.productType,
        );
        this.productList = this.productType?.products?.map(productSelected => productSelected.product_name || '');
        this.hasLink = this.productType && this.productType.iframe?.link_type !== 'Any';
        this.disableButton();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIndex) {
      this.tagName = `${SLICE_TAGGING_NAME.resiliation1}_bt_suivant_etape_${this.selectedIndex + 1}`;
    }
  }

  disableButton() {
    if (this.hasLink) {
      this._buttonDisabled = false;
    } else {
      this._buttonDisabled = this.regForm.invalid;
    }
  }

  previous(): void {
    this.myStepper.previous();
  }

  checkEstTitulaire(): boolean {
    return this.regForm.get('isOwner')?.value === false;
  }

  onSubmit(): void {
    // Si on a un lien vers un document prismic, alors on navigue vers celui-ci,
    // Sinon on passe à l'étape suivante,
    if (this.productType?.iframe && this.productType?.iframe.link_type === 'Document' && this.productType.iframe.uid) {
      this.router.navigateByUrl(this.productType.iframe.uid);
      return;
    } else if (this.productType?.iframe && this.productType?.iframe.link_type !== 'Document') {
      this.myStepper.next();
      return;
    }

    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
      return;
    }

    this.triggerFormFieldsStatus(this.regForm);
    this.changeDetectorRef.detectChanges();
    this.focusFirstInvalidFormField();
  }
}

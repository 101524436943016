import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FrontPageNewsSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { TagCommanderService } from '../../../services/tag-commander.service';
@Component({
  selector: 'slice-front-page-news',
  template: `
    <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
    <div class="front-page-news-content">
      <div class="left-col">
        <cb-responsive-img [mobileImage]="data.image?.mobile" [desktopImage]="data.image"> </cb-responsive-img>
      </div>
      <div class="right-col">
        <h3 role="h2">{{ data.subtitle }}</h3>
        <div class="description desktop-only" [innerHtml]="data.description"></div>
        <div class="description mobile-only" [innerHtml]="data.mobileDescription"></div>
        <a
          *ngIf="data.link"
          (click)="linkitemClickHandler(data.link.url, data.link.label)"
          [attr.href]="data.link.url"
          [attr.target]="data.link.target"
          >{{ data.link.label }}</a
        >
      </div>
    </div>
  `,
  styleUrls: ['./slice-front-page-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceFrontPageNewsComponent implements ComponentWithData<FrontPageNewsSlice> {
  constructor(private readonly tagCommanderService: TagCommanderService) {}

  @Input() data: FrontPageNewsSlice;
  @Input() isFirstSlice: boolean;

  linkitemClickHandler(): void {
    this.tagCommanderService.trackClickNavigationEvent(
      `${this.data.title}::${this.data.subtitle}_${this.data?.link?.label}`,
    );
  }
}

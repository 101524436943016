import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { SliceContactUsComponent } from './slice-contact-us.component';
import { ContactUsTabComponent } from './contact-us-tab/contact-us-tab.component';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { QuestionsTabComponent } from './questions-tab/questions-tab.component';
import { UiExpansionPanelModule } from '../../../ui/molecules/expansion-panel/expansion-panel.module';
import { AnalyticsModule } from '../../../../app/analytics/analytics.module';

@NgModule({
  imports: [CommonModule, MatTabsModule, CommonBehaviorsModule, UiExpansionPanelModule, AnalyticsModule],
  declarations: [ContactUsTabComponent, QuestionsTabComponent, SliceContactUsComponent],
  exports: [SliceContactUsComponent],
  entryComponents: [SliceContactUsComponent],
})
export class SliceContactUsModule {}

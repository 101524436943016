import 'hammerjs';

import { ApplicationRef, enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createNewHosts } from '@angularclass/hmr';

// import { Logger } from '@repo/shared/lib/logger/logger';
import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

async function main(): Promise<void> {
  // const appLogger = new Logger('MAIN');
  let appModuleRef: NgModuleRef<AppBrowserModule> | undefined;
  let appErrorElement: HTMLElement | undefined;

  try {
    // Logger.configure({
    //   level: appConfig.LOGGER_LEVEL,
    //   consoleApi: console,
    //   consoleBinding: true,
    // });

    appModuleRef = await platformBrowserDynamic().bootstrapModule(AppBrowserModule);
  } catch (err) {
    // appLogger.error(err);
    console.error(err);

    appErrorElement = document.createElement('div');
    appErrorElement.classList.add('app-fatal-error');
    appErrorElement.innerHTML = `
      <a href="https://www.lcl.fr/" class="logo">LCL Banque et Assurance</a>
      <h1>Oops !</h1>
      <p>Le site est momentanément indisponible. Nos équipes mettent tout en oeuvre pour rétablir la situation dans les meilleurs délais.</p>
    `;

    if (!environment.production) {
      appErrorElement.innerHTML += `<pre><code>${err}</code></pre>`;
    }

    document.body.appendChild(appErrorElement);
  }

  handleHotModuleReloading(appModuleRef, appErrorElement);
}

function handleHotModuleReloading<T>(
  appModuleRef: NgModuleRef<T> | undefined,
  appErrorElement: HTMLElement | undefined,
): void {
  if (module.hot) {
    module.hot.accept();

    module.hot.dispose(() => {
      if (appErrorElement) {
        document.body.removeChild(appErrorElement);
      }

      if (appModuleRef) {
        reloadNgModule(appModuleRef);
      }
    });
  }
}

function reloadNgModule<T>(ngModuleRef: NgModuleRef<T>): void {
  const appRef = ngModuleRef.injector.get(ApplicationRef);
  const elements = appRef.components.map(c => c.location.nativeElement);
  const makeVisible = createNewHosts(elements);

  ngModuleRef.destroy();

  makeVisible();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', main);
} else {
  main();
}

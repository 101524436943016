import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { FILE_UPLOAD_ERRORS } from './form-upload-file.type';

export class FileValidator {
  static requiredMaxFileSize(maxSize: number): ValidatorFn {
    return (control: FormControl) => {
      const files: File[] = control.value;
      if (files != null) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size > maxSize * 1000000) {
            files.splice(i, 1);
            return {
              error: FILE_UPLOAD_ERRORS.FILE_SIZE_ERROR,
              details: maxSize,
            };
          }
        }
      }
      return null;
    };
  }

  static requiredMaxFilesSize(maxSize: number): ValidatorFn {
    const sum = (files: File[]): number => {
      let res = 0;
      for (const file of files) {
        res += file.size;
      }
      return res;
    };

    return (control: FormControl) => {
      const files: File[] = control.value;
      if (files != null) {
        let size: number = sum(files);
        if (size > maxSize * 1000000) {
          do {
            files.splice(files.length - 1, 1);
            size = sum(files);
          } while (size > maxSize * 1000000);
          return {
            error: FILE_UPLOAD_ERRORS.MAX_FILES_SIZE_ERROR,
            details: maxSize,
          };
        }
      }
      return null;
    };
  }

  static requiredMinFiles(minFiles: number, minFilesErrorMessage: string): ValidatorFn {
    return (control: AbstractControl) => {
      const files: File[] = control.value;
      if (files != null) {
        if (files.length < minFiles) {
          const message = !minFilesErrorMessage
            ? FILE_UPLOAD_ERRORS.MIN_FILES_ERROR
            : FILE_UPLOAD_ERRORS.MIN_FILES_LOAN_INSURANCE_ERROR;
          return {
            error: message,
            details: minFiles,
          };
        }
      }
      return null;
    };
  }

  static requiredMaxFiles(maxFiles: number): ValidatorFn {
    return (control: AbstractControl) => {
      const files: File[] = control.value;
      if (files != null && files.length > maxFiles) {
        files.splice(maxFiles, files.length - maxFiles);
        return {
          error: FILE_UPLOAD_ERRORS.MAX_FILES_ERROR,
          details: maxFiles,
        };
      }
      return null;
    };
  }

  static requiredFileType(): ValidatorFn {
    return (control: AbstractControl) => {
      const files: File[] = control.value;
      if (files != null) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (!file.name.match(/\.(doc|docx|pdf|xls|xlsx|gif|jpg|jpeg|bmp|png|PNG)$/)) {
            files.splice(i, 1);
            return {
              error: FILE_UPLOAD_ERRORS.FILE_TYPE_ERROR,
            };
          }
        }
      }
      return null;
    };
  }
}

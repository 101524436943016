import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgenciesService } from '../../../services/agencies.service';
import { HereAutocompleteService } from '../../../services/here-autocomplete.service';
import { HereMapService } from '../../../services/here-map.service';
import { UiMoleculesModule } from '../../../ui/molecules/molecules.module';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { AgencyFeatureComponent } from './agency-feature/agency-feature.component';
import { AgencyFormSuggestionDirective } from './agency-form/agency-form-suggestion.directive';
import { AgencyFormComponent } from './agency-form/agency-form.component';
import { AgencyListComponent } from './agency-list/agency-list.component';
import { AgencyMapComponent } from './agency-map/agency-map.component';
import { AgencyPinComponent } from './agency-pin/agency-pin.component';
import { AgencyScheduleComponent } from './agency-schedule/agency-schedule.component';
import { AgencyContactComponent } from './agency-contact/agency-contact.component';
import { AgencyCountdownComponent } from './agency-countdown/agency-countdown.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    CommonModule,
    CommonBehaviorsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiMoleculesModule,
    ClickOutsideModule,
  ],
  declarations: [
    AgencyFormSuggestionDirective,
    AgencyFormComponent,
    AgencyMapComponent,
    AgencyListComponent,
    AgencyScheduleComponent,
    AgencyFeatureComponent,
    AgencyPinComponent,
    AgencyContactComponent,
    AgencyCountdownComponent,
  ],
  exports: [
    CommonModule,
    CommonBehaviorsModule,
    FormsModule,
    HttpClientModule,
    UiMoleculesModule,
    ClickOutsideModule,
    AgencyFormComponent,
    AgencyMapComponent,
    AgencyListComponent,
    AgencyScheduleComponent,
    AgencyFeatureComponent,
    AgencyPinComponent,
    AgencyContactComponent,
    AgencyCountdownComponent,
  ],
  providers: [HereAutocompleteService, HereMapService, AgenciesService],
})
export class SliceAgencyModule {}

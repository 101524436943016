import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonLdModule } from '../../../../json-ld/json-ld.module';

import { CommonBehaviorsModule } from '../../../common-behaviors/common-behaviors.module';
import { SlicesModule } from '../../../slices/slices.module';
import { ArticleComponent } from './article/article.component';
import { ArticleHeadComponent } from './head/article-head.component';
import { ArticleShareBarComponent } from './share-bar/article-share-bar.component';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule, SlicesModule, JsonLdModule],
  declarations: [ArticleComponent, ArticleHeadComponent, ArticleShareBarComponent],
  entryComponents: [ArticleComponent],
})
export class ArticleModule {}

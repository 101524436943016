import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ModalContainerComponent } from './modal-container/modal-container.component';
import { ModalEntryHostDirective } from './modal-entry-host/modal-entry-host.directive';
import { ModalRouterOutletDirective } from './modal-router-outlet/modal-router-outlet.directive';
import { ModalService } from './modal.service';

@NgModule({
  imports: [CommonModule, OverlayModule, RouterModule, A11yModule],
  declarations: [ModalEntryHostDirective, ModalContainerComponent, ModalRouterOutletDirective],
  entryComponents: [ModalContainerComponent],
  exports: [ModalEntryHostDirective, ModalContainerComponent, ModalRouterOutletDirective],
  providers: [ModalService],
})
export class AppModalModule {}

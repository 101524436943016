import { Component, Input } from '@angular/core';
import { ComponentWithData } from '../../../../typings';
import { CreditExampleSlice } from '@repo/shared';

@Component({
  selector: 'slice-credit-example',
  template: `
    <ng-container *ngIf="data && (data.title || data.richText || data.legal)">
      <div class="content-medium">
        <h3>{{ data.title }}</h3>
        <div class="text" [innerHTML]="data.richText"></div>
        <div class="legal">{{ data.legal }}</div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-credit-example.component.scss'],
})
export class SliceCreditExampleComponent implements ComponentWithData {
  @Input() data: CreditExampleSlice;
}

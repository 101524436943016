import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { DOMScriptService } from './dom-script.service';

export const PRISMIC_CONFIG = 'PRISMIC_CONFIG';
export const PRISMIC_CONTAINER = 'PRISMIC_URL';

@Injectable()
export class PrismicService {
  private readonly prismicApiUrl: string;
  private readonly prismicContainerUrl: string;

  constructor(private configService: ConfigService, private domScriptService: DOMScriptService) {
    this.prismicApiUrl = this.configService.get<string>('PRISMIC_API_URL');
    this.prismicContainerUrl = this.configService.get<string>('PRISMIC_CONTAINER_URL');
  }

  public appendScripts(): void {
    this.domScriptService.updateInlineScript(PRISMIC_CONFIG, this.buildPrismicConfigSnippet());
    this.domScriptService.updateExternalScript(PRISMIC_CONTAINER, this.prismicContainerUrl);
  }

  private buildPrismicConfigSnippet(): string {
    return `
      window.prismic = {
        endpoint: ${JSON.stringify(this.prismicApiUrl)}
      };
    `;
  }
}

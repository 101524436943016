import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { SitemapSlice } from '@repo/shared';

@Component({
  selector: 'slice-sitemap',
  template: `
    <div *ngFor="let column of data.sitemapColumns" class="column">
      <ul *ngFor="let group of column">
        <li>
          <h3>{{ group.title }}</h3>
        </li>
        <li *ngFor="let univers of group.univers">
          <a [attr.href]="univers.url" [attr.target]="univers.target">{{ univers.label }}</a>
          <ul *ngIf="univers.products && univers.products.length">
            <li *ngFor="let product of univers.products">
              <a [attr.href]="product.url" [attr.target]="product.target">{{ product.label }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./slice-sitemap.component.scss'],
})
export class SliceSitemapComponent implements SliceWithData<SitemapSlice> {
  data: SitemapSlice;
}

import { Component, HostBinding, Input } from '@angular/core';
import { Image } from '@repo/shared';

@Component({
  selector: 'cb-scalable-img',
  template: '',
  styleUrls: ['./scalable-img.component.scss'],
})
export class ScalableImgComponent {
  @Input() image: Image;
  @HostBinding('style.backgroundPosition') @Input() position: 'top' | 'middle' | 'bottom' | 'inherit';

  @HostBinding('attr.role')
  readonly attributeRole = 'img';

  @HostBinding('attr.aria-label')
  get imageAlt(): string | null {
    return (this.image && this.image.alt) || null;
  }

  @HostBinding('style.backgroundImage')
  get imageBackgroundUrl(): string | null {
    const imageUrl = this.getImageUrl();

    return imageUrl ? `url(${imageUrl})` : null;
  }

  @HostBinding('style.height.px')
  get height(): number | null {
    return this.image?.dimensions?.height || null;
  }

  @HostBinding('style.width.px')
  get width(): number | null {
    return this.image?.dimensions?.width || null;
  }

  getImageUrl(): string | null {
    return this.image?.url || null;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiExpansionHeaderComponent } from './expansion-header.component';
import { UiExpansionPanelComponent } from './expansion-panel.component';
import { UiExpansionListDirective } from './expansion-list.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [UiExpansionListDirective, UiExpansionHeaderComponent, UiExpansionPanelComponent],
  exports: [UiExpansionListDirective, UiExpansionHeaderComponent, UiExpansionPanelComponent],
})
export class UiExpansionPanelModule {}

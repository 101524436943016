import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SliceWithData } from '../../../../typings';
import { MultiLinksSlice, SimulatorDirectory } from '@repo/shared';
import { TagCommanderService } from '../../../services/tag-commander.service';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

export const SIMULATORS_FILTER_TAGGING_PREFIX = 'SimulateurMultiLiensFiltre_';

@Component({
  selector: 'slice-multi-links',
  template: `
    <ng-container *ngIf="data">
      <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <h3 [ngClass]="{ 'with-filter': withFilter }">{{ data.subtitle }}</h3>
      <cb-dropdown *ngIf="withFilter" [items]="categories" (selectItem)="onSimulatorFilterClick($event)"></cb-dropdown>
      <div
        *ngFor="let simulator of currentSimulatorDirectories"
        class="simulators"
        [@showHide]="action"
        (@showHide.done)="animationEnd()"
      >
        <cb-scalable-img [image]="simulator.image"></cb-scalable-img>
        <div [style.maxHeight.px]="simulator.image.dimensions.height" class="list">
          <div>
            <ul *ngFor="let block of simulator.blocks">
              <li>
                <h4>{{ block.title }}</h4>
                <ul>
                  <li *ngFor="let link of block.links">
                    <a
                      [attr.href]="link.url"
                      [attr.target]="link.target"
                      analyticsOn
                      [analyticsLabel]="getSimulatorLinkAnalyticsLabel(block.title, link.label)"
                      >{{ link.label }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-multi-links.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1,
        }),
      ),
      state(
        'hide',
        style({
          opacity: 0.3,
        }),
      ),
      transition('show => hide', [animate('.2s 0s ease-in-out')]),
      transition('hide => show', [animate('.4s 0s ease-in-out')]),
    ]),
  ],
})
export class SliceMultiLinksComponent implements SliceWithData<MultiLinksSlice>, OnInit, OnDestroy {
  @Input() data: MultiLinksSlice;

  action: 'show' | 'hide' = 'show';
  currentSimulatorDirectories: SimulatorDirectory[];

  private timer: any = null;

  constructor(private readonly tagCommanderService: TagCommanderService) {}

  get withFilter(): boolean {
    return this.categories.length !== 0;
  }

  get categories(): string[] {
    const simulatorDirectories = this.data?.simulatorDirectories;

    return simulatorDirectories
      ? simulatorDirectories.filter(simulator => simulator.category).map(simulator => simulator.category as string)
      : [];
  }

  ngOnInit(): void {
    if (this.data) {
      this.currentSimulatorDirectories =
        this.categories.length !== 0 ? [this.data.simulatorDirectories[0]] : this.data.simulatorDirectories;
    }
  }

  onSimulatorFilterClick(category: string): void {
    this.action = 'hide';
    this.timer = setTimeout(() => {
      this.currentSimulatorDirectories = [
        this.data.simulatorDirectories.find(simulator => simulator.category === category) as SimulatorDirectory,
      ];
    }, 300);

    this.tagCommanderService.trackClickActionEvent(`${SIMULATORS_FILTER_TAGGING_PREFIX}${category}`);
  }

  animationEnd(): void {
    this.action = 'show';
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  getSimulatorLinkAnalyticsLabel(blockTitle: string, label: string): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${blockTitle}_${label}`;
  }
}

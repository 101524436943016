import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ComponentWithData } from '../../../../typings';

@Component({
  selector: 'slice-zone-comparative',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section class="zone-container" [style.background]="'url(' + data.image_fond?.url + ')'">
      <ng-container *ngFor="let itemComparatif of data.tableau_comparatif">
        <tableau-comparatif [data]="itemComparatif"></tableau-comparatif>
      </ng-container>
    </section>
  `,
  styleUrls: ['./slice-zone-comparative.component.scss'],
})
export class SliceZoneComparativeComponent implements ComponentWithData {
  @Input() data: any;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Agency } from '../../../../services/agencies.type';
import { formatPhoneNumber } from '../slice-agency.helper';

import * as unorm from 'unorm';

@Component({
  selector: 'app-agency-list',
  template: `
    <div
      *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
      class="container"
      [ngClass]="{ 'container-odd': odd }"
      (click)="openAgencyDetails(agency)"
    >
      <div class="main">
        <app-agency-pin class="pin" [agencyType]="agency.agencyType">{{ agencyIndex + 1 }}</app-agency-pin>
        <div class="info">
          <div class="info-name">{{ agency.name }}</div>
          <div class="info-address">{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
          <div class="info-phone" *ngIf="agency.phoneNumber">Tél : {{ formatPhoneNumber(agency.phoneNumber) }}</div>
        </div>
      </div>
      <app-agency-feature class="feature" [agency]="agency"></app-agency-feature>
    </div>
  `,
  styleUrls: ['./agency-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyListComponent {
  @Input()
  agencies: Agency[];

  constructor(private router: Router) {}

  formatPhoneNumber(phoneNumber: string): string {
    return formatPhoneNumber(phoneNumber);
  }

  openAgencyDetails(agency: Agency): void {
    this.router.navigate([`/agence-banque/${agency.id}-${this.sanitizeAgencyName(agency.name)}`]);
  }

  sanitizeAgencyName(name: string): string {
    name = name.trim().replace(/\s+/g, '-');
    name = unorm.nfd(name).replace(/[\u0300-\u036f]/g, '');
    name = encodeURIComponent(name);
    return name;
  }
}

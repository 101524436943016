/* eslint-disable no-useless-escape */
import { ValidationErrors } from '@angular/forms';
import { FileUploadDirective } from '../form-upload-file/form-upload-file.directive';
import { AfterViewInit, ContentChild, Directive, OnDestroy } from '@angular/core';
import { EMPTY, merge, Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FormFieldInputDirective } from '../form-field-input/form-field-input.directive';
import { FormFieldRadioGroupDirective } from '../form-field-radio/form-field-radio';
import { FormFieldTimepickerDirective } from '../form-field-timepicker/form-field-timepicker';
import { DatepickerDirective } from '../datepicker/datepicker.component';
import { FormFieldSelectDirective } from '../form-field-select/form-field-select';
import { FILE_UPLOAD_ERRORS } from '../form-upload-file/form-upload-file.type';

export type FormControlStatus = 'INVALID' | 'VALID';

const NG_SELECT_ERROR = 'Champ obligatoire';

@Directive({
  selector: '[formFieldControl]',
  exportAs: 'formFieldControl',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-control',
    '[class.form-field-control--valid]': 'status === "VALID"',
    '[class.form-field-control--invalid]': 'status === "INVALID"',
  },
})
export class FormFieldControlDirective implements AfterViewInit, OnDestroy {
  get isTouched(): boolean {
    if (this._radioGroupChild) {
      return !!this._radioGroupChild.firstNgControl.touched;
    }
    if (this._inputChild) {
      return !!this._inputChild.ngControl.touched;
    }
    if (this._timepickerChild) {
      return !!this._timepickerChild.ngControl.touched;
    }
    if (this._selectChild) {
      return !!this._selectChild.ngControl.touched;
    }
    if (this._datepickerChild) {
      return !!this._datepickerChild.ngControl.touched;
    }
    if (this._uploadFileChild) {
      return !!this._uploadFileChild.ngControl.touched;
    }
    return false;
  }

  get status(): FormControlStatus | undefined {
    if (this.isTouched) {
      if (this._status === 'INVALID') {
        return 'INVALID';
      }
      if (this._status === 'VALID') {
        return 'VALID';
      }
    }
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  @ContentChild(FormFieldInputDirective, { static: true }) private _inputChild: FormFieldInputDirective;

  @ContentChild(FormFieldRadioGroupDirective, { static: true }) private _radioGroupChild: FormFieldRadioGroupDirective;

  @ContentChild(FormFieldTimepickerDirective, { static: true }) private _timepickerChild: FormFieldTimepickerDirective;

  @ContentChild(FormFieldSelectDirective, { static: true }) private _selectChild: FormFieldSelectDirective;

  @ContentChild(DatepickerDirective, { static: true }) private _datepickerChild: DatepickerDirective;

  @ContentChild(FileUploadDirective, { static: true }) private _uploadFileChild: FileUploadDirective;

  private _subscription: Subscription = new Subscription();
  private _status: FormControlStatus;
  private _errorMessage: string;

  ngAfterViewInit(): void {
    if (this._radioGroupChild) {
      this._handleRadioGroupChild();
    } else if (this._inputChild) {
      this._handleInputChild();
    } else if (this._timepickerChild) {
      this._handleTimepickerChild();
    } else if (this._datepickerChild) {
      this._handledatepickerChild();
    } else if (this._selectChild) {
      this._handleSelectChild();
    } else if (this._uploadFileChild) {
      this._handleUploadFileChild();
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }

  private _handleRadioGroupChild(): void {
    this._subscription.add(
      this._radioGroupChild.statusChanges.subscribe(() => {
        this._status = this._radioGroupChild.firstNgControl.status as FormControlStatus;
      }),
    );
  }

  private _handleInputChild(): void {
    this._subscription.add(
      merge(this._inputChildTouched$(), this._inputChild.blurred).subscribe(() => {
        this._status = this._inputChild.ngControl.status as FormControlStatus;
        if (this._inputChild.ngControl.errors) {
          const { message } = Object.values(this._inputChild.ngControl.errors)[0];
          this._errorMessage = message;
        }
      }),
    );
  }

  private _inputChildTouched$(): Observable<boolean | null> {
    return this._inputChild.ngControl.statusChanges?.pipe(mergeMap(() => (this.isTouched ? of(true) : EMPTY))) || EMPTY;
  }

  private _handleTimepickerChild(): void {
    this._subscription.add(
      merge(this._timepickerChildTouched$(), this._timepickerChild.blurred).subscribe(() => {
        this._status = this._timepickerChild.ngControl.status as FormControlStatus;
        if (this._timepickerChild.ngControl.errors) {
          const { message } = Object.values(this._timepickerChild.ngControl.errors)[0];
          this._errorMessage = message;
        }
      }),
    );
  }

  private _timepickerChildTouched$(): Observable<boolean | null> {
    return (
      this._timepickerChild.ngControl.statusChanges?.pipe(mergeMap(() => (this.isTouched ? of(true) : EMPTY))) || EMPTY
    );
  }

  private _handledatepickerChild(): void {
    this._subscription.add(
      merge(this._datepickerChildTouched$(), this._datepickerChild.blurred).subscribe(() => {
        this._status = this._datepickerChild.ngControl.status as FormControlStatus;

        if (this._datepickerChild.ngControl.errors) {
          const { message } = Object.values(this._datepickerChild.ngControl.errors)[0];
          this._errorMessage = message;
        }
      }),
    );
  }

  private _handleSelectChild(): void {
    this._subscription.add(
      this._selectChild.ngControl.statusChanges?.subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const value = this._selectChild.ngControl.value;
        this._status = this._selectChild.ngControl.status as FormControlStatus;
        if (this._selectChild.ngControl.errors) {
          this._errorMessage = NG_SELECT_ERROR;
        }
      }),
    );
  }

  private _handleUploadFileChild(): void {
    const handleError = (errors: ValidationErrors): void => {
      this._errorMessage = Object.values(errors)[0];

      if (errors.error) {
        switch (errors.error) {
          case FILE_UPLOAD_ERRORS.FILE_TYPE_ERROR:
            this._errorMessage =
              `Oops! ce format n'est pas accepté. Les formats autorisés sont ` +
              `les suivants: .doc, .docx, .pdf, .xls, .xlsx, .gif, .jpg, .jpeg, .bmp, .png`;
            break;
          case FILE_UPLOAD_ERRORS.FILE_SIZE_ERROR:
            this._errorMessage =
              `Votre fichier dépasse la taille maximale autorisée.` +
              ` Veuillez joindre un fichier inférieur à ${errors.details} Mo.`;
            break;
          case FILE_UPLOAD_ERRORS.MAX_FILES_SIZE_ERROR:
            this._errorMessage =
              `Attention! Vous avez atteint la taille maximale de pièces jointes téléchargeables soit ${errors.details} Mo. ` +
              `Merci de supprimer un des fichiers téléchargés ou de diminuer la taille de vos fichiers avant de les télécharger` +
              ` à nouveau`;
            break;
          case FILE_UPLOAD_ERRORS.MIN_FILES_ERROR:
            errors.details > 1
              ? (this._errorMessage = `Veuillez joindre au moins ${errors.details} fichiers.`)
              : (this._errorMessage = `Veuillez joindre au moins ${errors.details} fichier.`);
            break;
          case FILE_UPLOAD_ERRORS.MIN_FILES_LOAN_INSURANCE_ERROR:
            this._errorMessage =
              `Pour le bon traitement de votre demande, ` +
              `veuillez joindre les pièces justificatives suivantes: ` +
              `la notice d\'information ou les Conditions Générales du contrat proposé en substitution ` +
              ` ainsi que le devis ou les Conditions Particulières du contrat proposé en substitution.`;
            break;
          case FILE_UPLOAD_ERRORS.MAX_FILES_ERROR:
            this._errorMessage =
              `Vous avez atteint le nombre maximal de fichiers autorisés, soit ${errors.details}` +
              ` maximum. Merci de supprimer un des fichiers téléchargés si vous souhaitez inclure un autre document.`;
            break;
          default:
            this._errorMessage = Object.values(errors)[0];
        }
      }
    };
    this._subscription.add(
      this._uploadFileChild.ngControl.statusChanges?.subscribe(() => {
        this._status = this._uploadFileChild.ngControl.status as FormControlStatus;
        if (this._uploadFileChild.ngControl.errors) {
          handleError(this._uploadFileChild.ngControl.errors);
        }
      }),
    );
  }

  private _datepickerChildTouched$(): Observable<boolean | null> {
    return (
      this._datepickerChild.ngControl.statusChanges?.pipe(mergeMap(() => (this.isTouched ? of(true) : EMPTY))) || EMPTY
    );
  }
}

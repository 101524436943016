import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHrefPhone]',
})
export class HrefPhoneDirective implements OnInit {
  @Input() appHrefPhone: string | null;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.appHrefPhone) {
      const formattedHref = this.formatPhone(this.appHrefPhone);
      this.renderer.setAttribute(this.el.nativeElement, 'href', formattedHref);
    }
  }

  private formatPhone(phone: string): string {
    let hrefPhone = phone.replace(/[\s.]/g, '');
    if (hrefPhone[0] === '0') {
      hrefPhone = '+33' + hrefPhone.substr(1);
    } else if (hrefPhone[0] === '+') {
      hrefPhone = hrefPhone.replace('(0)', '');
    }
    return 'tel:' + hrefPhone;
  }
}

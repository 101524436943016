import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderButtonsLinks } from '../../header';
import { MenuItem } from '@repo/shared';

const IC_CHAT = '/assets/images/ic_chat.svg';
const IC_LOCK = '/assets/images/ic_lock.svg';

@Component({
  selector: 'app-desktop-header-second-line',
  template: `
    <nav role="navigation" aria-label="Menu principal" class="menu">
      <ul class="menu-list-item">
        <li class="menu-item" *ngFor="let item of universes">
          <app-desktop-header-menu-item
            [link]="item.link"
            [megamenu]="item.megamenu"
            [inStickyHeader]="inStickyHeader"
            [megamenuShowable]="megamenuShowable"
          >
          </app-desktop-header-menu-item>
        </li>
      </ul>
      <ul class="menu-list-item">
        <li class="menu-item" *ngFor="let item of communications">
          <app-desktop-header-menu-item
            [link]="item.link"
            [megamenu]="item.megamenu"
            [secondary]="true"
            [inStickyHeader]="inStickyHeader"
            [megamenuShowable]="megamenuShowable"
          >
          </app-desktop-header-menu-item>
        </li>
      </ul>
      <div class="small-actions-buttons" *ngIf="inStickyHeader">
        <app-desktop-header-action-button
          *ngIf="buttons.chat"
          [url]="buttons.chat.url"
          [label]="buttons.chat.label"
          [imgSrc]="chatIcon"
          [imgWidth]="33"
          [imgHeight]="32"
          [small]="true"
          [secondary]="true"
          [target]="buttons.chat.target"
        >
        </app-desktop-header-action-button>
        <app-desktop-header-action-button
          *ngIf="buttons.myAccount"
          [url]="buttons.myAccount.url"
          [label]="buttons.myAccount.label"
          [imgSrc]="lockIcon"
          [imgWidth]="15"
          [imgHeight]="20"
          [small]="true"
        >
        </app-desktop-header-action-button>
      </div>
    </nav>
  `,
  styleUrls: ['./desktop-header-second-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopHeaderSecondLineComponent {
  @Input() inStickyHeader = false;
  @Input() megamenuShowable = true;
  @Input() universes: MenuItem[];
  @Input() communications: MenuItem[];
  @Input() buttons: HeaderButtonsLinks = {};

  chatIcon: string = IC_CHAT;
  lockIcon: string = IC_LOCK;
}

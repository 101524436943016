import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Slice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { SLICE_COMPONENT_CLASS_MAP, SliceComponentClassMap } from '../slices.tokens';

@Component({
  selector: 'cb-slices',
  template: ` <ng-container #viewContainer></ng-container> `,
  styleUrls: ['./slices.component.scss'],
})
export class SlicesComponent implements OnInit, OnDestroy {
  @Input() data: Slice[];

  @ViewChild('viewContainer', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  constructor(
    @Inject(SLICE_COMPONENT_CLASS_MAP) private readonly sliceComponentClassMap: SliceComponentClassMap,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    this.createSliceComponents(this.data);
  }

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }

  createSliceComponents(body: Slice[]): void {
    if (!body || !body.length) {
      return;
    }

    const firstSliceType = body[0].type;
    const firstSliceIsHeader =
      firstSliceType === 'hero' ||
      firstSliceType === 'super_hero' ||
      firstSliceType === 'mag_hero' ||
      firstSliceType === 'agency_map_hero' ||
      firstSliceType === 'agency_details';

    for (let index = 0; index < body.length; ++index) {
      const item = body[index];
      const componentFactory = this.resolveSliceComponentFactory(item.type);
      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      const componentInstance = componentRef.instance;

      componentInstance.data = item;
      componentInstance.sliceIndex = index;
      componentInstance.isFirstSlice = (index === 0 && !firstSliceIsHeader) || (index === 1 && firstSliceIsHeader);
    }
  }

  resolveSliceComponentFactory(type: string): ComponentFactory<SliceWithData> {
    const componentClass = this.sliceComponentClassMap[type];

    if (!componentClass) {
      throw new Error(
        `No component class found for type "${type}". Did you add it to SLICE_COMPONENT_CLASS_MAP token value?`,
      );
    }

    return this.componentFactoryResolver.resolveComponentFactory(componentClass);
  }
}

<ng-container *ngIf="iframeURL">
  <iframe id="iframeContainer" class="iframe" [src]="iframeURL"></iframe>
</ng-container>
<ng-container *ngIf="!iframeURL">
  <form id="claim-form" class="form form-width" [formGroup]="regForm.get('claim')" novalidate>
    <div id="claim">
      <h2>Mes informations</h2>
      <div class="row">
        <div formFieldControl>
          <label>Civilité*</label>
          <div formFieldRadioGroup class="form-field-control--half-size--block">
            <form-field-radio id="civilTitle" name="civilTitle" formControlName="civilTitle" value="Mme">
              <span class="ic ic-woman civil-icon"></span>
              Madame
              <div class="check-icon">
                <span class="ic-check"></span>
              </div>
            </form-field-radio>
            <form-field-radio name="civilTitle" formControlName="civilTitle" value="M" class="form-field-radio--right">
              <span class="ic ic-man civil-icon"></span>
              Monsieur
              <div class="check-icon">
                <span class="ic-check"></span>
              </div>
            </form-field-radio>
          </div>
          <form-field-error></form-field-error>
        </div>
        <div class="row">
          <div formFieldControl class="form-field-control--half-size">
            <label>
              Nom*
              <input id="lastName" formControlName="lastName" type="text" placeholder="Dupont" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl class="form-field-control--half-size form-field-control--right">
            <label>
              Prénom*
              <input id="firstName" placeholder="Antoine" type="text" formControlName="firstName" formFieldInput />
            </label>
            <form-field-error></form-field-error>
          </div>
        </div>
        <div class="row">
          <div formFieldControl class="form-field-control--half-size--block">
            <label>
              E-mail*
              <input id="email" formControlName="email" type="email" formFieldInput placeholder="exemple@mail.com" />
            </label>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl class="form-field-control--half-size--block">
            <label>
              Date de naissance*
              <datepicker formFieldDatepicker id="birthDate" formControlName="birthDate"></datepicker>
            </label>
            <form-field-error></form-field-error>
          </div>
        </div>
      </div>
      <div>
        <h2>Agence LCL</h2>
        <div class="agencies-search-box">
          <div>
            <div formFieldControl class="form-field-control--half-size">
              <app-agency-form
                id="agencySearch"
                (optionSelected)="searchNearAgencies($event)"
                [isChildForm]="true"
                [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
              >
              </app-agency-form>
            </div>
          </div>
          <div
            *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
            formFieldControl
            class="agencies-result-container"
          >
            <label>Sélectionnez une agence dans la liste de résultats.</label>
            <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
            <div *ngIf="agencies.length === 0" class="agency-count">
              Aucune agence LCL trouvée. Veuillez refaire une recherche
            </div>
            <div class="agencies-container">
              <div
                *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
                class="agency"
                [ngClass]="{ 'agency-odd': odd }"
                (click)="updateAgencyData(agency)"
              >
                <div class="agency-info-name">{{ agency.name }}</div>
                <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
              </div>
            </div>
          </div>
          <div formFieldControl class="hide-item">
            <label>
              Agence
              <input formControlName="agencyRef" formFieldInput type="text" />
            </label>
          </div>
        </div>
        <h2>Référence compte</h2>
        <div formFieldControl class="form-field-control--half-size">
          <label>
            Indicatif*
            <input
              id="agencyID"
              placeholder="01234"
              formControlName="agencyID"
              formFieldInput
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00000"
            />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label>
            Compte*
            <input
              id="accountNumber"
              placeholder="012345 + clé"
              formControlName="accountNumber"
              formFieldInput
              type="text"
            />
          </label>
          <form-field-error></form-field-error>
        </div>
        <ng-container *ngIf="productSelected && productSelected.optional_reference">
          <h2>Référence contrat*</h2>
          <div formFieldControl class="form-field-control--half-size">
            <label>
              {{ productSelected.help_optional_reference || '' }}
              <input
                id="optionalIdentityField"
                [placeholder]="productSelected.help_optional_reference || ''"
                formControlName="optionalIdentityField"
                formFieldInput
                type="text"
              />
            </label>
            <form-field-error></form-field-error>
          </div>
        </ng-container>
      </div>
      <p class="mandatory-field-text">*Champs obligatoires</p>
    </div>
    <div class="button-wrapper">
      <cb-cta-btn-link
        class="previous"
        (click)="previous()"
        [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
        [buttonWithoutLink]="true"
        [sliceType]="'reclamation_connected_client_form'"
      ></cb-cta-btn-link>
      <cb-cta-btn-link
        class="next"
        (click)="onSubmit()"
        [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
        [buttonWithoutLink]="true"
        [sliceType]="'reclamation_connected_client_form'"
        [disabled]="_buttonDisabled"
        [analyticsLabel]="tagName"
        [analyticsSubPath]="tagSubPath"
        analyticsOn
      >
      </cb-cta-btn-link>
      <form-field-error
        class="post-error"
        [class.shown]="_hasPostError"
        message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
      >
      </form-field-error>
    </div>
  </form>
</ng-container>

import { Link, LinkTarget, LinkType } from '@repo/shared';

export abstract class AbstractCTAComponent {
  abstract get CTA(): Link;

  get target(): string {
    if (this.isPrismicDocument()) {
      return LinkTarget.Blank;
    }
    switch (this.CTA && this.CTA.target) {
      case LinkTarget.Self:
      case LinkTarget.Blank:
      case LinkTarget.ExternalLcl:
      case LinkTarget.Interstitial:
        return this.CTA.target as string;
      default:
        return LinkTarget.Self;
    }
  }

  isPrismicDocument(): boolean {
    return this.CTA && this.CTA.type === LinkType.Media;
  }
}

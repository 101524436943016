import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFaqPageComponent } from './faq-page.component';
import { UiFaqSectionDirective } from './faq-section.directive';
import { UiFaqTitleDirective } from './faq-title.directive';
import { UiFaqDescriptionDirective } from './faq-description.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [UiFaqPageComponent, UiFaqSectionDirective, UiFaqTitleDirective, UiFaqDescriptionDirective],
  exports: [UiFaqPageComponent, UiFaqSectionDirective, UiFaqTitleDirective, UiFaqDescriptionDirective],
})
export class UiFaqModule {}

import { Component, Input } from '@angular/core';
import { ContactUsTab, QuestionsBlock, SliceType } from '@repo/shared';

@Component({
  selector: 'questions-tab',
  template: `
    <ng-container *ngIf="questionsBlock">
      <div [innerHTML]="questionsBlock.title"></div>

      <ui-expansion-list>
        <ui-expansion-panel *ngFor="let question of questionsBlock.questions" [title]="question.text">
          <div [innerHTML]="question.answer" class="answer"></div>
        </ui-expansion-panel>
      </ui-expansion-list>

      <cb-cta-btn-link
        *ngIf="questionsBlock.button"
        [sliceType]="sliceType"
        [button]="questionsBlock.button"
      ></cb-cta-btn-link>
    </ng-container>
  `,
  styleUrls: ['./questions-tab.component.scss'],
})
export class QuestionsTabComponent {
  @Input() tab: ContactUsTab;

  @Input() sliceType: SliceType;

  get questionsBlock(): QuestionsBlock {
    return this.tab.questionsBlock as QuestionsBlock;
  }
}

export class XtPage {
  constructor(
    private readonly pageMarket: string,
    private readonly pageUniverse: string,
    private readonly pageUid: string,
  ) {}

  public shortFormat(): string {
    return this.pageMarket || this.pageUid || '';
  }

  public fullFormat(): string {
    return this.format([this.pageMarket, this.pageUniverse, this.pageUid]);
  }

  private format(parts: string[]): string {
    return parts.filter(part => part).join('::');
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AgencyHours } from '../../../../services/agencies.type';

@Component({
  selector: 'app-agency-schedule',
  template: `
    <table class="table">
      <tr *ngFor="let day of daysList">
        <td class="day">
          {{ daysMapInFrench[day] }}
        </td>
        <td>
          <ng-container *ngIf="getOpeningHours(day) as hours; else closed">
            {{ hours }}
          </ng-container>
          <ng-template #closed>
            <i class="closed">Fermé</i>
          </ng-template>
        </td>
      </tr>
    </table>
  `,
  styleUrls: ['./agency-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyScheduleComponent {
  @Input()
  agencyHours: AgencyHours;

  daysList: Array<keyof AgencyHours> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  daysMapInFrench = {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
  };

  getOpeningHours(day: keyof AgencyHours): string {
    const hoursDetails = this.agencyHours[day];
    if (!hoursDetails.isOpen || !hoursDetails.openingHours) {
      return '';
    }
    const hours: string[] = [];
    const { amOpen, amClose, pmOpen, pmClose } = hoursDetails.openingHours;
    if (amOpen && !amClose && !pmOpen && pmClose) {
      hours.push(hoursToString(amOpen, pmClose));
    } else {
      if (amOpen && amClose) {
        hours.push(hoursToString(amOpen, amClose));
      }
      if (pmOpen && pmClose) {
        hours.push(hoursToString(pmOpen, pmClose));
      }
    }
    return hours.join(', ');
  }
}

function hoursToString(open: string, close: string): string {
  const openStr = open.substr(0, 5).replace('.', 'h');
  const closeStr = close.substr(0, 5).replace('.', 'h');
  return `${openStr}-${closeStr}`;
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';

export function BooleanProperty(): PropertyDecorator {
  return function BooleanPropertyDecorator(target: any, name: string): PropertyDescriptor {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(target, name);
    const propertySetter = propertyDescriptor ? propertyDescriptor.set : null;

    if (propertySetter) {
      return {
        ...propertyDescriptor,
        set(value: any): void {
          propertySetter.call(this, coerceBooleanProperty(value));
        },
      };
    }

    const privateName = `_${name}`;

    Object.defineProperty(target, privateName, {
      enumerable: false,
      configurable: true,
      writable: true,
    });

    return {
      enumerable: false,
      configurable: true,
      get(): boolean {
        return this[privateName];
      },
      set(value: boolean): void {
        this[privateName] = coerceBooleanProperty(value);
      },
    };
  };
}

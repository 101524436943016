import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { UiTooltipMessageComponent } from './tooltip-message.component';
import { UiTooltipComponent } from './tooltip.component';
import { UiTooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, PlatformModule, ClickOutsideModule],
  declarations: [UiTooltipComponent, UiTooltipMessageComponent, UiTooltipDirective],
  exports: [UiTooltipComponent, UiTooltipMessageComponent, UiTooltipDirective],
  entryComponents: [UiTooltipMessageComponent, UiTooltipComponent],
})
export class UiTooltipModule {}

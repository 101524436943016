import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  forwardRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { HttpErrorResponse } from '@angular/common/http';
import { RECLAMATION_REQUEST_FORM } from '@repo/shared/form-schemas/reclamation-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

import { ConfigService } from '../../../../../config.service';
import { SliceFormsService } from '../../slice-forms.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../logger/logger.service';
import { Logger } from '../../../../../logger/logger';
import { RECAPTCHA_LANGUAGE, ReCaptchaV3Service } from 'ng-recaptcha';

import { FileUploadComponent } from '../../../../../ui/molecules/form-upload-file/form-upload-file.component';
import { ReclamationFormSlice } from '@repo/shared';

export const CONFIRMATION_PAGE_URL = 'confirmation-envoi-reclamation';

@Component({
  selector: 'reclamation-request',
  templateUrl: './reclamation-request.component.html',
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_REQUEST_FORM,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr',
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ReclamationRequestComponent),
    },
  ],
})
export class ReclamationRequestComponent extends AbstractFormComponent implements OnInit, AfterViewInit {
  @Input() regForm: FormGroup;
  @Input() data: ReclamationFormSlice;

  logger: Logger;

  subjectsOptions;
  _buttonDisabled = false;
  _hasPostError = false;
  selectError = false;

  @ViewChild(FileUploadComponent, { static: true }) fileUploadComponent;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly configService: ConfigService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(ReclamationRequestComponent.name);
  }

  ngOnInit() {
    this.subjectsOptions = this.data.liste_message;
  }

  ngAfterViewInit(): void {
    const requestGroup = this.regForm.get('request') as FormGroup;
    requestGroup.addControl('recaptcha', new FormControl('', Validators.required));
    requestGroup.addControl('recipt', new FormControl(this.data.recipt, Validators.required));
    requestGroup.addControl('email_redirection', new FormControl(this.data.email_redirection));
  }

  previous(): void {
    this.myStepper.previous();
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('entrepriseRequestForm').subscribe(
      token => {
        this.regForm.get('request')?.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  submitForm(): void {
    if (this.regForm.get('files')) {
      this.regForm.get('files')?.updateValueAndValidity();
    }
    const { valid, value } = this.regForm;
    if (valid) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendReclamationForm(value).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._buttonDisabled = false;
          this._hasPostError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
    } else {
      this.selectError = !this.regForm.get('request.subject')?.valid;
      this.triggerFormFieldsStatus(this.regForm.get('request') as FormGroup);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

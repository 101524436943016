import { Component, Input } from '@angular/core';

import { LinkWithImage } from '@repo/shared';
import { AnalyticsSubPaths } from '../../../services/tag-commander.service';

@Component({
  selector: 'footer-menu-toggle',
  template: `
    <input [attr.id]="id" name="submenu" type="checkbox" hidden />
    <label class="submenu-title" [ngStyle]="{ color: accordeonTextColor }" [attr.for]="id">
      {{ title }} <i class="arrow"></i>
    </label>
    <ul>
      <li *ngFor="let link of links">
        <a
          class="accordion_link"
          [ngStyle]="{ color: accordeonTextColor }"
          [attr.href]="link.url"
          [attr.target]="link.target"
          analyticsOn
          [analyticsLabel]="link.label"
          [analyticsSubPath]="analyticsSubPath"
        >
          <img
            *ngIf="link.image && link.image.url"
            [attr.src]="link.image.url"
            [attr.width]="link.image.dimensions.width"
            [attr.height]="link.image.dimensions.height"
          />
          {{ link.label }}
        </a>
      </li>
    </ul>
  `,
  styleUrls: ['./menu-toggle.component.scss'],
})
export class MenuToggleComponent {
  static ID_PREFIX = 'footer-accordion-';

  @Input() title: string;
  @Input() links: LinkWithImage[];
  @Input() accordeonTextColor = '#acb1bd';

  @Input()
  set id(value: string) {
    this._id = MenuToggleComponent.ID_PREFIX + value;
  }
  get id(): string {
    return this._id;
  }

  private _id: string;

  analyticsSubPath = AnalyticsSubPaths.Footer;
}

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { formatMarketUid } from './helpers/page.helpers';

export interface Config {
  [key: string]: string | number | boolean;
}

export const CONFIG_TOKEN = new InjectionToken<Config>('CONFIG_TOKEN');

export const CONFIG_STATE_KEY = makeStateKey<string>('config.state');

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private appConfig: Config;

  constructor(private readonly transferState: TransferState, @Optional() @Inject(CONFIG_TOKEN) config: Config) {
    if (config) {
      this.init(config);
    }

    if (this.isBrowser()) {
      this._loadConfigFromTransferState();
    } else {
      this._saveConfigInTransferState();
    }
  }

  get<T extends string | number | boolean>(key: string, defaultValue?: T): T {
    if (this.has(key)) {
      return this.appConfig[key] as T;
    }
    return defaultValue as T;
  }

  _loadConfigFromTransferState(): void {
    const state = JSON.parse(this.transferState.get<string>(CONFIG_STATE_KEY, 'null')) || {};
    this.init(state);
  }

  _saveConfigInTransferState(): void {
    this.transferState.onSerialize(CONFIG_STATE_KEY, () => this._toString());
  }

  _toString(): string {
    return JSON.stringify(this.appConfig);
  }

  getAccountUrl(marketUid: string): string {
    const market = formatMarketUid(marketUid);
    return this.get<string>('ACCOUNT_URL_' + market, this.get<string>('ACCOUNT_URL_PARTICULIER'));
  }

  private isBrowser(): boolean {
    return this.transferState.hasKey<Config>(CONFIG_STATE_KEY);
  }

  private init(config: Config): void {
    this.appConfig = config;
  }

  private has(key: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.appConfig.hasOwnProperty(key);
  }
}

import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as lottie from 'lottie-web';
import { Subscription, fromEvent } from 'rxjs';

import { ResponsiveService } from '../../../services/responsive.service';
import { WINDOW } from '../../../../app/services/window.provider';

export interface LottieAnimationConfig extends lottie.AnimationConfig {
  inViewportPlay: boolean;
  width: number;
  height: number;
  placeholder?: string | null;
}

export interface AnimationConfig {
  desktop: Partial<LottieAnimationConfig>;
  mobile: Partial<LottieAnimationConfig>;
}

// https://github.com/airbnb/lottie-web/
@Component({
  selector: 'cb-lottie-animation',
  template: ` <div
      [style.display]="isLoaded ? 'block' : 'none'"
      [style.height]="height"
      #weLoveAnimationDiv
      (mousemove)="playAnimation()"
      class="lottie-animation-body"
      inViewport
      [inViewportOptions]="{ threshold: 0.3 }"
      (inViewportAction)="onIntersection($event)"
    ></div>
    <div
      [style.height]="height"
      [style.width]="width"
      *ngIf="!isLoaded"
      [style.backgroundImage]="trustPlaceholder"
    ></div>`,
  styleUrls: ['./lottie-animation.component.scss'],
})
export class LottieAnimationComponent implements OnInit, OnDestroy, OnChanges {
  animItem: lottie.AnimationItem;

  @Input() active = true;
  @Input() animationConfig = {} as AnimationConfig;

  @ViewChild('weLoveAnimationDiv', { static: true }) animationContainer: ElementRef;

  private mobileView = true;

  private breakpointSubscription$: Subscription;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly responsiveService: ResponsiveService,
    private readonly sanitizer: DomSanitizer,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  get isLoaded(): boolean {
    return this.animItem && this.animItem.isLoaded;
  }

  get inViewportPlay(): boolean {
    return this.options && !!this.options.inViewportPlay;
  }

  get width(): string {
    return this.options.width ? this.options.width + 'px' : '100%';
  }

  get height(): string {
    return this.options.height ? this.options.height + 'px' : '100%';
  }

  get trustPlaceholder(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${this.options.placeholder}')`);
  }

  get options(): Partial<LottieAnimationConfig> {
    return this.mobileView ? this.animationConfig.mobile : this.animationConfig.desktop;
  }

  ngOnInit(): void {
    if (!this.win) {
      return;
    }

    this.breakpointSubscription$ = this.responsiveService.subscribeOnDesktop(matches => {
      this._reloadAnimation(matches);
    });
  }

  ngOnDestroy(): void {
    if (this.breakpointSubscription$) {
      this.breakpointSubscription$.unsubscribe();
      this.breakpointSubscription$ = (null as unknown) as Subscription;
    }

    if (this.animItem) {
      this.animItem.destroy();
    }
  }

  playAnimation(): void {
    if (this.active && this.animItem && this.animItem.isPaused) {
      this.animItem.stop();
      this.animItem.play();
    }
  }

  onIntersection({ visible }: { visible: boolean }): void {
    if (visible && this.inViewportPlay) {
      this.playAnimation();
    }
  }

  ngOnChanges({ active }: SimpleChanges): void {
    if (this.animItem && active) {
      if (active.currentValue) {
        this.animItem.play();
      } else {
        this.animItem.stop();
      }
    }
  }

  _reloadAnimation(isDesktop: boolean): void {
    let reload = !this.animItem;
    if (isDesktop && this.mobileView) {
      this.mobileView = false;
      reload = true;
    }
    if (!isDesktop && !this.mobileView) {
      this.mobileView = true;
      reload = true;
    }
    if (reload) {
      this.loadAnimation();
    }
  }

  loadAnimation(): void {
    const options: Lottie.AnimationConfig = {
      container: this.animationContainer.nativeElement,
      renderer: this.options.renderer || 'svg',
      loop: this.options.loop || false,
      autoplay: this.options.autoplay || false,
      autoloadSegments: false,
      path: this.options.path,
      rendererSettings: {},
    };
    if (this.animItem) {
      this.animItem.destroy();
    }
    this.animItem = lottie.loadAnimation(options);

    let domLoadedSubscribtion = fromEvent(this.animItem, 'DOMLoaded').subscribe(() => {
      this.changeDetectorRef.detectChanges();
      domLoadedSubscribtion.unsubscribe();
      domLoadedSubscribtion = (null as unknown) as Subscription;
    });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AGENCY_TYPE } from '../../../../services/agencies.type';

@Component({
  selector: 'app-agency-pin',
  template: ` <div [class]="pinCss"><ng-content></ng-content></div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyPinComponent {
  @Input()
  agencyType: AGENCY_TYPE = 'BAP';

  get pinCss(): string {
    switch (this.agencyType) {
      case 'BP': {
        return 'agency-map-marker-private-bank';
      }
      case 'PRO': {
        return 'agency-map-marker-pro-bank';
      }
      default: {
        return 'agency-map-marker-public-bank';
      }
    }
  }
}

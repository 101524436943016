import { Image } from '@repo/shared';

const PDF_EXTENSION = 'pdf';

export function isPdf(filePath: string): boolean {
  return typeof filePath === 'string' && filePath.split('.').pop() === PDF_EXTENSION;
}

export function getFileNameFromURL(url: string): string {
  return url.substr(url.lastIndexOf('/') + 1);
}

export function getImageTag(image: Image): string {
  return image && (image.alt || getFileNameFromURL(image.url));
}

import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectionStrategy,
  forwardRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';

import { AbstractFormComponent } from '../abstract-form-component';
import { SliceWithData } from '../../../../../typings';
import { AssistanceFormSlice } from '@repo/shared';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../tokens';
import {
  ASSISTANCE_CONTACT_FORM,
  ASSISTANCE_EQUIPEMENTS_FORM,
  ASSISTANCE_REQUEST_FORM,
} from '@repo/shared/form-schemas/assistance-client';
import { SliceFormsService } from '../slice-forms.service';
import { DOMService } from '../../../../services/dom.service';
import { LoggerService } from '../../../../logger/logger.service';
import { Logger } from '../../../../logger/logger';
import { FormGroup } from '@angular/forms';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'app-slice-assistance',
  template: `
    <div id="assistance">
      <form id="regForm" [formGroup]="assistanceForm">
        <cb-mini-title class="mini-title" *ngIf="data.title" [title]="data.title" [withFrame]="false"></cb-mini-title>
        <cdk-stepper #stepper linear>
          <cdk-step formGroupName="identity" [stepControl]="assistanceForm.get('identity')">
            <assistance-identity [regForm]="assistanceForm.get('identity')"></assistance-identity>
          </cdk-step>
          <cdk-step formGroupName="equipements" [stepControl]="assistanceForm.get('equipements')">
            <assistance-equipements [regForm]="assistanceForm.get('equipements')"></assistance-equipements>
          </cdk-step>
          <cdk-step formGroupName="request" [stepControl]="assistanceForm.get('request')">
            <assistance-request [regForm]="assistanceForm"></assistance-request>
          </cdk-step>
        </cdk-stepper>
      </form>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: ASSISTANCE_CONTACT_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceAssistanceFormComponent),
    },
  ],
})
export class SliceAssistanceFormComponent
  extends AbstractFormComponent
  implements SliceWithData<AssistanceFormSlice>, OnInit {
  @Input() data: AssistanceFormSlice;

  logger: Logger;

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _assistanceFormValidationSchema: JSONSchema7,
    public readonly sliceFormsService: SliceFormsService,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    public readonly domService: DOMService,
    public readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_assistanceFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceAssistanceFormComponent.name);
  }

  assistanceForm: FormGroup;

  ngOnInit(): void {
    this.assistanceForm = new FormGroup({
      identity: this.sliceFormsService.buildForm(ASSISTANCE_CONTACT_FORM),
      equipements: this.sliceFormsService.buildForm(ASSISTANCE_EQUIPEMENTS_FORM),
      request: this.sliceFormsService.buildForm(ASSISTANCE_REQUEST_FORM),
    });
  }
}

import { FORM_COMPONENT } from '../../../../tokens';
import { JSONSchema7 } from 'json-schema';
import { OnInit, Inject, Output, EventEmitter, Input, Directive, Self, HostListener, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractFormComponent } from '../../../cms/slices/slice-forms/abstract-form-component';
import { FileValidator } from './fileValidator';

@Directive({
  selector: 'file-upload-form[fileUploadForm]',
  exportAs: 'fileUploadForm',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'file-upload-form',
  },
})
export class FileUploadDirective implements OnInit {
  @Input() formControlName: string;
  @Input() minFiles = 0;
  @Input() minFilesErrorMessage: string;
  @Input() maxFiles = 5;
  @Input() fileSize = 2; // Mo
  @Input() maxFilesSize = 4; // Mo

  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blurred: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.file-upload-form--invalid')
  get cssClassFileUploadFormInvalid(): boolean {
    return this.status === 'INVALID' && this.touched;
  }

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  get model(): string {
    return this.ngControl.value;
  }

  get status(): string {
    return (this.ngControl.status as unknown) as string;
  }

  get touched(): boolean {
    return (this.ngControl.touched as unknown) as boolean;
  }

  private _validationSchema: JSONSchema7;

  constructor(
    @Inject(FORM_COMPONENT) public readonly _parentSliceFormComponent: AbstractFormComponent,
    @Self() public ngControl: NgControl,
  ) {}

  @HostListener('focus') onFocusEvent(): void {
    this.focused.emit(true);
  }

  @HostListener('blur') onBlurEvent(): void {
    this.onTouched();
    this.blurred.emit(true);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    if (this._parentSliceFormComponent) {
      this._validationSchema = this._parentSliceFormComponent.getFormFieldValidationSchema(this.formControlName);
      this.ngControl.control?.setValidators([
        FileValidator.requiredMinFiles(this.minFiles, this.minFilesErrorMessage),
        FileValidator.requiredFileType(),
        FileValidator.requiredMaxFileSize(this.fileSize),
        FileValidator.requiredMaxFilesSize(this.maxFilesSize),
        FileValidator.requiredMaxFiles(this.maxFiles),
      ]);
      this.ngControl.control?.updateValueAndValidity();
    } else {
      throw Error(
        'You must to create a token injection to define which' +
          ' class implements the abstract SliceFormComponent class.',
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiExpansionPanelModule } from './expansion-panel/expansion-panel.module';
import { FormFieldCheckboxComponent } from './form-field-checkbox/form-field-checkbox.component';
import { FormFieldControlDirective } from './form-field-control/form-field-control.directive';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';
import { FormFieldHintDirective } from './form-field-hint/form-field-hint.directive';
import { FormFieldInputDirective } from './form-field-input/form-field-input.directive';
import { FormFieldRadioComponent, FormFieldRadioGroupDirective } from './form-field-radio/form-field-radio';
import { DatepickerComponent, DatepickerDirective } from './datepicker/datepicker.component';
import {
  FormFieldTimepickerComponent,
  FormFieldTimepickerDirective,
} from './form-field-timepicker/form-field-timepicker';

import { DragDropDirective } from './form-upload-file/drag-drop.directive';
import { FileUploadComponent } from './form-upload-file/form-upload-file.component';
import { FileUploadDirective } from './form-upload-file/form-upload-file.directive';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiPushBlockModule } from './push-block/push-block.module';
import { TimepickerModule, TimepickerConfig, TimepickerActions, TimepickerStore } from 'ngx-bootstrap/timepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormFieldSelectComponent, FormFieldSelectDirective } from './form-field-select/form-field-select';
import { FilterBlockComponent } from './filter-block/filter-block';
import { CommonBehaviorsModule } from '../../cms/common-behaviors/common-behaviors.module';
import { UiTooltipModule } from './tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    UiPushBlockModule,
    UiExpansionPanelModule,
    UiTooltipModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TimepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    CommonBehaviorsModule,
  ],
  declarations: [
    FormFieldErrorComponent,
    FormFieldControlDirective,
    FormFieldHintDirective,
    FormFieldInputDirective,
    FormFieldRadioGroupDirective,
    FormFieldRadioComponent,
    FormFieldCheckboxComponent,
    DatepickerComponent,
    FormFieldTimepickerComponent,
    FormFieldSelectComponent,
    FormFieldTimepickerDirective,
    FormFieldSelectDirective,
    DatepickerDirective,
    FilterBlockComponent,
    DragDropDirective,
    FileUploadComponent,
    FileUploadDirective,
  ],
  exports: [
    FormFieldErrorComponent,
    FormFieldControlDirective,
    FormFieldHintDirective,
    FormFieldInputDirective,
    FormFieldRadioGroupDirective,
    FormFieldRadioComponent,
    FormFieldCheckboxComponent,
    UiExpansionPanelModule,
    UiPushBlockModule,
    UiTooltipModule,
    DatepickerComponent,
    FormFieldTimepickerComponent,
    FormFieldTimepickerDirective,
    FormFieldSelectComponent,
    FormFieldSelectDirective,
    DatepickerDirective,
    FilterBlockComponent,
    DragDropDirective,
    FileUploadComponent,
    FileUploadDirective,
  ],
  entryComponents: [DatepickerComponent],
  providers: [MatDatepickerModule, TimepickerConfig, TimepickerActions, TimepickerStore],
})
export class UiMoleculesModule {}

import { WINDOW } from '../../../services/window.provider';
import { map, bufferCount, takeWhile } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Input, Inject, OnInit, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SliceWithData } from '../../../../typings';
import { SolutionProduct, SolutionProductsSlice } from '@repo/shared';
import { StateService } from '../../../services/state.service';
import { TagCommanderService, TrackEventLabels } from '../../../services/tag-commander.service';
import { getDocumentHeight } from '../../../helpers/dom.helpers';
import { interval, Subscription, Observable } from 'rxjs';

@Component({
  selector: 'slice-solution-products',
  template: `
    <h2 role="section-h2" *ngIf="data && data.title">{{ data.title }}</h2>
    <ui-slideshow-block
      *ngIf="data"
      [fixTopOffset]="fixTopOffsetDesktop"
      [fixTopOffsetDesktop]="fixTopOffsetDesktop"
      inViewport
      (inViewportAction)="onSlidesContainerIntersection($event)"
    >
      <nav *ngIf="isNavShown()" uiSlideshowNav>
        <a
          *ngFor="let solutionProduct of solutionProductsWithAnchor"
          [href]="'#' + solutionProduct.anchor"
          uiSlideshowAnchor
          (click)="onTrackAnchorClickEvent(solutionProduct.anchorLabel)"
          >{{ solutionProduct.anchorLabel }}</a
        >
      </nav>
      <ssp-solution-product
        *ngFor="let solutionProduct of data.solutionProducts; let odd = odd"
        uiSlideshowSlide
        [data]="solutionProduct"
        [right]="odd"
      ></ssp-solution-product>
    </ui-slideshow-block>
  `,
  animations: [
    trigger('enterInOut', [
      state('out', style({ opacity: 0.25 })),
      state('in', style({ opacity: 1 })),
      transition('out -> in', animate('0.4s ease-in-out')),
    ]),
  ],
  styleUrls: ['./slice-solution-products.component.scss'],
})
export class SliceSolutionProductsComponent implements SliceWithData<SolutionProductsSlice>, OnInit, OnDestroy {
  @Input() data: SolutionProductsSlice;

  @HostBinding('@enterInOut') get enterInOutState(): 'in' | 'out' {
    if (this.isNavShown()) {
      return this.isInViewport ? 'in' : 'out';
    } else {
      return 'in';
    }
  }

  @HostBinding('class.with-nav') get cssClassWithNav(): boolean {
    return this.isNavShown();
  }

  isInViewport = false;

  _iframePageHeightSubscription: Subscription;

  get fixTopOffsetDesktop(): number {
    return this.stateService.get().stickyHeaderBottomPosition || 0;
  }

  get solutionProductsWithAnchor(): SolutionProduct[] {
    return this.data && this.data.solutionProducts
      ? this.data.solutionProducts.filter(solutionProduct => solutionProduct.anchor && solutionProduct.anchorLabel)
      : [];
  }

  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(WINDOW) private win: Window,
    private readonly stateService: StateService,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  ngOnInit(): void {
    if (this.win && this.isIframePage()) {
      this._iframePageHeightSubscription = this.sendIframePageHeightToParent();
    }
  }

  ngOnDestroy(): void {
    if (this._iframePageHeightSubscription) {
      this._iframePageHeightSubscription.unsubscribe();
    }
  }

  isIframePage(): boolean {
    return this.win.self !== this.win.top;
  }

  sendIframePageHeightToParent(): Subscription {
    return this.getIframePageHeightObservable().subscribe(([firstHeight, secondHeight, thirdHeight]) => {
      if (firstHeight === secondHeight && secondHeight === thirdHeight) {
        this.win.parent.postMessage({ minHeight: thirdHeight }, '*');
      }
    });
  }

  getIframePageHeightObservable(): Observable<any> {
    return interval(200).pipe(
      map(() => getDocumentHeight(this.doc)),
      bufferCount(3, 1),
      takeWhile(
        ([firstHeight, secondHeight, thirdHeight]) => firstHeight !== secondHeight || secondHeight !== thirdHeight,
        true,
      ),
    );
  }

  isNavShown(): boolean {
    return this.solutionProductsWithAnchor.length > 1;
  }

  onSlidesContainerIntersection(event: { visible: boolean }): void {
    if (!this.isInViewport && event.visible) {
      this.isInViewport = true;
    }
  }

  onTrackAnchorClickEvent(label: string): void {
    this.tagCommanderService.trackEventPage(TrackEventLabels.SolutionProduct + label);
  }
}

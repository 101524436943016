import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Image } from '@repo/shared';
import { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE } from '../../../common/constants/breakpoints';
import { isInternetExplorer } from '../../../helpers/dom.helpers';
import { WINDOW } from '../../../services/window.provider';
import { PICTUREFILL, PicturefillFunction } from '../../../services/picturefill.provider';

@Component({
  selector: 'cb-responsive-img',
  template: `
    <picture #picture [class.mobile-only]="mobileOnly" [class.desktop-only]="desktopOnly">
      <source [attr.srcset]="desktopImage?.url" media="(min-width: {{ desktopBreakpoint }})" />
      <source [attr.srcset]="mobileImage?.url" media="(max-width: {{ mobileBreakpoint }})" />
      <img [attr.alt]="alt" />
    </picture>
  `,
  styleUrls: ['./responsive-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveImgComponent implements OnChanges {
  @Input() desktopImage: Image;
  @Input() mobileImage: Image;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    @Inject(PICTUREFILL) private readonly picturefill: PicturefillFunction,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.desktopImage || changes.mobileImage) {
      this.refreshPicture();
    }
  }

  private refreshPicture(): void {
    if (isInternetExplorer(this.win)) {
      setTimeout(() => {
        this.picturefill({
          reevaluate: false,
        });
      });
    }
  }

  get desktopBreakpoint(): string {
    return BREAKPOINT_DESKTOP + 'px';
  }

  get mobileBreakpoint(): string {
    return BREAKPOINT_MOBILE + 'px';
  }

  get alt(): string {
    return this.mobileImage?.alt || this.desktopImage?.alt || '';
  }

  get mobileOnly(): boolean {
    return !!(!this.desktopImage && this.mobileImage);
  }

  get desktopOnly(): boolean {
    return !!(!this.mobileImage && this.desktopImage);
  }
}

import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-contacts-block',
  encapsulation: ViewEncapsulation.None,
  template: `
    <ui-contacts-block-title *ngIf="title">
      {{ title }}
    </ui-contacts-block-title>
    <ng-content select="[uiContactsBlockTitle], [ui-contacts-block-title], ui-contacts-block-title"> </ng-content>
    <ng-content> </ng-content>
  `,
})
export class UiContactsBlockComponent {
  @HostBinding('class.ui-contacts-block') hostClass = true;
  @HostBinding('class.with-bg')
  get hostClassWithBackground(): string {
    return this.background;
  }

  @HostBinding('style.background-image')
  get hostStyleBackground(): string {
    return this.background && `url(${this.background})`;
  }

  @Input() background: string;
  @Input() title: string;
}

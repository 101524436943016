export interface AgenciesFilter {
  query?: string;
  latitude?: number;
  longitude?: number;
  hasVault?: boolean;
  isOpenSaturday?: boolean;
  isPrivateBank?: boolean;
  isProBank?: boolean;
}

export interface AgenciesData {
  agenciesFilter: AgenciesFilter;
  tagSource: string;
}

export interface AgenciesQueryResult {
  agencies: AgenciesResult;
}

export interface AgencyDetailsQueryResult {
  agency: Agency;
}

export interface AgenciesResult {
  number: number;
  agencies: Agency[];
}

export interface Agency {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  town: string;
  country: string;
  phoneNumber: string;
  fax: string;
  agencyType: AGENCY_TYPE;
  hasVault: boolean;
  latitude: number;
  longitude: number;
  distance: number;
  timezone: string;
  hours: AgencyHours;
  closedDates: ClosedDates[];
  communicationArea: string;
}

export interface AgencyHours {
  monday: HoursDetails;
  tuesday: HoursDetails;
  wednesday: HoursDetails;
  thursday: HoursDetails;
  friday: HoursDetails;
  saturday: HoursDetails;
  sunday: HoursDetails;
}

export interface HoursDetails {
  isOpen: boolean;
  openingHours?: OpeningHours;
}

export interface OpeningHours {
  amOpen?: string;
  amClose?: string;
  pmOpen?: string;
  pmClose?: string;
}

export interface ClosedDates {
  end: string;
  begin: string;
}

export type AGENCY_TYPE = 'BAP' | 'BP' | 'PRO';

export enum TagSources {
  Keyboard = 'Clavier',
  Button = 'Bouton',
  MouseClick = 'Autocompletion',
  Localize = 'Me_localiser',
}

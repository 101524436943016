import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentWithData } from '../../../../typings';
import { MagHeroSlice } from '@repo/shared';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ResponsiveService } from '../../../services/responsive.service';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';

@Component({
  selector: 'slice-mag-hero',
  template: `
    <ng-container *ngIf="data">
      <div class="image desktop-only" [style.background-image]="getBackgroundImageSource(data.image.url)"></div>
      <div class="wrapper">
        <div class="section-title">
          <h1 class="title">{{ data.title }}</h1>
          <div class="image mobile-only" [style.background-image]="getBackgroundImageSource(data.image.mobile.url)">
            <div class="image-bar"></div>
          </div>
          <cb-mini-title
            *ngIf="shouldShowSubtitle()"
            class="mini-title"
            [title]="data.subtitle"
            [withFrame]="showMiniTitleFrame"
          ></cb-mini-title>
        </div>
        <div class="article" *ngFor="let article of data.articles">
          <a
            [attr.href]="article.url"
            class="article-link"
            analyticsOn
            [analyticsLabel]="getAnalyticsLabel(article.title)"
          >
            <span class="article-title">{{ article.title }}</span>
          </a>
          <cb-article-info-bar
            [publishOn]="article.publishOn"
            [readingTime]="article.readingTime"
            [type]="article.type"
          >
          </cb-article-info-bar>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-mag-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceMagHeroComponent implements ComponentWithData, OnInit, OnDestroy {
  @Input() data: MagHeroSlice;

  private _showMiniTitleFrame = true;

  private subscription: Subscription | null = null;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly responsiveService: ResponsiveService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscription = this.responsiveService.isDesktopMatched().subscribe(isDesktop => {
      if (isDesktop === this._showMiniTitleFrame) {
        this._showMiniTitleFrame = !isDesktop;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  get showMiniTitleFrame(): boolean {
    return this._showMiniTitleFrame;
  }

  shouldShowSubtitle(): boolean {
    return !!this.data?.subtitle && !!this.data?.articles && this.data.articles.length !== 0;
  }

  getBackgroundImageSource(src: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${src}')`);
  }

  getAnalyticsLabel(title: string): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${title}`;
  }
}

import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { animationFrameScheduler, MonoTypeOperatorFunction } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { TechnicalErrorComponent } from './cms/page/technical-error/technical-error.component';
import { TECHNICAL_ERROR_ROUTE } from './common/constants/routes';

const routes: Routes = [
  { path: TECHNICAL_ERROR_ROUTE, component: TechnicalErrorComponent },
  { path: '', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule) },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
})
export class AppRoutingModule {
  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    private readonly platform: Platform,
  ) {
    this.consumeScrollEvents();
  }

  // https://angular.io/api/router/ExtraOptions#scrollPositionRestoration
  private consumeScrollEvents(): void {
    if (!this.platform.isBrowser) {
      return;
    }

    this.router.events
      .pipe(
        filter(routerEvent => routerEvent instanceof Scroll),
        nextAnimationFrame(),
      )
      .subscribe((scrollEvent: Scroll) => {
        if (scrollEvent.position) {
          this.viewportScroller.scrollToPosition(scrollEvent.position);
        } else if (isValidAnchorForScrolling(scrollEvent.anchor as string)) {
          this.viewportScroller.scrollToAnchor(scrollEvent.anchor as string);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}

function nextAnimationFrame<T>(): MonoTypeOperatorFunction<T> {
  return debounceTime(0, animationFrameScheduler);
}

function isValidAnchorForScrolling(anchor: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return !!anchor && /^[A-Za-z][\w\-]*$/.test(anchor);
}

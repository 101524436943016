import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractFormComponent } from '../../../cms/slices/slice-forms/abstract-form-component';
import { FORM_COMPONENT } from '../../../../tokens';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

@Directive({
  selector: 'input[formFieldInput], textarea[formFieldInput], re-captcha[formFieldInput]',
  exportAs: 'formFieldInput',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-input',
    '[class.form-field-input--multiline]': '_isTextarea',
  },
})
export class FormFieldInputDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() formControlName: string;

  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blurred: EventEmitter<boolean> = new EventEmitter<boolean>();

  get minlength(): number {
    return this._minlength;
  }

  // See HTML5 spec
  @Input('minlength')
  @HostBinding('attr.minlength')
  set minlength(value: number) {
    if (value) {
      this._minlength = coerceNumberProperty(value);
    }
  }

  get maxlength(): number {
    return this._maxlength;
  }

  @Input('maxlength')
  @HostBinding('attr.maxlength')
  set maxlength(value: number) {
    if (value) {
      this._maxlength = coerceNumberProperty(value);
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input('required')
  @HostBinding('attr.required')
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  get model(): string {
    return this.ngControl.value;
  }

  @Input('autocorrect') @HostBinding('attr.autocorrect') private _autocorrect = 'off'; // tslint:disable-line:no-input-rename
  @Input('autocapitalize') @HostBinding('attr.autocapitalize') private _autocapitalize = 'off'; // tslint:disable-line:no-input-rename
  @Input('spellcheck') @HostBinding('attr.spellcheck') private _spellcheck = 'false'; // tslint:disable-line:no-input-rename

  private _isTextarea: boolean;
  private _subscription: Subscription = new Subscription();
  private _minlength: number;
  private _maxlength: number;
  private _required = false;
  private _validationSchema: JSONSchema7;

  constructor(
    @Inject(FORM_COMPONENT) public readonly _parentSliceFormComponent: AbstractFormComponent,
    @Self() public readonly ngControl: NgControl,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef,
  ) {}

  @HostListener('focus') onFocusEvent(): void {
    this.focused.emit(true);
  }

  @HostListener('blur') onBlurEvent(): void {
    this.onTouched();
    this.blurred.emit(true);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    if (this._parentSliceFormComponent) {
      this._validationSchema = this._parentSliceFormComponent.getFormFieldValidationSchema(this.formControlName);
    } else {
      throw Error(
        'You must to create a token injection to define which' +
          ' class implements the abstract SliceFormComponent class.',
      );
    }
  }

  ngAfterViewInit(): void {
    this._updateFieldType();

    if (this.validationSchema) {
      this._bindToAttributes();
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }

  _bindToAttributes(): void {
    const {
      maxLength,
      minLength,
      // pattern,
    } = this.validationSchema;
    this.maxlength = maxLength as number;
    if ((minLength as number) > 0) {
      this.minlength = minLength as number;
      this.required = true;
    }

    this._changeDetectorRef.detectChanges();
  }

  get charCountLabel(): string {
    if (this.maxlength) {
      let charCount: number = this.maxlength;
      if (this.model) {
        charCount -= this.model.length;
      }
      const isPlural = charCount > 1;
      return `${charCount} caractère${isPlural ? 's' : ''} restant${isPlural ? 's' : ''}`;
    }
    return '';
  }

  private _updateFieldType(): void {
    if (this._elementRef) {
      this._isTextarea = this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentWithData } from '../../../../typings';
import { Argument, ArgumentsSlice } from '@repo/shared';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';
import { extractTextContent } from '../../../helpers/rich-text.helpers';

@Component({
  selector: 'slice-arguments',
  template: `
    <div class="wrapper" [class.hasBackgroundColor]="data.hasBackgroundColor">
      <cb-mini-title
        *ngIf="data.title"
        class="mini-title"
        [title]="data.title"
        [withFrame]="!isFirstSlice && !data.hasBackgroundColor"
      ></cb-mini-title>
      <dl>
        <div class="arguments" *ngFor="let argumentItem of arguments">
          <a
            [attr.href]="argumentItem.link.url"
            [attr.target]="argumentItem.link.target"
            analyticsOn
            [analyticsLabel]="getAnalyticsLabel(argumentItem.text)"
          >
            <dt>
              <span class="frame">
                <img [attr.src]="argumentItem.image.url" [attr.alt]="argumentItem.image.alt" />
              </span>
            </dt>
            <dd *ngIf="argumentItem.subtitle" class="subtitle">
              <span class="label" [innerHTML]="argumentItem.subtitle"></span>
            </dd>
            <dd *ngIf="argumentItem.text" class="description" [innerHTML]="argumentItem.text"></dd>
          </a>
        </div>
      </dl>
      <cb-cta-btn-link
        *ngIf="data.button && data.button.url && data.button.label"
        class="cta"
        [button]="data.button"
        [sliceType]="data.type"
      >
      </cb-cta-btn-link>
    </div>
  `,
  styleUrls: ['./slice-arguments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceArgumentsComponent implements ComponentWithData {
  @Input() data: ArgumentsSlice;
  @Input() isFirstSlice: boolean;

  get arguments(): Argument[] {
    return this.data?.argumentItems || [];
  }

  getAnalyticsLabel(linkLabel: string): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${extractTextContent(linkLabel)}`;
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsSubPaths, TagCommanderService } from '../../services/tag-commander.service';

@Directive({
  selector: '[analyticsOn]',
})
export class AnalyticsOnDirective {
  @Input() analyticsLabel: string;
  @Input() analyticsSubPath: AnalyticsSubPaths;
  @Input() analyticsActive = true;

  constructor(private readonly tagCommanderService: TagCommanderService) {}

  @HostListener('click')
  onClick(): void {
    if (!this.analyticsActive) {
      return;
    }

    this.tagCommanderService.trackClickNavigationEvent(this.analyticsLabel, this.analyticsSubPath);
  }
}

import { Component, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';
import { ComponentWithData } from '../../../../typings';
import { SectionTextSlice } from '@repo/shared';

@Component({
  selector: 'slice-section-text',
  template: `
    <ng-container *ngIf="data">
      <cb-mini-title class="title" *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <div class="section-text">
        <div class="icon" *ngIf="data.image">
          <img [attr.alt]="data.image.alt" [attr.src]="data.image.url" />
        </div>
        <h2 class="subtitle">{{ data.subtitle }}</h2>
        <div *ngIf="data.description" class="description" [innerHTML]="data.description"></div>
        <cb-cta-link
          *ngIf="data.link"
          class="cta-link"
          type="product-page"
          [sliceType]="data.type"
          [link]="data.link"
        ></cb-cta-link>
        <cb-cta-btn-link
          *ngIf="data.button && !data.isLandingPageIframe"
          class="cta-button"
          [button]="data.button"
          [sliceType]="data.type"
        ></cb-cta-btn-link>
        <cb-cta-btn-link
          *ngIf="data.button && data.isLandingPageIframe"
          class="cta-button"
          [button]="{ label: data.button.label, type: data.button.type }"
          [buttonWithoutLink]="true"
          (click)="followLink()"
          [sliceType]="data.type"
        ></cb-cta-btn-link>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-section-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceSectionTextComponent implements ComponentWithData {
  @Input() data: SectionTextSlice;

  isFirstSlice?: boolean;

  @HostBinding('class.with-icon') get cssClassWithIcon(): boolean {
    return !!this.data && !!this.data.image && !!this.data.image.url;
  }

  followLink(): void {
    window.parent.postMessage({ link: this.data.button?.url }, '*');
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FooterAccordion, Link, LinkWithImage } from '@repo/shared';
import { LoggerService } from '../../../logger/logger.service';
import { Logger } from '../../../logger/logger';
import { FooterService } from '../footer.service';

@Component({
  selector: 'app-footer',
  template: `
    <ui-footer
      [socialNetworks]="socialNetworks"
      [accordions]="accordions"
      [bottomBarLinks]="bottomBarLinks"
      [isBottomBarLinksUnderlinedOnHover]="isBottomBarLinksUnderlinedOnHover"
      [bottomBarLinksColor]="bottomFooterColor"
    >
    </ui-footer>
  `,
})
export class FooterComponent implements OnInit, OnDestroy {
  socialNetworks: LinkWithImage[] = [];
  accordions: FooterAccordion[] = [];
  bottomBarLinks: Link[] = [];
  bottomFooterColor: string;
  isBottomBarLinksUnderlinedOnHover: boolean;

  private logger: Logger;

  constructor(private readonly footerService: FooterService, private readonly loggerService: LoggerService) {
    this.logger = this.loggerService.get(FooterComponent.name);
  }

  private querySubscription: Subscription;

  ngOnInit(): void {
    this.querySubscription = this.footerService.getFooter().subscribe(
      footer => {
        this.socialNetworks = footer.socialNetworks;
        this.accordions = footer.accordions;
        this.bottomBarLinks = footer.bottomBarLinks;
        this.bottomFooterColor = footer.bottomBarTextColor;
      },
      error => {
        if (this.isFirstLoad()) {
          throw error;
        }
        this.logger.warn(`Could not get footer: ${error}`);
      },
    );
  }

  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  private isFirstLoad(): boolean {
    return !this.socialNetworks.length && !this.accordions.length && !this.bottomBarLinks.length;
  }
}

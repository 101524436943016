import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { SliceZoneComparativeComponent } from './slice-zone-comparative.component';
import { TableauComparatifComponent } from './tableau-comparatif/tableau-comparatif.component';
import { Zone1TableauComparatifComponent } from './zone1-tableau-comparatif/zone1-tableau-comparatif.component';
import { ListePictosComponent } from './liste-pictos/liste-pictos.component';
import { ZonePromoComponent } from './zone-promo/zone-promo.component';
import { ZonePrixComponent } from './zone-prix/zone-prix.component';
import { ZoneListeAvantagesComponent } from './zone-liste-avantages/zone-liste-avantages.component';
import { ZoneDescriptionTableauComponent } from './zone-description-tableau/zone-description-tableau.component';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule],
  declarations: [
    SliceZoneComparativeComponent,
    TableauComparatifComponent,
    Zone1TableauComparatifComponent,
    ListePictosComponent,
    ZonePromoComponent,
    ZonePrixComponent,
    ZoneListeAvantagesComponent,
    ZoneDescriptionTableauComponent,
  ],
  exports: [SliceZoneComparativeComponent],
  entryComponents: [SliceZoneComparativeComponent],
})
export class SliceZoneComparativeModule {}

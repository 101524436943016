import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { ProjectBlock, SliceType } from '@repo/shared';
import { BREAKPOINT_MOBILE } from '../../../../common/constants/breakpoints';

@Component({
  selector: 'spbc-project-block',
  template: `
    <div [id]="block.anchor" [ngClass]="{ small: block.size === 'small' }">
      <div ng-if="block.image">
        <cb-scalable-img class="desktop-only" [image]="block.image"></cb-scalable-img>
        <cb-responsive-img [mobileImage]="block.image?.mobile"></cb-responsive-img>
      </div>
      <div class="aside">
        <h2 class="title">{{ block.title }}</h2>
        <div class="description" [innerHTML]="block.richText"></div>
        <div class="button" *ngIf="block.button">
          <cb-cta-btn-link [button]="block.button" [sliceType]="parentSliceType"></cb-cta-btn-link>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./project-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectBlockComponent {
  @Input() block: ProjectBlock;
  @Input() even: boolean;
  @Input() parentSliceType: SliceType;

  readonly mobile = `${BREAKPOINT_MOBILE}px`; // https://github.com/angular/angular/issues/13947

  protected _right: boolean;

  @HostBinding('attr.id')
  get anchor(): string | null {
    return this.block?.anchor || null;
  }

  @HostBinding('class.right')
  get cssClassEven(): boolean {
    return this.right;
  }

  get right(): boolean {
    return this._right;
  }

  @Input() set right(value: boolean) {
    this._right = coerceBooleanProperty(value);
  }
}

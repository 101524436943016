import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TimelineStep } from '@repo/shared';

@Component({
  selector: 'st-mobile-timeline-steps',
  template: `
    <div *ngIf="steps">
      <div class="step" *ngFor="let step of steps">
        <img
          class="step-icon"
          [class.only-image]="!hasText(step)"
          [attr.src]="step.mobileImage.url"
          [attr.alt]="step.mobileImage.alt"
        />
        <div class="step-link">
          <a *ngIf="hasText(step)" class="link" [attr.href]="step.link?.url" [attr.target]="step.link?.target">
            <h4 *ngIf="step.title">{{ step.title }}</h4>
            <div *ngIf="step.description" class="step-description" [innerHtml]="step.description"></div>
            <span *ngIf="step.link?.url" class="ic ic-arrow"></span>
          </a>
          <img
            *ngIf="step.mobileAdditionalImage?.url"
            class="step-mobile-image"
            [attr.src]="step.mobileAdditionalImage.url"
            [attr.alt]="step.mobileAdditionalImage.alt"
          />
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./mobile-timeline-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTimelineStepsComponent {
  @Input() steps: TimelineStep[];

  hasText(step: TimelineStep): boolean {
    return !!(step.title || step.description);
  }
}

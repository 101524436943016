import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UnsupportedBrowserComponent } from './unsupported-browser.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UnsupportedBrowserComponent],
  exports: [UnsupportedBrowserComponent],
})
export class UnsupportedBrowserModule {}

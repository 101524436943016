import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import groupby = require('lodash.groupby');
import flatten = require('lodash.flatten');

import { BlocSizeType, Link, LinkWithDescription, Megamenu, MegamenuItemType } from '@repo/shared';
import { AnalyticsSubPaths, TagCommanderService } from '../../../../services/tag-commander.service';

export interface GridItemsByColumn {
  columnsCount: number;
  itemsPerColumn: number;
}

@Component({
  selector: 'app-desktop-header-menu-item',
  template: `
    <div class="flex-container" [class.secondary]="secondary" [class.megamenuShown]="megamenuShown">
      <a
        *ngIf="link"
        class="head"
        analyticsOn
        (click)="itemClickHandler(link.url, link.label)"
        [attr.href]="link.url"
        [attr.target]="link.target"
        >{{ link.label }}
        <div class="arrow-icon" [class.menuHovered]="megamenuShown" [class.hideArrowIcon]="!hasMegamenu"></div
      ></a>
    </div>
    <div
      *ngIf="hasMegamenu"
      class="megamenu toggle"
      [class.active]="megamenuShowable && megamenuShown"
      [class.inStickyHeader]="inStickyHeader"
    >
      <div class="megamenu-wrapper">
        <ul
          class="grid-container"
          [class.with-1-column]="columnsCount === 1"
          [class.with-2-column]="columnsCount === 2"
          [class.with-3-column]="columnsCount === 3"
        >
          <li class="grid-row" *ngFor="let item of megamenuItems">
            <cb-cta-link
              *ngIf="item"
              class="item"
              type="landing-universe"
              [link]="item"
              (click)="onMouseLeave()"
              [analyticsLabel]="item.label"
              [analyticsSubPath]="analyticsSubPath"
            >
              <p>{{ item.description }}</p>
            </cb-cta-link>
          </li>
        </ul>
      </div>
      <div *ngIf="hasMegamenuOffer" class="offer" [class.offerLarge]="isOfferLarge"></div>
      <div *ngIf="hasMegamenuPromoDoc" class="mag">
        <cb-cta-link
          class="item"
          type="product-page"
          [link]="megamenuPromoDoc"
          (click)="onMouseLeave()"
          [analyticsLabel]="megamenuPromoDoc?.label"
          [analyticsSubPath]="analyticsSubPath"
        >
          <p>{{ megamenuPromoDoc?.description }}</p>
        </cb-cta-link>
      </div>
    </div>
  `,
  styleUrls: ['./desktop-header-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopHeaderMenuItemComponent {
  @Input() link: Link;
  @Input() megamenu: Megamenu;
  @Input() secondary = false;
  @Input() inStickyHeader = false;
  @Input() megamenuShowable = true;

  megamenuShown = false;
  analyticsSubPath = AnalyticsSubPaths.HeaderMegaMenu;

  get hasMegamenu(): boolean {
    return !(this.link && this.link.url) && this.megamenu && this.megamenu.items && this.megamenu.items.length > 0;
  }

  get hasMegamenuOffer(): boolean {
    return (
      this.megamenu.offerSize &&
      (this.megamenu.offerSize === BlocSizeType.Large || this.megamenu.offerSize === BlocSizeType.Small)
    );
  }

  get hasMegamenuPromoDoc(): boolean {
    return this.megamenuPromoDoc !== null;
  }

  get columnsCount(): number {
    return this.computeMegamenuItemsPerColumn(this.megamenu).columnsCount;
  }

  get megamenuItems(): LinkWithDescription[] {
    const { columnsCount, itemsPerColumn } = this.computeMegamenuItemsPerColumn(this.megamenu);
    return reorganizeByColumn(this.getItemsByType(), columnsCount, itemsPerColumn);
  }

  get megamenuPromoDoc(): LinkWithDescription | undefined {
    return this.megamenu?.promoDoc;
  }

  get isOfferLarge(): boolean {
    return this.megamenu.offerSize === BlocSizeType.Large;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  getItemsByType(type: MegamenuItemType = MegamenuItemType.LandingUnivers): LinkWithDescription[] {
    if (!this.hasMegamenu) {
      return [];
    }
    return groupby(this.megamenu.items, (item: LinkWithDescription) => item.type)[type] || [];
  }

  @HostListener('mouseenter')
  onMouseOver(): void {
    if (this.hasMegamenu) {
      this.megamenuShown = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.megamenuShown = false;
    this.changeDetectorRef.detectChanges();
  }

  computeMegamenuItemsPerColumn(megamenu: Megamenu): GridItemsByColumn {
    let columnsCount = 3;
    let itemsPerColumn = 2;
    const itemsLength: number = megamenu.items.length;

    switch (megamenu.offerSize) {
      case BlocSizeType.Large:
        columnsCount = 1;
        if (itemsLength >= 3) {
          itemsPerColumn++;
        }
        break;
      case BlocSizeType.Small:
        columnsCount = 2;
        if (itemsLength >= 5) {
          itemsPerColumn++;
        }
        break;
    }

    if (megamenu.items.some(item => item.type === MegamenuItemType.HomeMag)) {
      columnsCount = 2;
      if (itemsLength >= 5) {
        itemsPerColumn++;
      }
    }

    return {
      columnsCount,
      itemsPerColumn,
    };
  }

  itemClickHandler(): void {
    this.tagCommanderService.trackClickNavigationEvent(this.link.label as string);
  }
}

export function reorganizeByColumn<T>(items: T[], columns: number, itemPerColumn: number): T[] {
  if (!items || !items.length) {
    return [];
  }

  const result: T[][] = new Array(itemPerColumn);

  for (let i = 0; i < itemPerColumn; i++) {
    result[i] = new Array(columns);
  }

  let x = 0;
  let y = 0;

  items.slice(0, columns * itemPerColumn).forEach((item: T) => {
    result[x][y] = item;
    x = x + 1;
    if (x + 1 > itemPerColumn) {
      y = y + 1;
      x = 0;
    }
  });

  return flatten(result);
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { ConfigService } from '../../../../../app/config.service';
import { Agency } from '../../../../services/agencies.type';
import { HereMapService } from '../../../../services/here-map.service';
import { Leaflet as L } from '../../../../vendors/leaflet';
import { formatPhoneNumber } from '../slice-agency.helper';

const DEFAULT_LAT = 48.856614;
const DEFAULT_LNG = 2.3522219;
const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-agency-map',
  template: '',
  styleUrls: ['./agency-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyMapComponent implements OnChanges {
  @Input()
  agencies: Agency[];

  @Input()
  showPinNumber = true;

  private latitude = DEFAULT_LAT;
  private longitude = DEFAULT_LNG;

  private mapElement: HTMLDivElement;

  private map: L.Map;

  private readonly appointmentLinkHref: string;

  constructor(
    private readonly configService: ConfigService,
    private readonly hereMapService: HereMapService,
    private readonly rootElement: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone,
  ) {
    this.appointmentLinkHref = this.configService.get('CONNECTION_URL_APPOINTMENT');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.agencies) {
      this.displayMapAndMarkers();
    }
  }

  public displayMapAndMarkers(): void {
    if (!L) {
      return;
    }

    this.removeMapElement();

    if (!this.agencies || !this.agencies.length) {
      return;
    }

    this.setPosition();

    this.insertMapElement();

    this.ngZone.runOutsideAngular(() => {
      this.initMapContent();
      this.dropMarkers();
    });
  }

  private setPosition(): void {
    const [firstAgency] = this.agencies;
    if (firstAgency) {
      this.latitude = firstAgency.latitude;
      this.longitude = firstAgency.longitude;
    } else {
      this.latitude = DEFAULT_LAT;
      this.longitude = DEFAULT_LNG;
    }
  }

  private removeMapElement(): void {
    if (this.mapElement) {
      this.renderer.removeChild(this.rootElement.nativeElement, this.mapElement);
    }
  }

  private insertMapElement(): void {
    this.mapElement = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(this.mapElement, 'agency-map-container');

    this.renderer.appendChild(this.rootElement.nativeElement, this.mapElement);
  }

  public initMapContent(): void {
    const center: [number, number] = [this.latitude, this.longitude];

    this.map = L.map(this.mapElement, {
      center,
      zoom: DEFAULT_ZOOM,
      zoomControl: true,
    });

    const mapLayersTitle = { default: 'Par défaut', satellite: 'Satellite' };
    const mapLayers = {
      [mapLayersTitle.default]: L.tileLayer(this.hereMapService.getMapLayer('base', 'normal', true)),
      [mapLayersTitle.satellite]: L.tileLayer(this.hereMapService.getMapLayer('aerial', 'hybrid', true)),
    };
    this.map.addControl(new L.Control.Layers(mapLayers, {}));
    this.map.addLayer(mapLayers[mapLayersTitle.default]);
  }

  private dropMarkers(): void {
    this.agencies.map((agency, index) => this.dropMarker(agency, index));
  }

  public dropMarker(agency: Agency, index: number): void {
    if (agency.latitude && agency.longitude) {
      const icon = L.divIcon({
        className: this.getMarkerClassName(agency),
        iconSize: [30, 40],
        html: this.showPinNumber ? (index + 1).toString() : '',
      });
      const marker = L.marker([agency.latitude, agency.longitude], { icon });
      const popupContent = this.buildPopupContent(agency);
      marker.bindPopup(popupContent).openPopup();
      marker.addTo(this.map).on('click', event => this.map.setView(event.target.getLatLng(), DEFAULT_ZOOM));
    }
  }

  private getMarkerClassName(agency: Agency): string {
    switch (agency.agencyType) {
      case 'BP': {
        return 'agency-map-marker-private-bank';
      }
      case 'PRO': {
        return 'agency-map-marker-pro-bank';
      }
      default: {
        return 'agency-map-marker-public-bank';
      }
    }
  }

  private buildPopupContent(agency: Agency): string {
    return `
      <div class="agency-map-popup">
        <div class="agency-map-popup-name">${agency.name}</div>
        <div class="agency-map-popup-address">${agency.address}<br> ${agency.postalCode} ${agency.town}</div>
        <div class="agency-map-popup-phone">Tél : ${formatPhoneNumber(agency.phoneNumber)}</div>
        <a class="agency-map-popup-appointment" href="${this.appointmentLinkHref}">Déjà client LCL ? Prendre RDV</a>
      </div>
    `;
  }
}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Page, PushBanner } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { StateService } from '../../../services/state.service';
import { TagCommanderService } from '../../../services/tag-commander.service';

import { ArticleComponent } from '../pages/article/article/article.component';
import { CmsFaqLandingComponent } from '../pages/faq/faq-landing.component';
import { SimplePageComponent } from '../pages/simple-page/simple-page.component';
import { SlicesComponent } from '../../slices/slices/slices.component';
import { UiStickyBottomDirective } from '../../../ui/atoms/sticky/sticky-bottom.directive';

export const TAG_COMMANDER_LABEL_PREFIX = 'ProduitBandeauSticky_';
export const TAG_COMMANDER_LABEL_CLOSE = TAG_COMMANDER_LABEL_PREFIX + 'Fermeture';

@Component({
  selector: 'dynamic-page',
  template: `
    <ng-container #viewContainer></ng-container>

    <cb-push-banner
      #sticky="uiStickyBottom"
      *ngIf="pushBanner"
      uiStickyBottom
      [enable]="isPushBannerOpened()"
      [spot]="heroElement"
      (close)="onPushBannerClose()"
    >
      <span class="title">{{ pushBanner.title }}</span>
      <cb-cta-btn-link
        [button]="{ label: pushBanner.link.label, url: pushBanner.link.url, type: 'push-banner' }"
        [analyticsLabel]="getPushBannerLinkAnalyticsLabel()"
        [attr.target]="pushBanner.link.target"
        analyticsOn
      ></cb-cta-btn-link>
    </cb-push-banner>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPageComponent implements AfterContentChecked, OnDestroy, OnChanges {
  @Input() page: Page;

  @ViewChild('viewContainer', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;
  @ViewChild('sticky', { read: UiStickyBottomDirective, static: false }) sticky: UiStickyBottomDirective;

  heroElement: HTMLElement | null;
  pushBanner: PushBanner;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly stateService: StateService,
    private readonly tagCommanderService: TagCommanderService,
  ) {}

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.pushBanner = (null as unknown) as PushBanner;
      this.heroElement = (null as unknown) as HTMLElement;
      this.viewContainerRef.clear();

      this.createPageComponent(this.page);
    }
  }

  ngAfterContentChecked(): void {
    this.heroElement = this.elementRef.nativeElement.querySelector('slice-hero, slice-super-hero');
    this.changeDetectorRef.markForCheck();
    this.sticky?._refresh(true);
  }

  createPageComponent(page: Page): void {
    let factory = (null as unknown) as ComponentFactory<ComponentWithData>;
    let data: any;

    switch (page.type) {
      case 'home_agence':
      case 'home_agence_details':
      case 'homepage_institutional':
      case 'homepage_particuliers':
      case 'homepage_mag':
      case 'landing_universe':
      case 'landing_page_event':
      case 'page_produit_gal':
      case 'landing_univers_gal':
      case 'product':
      case 'newsroom_institutional':
      case 'simulators_and_estimations':
      case 'interstitial':
      case 'form':
      case 'landing_page_campaign':
        factory = this.componentFactoryResolver.resolveComponentFactory(SlicesComponent);
        data = page.data.pageContent?.body;
        break;
      case 'page_simple':
      case 'page_simple_institutional':
      case 'simulator_encapsulated':
      case 'contact':
      case 'sitemap':
      case 'thematic_faq':
      case 'thematic_faq_free':
      case 'landing_page_step':
      case 'page_timeline':
      case 'mag_thematic_page':
        factory = this.componentFactoryResolver.resolveComponentFactory(SimplePageComponent);
        data = page.data.pageContent;
        break;
      case 'pagefaq':
        factory = this.componentFactoryResolver.resolveComponentFactory(CmsFaqLandingComponent);
        data = page.data.pageContent;
        break;
      case 'article':
        factory = this.componentFactoryResolver.resolveComponentFactory(ArticleComponent);
        data = page.data.pageContent;
        break;
      default:
        break;
    }

    if (factory) {
      const componentRef = this.viewContainerRef.createComponent(factory);
      componentRef.instance.data = data;
      componentRef.instance.type = page.type;

      this.pushBanner = page.data?.pushBanner as PushBanner;
    }
  }

  getPushBannerLinkAnalyticsLabel(): string | null {
    return this.pushBanner?.link?.label ? TAG_COMMANDER_LABEL_PREFIX + this.pushBanner.link.label : null;
  }

  isPushBannerOpened(): boolean {
    return this.page ? !this.stateService.get().pushBannerClosedIds.includes(this.page.id) : false;
  }

  onPushBannerClose(): void {
    if (!this.isPushBannerOpened()) {
      return;
    }

    const { pushBannerClosedIds } = this.stateService.get();

    this.stateService.update({
      pushBannerClosedIds: [...pushBannerClosedIds, this.page.id],
    });

    this.tagCommanderService.trackClickNavigationEvent(TAG_COMMANDER_LABEL_CLOSE);

    this.changeDetectorRef.detectChanges();
  }
}

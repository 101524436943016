import { Component, ElementRef, HostBinding, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { LinkTarget } from '@repo/shared';
import { WINDOW } from './services/window.provider';
import { RouterService } from './services/router.service';
import { ThemeService } from './services/theme.service';
import { ConfigService } from './config.service';

const EXTERNAL_LINK_PATTERN = /^(https?|mailto|tel):/;

@Component({
  selector: 'app-root',
  template: `
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  private readonly NG_VERSION: string = 'ng-version';

  @HostBinding('class') themeClassName;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (event.defaultPrevented || event.ctrlKey || event.metaKey) {
      return;
    }
    const anchor = this.getParentAnchor(event.target as HTMLElement);
    if (anchor) {
      const url = anchor.getAttribute('href');
      if (anchor.target === LinkTarget.Interstitial) {
        event.preventDefault();
        this.router.navigate([], {
          queryParams: { popin: url },
          queryParamsHandling: 'merge',
        });
      } else if (url && anchor.target !== LinkTarget.Blank && this.isAppLink(url)) {
        event.preventDefault();
        this.router.navigateByUrl(url);
      } else if (url && url.endsWith('.pdf')) {
        event.preventDefault();
        this.win.open(url, LinkTarget.Blank);
      } else if (anchor.hash && this.location.path() === anchor.pathname) {
        event.preventDefault();
        this.routerService.scrollById(anchor.hash.replace('#', ''));
      }
    }
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly configService: ConfigService,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly location: Location,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly routerService: RouterService,
    private readonly themeService: ThemeService,
  ) {
    this.themeService.getThemeClass$().subscribe(theme => {
      setTimeout(() => {
        this.themeClassName = theme;
      }, 0);
    });
  }

  ngOnInit(): void {
    if (this.configService.get<boolean>('PROD')) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, this.NG_VERSION);
    }
  }

  private isAppLink(url: string): boolean {
    return !url.match(EXTERNAL_LINK_PATTERN) && url[0] !== '#';
  }

  private getParentAnchor(element: HTMLElement): HTMLAnchorElement | null {
    let anchor = element;
    while (anchor) {
      if (anchor.tagName === 'A') {
        return anchor as HTMLAnchorElement;
      }
      anchor = anchor.parentElement as HTMLAnchorElement;
    }
    return null;
  }
}

import { Component, Inject, Input } from '@angular/core';
import { WINDOW } from '../../../../../services/window.provider';
import { ArticleShareType } from '@repo/shared';

interface ShareBar {
  title: string;
  pdfDownloadLink: string;
  shareList: ArticleShareType[];
}

interface MediaLink {
  active: boolean;
}

interface SocialMediaLink extends MediaLink {
  url: string;
}

@Component({
  selector: 'article-share-bar',
  template: `
    <ul class="share-bar">
      <li *ngIf="print.active">
        <a (click)="printPage()" title="Imprimer">
          <span role="img" aria-label="icone impression" class="ic ic-print"></span>
        </a>
      </li>
      <li *ngIf="download.active">
        <a title="Télécharger" target="_blank" [attr.href]="downloadLink">
          <span role="img" aria-label="icone téléchargement" class="ic ic-download"></span>
        </a>
      </li>
      <hr />
      <li *ngIf="facebook.active">
        <a title="Partager sur Facebook" target="_blank" [attr.href]="facebook.url">
          <span role="img" aria-label="icone facebook" class="ic ic-facebook"></span>
        </a>
      </li>
      <li *ngIf="twitter.active">
        <a title="Partager sur Twitter" target="_blank" [attr.href]="twitter.url">
          <span role="img" aria-label="icone twitter" class="ic ic-tweet"></span>
        </a>
      </li>
      <li *ngIf="linkedin.active">
        <a title="Partager sur Linkedin" target="_blank" [attr.href]="linkedin.url">
          <span role="img" aria-label="icone linkedin" class="ic ic-linkedin"></span>
        </a>
      </li>
      <li *ngIf="mail.active">
        <a title="Partager par mail" [attr.href]="mail.url">
          <span role="img" aria-label="icone mail" class="ic ic-mail"></span>
        </a>
      </li>
    </ul>
  `,
  styleUrls: ['./article-share-bar.component.scss'],
})
export class ArticleShareBarComponent {
  @Input() data = {} as ShareBar;

  get articleTitle(): string {
    return this.data && this.data.title;
  }

  get downloadLink(): string {
    return this.data && this.data.pdfDownloadLink;
  }

  get print(): MediaLink {
    return {
      active: !!this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Print) !== -1,
    };
  }

  get download(): MediaLink {
    return {
      active:
        !!this.downloadLink && this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Download) !== -1,
    };
  }

  get facebook(): SocialMediaLink {
    return {
      active: !!this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Facebook) !== -1,
      url: `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this._url)}`,
    };
  }

  get twitter(): SocialMediaLink {
    return {
      active: !!this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Twitter) !== -1,
      url: `http://twitter.com/share?text=${encodeURIComponent(this.articleTitle)}&url=${encodeURIComponent(
        this._url,
      )}`,
    };
  }

  get mail(): SocialMediaLink {
    return {
      active: !!this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Mail) !== -1,
      url: `mailto:?Subject=LCL%20-%20${encodeURIComponent(this.articleTitle)}&body=Découvrir cet article
 ${encodeURIComponent(this._url)}`,
    };
  }

  get linkedin(): SocialMediaLink {
    return {
      active: !!this.data.shareList && this.data.shareList.indexOf(ArticleShareType.Linkedin) !== -1,
      url: `http://www.linkedin.com/shareArticle?mini=true&source=LCL&url=${encodeURIComponent(this._url)}`,
    };
  }

  private readonly _url: string;

  constructor(@Inject(WINDOW) private readonly win: Window) {
    if (this.win) {
      this._url = this.win.location.href;
    }
  }

  printPage(): void {
    if (this.win) {
      this.win.print();
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { AlternateLanguages, Page, PageFilters, PageType } from '@repo/shared';
import { CMS_PAGES } from '@repo/shared/cms.pages';
import { BackUnavailableError, PageNotFoundError } from '../errors';
import { cmsPageBydId, cmsPageByTypesAndUidQueryString } from '@repo/shared/dynamicQuery.graphql';
import { LoggerService } from '../logger/logger.service';
import { Logger } from '../logger/logger';

export const cmsPageByIdQuery: DocumentNode = gql(cmsPageBydId);
export const cmsPageByTypesAndUidQuery: DocumentNode = gql(cmsPageByTypesAndUidQueryString);

const conversionFunnelPageTypes: PageType[] = [
  'landing_page_campaign',
  'thematic_faq_free',
  'landing_page_event',
  'page_produit_gal',
  'landing_univers_gal',
];

const onlyFooterPageTypes: PageType[] = ['landing_page_event'];

@Injectable()
export class PageService {
  private logger: Logger;

  private _currentPage$ = new ReplaySubject<Page>();
  currentPage$ = this._currentPage$.asObservable();

  constructor(private readonly apollo: Apollo, private readonly loggerService: LoggerService) {
    this.logger = this.loggerService.get(PageService.name);
  }

  getPageById(documentId: string): Observable<Page> {
    return this.apollo
      .query({
        query: cmsPageByIdQuery,
        variables: { documentId },
      })
      .pipe(
        catchError(error => this.catchApolloError(error)),
        pluck('data', 'pageById'),
        tap((page: Page) => this._currentPage$.next(page)),
      );
  }

  getPageByUid(uid: string, pageFilters?: PageFilters, iframe?: boolean): Observable<Page> {
    return this.getPageByTypesAndUid(Object.keys(CMS_PAGES), uid, pageFilters as PageFilters, iframe as boolean).pipe(
      tap(page => this._currentPage$.next(page)),
    );
  }

  getPageByTypesAndUid(types: string[], uid: string, pageFilters: PageFilters, iframe: boolean): Observable<Page> {
    return this.apollo
      .query({
        query: cmsPageByTypesAndUidQuery,
        variables: {
          types,
          uid,
          filters: pageFilters,
          iframe,
        },
      })
      .pipe(
        catchError(error => this.catchApolloError(error)),
        pluck('data', 'pageByTypesAndUid'),
        map(this.computePageFromDocument.bind(this)),
      );
  }

  catchApolloError(error: any): Observable<never> {
    this.logger.error(
      'GraphQL error ' + error.message,
      error.graphQLErrors,
      error.networkError && error.networkError.error,
    );

    return throwError(new BackUnavailableError('Could not get page by type and uid', error));
  }

  computePageFromDocument(page: Page): Page {
    if (!page) {
      throw new PageNotFoundError();
    }
    return {
      ...page,
      alternateLanguages: [
        this.computePageLanguageFromDocument(page),
        ...(page.alternateLanguages as AlternateLanguages[]),
      ],
    };
  }

  // noinspection JSMethodCanBeStatic
  computePageLanguageFromDocument(page: Page): AlternateLanguages {
    return {
      lang: page.lang as string,
      id: page.id,
      type: page.type,
      uid: page.uid,
    };
  }

  isConversionFunnelPage(page: Page): boolean {
    return conversionFunnelPageTypes.includes(page.type);
  }

  isOnlyFooterPage(page: Page): boolean {
    return onlyFooterPageTypes.includes(page.type);
  }
}

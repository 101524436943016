import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HeaderButtonsLinks } from '../../header';

const IC_CHAT = '/assets/images/ic_chat.svg';
const IC_CARD = '/assets/images/ic_card.svg';
const IC_LOCK = '/assets/images/ic_lock.svg';

@Component({
  selector: 'app-mobile-header-opened-bottom-menu',
  template: `
    <div class="header-mobile">
      <div class="line">
        <a
          *ngIf="buttonsLinks.chat"
          [attr.href]="buttonsLinks.chat.url"
          [attr.target]="buttonsLinks.chat.target"
          class="chat"
          [style.background-color]="colorBtnContact"
          [style.color]="labelColorBtnContact"
          (click)="emitClicked()"
        >
          <img [attr.src]="iconBtnContact" width="26" height="26" alt="icone chat" />
          <span>{{ buttonsLinks.chat.label }}</span>
        </a>
        <a
          *ngIf="buttonsLinks.openAccount"
          [attr.href]="buttonsLinks.openAccount.url"
          [attr.target]="buttonsLinks.openAccount.target"
          class="open-account"
          [style.background-color]="colorBtnOpenAccount"
          [style.color]="labelColorBtnOpenAccount"
          (click)="emitClicked()"
        >
          <img [attr.src]="iconBtnOpenAccount" width="23" height="17" alt="icone ouvrir un compte" />
          <span>{{ buttonsLinks.openAccount.label }}</span>
        </a>
        <a
          *ngIf="buttonsLinks.myAccount"
          [href]="buttonsLinks.myAccount.url"
          class="lock"
          [style.background-color]="colorBtnMyAccount"
          [style.color]="labelColorBtnMyAccount"
          (click)="emitClicked()"
        >
          <img [attr.src]="iconBtnMyAccount" width="15" height="20" alt="icone mon espace" />
          <span>{{ buttonsLinks.myAccount.label }}</span>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./mobile-header-opened-bottom-menu.component.scss'],
})
export class MobileHeaderOpenedBottomMenuComponent {
  @Input() buttonsLinks: HeaderButtonsLinks = {};

  @Output() clicked = new EventEmitter<void>();

  icChat: string = IC_CHAT;
  icCard: string = IC_CARD;
  icLock: string = IC_LOCK;

  emitClicked(): void {
    this.clicked.emit();
  }

  get colorBtnContact(): string | undefined {
    return this.buttonsLinks.chat?.buttonLinkData?.mobileBackgroundColor;
  }

  get colorBtnOpenAccount(): string | undefined {
    return this.buttonsLinks.openAccount?.buttonLinkData?.mobileBackgroundColor;
  }

  get colorBtnMyAccount(): string | undefined {
    return this.buttonsLinks.myAccount?.buttonLinkData?.mobileBackgroundColor;
  }

  get labelColorBtnContact(): string | undefined {
    return this.buttonsLinks.chat?.buttonLinkData?.mobileLabelColor;
  }

  get labelColorBtnOpenAccount(): string | undefined {
    return this.buttonsLinks.openAccount?.buttonLinkData?.mobileLabelColor;
  }

  get labelColorBtnMyAccount(): string | undefined {
    return this.buttonsLinks.myAccount?.buttonLinkData?.mobileLabelColor;
  }

  get iconBtnContact(): string | undefined {
    return this.buttonsLinks.chat?.buttonLinkData?.mobileIcon?.url;
  }

  get iconBtnOpenAccount(): string | undefined {
    return this.buttonsLinks.openAccount?.buttonLinkData?.mobileIcon?.url;
  }

  get iconBtnMyAccount(): string | undefined {
    return this.buttonsLinks.myAccount?.buttonLinkData?.mobileIcon?.url;
  }
}

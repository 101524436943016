import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

export const APP_DATE_ADAPTER_PLATFORM_STATIC = {
  isBrowser: true,
  EDGE: false,
  TRIDENT: false,
  BLINK: false,
  WEBKIT: false,
  IOS: false,
  FIREFOX: false,
  ANDROID: false,
  SAFARI: false,
} as Platform;

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor() {
    super('fr', APP_DATE_ADAPTER_PLATFORM_STATIC);
  }

  format(date: Date, displayFormat: string): string {
    let result;
    switch (displayFormat) {
      case 'input': {
        result = this.generateDate(date);
        break;
      }
      case 'inputMonth': {
        const monthNames = [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        result = month + ' ' + year;
        break;
      }
      default: {
        result = date.toDateString();
      }
    }

    return result;
  }

  private generateDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${to2digit(day)}/${to2digit(month)}/${year}`;
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export function to2digit(n: number): any {
  return ('00' + n).slice(-2);
}

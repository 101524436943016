import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { interval, merge, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  InteractOffer,
  InteractOffersResponse,
  InteractPostEventRequest,
  InteractRequest,
  InteractResponse,
  InteractStartRequest,
  InteractStartResponse,
} from '@repo/shared/interact.type';
import { ConfigService } from '../config.service';
import { CookieService } from './cookie.service';
import { InteractOfferWithDetail, InteractOfferWithPostEvent } from './interact.service.type';
import { WINDOW } from './window.provider';

// Info: `ENCRYPTED_ID_REPER_KEY` is also defined in `front/src/interact.js`.
const ENCRYPTED_ID_REPER_KEY = 'cookie_personnalisation_LCL';
const INTERACT_START_TIME_KEY = 'interact_start_time';

@Injectable()
export class InteractService {
  private readonly interactUrl: string;

  private readonly encryptedIdReper: string;

  private readonly interactTimeout = 2000;

  private readonly isInteractEnabled: boolean;

  get isEnabled(): boolean {
    return !!(this.win && this.isInteractEnabled && this.encryptedIdReper);
  }

  private get startTime(): number {
    return +(this.cookieService.get(INTERACT_START_TIME_KEY) as string) || ((undefined as unknown) as number);
  }

  private set startTime(value: number) {
    if (value === null) {
      this.cookieService.delete(INTERACT_START_TIME_KEY);
    } else {
      this.cookieService.set(INTERACT_START_TIME_KEY, value.toString());
    }
  }

  private startInProgress = false;

  private startResponse$ = new Subject<InteractStartResponse>();

  pendingOffersAvailable$ = new Subject<{ eventContext: string; interactionPoint: string }>();

  constructor(
    private readonly configService: ConfigService,
    private readonly cookieService: CookieService,
    private readonly httpClient: HttpClient,
    @Inject(WINDOW) private readonly win: Window,
    @Inject(SESSION_STORAGE) private readonly pendingOffersStorageService: StorageService<InteractOfferWithDetail[]>,
  ) {
    this.interactUrl = `${this.configService.get<string>('API_PUBLIC_URL')}/interact`;
    this.encryptedIdReper = this.cookieService.get(ENCRYPTED_ID_REPER_KEY) as string;
    this.isInteractEnabled = this.configService.get<boolean>('INTERACT_ENABLED');
  }

  getOffers(eventContext: string, interactionPoint: string): Observable<InteractOfferWithPostEvent[]> {
    if (!this.isEnabled) {
      return of([]);
    }

    const pendingOffers = this.getPendingOffers(eventContext, interactionPoint);
    if (pendingOffers) {
      this.removePendingOffers(eventContext, interactionPoint);
      return of(pendingOffers.map(offer => this.mapOfferDetail(offer)));
    }

    const firstValue$ = new Subject<InteractOfferWithPostEvent[]>();

    const offersAndTimeout$ = merge(
      this.offersSafe(eventContext, interactionPoint),
      interval(this.interactTimeout).pipe(
        first(),
        map(() => this.interactTimeout),
      ),
    );

    let once = false;
    offersAndTimeout$.subscribe(value => {
      if (!once) {
        if (typeof value !== 'number') {
          firstValue$.next(value.map(offer => this.mapOfferDetail(offer)));
        } else {
          firstValue$.next([]);
        }
      } else if (typeof value !== 'number' && value.length) {
        this.setPendingOffers(eventContext, interactionPoint, value);
        this.pendingOffersAvailable$.next({ eventContext, interactionPoint });
      }
      once = true;
    });

    return firstValue$.pipe(first());
  }

  private mapOfferDetail(offerDetail: InteractOfferWithDetail): InteractOfferWithPostEvent {
    const { treatmentCode, offerCode, idCms, indTmo, slices } = offerDetail;
    const offer: InteractOffer = { treatmentCode, offerCode, idCms, indTmo, slices };
    const offerWithoutSlices = ({ ...offer, slices: null } as unknown) as InteractOffer;

    const postEvent = (postEventContext: string) =>
      this.event(postEventContext, offerDetail.interactionPoint, offerWithoutSlices);

    return { ...offer, postEvent };
  }

  private offersSafe(eventContext: string, interactionPoint: string): Observable<InteractOfferWithDetail[]> {
    return this.execOperation<InteractOffersResponse>(
      () => this.startSafe(eventContext, interactionPoint),
      () => this.offers(eventContext, interactionPoint),
    ).pipe(
      map(response => {
        if (response && response.success) {
          return response.offers.map(offer => ({ ...offer, eventContext, interactionPoint }));
        }
        return [];
      }),
    );
  }

  private execOperation<T extends InteractResponse>(
    startSafeBuilder: () => Observable<InteractStartResponse>,
    operationBuilder: () => Observable<T>,
  ): Observable<T> {
    if (!this.startTime) {
      return startSafeBuilder().pipe(
        switchMap(startResponse => {
          if (startResponse.success) {
            return operationBuilder();
          }

          return of((null as unknown) as T);
        }),
      );
    } else {
      return operationBuilder().pipe(
        switchMap(response => {
          if (response.success) {
            return of(response);
          }

          if (response.isApiAvailable) {
            this.startTime = (null as unknown) as number;
            return this.execOperation<T>(startSafeBuilder, operationBuilder);
          }

          return of((null as unknown) as T);
        }),
      );
    }
  }

  private startSafe(eventContext: string, interactionPoint: string): Observable<InteractStartResponse> {
    const startBuilder = () => {
      this.startInProgress = true;
      return this.start(eventContext, interactionPoint).pipe(
        map(startResponse => {
          this.startInProgress = false;
          this.startResponse$.next(startResponse);
          return startResponse;
        }),
      );
    };

    return this.startInProgress ? this.startResponse$ : startBuilder();
  }

  private start(eventContext: string, interactionPoint: string): Observable<InteractStartResponse> {
    const request: InteractStartRequest = {
      ...this.buildRequest(eventContext, interactionPoint),
      preserveSession: true,
    };
    return this.httpClient.post<InteractStartResponse>(this.url('start'), request).pipe(
      map(response => {
        this.startTime = response.success ? response.startTime : ((null as unknown) as number);
        return response;
      }),
    );
  }

  private offers(eventContext: string, interactionPoint: string): Observable<InteractOffersResponse> {
    return this.httpClient.post<InteractOffersResponse>(
      this.url('offers'),
      this.buildRequest(eventContext, interactionPoint),
    );
  }

  private event(eventContext: string, interactionPoint: string, offer: InteractOffer): Observable<InteractResponse> {
    const request: InteractPostEventRequest = {
      ...this.buildRequest(eventContext, interactionPoint),
      offer,
    };
    return this.httpClient.post<InteractResponse>(this.url('event'), request);
  }

  private url(path: 'start' | 'offers' | 'event'): string {
    return `${this.interactUrl}/${path}`;
  }

  private buildRequest(eventContext: string, interactionPoint: string): InteractRequest {
    return {
      startTime: this.startTime,
      encryptedIdReper: this.encryptedIdReper as string,
      eventContext,
      interactionPoint,
    };
  }

  private setPendingOffers(eventContext: string, interactionPoint: string, offers: InteractOfferWithDetail[]): void {
    this.pendingOffersStorageService.set(this.pendingOffersKey(eventContext, interactionPoint), offers);
  }

  private getPendingOffers(eventContext: string, interactionPoint: string): InteractOfferWithDetail[] {
    return this.pendingOffersStorageService.get(
      this.pendingOffersKey(eventContext, interactionPoint),
    ) as InteractOfferWithDetail[];
  }

  private removePendingOffers(eventContext: string, interactionPoint: string): void {
    this.pendingOffersStorageService.remove(this.pendingOffersKey(eventContext, interactionPoint));
  }

  private pendingOffersKey(eventContext: string, interactionPoint: string): string {
    return `interactService.pendingOffers.${eventContext}.${interactionPoint}`;
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'zone-prix',
  template: `
    <ng-container *ngIf="this.data.prix_offre">
      <div class="prix-container" [innerHTML]="data.prix_offre"></div>
    </ng-container>
  `,
  styleUrls: ['./zone-prix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ZonePrixComponent {
  @Input() data: any;
}

import { SliceLandingPageEventOfferListModule } from './slice-landing-page-event-offer-list/slice-landing-page-event-offer-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InViewportModule } from 'ng-in-viewport';

import { AnalyticsModule } from '../../analytics/analytics.module';
import { CommonBehaviorsModule } from '../common-behaviors/common-behaviors.module';
import { PipesModule } from '../../pipes/pipes.module';
import { UiMoleculesModule } from '../../ui/molecules/molecules.module';

import { SliceCarouselServicesModule } from './slice-carousel-services/slice-carousel-services.module';
import { SliceContactUsModule } from './slice-contact-us/slice-contact-us.module';
import { SliceContactsPresseModule } from './slice-contacts-presse/slice-contacts-presse.module';
import { SliceFaqAccordionModule } from './slice-faq-accordion/slice-faq-accordion.module';
import { SliceOfferBlockModule } from './slice-offer-block/slice-offer-block.module';
import { SliceProjectBlockCtaModule } from './slice-project-block-cta/slice-project-block-cta.module';
import { SliceSolutionProductsModule } from './slice-solution-products/slice-solution-products.module';
import { SliceTableModule } from './slice-table/slice-table.module';
import { SliceTimelineModule } from './slice-timeline/slice-timeline.module';
import { SliceSocialEmbedsModule } from './slice-social-embeds/slice-social-embeds.module';
import { SlicesComponent } from './slices/slices.component';

import {
  SLICE_COMPONENT_CLASS_BY_TYPE_MAP,
  SLICE_COMPONENT_CLASS_LIST,
  SLICE_COMPONENT_CLASS_STANDALONE_LIST,
} from './slices.defs';
import { SLICE_COMPONENT_CLASS_MAP } from './slices.tokens';
import { SliceFormsModule } from './slice-forms/slice-forms.module';
import { SliceAgencyMapHeroModule } from './slice-agency/slice-agency-map-hero/slice-agency-map-hero.module';
import { SliceAgencyDetailsModule } from './slice-agency/slice-agency-details/slice-agency-details.module';
import { SliceLandingPageEventModule } from './slice-landing-page-event-hero/slice-landing-page-event.module';
import { SliceZoneComparativeModule } from './slice-zone-comparative/slice-zone-comparative.module';

@NgModule({
  imports: [
    CommonModule,
    InViewportModule,
    UiMoleculesModule,
    RouterModule,
    AnalyticsModule,
    CommonBehaviorsModule,
    PipesModule,
    SliceAgencyMapHeroModule,
    SliceAgencyDetailsModule,
    SliceContactsPresseModule,
    SliceFaqAccordionModule,
    SliceContactUsModule,
    SliceCarouselServicesModule,
    SliceFormsModule,
    SliceLandingPageEventModule,
    SliceLandingPageEventOfferListModule,
    SliceProjectBlockCtaModule,
    SliceOfferBlockModule,
    SliceSolutionProductsModule,
    SliceTableModule,
    SliceTimelineModule,
    SliceSocialEmbedsModule,
    UiMoleculesModule,
    SliceZoneComparativeModule,
  ],
  declarations: [SlicesComponent, SLICE_COMPONENT_CLASS_STANDALONE_LIST],
  exports: [SlicesComponent],
  entryComponents: [SlicesComponent, SLICE_COMPONENT_CLASS_LIST],
  providers: [{ provide: SLICE_COMPONENT_CLASS_MAP, useValue: SLICE_COMPONENT_CLASS_BY_TYPE_MAP }],
})
export class SlicesModule {}

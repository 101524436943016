import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, Form } from './form';

export interface Category {
  key: string;
  label: string;
}

export interface EntrepriseContactForm extends Form {
  function: string;
  companyName: string;
  siret: string;
  turnover: string;
}

export const FUNCTION_OPTIONS = [
  'Assistant de direction',
  'Comptable',
  'Contrôleur de gestion',
  'Directeur',
  'Directeur export',
  'Directeur administratif et financier',
  'Directeur des Ressources Humaines',
  'Directeur Général',
  'Directeur Général Adjoint',
  'Expert-comptable',
  'Président',
  'Président Directeur Général',
  'Trésorier',
  'Autre',
];

export const TURNOVER_OPTIONS: string[] = [
  `Inférieur à 7 millions d'euros`,
  `Entre 7 et 50 millions d'euros`,
  `Supérieur à 50 millions d'euros`,
];

export const ENTREPRISE_CONTACT_SUBJECT_CATEGORIES: string[] = [
  'Assurances de votre entreprise ou de son dirigeant',
  'Banque privée et gestion patrimoniale',
  'Commerce international',
  'Devenir client',
  'Epargne salariale',
  `Financement de vos équipements ou d'un projet immobilier`,
  'Financement de vos opérations haut de bilan',
  'Financement de votre trésorerie',
  'Fusions acquisitions',
  'Gestion de vos flux',
  'Gestion du risque de taux ou de change',
  'Placement de votre trésorerie',
  'Réclamation',
  'Transformation et innovation',
];

export const ENTREPRISE_CONTACT_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    function: {
      type: 'integer',
      minimum: 0,
      maximum: FUNCTION_OPTIONS.length - 1,
    },
    companyName: {
      type: 'string',
      minLength: 1,
    },
    siret: {
      type: 'string',
      pattern: '^$|^[0-9]{9}$',
      minLength: 1,
    },
    turnover: {
      type: 'integer',
      minimum: 0,

      maximum: TURNOVER_OPTIONS.length - 1,
    },
    subject: {
      type: 'integer',
      minimum: 0,

      maximum: ENTREPRISE_CONTACT_SUBJECT_CATEGORIES.length - 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'phone',
    'function',
    'companyName',
    'siret',
    'turnover',
    'subject',
    'message',
    'recaptcha',
  ],
};

import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { UiPushBlockItemComponent } from './push-block-item.component';
import { UiPushBlockComponent } from './push-block.component';

@Component({
  selector: 'ui-push-block-group',
  template: `
    <div *ngIf="title" class="title">
      {{ title }}
    </div>
    <div class="ui-push-block-group-content">
      <ng-content select="ui-push-block-item"></ng-content>
    </div>
  `,
  exportAs: 'uiPushBlockGroup',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPushBlockGroupComponent {
  @ContentChildren(UiPushBlockItemComponent) items: QueryList<UiPushBlockItemComponent>;

  @Input() title: string;

  @HostBinding('class') class = 'ui-push-block-group';

  @HostBinding('style.flex-grow')
  get hostStyleBackground(): number {
    return this.itemsCount;
  }

  @HostBinding('style.min-width.%')
  get hostStyleMinWidth(): number {
    return (100 / this.parentBlock.itemsCount) * this.itemsCount;
  }

  get itemsCount(): number {
    return this.items ? this.items.length : 0;
  }

  constructor(private parentBlock: UiPushBlockComponent) {}
}

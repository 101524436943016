import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectionStrategy,
  forwardRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { JSONSchema7 } from 'json-schema';

import { AbstractFormComponent } from '../abstract-form-component';
import { SliceWithData } from '../../../../../typings';
import { PrismicWebLink, Produit, ResiliationFormSlice } from '@repo/shared';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../tokens';
import { SliceFormsService } from '../slice-forms.service';
import { DOMService } from '../../../../services/dom.service';
import { LoggerService } from '../../../../logger/logger.service';
import { TagCommanderService } from '../../../../services/tag-commander.service';
import { Logger } from '../../../../logger/logger';
import { FormGroup } from '@angular/forms';
import {
  RESILIATION_CLAIM_FORM,
  RESILIATION_IDENTITY_FORM,
  RESILIATION_REQUEST_FORM,
} from '@repo/shared/form-schemas/resiliation-client';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'slice-resiliation-form',
  template: `
    <div id="resiliation">
      <form id="regForm" [formGroup]="form">
        <cb-mini-title class="mini-title" [title]="title" [withFrame]="false"></cb-mini-title>
        <cdk-stepper
          #stepper
          linear
          [selectedIndex]="selectedFormStep"
          (selectionChange)="handleFormStepChange($event.selectedIndex)"
        >
          <cdk-step formGroupName="identity">
            <resiliation-identity
              [regForm]="form.get('identity')"
              [formSlice]="data"
              [informations]="informations"
              [selectedIndex]="selectedFormStep"
            ></resiliation-identity>
          </cdk-step>
          <cdk-step formGroupName="claim" [stepControl]="form.get('claim')">
            <resiliation-claim
              [regForm]="form"
              [iframeURL]="iframeURL"
              [productSelected]="productSelected"
              [selectedIndex]="selectedFormStep"
            ></resiliation-claim>
          </cdk-step>
          <cdk-step formGroupName="request">
            <resiliation-request
              [regForm]="form"
              [productSelected]="productSelected"
              [recipt]="data.recipt"
              [selectedIndex]="selectedFormStep"
            ></resiliation-request>
          </cdk-step>
          <cdk-step>
            <summary
              [regForm]="form.value"
              [informations]="informations"
              (goToStep)="handleFormStepChange($event)"
            ></summary>
          </cdk-step>
        </cdk-stepper>
      </form>
      <div>
        <p class="rgpd-text">
          Les données à caractère personnel collectées dans ce formulaire par Crédit Lyonnais S.A. en qualité de
          responsable de traitement sont nécessaires pour la prise en compte de votre demande de résiliation de contrat.
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque, est
          disponible sur la page d'accueil du site lcl.fr
        </p>
      </div>
    </div>
  `,
  styleUrls: ['../slice-forms.scss', './slice-resiliation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RESILIATION_IDENTITY_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceResiliationFormComponent),
    },
  ],
})
export class SliceResiliationFormComponent
  extends AbstractFormComponent
  implements SliceWithData<ResiliationFormSlice>, OnInit {
  @Input() data: ResiliationFormSlice;

  title = 'Resilier un de mes contrats';

  form: FormGroup;

  logger: Logger;

  iframeURL?: SafeResourceUrl | null;
  productSelected?: Produit;
  selectedFormStep = 0;

  informations?: string | null = null;

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly tagCommanderService: TagCommanderService,
    readonly domService: DOMService,
    readonly elementRef: ElementRef,
    readonly loggerService: LoggerService,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceResiliationFormComponent.name);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      identity: this.sliceFormsService.buildForm(RESILIATION_IDENTITY_FORM),
      claim: this.sliceFormsService.buildForm(RESILIATION_CLAIM_FORM),
      request: this.sliceFormsService.buildForm(RESILIATION_REQUEST_FORM),
    });

    this.form.valueChanges.subscribe(values => {
      const typeSelected = this.data.product_types?.find(family => family.type_name === values.identity.productType);
      this.productSelected = typeSelected?.products?.find(
        product => product.product_name === values.identity.productSelected,
      );
      if (typeSelected?.iframe?.link_type === 'Web') {
        this.iframeURL = this.domSanitizer.bypassSecurityTrustResourceUrl((typeSelected.iframe as PrismicWebLink).url);
      }

      this.informations = this.getInformations(this.data, values);
    });
  }

  getInformations(data: ResiliationFormSlice, values: any): string | null {
    const informations = data.product_types
      ?.find(family => family.type_name === values.identity.productType)
      ?.products?.find(productSelected => productSelected.product_name === values.identity.productSelected)
      ?.informations;
    if (informations && this.form.get('identity.isOwner')?.touched) {
      if (values.identity.isOwner) {
        return informations;
      } else {
        return 'Vous devez être le souscripteur du contrat pour pouvoir en demander sa résiliation';
      }
    }
    return null;
  }

  handleFormStepChange(step: number) {
    this.changeStep(step);
    this.callTrackEventPage();
  }

  callTrackEventPage(): void {
    const CURRENT_STEP = this.selectedFormStep + 1;
    const STEP_RECAP = 4;
    const TAG_NAME = CURRENT_STEP === STEP_RECAP ? `formulaire-recapitulatif` : `formulaire_etape_${CURRENT_STEP}`;

    this.tagCommanderService.trackEventPage(TAG_NAME);
  }

  changeStep(step: number) {
    this.selectedFormStep = step;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'cb-dropdown',
  template: `
    <div
      *ngIf="items && items.length"
      (mouseenter)="choicesVisible = true"
      (mouseleave)="choicesVisible = false"
      class="dropdown"
    >
      <div class="selected">
        <p>
          <span>{{ selectedItem }}</span>
          <span class="arrow-icon ic ic-chevron"></span>
        </p>
      </div>
      <div [class.visible]="choicesVisible" class="choices">
        <p *ngFor="let item of itemsWithoutSelected" (click)="triggerSelectItem(item)">{{ item }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent implements OnInit {
  @Input() items: string[];

  @Output() selectItem: EventEmitter<string> = new EventEmitter<string>();

  selectedItem: string;
  choicesVisible = false;

  get itemsWithoutSelected(): string[] {
    return (this.items && this.items.filter(item => item !== this.selectedItem)) || [];
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.items && this.items.length) {
      this.selectedItem = this.items[0];
    }
  }

  private triggerSelectItem(item: string): void {
    this.selectedItem = item;
    this.selectItem.emit(item);

    this.choicesVisible = false;

    this.changeDetectorRef.detectChanges();
  }
}

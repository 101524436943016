import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { tooltipAnimations } from './tooltip.animations';
import { TooltipPosition, tooltipStyle } from './tooltip.types';

export type TooltipVisibility = 'initial' | 'visible' | 'hidden';

@Component({
  selector: 'ui-tooltip-message',
  template: ` <div [ngClass]="styleClass" [@state]="visibility">{{ message }}</div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tooltipAnimations.tooltipState],
  styleUrls: ['./tooltip.component.scss'],
})
export class UiTooltipMessageComponent {
  @Input() message = '';

  @HostBinding('attr.role')
  role = 'tooltip';

  // Forces the element to have a layout in IE and Edge.
  @HostBinding('style.zoom')
  get zoom(): any {
    return this.visibility === 'visible' ? 1 : null;
  }

  styleClass: string[];
  visibility: TooltipVisibility = 'initial';

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

  show(direction: TooltipPosition = 'right', style: tooltipStyle = 'white'): void {
    this.styleClass = [style, direction];
    this.visibility = 'visible';
    this.updateView();
  }

  hide(): void {
    this.visibility = 'hidden';
    this.updateView();
  }

  private updateView(): void {
    this.changeDetectionRef.detectChanges();
  }
}

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiContactsBlockTitle], [ui-contacts-block-title], ui-contacts-block-title',
  exportAs: 'uiContactsBlockTitle',
})
export class UiContactsBlockTitleDirective {
  @HostBinding('class.ui-contacts-block-title') hostClass = true;
  @HostBinding('attr.role') hostRole = 'section-h2';
}

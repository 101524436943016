import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Alignment, TimelineStep } from '@repo/shared';

interface AlignedTimelineStep extends TimelineStep {
  alignment: Alignment;
}

@Component({
  selector: 'st-desktop-timeline-steps',
  template: `
    <div
      *ngFor="let step of alignedSteps"
      class="step"
      [class.align-right]="isRightAligned(step)"
      [style.background-image]="getBackgroundImageStyle(step)"
    >
      <a *ngIf="hasText(step)" class="link" [attr.href]="step.link?.url" [attr.target]="step.link?.target">
        <h4 class="title">
          <span class="label">{{ step.title }}</span>
        </h4>
        <div class="description" [innerHtml]="step.description"></div>
        <span *ngIf="step.link?.url" class="ic ic-arrow"></span>
      </a>
    </div>
  `,
  styleUrls: ['./desktop-timeline-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopTimelineStepsComponent {
  @Input() steps: TimelineStep[];

  get alignedSteps(): AlignedTimelineStep[] {
    if (!this.steps) {
      return [];
    }

    const alignedSteps: AlignedTimelineStep[] = [];
    let alignmentCounter = 0;

    for (const step of this.steps) {
      const alignedStep = { ...step, alignment: Alignment.Left };

      if (this.hasText(step)) {
        alignedStep.alignment = alignmentCounter % 2 === 1 ? Alignment.Right : Alignment.Left;
        alignmentCounter++;
      }

      alignedSteps.push(alignedStep);
    }

    return alignedSteps;
  }

  getBackgroundImageStyle(step: TimelineStep): string {
    if (step.desktopImage && step.desktopImage.url) {
      return `url(${step.desktopImage.url})`;
    }

    return (null as unknown) as string;
  }

  hasText(step: TimelineStep): boolean {
    return !!(step.title || step.description);
  }

  isRightAligned(step: AlignedTimelineStep): boolean {
    return step.alignment === Alignment.Right;
  }
}

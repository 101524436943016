import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { getDocumentHeight } from '../../../helpers/dom.helpers';
import { AUTO_STYLE, AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, Subject, merge, Subscription, interval, animationFrameScheduler, fromEvent } from 'rxjs';
import { map, throttleTime, distinctUntilChanged, mapTo, debounceTime, takeWhile, bufferCount } from 'rxjs/operators';

import { BREAKPOINT_DESKTOP } from '../../../common/constants/breakpoints';
import { WINDOW } from '../../../services/window.provider';
import { DOCUMENT } from '@angular/common';

enum TextExpansionState {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  Normal = 'normal',
  Reset = 'reset',
}

const MAX_HEIGHT_DESKTOP = 345;
const MAX_HEIGHT_MOBILE = 160;

@Component({
  selector: 'cb-block-with-blur',
  template: `
    <div
      #block
      class="block-text"
      [@textExpansion]="{ value: _textExpansionState, params: { textExpansionHeight: _textExpansionHeight } }"
      (@textExpansion.done)="_handleTextExpansionAnimation($event)"
      [innerHTML]="innerHtml"
    ></div>
    <div class="block-mask" *ngIf="textOverSized">
      <div *ngIf="hidden" class="mask"></div>
      <button (click)="toggleHidden()" class="see-detail-button">
        <span class="label">{{ buttonLabel }}</span>
      </button>
    </div>
  `,
  styleUrls: ['./block-with-blur.component.scss'],
  animations: [
    trigger('textExpansion', [
      state('collapsed, expanded', style({ height: '{{ textExpansionHeight }}' }), {
        params: { textExpansionHeight: AUTO_STYLE },
      }),
      state('normal, reset', style({ height: AUTO_STYLE })),
      transition('collapsed => expanded', animate('800ms ease-in')),
      transition('expanded => collapsed', animate('1800ms cubic-bezier(0, 1, 0, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockWithBlurComponent implements AfterViewInit, OnDestroy {
  @Input() innerHtml: string;
  @Input() showButtonLabel = 'Afficher le détail';
  @Input() hideButtonLabel = 'Masquer le détail';

  @ViewChild('block', { static: true }) block: ElementRef<HTMLDivElement>;
  _hidden$ = new BehaviorSubject<boolean>(true);
  _resetDone$ = new Subject<void>();
  _textExpansionState: TextExpansionState = TextExpansionState.Reset;
  _textExpansionHeight = AUTO_STYLE;
  _textHeight = 0;
  _textMaxHeight = 0;
  _monitoringSubscription: Subscription;

  get hidden(): boolean {
    return this._hidden$.getValue();
  }

  get textOverSized(): boolean {
    return (
      this._textExpansionState !== TextExpansionState.Normal && this._textExpansionState !== TextExpansionState.Reset
    );
  }

  get buttonLabel(): string {
    return this.hidden ? this.showButtonLabel : this.hideButtonLabel;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(WINDOW) private win: Window,
    @Inject(DOCUMENT) private readonly doc: Document,
  ) {}

  ngAfterViewInit(): void {
    if (!this.win) {
      return;
    }

    this._updateTextHeight();
    this._updateTextMaxHeight();

    this.ngZone.runOutsideAngular(() => {
      this._monitoringSubscription = merge(
        this._hidden$.pipe(
          distinctUntilChanged(),
          throttleTime(0, animationFrameScheduler),
          map(() => this._determineTextExpansionState()),
        ),
        this._resetDone$.pipe(
          debounceTime(0, animationFrameScheduler),
          map(() => this._determineTextExpansionState()),
        ),
        fromEvent(this.win, 'resize').pipe(throttleTime(0, animationFrameScheduler), mapTo(TextExpansionState.Reset)),
      )
        .pipe(distinctUntilChanged())
        .subscribe((textExpansionState: TextExpansionState) => {
          if (textExpansionState === this._textExpansionState) {
            return;
          }

          this.ngZone.run(() => {
            this._setExpansionState(textExpansionState);
            this.changeDetectorRef.detectChanges();
          });
        });
    });

    const faqMaxHeight = this.block.nativeElement.clientHeight;
    interval(200)
      .pipe(
        map(() => getDocumentHeight(this.doc)),
        bufferCount(3, 1),
        takeWhile(
          ([firstHeight, secondHeight, thirdHeight]) => firstHeight !== secondHeight || secondHeight !== thirdHeight,
          true,
        ),
      )
      .subscribe(([firstHeight, secondHeight, thirdHeight]) => {
        if (firstHeight === secondHeight && secondHeight === thirdHeight) {
          const documentMaxHeight = thirdHeight + (faqMaxHeight - MAX_HEIGHT_DESKTOP);
          window.parent.postMessage({ minHeight: thirdHeight, maxHeight: documentMaxHeight }, '*');
        }
      });
  }

  ngOnDestroy(): void {
    if (this._monitoringSubscription) {
      this._monitoringSubscription.unsubscribe();
      this._monitoringSubscription = (null as unknown) as Subscription;
    }
  }

  toggleHidden(): void {
    window.parent.postMessage({ unwind: this.hidden }, '*');
    this._hidden$.next(!this._hidden$.getValue());
  }

  _handleTextExpansionAnimation(event: AnimationEvent): void {
    if (event.toState === TextExpansionState.Reset) {
      this._updateTextHeight();
      this._updateTextMaxHeight();

      this._resetDone$.next();
    }
  }

  private _determineTextExpansionState(): TextExpansionState {
    let textExpansionState = TextExpansionState.Normal;

    if (this._textHeight > this._textMaxHeight) {
      textExpansionState = this._hidden$.getValue() ? TextExpansionState.Collapsed : TextExpansionState.Expanded;
    }

    return textExpansionState;
  }

  private _setExpansionState(textExpansionState: TextExpansionState): void {
    this._textExpansionState = textExpansionState;
    this._textExpansionHeight =
      textExpansionState === TextExpansionState.Collapsed ? `${this._textMaxHeight}px` : AUTO_STYLE;
  }

  private _updateTextHeight(): void {
    if (this.block && this.block.nativeElement) {
      this._textHeight = this.block.nativeElement.offsetHeight || 0;
    }
  }

  private _updateTextMaxHeight(): void {
    if (this.win) {
      this._textMaxHeight = this.win.innerWidth >= BREAKPOINT_DESKTOP ? MAX_HEIGHT_DESKTOP : MAX_HEIGHT_MOBILE;
    }
  }
}

// tslint:disable-next-line:max-line-length quotemark
export const emailRegex =
  "^$|[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?!lcl\\.com)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"; // tslint:disable-line:max-line-length quotemark
export const nameRegex = "^[A-Za-z @.\\-ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ’\\']*$"; // tslint:disable-line:quotemark
export const streetRegex = "^[0-9,A-Za-z @.\\-ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ’\\']*$"; // tslint:disable-line:quotemark

export interface Form {
  civilTitle: string;
  lastName: string;
  firstName: string;
  phone: string;
  email: string;
  subject: string;
  message: string;
  recaptcha: string;
}

import { Inject, NgModule, Optional } from '@angular/core';
import { BrowserTransferStateModule, makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { ApolloLink } from 'apollo-link';

import { PrismicJS } from '@repo/shared/prismic-javascript';
import { CookieService } from './services/cookie.service';
import { ConfigService } from './config.service';
import { Logger } from './logger/logger';
import { LoggerService } from './logger/logger.service';
import { API_IN_MEMORY_CACHE } from './graphql.tokens';

export const GRAPHQL_STATE_KEY = makeStateKey<string>('apollo.state');

const previewCookie = PrismicJS.previewCookie;

const GRAPHQL_REQUEST_TIMEOUT_DEFAULT = 10000;
@NgModule({
  imports: [BrowserTransferStateModule],
  exports: [HttpClientModule, ApolloModule, HttpLinkModule],
})
export class GraphQLModule {
  cache: InMemoryCache;
  private logger: Logger;

  constructor(
    private readonly apollo: Apollo,
    private readonly transferState: TransferState,
    private readonly httpLink: HttpLink,
    private readonly cookieService: CookieService,
    private readonly configService: ConfigService,
    private readonly loggerService: LoggerService,
    @Optional() @Inject(API_IN_MEMORY_CACHE) apiInMemoryCache?: InMemoryCache,
  ) {
    this.logger = this.loggerService.get(GraphQLModule.name);
    const browser = this.isBrowser();
    const apiURL = browser
      ? this.configService.get<string>('API_PUBLIC_URL')
      : this.configService.get<string>('API_INTERNAL_URL');

    // https://github.com/apollographql/apollo-client/issues/3397#issuecomment-421433032
    // Avoid "WARNING: heuristic fragment matching going on!"
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: [],
        },
      },
    });
    this.cache = apiInMemoryCache || new InMemoryCache({ fragmentMatcher });

    const link = this._createLink(apiURL);
    if (link) {
      this.apollo.create({
        link,
        cache: this.cache,
      });
    } else {
      this.logger.warn('cannot create apollo instance: apollo link is not valid');
    }

    if (browser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  isBrowser(): boolean {
    return this.transferState.hasKey<NormalizedCacheObject>(GRAPHQL_STATE_KEY);
  }

  onServer(): void {
    this.transferState.onSerialize<NormalizedCacheObject>(GRAPHQL_STATE_KEY, () => {
      return this.cache.extract();
    });
  }

  onBrowser(): void {
    const state = this.transferState.get<NormalizedCacheObject>(
      GRAPHQL_STATE_KEY,
      (null as unknown) as NormalizedCacheObject,
    );
    this.cache.restore(state);
  }

  _createLink(apiURL: string): ApolloLink | null {
    if (!apiURL) {
      return null;
    }
    const timeout = this.configService.get<number>('GRAPHQL_REQUEST_TIMEOUT', GRAPHQL_REQUEST_TIMEOUT_DEFAULT);

    const timeoutLink = new ApolloLinkTimeout(timeout);
    const link = this.httpLink.create({
      uri: `${apiURL}/graphql`,
      headers: new HttpHeaders({
        [previewCookie]: this.cookieService.get(previewCookie) || '',
      }),
    });

    return timeoutLink.concat(link);
  }
}

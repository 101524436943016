import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { pluck } from 'rxjs/operators';

import { BREAKPOINT_DESKTOP } from '../common/constants/breakpoints';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private readonly breakpointDesktop$: Observable<BreakpointState>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointDesktop$ = this.breakpointObserver.observe(`(min-width: ${BREAKPOINT_DESKTOP}px)`);
  }

  subscribeOnDesktop(fn: (value: boolean) => void): Subscription {
    return this.isDesktopMatched().subscribe(fn);
  }

  isDesktopMatched(): Observable<boolean> {
    return this.breakpointDesktop$.pipe(pluck('matches'));
  }
}

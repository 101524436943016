import { Inject, Injectable } from '@angular/core';
import { DOMScriptService } from '../../../services/dom-script.service';
import { WINDOW } from '../../../services/window.provider';

export const TWITTER_SCRIPT_ID = 'twitter-script';
export const TWITTER_SCRIPT_URL = 'https://platform.twitter.com/widgets.js';
export const TIKTOK_SCRIPT_ID = 'tiktok-script';
export const TIKTOK_SCRIPT_URL = 'https://www.tiktok.com/embed.js';

export interface WindowWithTwttr extends Window {
  __twttr?: any;
}

@Injectable()
export class SocialEmbedsService {
  constructor(private readonly domScriptService: DOMScriptService, @Inject(WINDOW) private win: WindowWithTwttr) {}

  initTwitterEmbeds(): void {
    // Force twitter script init: workaround for "TypeError: __twttr.callbacks.cbX is not a function"
    delete this.win.__twttr;

    this.domScriptService.updateExternalScript(TWITTER_SCRIPT_ID, TWITTER_SCRIPT_URL);
  }

  initTikTokEmbeds() {
    this.domScriptService.updateExternalScript(TIKTOK_SCRIPT_ID, TIKTOK_SCRIPT_URL, true);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '@repo/shared';
import { AnalyticsSubPaths } from '../../../../services/tag-commander.service';
import { MobileHeaderOpenedComponent } from '../mobile-header-opened/mobile-header-opened.component';

@Component({
  selector: 'app-mobile-header-menu-opened',
  template: `
    <ng-container *ngIf="submenu && submenu.link">
      <div class="submenu-title text-uppercase" (click)="goBackToParentMenu()">
        <span>{{ submenu.link.label }}</span>
      </div>
      <div *ngIf="submenu.megamenu && submenu.megamenu.items && submenu.megamenu.items.length !== 0" class="link-list">
        <cb-cta-link
          *ngFor="let item of submenu.megamenu.items"
          class="item"
          type="landing-universe"
          [link]="item"
          [analyticsLabel]="item?.label"
          [analyticsSubPath]="analyticsSubPath"
          (click)="triggerToggleMenu()"
        >
          <div class="description">{{ item?.description }}</div>
        </cb-cta-link>
      </div>
    </ng-container>
  `,
  styleUrls: ['./mobile-header-menu-opened.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderMenuOpenedComponent {
  @Input() submenu: MenuItem;

  analyticsSubPath = AnalyticsSubPaths.HeaderMegaMenu;

  constructor(private parent: MobileHeaderOpenedComponent) {}

  triggerToggleMenu(): void {
    this.parent.triggerToggleMenu();
  }

  goBackToParentMenu(): void {
    this.parent.closeSubmenu();
  }
}

import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Alignment, Page, ParagraphSlice, SimplePage } from '@repo/shared';
import { PageService } from '../page.service';
import { TagCommanderService } from '../../services/tag-commander.service';

@Injectable()
export class NotFoundService {
  private readonly notFound$: Observable<Page>;

  private readonly paragraphSlice: ParagraphSlice = {
    type: 'paragraph',
    richText: `
      <h2>Cette page n'est malheureusement pas disponible.</h2>
      <p>Le lien que vous avez suivi n'est peut-être pas correct ou la page peut avoir été supprimée.</p>
      <p></p>
      <p><a href="https://lcl.fr" target="_self">Revenir à l'accueil</a> | <!--
        --><a href="https://monespace.lcl.fr/connexion" target="_self"><!--
          -->Me connecter à mes comptes</a></p>`,
    alignment: Alignment.Center,
  };

  private offlineErrorPage: Page = {
    id: 'W2q5jy0AADRgP83i',
    uid: 'erreur',
    url: '/erreur',
    type: 'page_simple',
    data: {
      seo: {
        robots: 'noindex, nofollow',
        openGraph: {},
      },
      homepageId: '',
      pageContent: {
        title: 'Oops !',
        body: [this.paragraphSlice],
      } as SimplePage,
    },
    lang: 'fr-fr',
    alternateLanguages: [{ lang: 'fr-fr', id: 'W2q5jy0AADRgP83i', type: 'page_simple', uid: 'erreur' }],
    error: true,
  };

  constructor(private readonly pageService: PageService, public readonly tagCommanderService: TagCommanderService) {}

  atInternetTracking(): void {
    this.tagCommanderService.trackEventPage('');
  }
  getNotFoundPage(): Observable<Page> {
    return this.pageService.getPageByUid('erreur').pipe(
      tap(() => this.atInternetTracking()),
      map(this.withError),
      catchError(() => of(this.offlineErrorPage)),
    );
  }

  private withError(page: Page): Page {
    return { ...page, error: true };
  }
}

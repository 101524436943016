import { ComponentFactory } from '@angular/core';

import { OutletInjector } from './outlet-injector';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ModalRouterOutletContext<T> {
  constructor(
    readonly factory: ComponentFactory<any>,
    readonly componentIndex: number,
    readonly outletInjector: OutletInjector,
  ) {}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ConfigService } from '../../../../config.service';

const AGENCY_CALENDAR = '/assets/images/agency-calendar.svg';
const IC_PHONE = '/assets/images/ic_phone.svg';

@Component({
  selector: 'app-agency-contact',
  template: `
    <h3 class="sub-section">Vous êtes un client ?</h3>
    <div class="legend-item">
      <div class="legend-picto"><img [src]="agencyCalendar" /></div>
      <div class="legend-text">
        <a [attr.href]="appointmentLinkHref" target="_blank">Prendre rendez-vous</a>
        avec votre conseiller
      </div>
    </div>
    <h3 class="sub-section">Pas encore client ?</h3>
    <div class="legend-item">
      <div class="legend-picto"><img [src]="icPhone" /></div>
      <div class="legend-text legend-phone">Appeler le <a href="tel:+33969363030">09 69 36 30 30</a></div>
    </div>
    <p class="quote-information">
      Nous sommes joignables du lundi au samedi de 9h à 17h30. Horaire valable en France métropolitaine. Appel non
      surtaxé pour tout opérateur en France Métropolitaine. Service vocal 7j/7 et 24h/24.
    </p>
  `,
  styleUrls: ['./agency-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyContactComponent {
  readonly agencyCalendar = AGENCY_CALENDAR;
  readonly icPhone = IC_PHONE;

  readonly appointmentLinkHref: string;

  constructor(private readonly configService: ConfigService) {
    this.appointmentLinkHref = this.configService.get('CONNECTION_URL_APPOINTMENT');
  }
}

import { OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Organization, WithContext } from 'schema-dts';

import { Logos, Link, Page } from '@repo/shared';
import { SMART_APP_BANNER_COOKIE_NAME } from '../common/constants/cookies';
import { ConfigService } from '../config.service';
import { CookieService } from '../services/cookie.service';
import { RouterService } from '../services/router.service';
import { WINDOW } from '../services/window.provider';
import { NEC_UNSUPPORTED_BROWSER } from '../ui/molecules/unsupported-browser/unsupported-browser.providers';
import { ModalService } from '../ui/templates/modal/modal.service';
import { HeaderService } from './header/header.service';
import { PageService } from './page.service';
import { SchemaOrgService } from './schema-org.service';
import { HOMEPAGE_MARKET_UID, PageMarketUid } from '../common/enum/enum-lcl';

const MOBILE_DEVICES = [/Android/i, /iPhone/i, /iPod/i];

@Component({
  selector: 'app-cms',
  template: `
    <ng-container *ngIf="currentPage$ | async as content">
      <app-smart-banner
        *ngIf="shouldDisplaySmartBanner"
        [message]="smartMessage"
        [image]="imageBanner"
        [button]="getSmartButton"
        [campaignCode]="getCodeCampagneSmartBanner"
        (closeSmartBanner)="closeSmartBanner()"
      >
      </app-smart-banner>
    </ng-container>
    <app-header *ngIf="shouldDisplayHeaderAndFooter"></app-header>
    <ng-container *ngIf="isHomePageType">
      <app-alert-banner
        *ngIf="shouldDisplayHeaderAndFooter && message && !shouldDisplayOnlyFooter"
        [message]="message"
        [couleurPicker]="getCouleurPicker"
        (closeAlertBanner)="closeAlertBanner()"
      >
      </app-alert-banner>
    </ng-container>
    <main role="main"><router-outlet></router-outlet></main>
    <ng-template #unsupportedBrowserTemplate>
      <unsupported-browser-modal></unsupported-browser-modal>
    </ng-template>
    <footer role="contentinfo">
      <app-footer *ngIf="shouldDisplayHeaderAndFooter || shouldDisplayOnlyFooter"></app-footer>
    </footer>
    <app-json-ld *ngIf="schema" [json]="schema"></app-json-ld>
  `,
})
export class CmsComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('unsupportedBrowserTemplate')
  unsupportedBrowserRef: TemplateRef<{}>;

  _visitedMarkets: Record<string, boolean>;
  _exceptionalInformationSubscription: Subscription | null = null;
  _isConversionFunnelSubscription: Subscription | null = null;
  _onlyFooterPageSubscription: Subscription | null = null;
  _currentPageSubscription: Subscription | null = null;

  isConversionFunnel: boolean;
  isOnlyFooterPage: boolean;
  isHomePageType: boolean;

  currentPage$: Observable<Page>;
  currentPageId: string;

  get isIframePage(): boolean {
    return this.routerService.isIframePage();
  }

  get shouldDisplayOnlyFooter(): boolean {
    return this.isOnlyFooterPage;
  }

  get shouldDisplayHeaderAndFooter(): boolean {
    return !this.isIframePage && !this.isConversionFunnel;
  }

  get checkIfPageIdAllowed(): boolean {
    if (this.platform.isBrowser) {
      return this.appearsOnPageList.some(pageId => pageId === this.currentPageId);
    }
    return false;
  }

  get checkIfMobileDevice(): boolean {
    if (this.platform.isBrowser) {
      return MOBILE_DEVICES.some(device => {
        return this.win.navigator.userAgent.match(device);
      });
    }
    return false;
  }

  get statutSmartBanner(): boolean {
    return this.statutSmartApp;
  }

  get getCodeCampagneSmartBanner(): string {
    return this.codeCampagneSmartApp;
  }

  get smartBannerImage(): boolean {
    return !!this.imageBanner.url;
  }

  get smartMessage(): string {
    return this.descriptionSmartApp;
  }

  get getSmartButton(): Link {
    return this.smartBannerButton;
  }

  get message(): string {
    return this.alertBannerMessage;
  }

  get getCouleurPicker(): string {
    return this.couleurPicker;
  }

  get schema(): WithContext<Organization> {
    return this.schemaOrgService.getOrganizationSchema();
  }

  get checkIfCookieIsMissing(): boolean {
    if (this.platform.isBrowser) {
      return !this.cookieService.get(SMART_APP_BANNER_COOKIE_NAME);
    }
    return false;
  }

  get shouldDisplaySmartBanner(): boolean {
    return !!(
      this.statutSmartBanner &&
      this.smartBannerImage &&
      this.checkIfPageIdAllowed &&
      this.checkIfMobileDevice &&
      this.shouldDisplayHeaderAndFooter &&
      !this.shouldDisplayOnlyFooter &&
      this.getSmartButton.url &&
      !!this.getSmartButton.label &&
      this.checkIfCookieIsMissing
    );
  }

  private alertBannerMessage: string;
  private statutSmartApp: boolean;
  private codeCampagneSmartApp: string;
  private descriptionSmartApp: string;
  private couleurPicker: string;
  private imageBanner: Logos;
  private appearsOnPage: any[];
  private appearsOnPageList: string[] = [];
  private smartBannerButton: Link;
  private unsupportedBrowserOverlayRef: OverlayRef | null = null;

  constructor(
    @Inject(WINDOW) private win: Window,
    @Inject(NEC_UNSUPPORTED_BROWSER) readonly unsupportedBrowser: boolean,
    private readonly router: Router,
    private readonly headerService: HeaderService,
    private readonly routerService: RouterService,
    private readonly schemaOrgService: SchemaOrgService,
    private readonly pageService: PageService,
    private readonly platform: Platform,
    private readonly configService: ConfigService,
    private readonly cookieService: CookieService,
    private readonly modalService: ModalService,
  ) {
    this._visitedMarkets = {};
    this.alertBannerMessage = (null as unknown) as string;
    this.statutSmartApp = false;
  }

  ngOnInit(): void {
    this._exceptionalInformationSubscription = merge(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => null),
      ),
      this.headerService.getHeader().pipe(
        filter(header => !this._visitedMarkets[header.market]),
        tap(header => {
          this._visitedMarkets[header.market] = true;
        }),
      ),
    ).subscribe(header => {
      if (header !== null) {
        this.alertBannerMessage = header.exceptionalInformation as string;
        this.statutSmartApp = header.statutSmartApp as boolean;
        this.codeCampagneSmartApp = header.codeCampagneSmartApp as string;
        this.descriptionSmartApp = header.descriptionSmartApp as string;
        this.imageBanner = header.imageBanner as Logos;
        this.appearsOnPage = header.appearsOnPage as any[];
        this.couleurPicker = header.couleurPicker as string;
        this.smartBannerButton = {
          label: header.smartButtonLabel,
          url: header.lienBouton?.url,
          type: 'smart-app-banner-download',
        };
        this.appearsOnPage.forEach(linkedPage => this.appearsOnPageList.push(linkedPage.linked_page.id));
      }
    });

    this._isConversionFunnelSubscription = this.pageService.currentPage$.subscribe(page => {
      this.isConversionFunnel = this.pageService.isConversionFunnelPage(page);
    });

    this.currentPage$ = this.pageService.currentPage$;

    this.currentPage$.subscribe(page => {
      this.currentPageId = page.id;
      this.checkIsHomePageType(page.id, page.uid);
    });

    this._onlyFooterPageSubscription = this.pageService.currentPage$.subscribe(page => {
      this.isOnlyFooterPage = this.pageService.isOnlyFooterPage(page);
    });
  }

  ngAfterViewInit(): void {
    if (this.unsupportedBrowser && !this.unsupportedBrowserOverlayRef) {
      if (typeof window !== 'undefined' && window.sessionStorage) {
        if (window.sessionStorage.getItem('IE_MODAL') === null) {
          this.unsupportedBrowserOverlayRef = this.modalService.open(this.unsupportedBrowserRef, {
            hasBackdrop: true,
            hasCloseCross: true,
            disposeOnBackdropClick: true,
          });
          window.sessionStorage.setItem('IE_MODAL', 'true');
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this._exceptionalInformationSubscription) {
      this._exceptionalInformationSubscription.unsubscribe();
      this._exceptionalInformationSubscription = null;
    }
    if (this._isConversionFunnelSubscription) {
      this._isConversionFunnelSubscription.unsubscribe();
      this._isConversionFunnelSubscription = null;
    }

    if (this._onlyFooterPageSubscription) {
      this._onlyFooterPageSubscription.unsubscribe();
      this._onlyFooterPageSubscription = null;
    }
    if (this._currentPageSubscription) {
      this._currentPageSubscription.unsubscribe();
      this._currentPageSubscription = null;
    }

    this.unsupportedBrowserOverlayRef?.dispose();
    this.unsupportedBrowserOverlayRef = null;
  }

  checkIsHomePageType(pageId: string, pageUid: string): boolean {
    const _marketPageUid = HOMEPAGE_MARKET_UID.get(pageId);
    return (this.isHomePageType =
      HOMEPAGE_MARKET_UID.has(pageId) &&
      _marketPageUid === pageUid &&
      Object.values(PageMarketUid).includes(_marketPageUid as PageMarketUid));
  }

  closeAlertBanner(): void {
    this.alertBannerMessage = (null as unknown) as string;
  }

  closeSmartBanner(): void {
    // adding smart app banner cookie
    const cookieOptions = {
      expires: moment().add(this.configService.get<number>('SMART_APP_BANNER_COOKIE_EXPIRE_DAYS'), 'days').toDate(),
    };
    this.cookieService.set(SMART_APP_BANNER_COOKIE_NAME, moment().format(), cookieOptions);

    this.statutSmartApp = false;
  }
}

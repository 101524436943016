import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class CookieConsentService {
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}

  cookieConsentRemover(): void {
    const unsubscribe$ = new Subject<any>();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(unsubscribe$),
      )
      .subscribe((event: NavigationEnd) => this.handlePrivacy(unsubscribe$, event));
  }

  handlePrivacy(unsubscribe$: Subject<any>, event: NavigationEnd): void {
    const isFirstPageLoad = event.id <= 1;

    if (isFirstPageLoad) {
      return;
    }

    const footerPrivacy = this.document.getElementById('footer_tc_privacy');

    if (footerPrivacy) {
      footerPrivacy.remove();

      unsubscribe$.next();
      unsubscribe$.complete();
    }
  }
}

import { HereAutocompleteResponse, HereAutocompleteRequest } from '@repo/shared/store-locator-schemas/suggestion';
import { BackUnavailableError } from '../errors';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class HereAutocompleteService {
  private readonly HERE_SUGGEST_URL: string;
  private readonly HERE_APP_ID: string;
  private readonly HERE_APP_CODE: string;
  private readonly HERE_AUTOCOMPLETE_BEGIN_HIGHLIGHT_SEPARATOR = '<b>';
  private readonly HERE_AUTOCOMPLETE_END_HIGHLIGHT_SEPARATOR = '</b>';
  private readonly HERE_AUTOCOMPLETE_COUNTRY_LIST = 'FRA,MTQ,GLP,REU,MAF,BLM,GUF,MCO';

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
    this.HERE_SUGGEST_URL = this.configService.get('HERE_SUGGEST_URL');
    this.HERE_APP_ID = this.configService.get('HERE_APP_ID');
    this.HERE_APP_CODE = this.configService.get('HERE_APP_CODE');
  }

  public autocompleteAddress(text: string): Observable<HereAutocompleteResponse> {
    if (!text || !text.trim()) {
      return of({
        suggestions: [],
      });
    }

    const hereAutocompleteRequest: HereAutocompleteRequest = {
      app_id: this.HERE_APP_ID,
      app_code: this.HERE_APP_CODE,
      country: this.HERE_AUTOCOMPLETE_COUNTRY_LIST,
      query: text,
      beginHighlight: this.HERE_AUTOCOMPLETE_BEGIN_HIGHLIGHT_SEPARATOR,
      endHighlight: this.HERE_AUTOCOMPLETE_END_HIGHLIGHT_SEPARATOR,
    };

    return this.httpClient
      .get<HereAutocompleteResponse>(this.HERE_SUGGEST_URL, { params: { ...hereAutocompleteRequest } })
      .pipe(catchError((err: any) => throwError(new BackUnavailableError('Could not get suggestions', err))));
  }
}

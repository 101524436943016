import { isPlatformBrowser } from '@angular/common';
import { Point } from '@repo/shared/point';
import {
  Component,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
  PLATFORM_ID,
} from '@angular/core';
import { Image as PrismicImage } from '@repo/shared/index';
import { ComponentWithData } from '../../../../typings';
import { ScratchAreaSlice } from '@repo/shared';
import { ResponsiveService } from '../../../../app/services/responsive.service';
import { map, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { getAbsoluteRect, loadImage } from '../../../../app/helpers/dom.helpers';

const BRUSH_IMG = '/assets/images/brush.png';

@Component({
  selector: 'app-slice-scratch-area',
  template: `
    <ng-container *ngIf="data && content$ | async as content">
      <cb-mini-title class="title" *ngIf="data.title" [title]="data.title"></cb-mini-title>
      <div #canvasContainer class="scratch-area-container">
        <canvas #canvas class="scratch-canvas"></canvas>
        <div class="scratch-area-description" [style.visibility]="showContent ? 'visible' : 'hidden'">
          <h2 class="subtitle">{{ data.subtitle }}</h2>
          <div *ngIf="data.description" class="description" [innerHTML]="data.description"></div>
          <cb-cta-link
            *ngIf="data.link"
            class="cta-link"
            type="product-page"
            [sliceType]="data.type"
            [link]="data.link"
          ></cb-cta-link>
          <cb-cta-btn-link
            *ngIf="data.button"
            class="cta-button"
            [button]="data.button"
            [sliceType]="data.type"
          ></cb-cta-btn-link>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-scratch-area.component.scss'],
})
export class SliceScratchAreaComponent implements ComponentWithData, OnInit, AfterViewInit, OnDestroy {
  @Input() data: ScratchAreaSlice;

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement>;

  CANVAS_DISPLAY_HIDDEN_CONTENT_PERCENT = 30;

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;

  brushImageSrc: string = BRUSH_IMG;

  imageDrawn = false;
  imageExists = true;
  isDrawing = false;
  imageScratched = false;

  imageDesktopSize = { width: 690, height: 298 };
  imageMobileSize = { width: 320, height: 307 };
  canvasSize = this.imageDesktopSize;

  lastPoint: Point;

  canvasImage: HTMLImageElement;
  brush: HTMLImageElement;

  content$: Observable<any>;
  imgContent$: BehaviorSubject<any> = new BehaviorSubject(null);
  subscription$: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly renderer: Renderer2,
    private readonly responsiveService: ResponsiveService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  get showContent(): boolean {
    return this.imageDrawn || !this.imageExists || this.imageScratched;
  }

  ngOnInit(): void {
    this.imageExists = ((this.data && this.data.image.url && this.data.image.mobile?.url) as {}) as boolean;
    if (isPlatformBrowser(this.platformId)) {
      this.initContentSubscription();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.platformId && this.imageExists && this.data) {
      this.subscription$ = this.imgContent$.pipe(filter(Boolean), distinctUntilChanged()).subscribe((content: any) => {
        if (!this.imageScratched) {
          this.initCanvas(content);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
      this.subscription$ = (null as unknown) as Subscription;
    }
  }

  initContentSubscription(): void {
    this.content$ = this.responsiveService.isDesktopMatched().pipe(
      map(isDesktop => ({
        isMobile: !isDesktop,
        image: isDesktop || !this.data.image || !this.data.image.mobile ? this.data.image : this.data.image.mobile,
      })),
      tap(content => {
        this.imageDrawn = false;
        this.imgContent$.next(content);
      }),
    );
  }

  async initCanvas({ isMobile, image }: { isMobile: boolean; image: any }): Promise<void> {
    if (!this.canvas) {
      this.canvas = this.canvasRef.nativeElement;
    }
    this.setCanvasSize({ isMobile });
    await this.initCanvasContextAndImages(image);
    this.context.drawImage(this.canvasImage, 0, 0);
    this.imageDrawn = true;
    this.initCanvasEventListener();
  }

  async initCanvasContextAndImages(image: PrismicImage): Promise<void> {
    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.canvasImage = await loadImage(image.url, image.alt || '', true);
    this.brush = await loadImage(this.brushImageSrc);
  }

  setCanvasSize({ isMobile }: { isMobile: boolean }): void {
    this.canvasSize = isMobile ? this.imageMobileSize : this.imageDesktopSize;
    this.renderer.setAttribute(this.canvas, 'width', `${this.canvasSize.width}px`);
    this.renderer.setAttribute(this.canvas, 'height', `${this.canvasSize.height}px`);
  }

  initCanvasEventListener(): void {
    this.canvas.addEventListener('mousedown', this.handleMouseDown.bind(this), false);
    this.canvas.addEventListener('touchstart', this.handleMouseDown.bind(this), false);
    this.canvas.addEventListener('mousemove', this.handleMouseMove.bind(this), false);
    this.canvas.addEventListener('touchmove', this.handleMouseMove.bind(this), false);
    this.canvas.addEventListener('mouseup', this.handleMouseUp.bind(this), false);
    this.canvas.addEventListener('touchend', this.handleMouseUp.bind(this), false);
  }

  handleMouseMove(e: Event): void {
    if (!this.isDrawing) {
      return;
    }

    e.preventDefault();

    const currentPoint = this.getMousePosition(e);
    const dist = this.lastPoint.distanceTo(currentPoint);
    const angle = this.lastPoint.angleBetween(currentPoint);
    let x: number;
    let y: number;
    this.context.globalCompositeOperation = 'destination-out';

    for (let i = 0; i < dist; i++) {
      x = this.lastPoint.x + Math.sin(angle) * i - 25;
      y = this.lastPoint.y + Math.cos(angle) * i - 25;
      this.context.drawImage(this.brush, x, y);
    }

    this.lastPoint = currentPoint;
    const percents = this.computePercents();
    this.handlePercentage(percents);
  }

  handleMouseDown(e: Event): void {
    this.isDrawing = true;
    this.lastPoint = this.getMousePosition(e);
  }

  handleMouseUp(): void {
    this.isDrawing = false;
  }

  getMousePosition(e: any): Point {
    const { left, top } = getAbsoluteRect(this.canvas);

    const mx = (e.pageX || e.touches[0].pageX) - left;
    const my = (e.pageY || e.touches[0].pageY) - top;

    return new Point(mx, my);
  }

  handlePercentage(filledInPixelsPercents: number): void {
    this.changeDetectorRef.detectChanges();
    if (filledInPixelsPercents >= this.CANVAS_DISPLAY_HIDDEN_CONTENT_PERCENT && this.canvas.parentNode) {
      this.canvas.parentNode.removeChild(this.canvas);
      this.imageScratched = true;
    }
  }

  /*
   * Credits to https://github.com/Masth0/ScratchCard/blob/4ef5f51cd424090b415e295b113f90ce3a612130/src/ScratchCard.ts#L258
   * Image data :
   * Red: image.data[0]
   * Green: image.data[1]
   * Blue: image.data[2]
   * Alpha: image.data[3]
   * */
  computePercents(): number {
    let counter = 0; // number of pixels cleared
    const imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
    const imageDataLength = imageData.data.length;

    // loop data image drop every 4 items [r, g, b, a, ...]
    for (let i = 0; i < imageDataLength; i += 4) {
      // Increment the counter only if the pixel in completely clear
      if (
        imageData.data[i] === 0 &&
        imageData.data[i + 1] === 0 &&
        imageData.data[i + 2] === 0 &&
        imageData.data[i + 3] === 0
      ) {
        counter++;
      }
    }

    return counter >= 1 && this.canvas.width !== 0 && this.canvas.height !== 0
      ? Math.round((counter / (this.canvas.width * this.canvas.height)) * 100)
      : 0;
  }
}

import { Component, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { HeaderButtonsLinks } from '../../header';
import { Image, Link, MenuItem } from '@repo/shared';
import { ResponsiveService } from '../../../../services/responsive.service';
import { StateService } from '../../../../services/state.service';
import { STICKY_HEADER_HEIGHT_MOBILE } from '../../../../common/constants/sizes';

@Component({
  selector: 'app-mobile-header',
  template: `
    <app-mobile-header-opened
      [@sliding]="menuState"
      (@sliding.start)="handleSlidingAnimation($event)"
      (@sliding.done)="handleSlidingAnimation($event)"
      (toggleMenu)="toggleMenu()"
      [universes]="universes"
      [communications]="communications"
      [buttons]="buttons"
      [markets]="markets"
      [marketsMenuLabel]="marketsMenuLabel"
      [mobileTitle]="mobileTitle"
      [logo]="logoMenuOpened"
    >
    </app-mobile-header-opened>

    <app-mobile-header-closed (toggleMenu)="toggleMenu()" [buttons]="buttons" [logo]="logoMenuClosed">
    </app-mobile-header-closed>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  animations: [
    trigger('sliding', [
      state('open', style({ transform: 'translateX(0%)' })),
      state('close', style({ transform: 'translateX(-100%)' })),
      transition('open <=> close', animate('0.3s cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  @Input() buttons: HeaderButtonsLinks = {};
  @Input() universes: MenuItem[] = [];
  @Input() communications: MenuItem[] = [];
  @Input() markets: Link[];
  @Input() marketsMenuLabel: string;
  @Input() mobileTitle: string;
  @Input() logoMenuOpened: Image;
  @Input() logoMenuClosed: Image;

  opened = false;

  private breakpointSubscription: Subscription;

  get menuState(): 'open' | 'close' {
    return this.opened ? 'open' : 'close';
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private responsiveService: ResponsiveService,
    private renderer: Renderer2,
    private readonly ngZone: NgZone,
    private readonly stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.breakpointSubscription = this.responsiveService.subscribeOnDesktop(matches => {
      if (matches) {
        this.opened = false;
        this.toggleWindowScroll(true);
      }
    });

    this.ngZone.run(() => {
      this.stateService.update({
        stickyHeaderBottomPosition: STICKY_HEADER_HEIGHT_MOBILE,
      });
    });
  }

  ngOnDestroy(): void {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
      this.breakpointSubscription = (null as unknown) as Subscription;
    }

    this.toggleWindowScroll(true);
  }

  toggleMenu(): void {
    this.opened = !this.opened;
  }

  handleSlidingAnimation(event: AnimationEvent): void {
    if (event.phaseName === 'done' && event.toState === 'open') {
      this.toggleWindowScroll(false);
    } else if (event.phaseName === 'start' && event.toState === 'close') {
      this.toggleWindowScroll(true);
    }
  }

  private toggleWindowScroll(value: boolean): void {
    this.renderer.setStyle(this.document.body, 'overflow', value ? '' : 'hidden');
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

interface Response {
  status(code: number): this;

  redirect(status: number, url: string): this;
}

@Injectable({
  providedIn: 'root',
})
export class HttpResponseService {
  constructor(@Optional() @Inject(RESPONSE) private response: Response) {}

  public setStatus(code: number): void {
    if (this.response) {
      this.response.status(code);
    }
  }

  public redirect(status: number, url: string): void {
    if (this.response) {
      this.response.redirect(status, url);
    }
  }
}

import { Component, Input } from '@angular/core';
import { Article as SchemaArticle, WithContext } from 'schema-dts';

import { Article, ArticleHead } from '@repo/shared';
import { SchemaOrgService } from '../../../../schema-org.service';

@Component({
  selector: 'cms-article',
  template: `
    <article-head *ngIf="data" [head]="data.head"></article-head>
    <div class="articleBody" *ngIf="data && data.body">
      <article-share-bar [data]="data.head"></article-share-bar>
      <cb-slices [data]="data.body"></cb-slices>
    </div>
  `,
  styleUrls: ['../article.common.scss'],
})
export class ArticleComponent {
  @Input() data: Article;

  constructor(private readonly schemaOrgService: SchemaOrgService) {}

  get schema(): WithContext<SchemaArticle> | undefined {
    return this.schemaOrgService.getArticleSchema(this.data?.head as ArticleHead);
  }
}

import { JSONSchema7 } from 'json-schema';
import { nameRegex, emailRegex, streetRegex, Form } from './form';

export interface ComplaintCategory {
  key: string;
  label: string;
}

export interface ComplaintForm extends Form {
  city: string;
  postCode: string;
  street: string;
}

export const NON_CUSTOMER_COMPLAINT_CATEGORIES: ComplaintCategory[] = [
  { key: 'payment', label: 'Moyens de paiements' },
  { key: 'credit', label: 'Crédits' },
  { key: 'saving', label: 'Épargne et placements' },
  { key: 'insurance', label: 'Assurances' },
  { key: 'account', label: 'Fonctionnement de compte et incidents' },
  { key: 'reception', label: 'Accueil et conseil' },
];

export const NON_CUSTOMER_COMPLAINT_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
    },
    street: {
      type: 'string',
      pattern: streetRegex,
      minLength: 1,
    },
    postCode: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    city: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    subject: {
      type: 'string',
      enum: NON_CUSTOMER_COMPLAINT_CATEGORIES.map((category: ComplaintCategory) => category.key),
      minLength: 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'street',
    'postCode',
    'city',
    'subject',
    'message',
    'recaptcha',
  ],
};

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Inject,
  OnInit,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { RECLAMATION_IDENTITY_FORM } from '@repo/shared/form-schemas/reclamation-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { ReclamationFormSlice } from '@repo/shared';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'reclamation-identity',
  templateUrl: './reclamation-identity.component.html',
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_IDENTITY_FORM,
    },
  ],
})
export class ReclamationIdentityComponent extends AbstractFormComponent implements OnInit {
  @Input() regForm: FormGroup;
  @Input() data: ReclamationFormSlice;

  clientFunctionOptions;
  _buttonDisabled = false;
  _hasPostError = false;

  selectError = false;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngOnInit() {
    this.clientFunctionOptions = this.data.select_relation;
  }

  onSubmit(): void {
    if (!this.regForm.get('clientFunction')?.valid) {
      this.selectError = true;
    }
    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}

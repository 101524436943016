import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { ContactUsSlice } from '@repo/shared';

@Component({
  selector: 'slice-contact-us',
  template: `
    <mat-tab-group class="wrapper" mat-align-tabs="center" dynamicHeight="true" disableRipple="true">
      <mat-tab *ngFor="let tab of data.contactUsTabs" class="contact-tabs">
        <ng-template mat-tab-label>
          <h2>{{ tab.title }}</h2>
        </ng-template>
        <div class="tabs">
          <contact-us-tab [tab]="tab" *ngIf="tab.type === 'contact'"></contact-us-tab>
          <questions-tab [tab]="tab" *ngIf="tab.type === 'faq'" [sliceType]="data.type"></questions-tab>
        </div>
      </mat-tab>
    </mat-tab-group>
  `,
  styleUrls: ['./slice-contact-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceContactUsComponent implements SliceWithData<ContactUsSlice> {
  @Input() data: ContactUsSlice;
}

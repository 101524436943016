import { InjectionToken } from '@angular/core';

export interface PicturefillEvaluateArg {
  reevaluate?: boolean;
  elements?: NodeList | Array<Element | null>;
}

export type PicturefillFunction = (arg?: PicturefillEvaluateArg) => void;

export const PICTUREFILL = new InjectionToken<PicturefillFunction>('PICTUREFILL', {
  providedIn: 'root',
  factory: picturefillFactory as () => PicturefillFunction,
});

export function picturefillFactory(): PicturefillFunction | null {
  return (typeof window !== 'undefined' && window['picturefill']) || null;
}

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, NavigationError, Params, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';

import { Page, PageFilters } from '@repo/shared';
import { ErrorType } from '../../errors';
import { PageService } from '../page.service';
import { HeaderService } from '../header/header.service';
import { FooterService } from '../footer/footer.service';
import { HttpResponseService } from '../../http-response.service';
import { NotFoundService } from './not-found.service';
import { StateService } from '../../services/state.service';
import { HOME_PAGE_DOCUMENT } from '@repo/shared/cms.pages';
import { HttpStatusCode } from '../../common/constants/http-status-code';

@Injectable()
export class PageResolverService implements Resolve<Page> {
  constructor(
    private readonly pageService: PageService,
    private readonly headerService: HeaderService,
    private readonly footerService: FooterService,
    private readonly responseService: HttpResponseService,
    private readonly notFoundService: NotFoundService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly stateService: StateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Page> {
    const uid = !route.url.length ? HOME_PAGE_DOCUMENT.uid : route.params.uid;

    if (!uid) {
      return this.resolveNotFoundErrorPage();
    }

    const pageFilters: PageFilters = {
      market: route.params.market,
      universe: route.params.universe,
      theme: route.params.theme,
      marketOrUniverse: route.params.marketOrUniverse,
      universeOrTheme: route.params.universeOrTheme,
    };

    return this.getPageByUid(uid, pageFilters, route.queryParams).pipe(
      tap(page => this.reloadHeaderFooterIfNecessary(page)),
      catchError(error => {
        if (error.type === ErrorType.PageNotFound) {
          return this.resolveNotFoundErrorPage();
        }
        this.preventRoutingToRootWhenError(state.url);
        throw error;
      }),
    );
  }

  private getPageByUid(uid: string, pageFilters: PageFilters, queryParams: Params): Observable<Page> {
    return this.pageService.getPageByUid(uid, pageFilters, queryParams.iframe !== undefined);
  }

  private resolveNotFoundErrorPage(): Observable<Page> {
    this.responseService.setStatus(HttpStatusCode.NotFound);
    return this.notFoundService.getNotFoundPage().pipe(tap(page => this.reloadHeaderFooterIfNecessary(page)));
  }

  private reloadHeaderFooterIfNecessary(page: Page): void {
    const homepageId = page.data.homepageId as string;
    if (homepageId !== this.stateService.get().homepageId) {
      this.headerService.reload(homepageId);
      this.footerService.reload(homepageId);
      this.stateService.update({ homepageId });
    }
  }

  private preventRoutingToRootWhenError(currentUrl: string): void {
    // Workaround similar to:
    // https://github.com/angular/angular/issues/16981#issuecomment-397218203
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationError),
        take(1),
      )
      .subscribe(() => this.location.replaceState(currentUrl));
  }
}

import { Subscription } from 'rxjs';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';

import {
  Component,
  ViewEncapsulation,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  OnDestroy,
  Inject,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

import { WINDOW } from '../../../services/window.provider';
import { ResponsiveService } from '../../../services/responsive.service';
import { LandingPageEventOfferListSlice, LandingPageEventOffer } from '@repo/shared/index';
import { SliceWithData } from '../../../../typings';
import { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE } from '../../../common/constants/breakpoints';
import { TagCommanderService, TrackEventLabels } from '../../../services/tag-commander.service';

@Component({
  selector: 'slice-landing-page-event-offer-list',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="offer-list" *ngIf="offers.length > 1; else noCardImageOffer">
      <mat-tab-group
        #tabGroup
        class="wrapper"
        mat-align-tabs="center"
        disableRipple="true"
        [disablePagination]="false"
        [selectedIndex]="activeTabIndex"
        (swipeleft)="swipe($event.type)"
        (swiperight)="swipe($event.type)"
        animationDuration="0ms"
      >
        <mat-tab *ngFor="let offer of offers; let i = index" [disabled]="[!isDesktop]">
          <ng-template mat-tab-label>
            <img [src]="getCardImage(i, offer)" (click)="updateActiveTabIndex(i)" />
          </ng-template>
          <landing-page-event-offer
            mat-tab-content
            [data]="offer"
            [index]="i"
            (triggerSwipe)="updateActiveTabIndex($event)"
          >
          </landing-page-event-offer>
        </mat-tab>
      </mat-tab-group>
    </div>
    <ng-template #noCardImageOffer>
      <landing-page-event-offer [data]="offers[0]"> </landing-page-event-offer>
    </ng-template>
  `,
  styleUrls: ['./slice-landing-page-event-offer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingPageEventOfferListComponent
  implements SliceWithData<LandingPageEventOfferListSlice>, OnInit, OnDestroy, AfterViewInit {
  readonly SWIPE_ACTION_LEFT = 'swipeleft';
  readonly SWIPE_ACTION_RIGHT = 'swiperight';

  readonly PADDING_LEFT_SELECTOR = 'padding-left';
  readonly DESKTOP_TABS_PADDING_LEFT = '10.4%';
  readonly MOBILE_TABS_PADDING_LEFT = '24px';
  readonly DESKTOP_TABS_PADDING_LEFT_NUMBER = 0.14;
  readonly DESKTOP_CARD_SIZE = 294;

  _breakpointDesktopSubscription: Subscription | null = null;
  isDesktop = true;
  isAlign = false;
  offers: LandingPageEventOffer[];
  activeTabIndex = 0;

  @Input()
  data: LandingPageEventOfferListSlice;

  @ViewChild('tabGroup')
  _offerTabGroup: MatTabGroup;

  get _offerTabHeader(): MatTabHeader {
    return this._offerTabGroup?._tabHeader as MatTabHeader;
  }

  alignNavbar(): void {
    // Hack To reset Card position when resizing
    if (this._offerTabHeader && this.offers.length > 1) {
      this._offerTabHeader._scrollToLabel(0);
      this._offerTabHeader._scrollToLabel(this.activeTabIndex);

      if (this.isDesktop && !this.isAlign) {
        this.renderer.removeStyle(this._offerTabHeader._tabList.nativeElement, this.PADDING_LEFT_SELECTOR);
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  get desktopBreakpoint(): string {
    return BREAKPOINT_DESKTOP + 'px';
  }

  get mobileBreakpoint(): string {
    return BREAKPOINT_MOBILE + 'px';
  }

  ngOnInit(): void {
    this.offers = this.data.offers;
    this._breakpointDesktopSubscription = this.responsiveService.isDesktopMatched().subscribe(isDesktop => {
      this.isDesktop = isDesktop && !!this.win;
      this.alignNavbar();
    });
  }

  ngAfterViewInit(): void {
    if (this.offers.length > 1 && this.win && this.isDesktop && this.isOfferCardsOverflowing()) {
      this.alignTabsListWithHeroText();
      this.isAlign = true;
    }
  }

  ngOnDestroy(): void {
    if (this._breakpointDesktopSubscription) {
      this._breakpointDesktopSubscription.unsubscribe();
      this._breakpointDesktopSubscription = null;
    }
  }

  getCardImage(index: number, offer: LandingPageEventOffer): string {
    if (index === this.activeTabIndex) {
      if (this.isDesktop) {
        return offer.activeCard.url;
      } else {
        return offer.activeCard.mobile?.url as string;
      }
    }

    if (this.isDesktop) {
      return offer.inactiveCard.url;
    } else {
      return offer.inactiveCard.mobile?.url as string;
    }
  }

  constructor(
    @Inject(WINDOW) readonly win: Window,
    readonly renderer: Renderer2,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly responsiveService: ResponsiveService,
    readonly tagCommanderService: TagCommanderService,
  ) {}

  isOfferCardsOverflowing(): boolean {
    const windowWidth = this.win.innerWidth - this.DESKTOP_TABS_PADDING_LEFT_NUMBER * this.win.innerWidth;
    const offerWidth = this.DESKTOP_CARD_SIZE * this.offers.length;

    return offerWidth >= windowWidth;
  }

  alignTabsListWithHeroText(): void {
    let paddingLeftSize = this.DESKTOP_TABS_PADDING_LEFT;

    if (!this.isDesktop) {
      paddingLeftSize = this.MOBILE_TABS_PADDING_LEFT;
    }

    if (this._offerTabHeader) {
      this.renderer.setStyle(this._offerTabHeader._tabList.nativeElement, this.PADDING_LEFT_SELECTOR, paddingLeftSize);
    }
  }

  updateActiveTabIndex(index: number): void {
    if (this._offerTabHeader && this.isDesktop) {
      if (index < 0) {
        index = this.offers.length - 1;
      }
      if (index > this.offers.length - 1) {
        index = 0;
      }
      if (this.isAlign && index === 0) {
        this._offerTabHeader.scrollDistance = 0;
      }
      this.activeTabIndex = index;
      this.trackOfferCardClickOrSwipeEvent();
    }
  }

  swipe(eventType: 'swipeleft' | 'swiperight'): void {
    if (eventType === this.SWIPE_ACTION_LEFT && this.activeTabIndex < this.offers.length - 1) {
      this.activeTabIndex++;
      this.trackOfferCardClickOrSwipeEvent();
    } else if (eventType === this.SWIPE_ACTION_RIGHT && this.activeTabIndex > 0) {
      if (this._offerTabHeader && this.isAlign && this.activeTabIndex - 1 === 0) {
        this._offerTabHeader.scrollDistance = 0;
      }
      this.activeTabIndex--;
      this.trackOfferCardClickOrSwipeEvent();
    }
  }

  trackOfferCardClickOrSwipeEvent(): void {
    this.atInternetTracking();
    this.handleDMPClickTracking();
  }

  atInternetTracking(): void {
    this.tagCommanderService.trackEventPage(TrackEventLabels.LPEvent + this.offers[this.activeTabIndex].title);
  }

  handleDMPClickTracking(): void {
    this.tagCommanderService.trackDMPClickNavigationEvent(
      this.formatOfferTitle(this.offers[this.activeTabIndex].title),
    );
  }

  formatOfferTitle(label: string): string {
    const searchRegExp = /\s/g;
    const replaceWith = '_';

    return label.replace(searchRegExp, replaceWith);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { DesktopTimelineStepsComponent } from './desktop-timeline-steps/desktop-timeline-steps.component';
import { MobileTimelineStepsComponent } from './mobile-timeline-steps/mobile-timeline-steps.component';
import { SliceTimelineComponent } from './slice-timeline/slice-timeline.component';

@NgModule({
  declarations: [SliceTimelineComponent, MobileTimelineStepsComponent, DesktopTimelineStepsComponent],
  imports: [CommonModule, CommonBehaviorsModule],
  exports: [SliceTimelineComponent],
  entryComponents: [SliceTimelineComponent],
})
export class SliceTimelineModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { SliceSocialEmbedsComponent } from './slice-social-embeds.component';
import { SocialEmbedsService } from './social-embeds.service';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule],
  declarations: [SliceSocialEmbedsComponent],
  providers: [SocialEmbedsService],
  exports: [SliceSocialEmbedsComponent],
})
export class SliceSocialEmbedsModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { Subject, Subscription } from 'rxjs';

import { UiExpansionListDirective } from './expansion-list.directive';
import { uiExpansionPanelAnimations } from './expansion-panel.animations';

/** UiExpansionPanel's states. */
export type UiExpansionPanelState = 'expanded' | 'collapsed';

/** Counter for generating unique element ids. */
let uniqueId = 0;

/**
 * `<ui-expansion-panel>`
 *
 * This component can be used to display expansion panels.
 *
 * See expansion-panel.md for usage details.
 */
@Component({
  // moduleId: module.id,
  selector: 'ui-expansion-panel',
  exportAs: 'uiExpansionPanel',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [uiExpansionPanelAnimations.bodyExpansion],
  template: `
    <ng-content select="ui-expansion-header"> </ng-content>
    <ng-container *ngIf="title">
      <ui-expansion-header>
        {{ title }}
      </ui-expansion-header>
    </ng-container>
    <div
      #body
      [id]="id"
      [attr.aria-labelledby]="_headerId"
      class="ui-expansion-panel-content"
      [@bodyExpansion]="expandedState"
      (@bodyExpansion.done)="_bodyAnimation($event)"
      (@bodyExpansion.start)="_bodyAnimation($event)"
      role="region"
      class="ui-expansion-panel-content"
    >
      <div class="ui-expansion-panel-body">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class UiExpansionPanelComponent extends CdkAccordionItem implements OnChanges, OnInit, OnDestroy {
  @HostBinding('class.ui-expansion-panel') hostClass = true;

  @HostBinding('class.ui-expanded')
  get hostClassExpanded(): boolean {
    return !!this.expanded;
  }

  @HostBinding('attr.aria-controls')
  get ariaControls(): string {
    return this.id;
  }

  @HostBinding('attr.aria-expanded')
  get ariaExpanded(): any {
    return this.expanded;
  }

  @Input() title: string;

  /** Stream that emits for changes in `@Input` properties. */
  readonly _inputChanges = new Subject<SimpleChanges>();

  /** UiExpansionListDirective belongs to. */
  accordion: UiExpansionListDirective;

  /** ID for the associated header element. Used for a11y labelling. */
  _headerId = `ui-expansion-header-${uniqueId++}`;

  get expandedState(): UiExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  broadcasting: Subscription[] = [];

  constructor(
    @Optional() accordion: UiExpansionListDirective,
    _changeDetectorRef: ChangeDetectorRef,
    protected _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
  ) {
    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this.accordion = accordion;
  }

  ngOnInit(): void {
    this._broadcastAccordionItemChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._inputChanges.next(changes);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._inputChanges.complete();
    this._destroyBroadcasting();
  }

  _broadcastAccordionItemChanges(): void {
    if (this.accordion) {
      this.broadcasting.push(
        this.opened.subscribe(() => this.accordion.opened.emit(this)),
        this.closed.subscribe(() => this.accordion.closed.emit(this)),
        this.destroyed.subscribe(() => this.accordion.destroyed.emit(this)),
      );
    }
  }

  _destroyBroadcasting(): void {
    for (const subscription of this.broadcasting) {
      subscription.unsubscribe();
    }

    this.broadcasting.length = 0;
  }

  _bodyAnimation(event: AnimationEvent): void {
    const cssClass = 'ui-expanded';
    const { element, phaseName, toState } = event;

    // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
    // order to prevent the cases where switching too early would cause the animation to jump.
    // Note that we do it directly on the DOM element to avoid the slight delay that comes
    // with doing it via change detection.
    if (phaseName === 'done' && toState === 'expanded') {
      element.classList.add(cssClass);
    } else if (phaseName === 'start' && toState === 'collapsed') {
      element.classList.remove(cssClass);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { ArticleHead, ImageWithMobile, PushArticlesSlice } from '@repo/shared';
import { SliceWithData } from '../../../../typings';

export const ARTICLE_IMAGE_DEFAULT_1 = '/assets/images/article_image_default_1.jpg';
export const ARTICLE_IMAGE_DEFAULT_2 = '/assets/images/article_image_default_2.jpg';
export const ARTICLE_IMAGE_DEFAULT_3 = '/assets/images/article_image_default_3.jpg';

export const ARTICLE_IMAGE_DEFAULTS: ImageWithMobile[] = [
  { url: ARTICLE_IMAGE_DEFAULT_1, alt: '' },
  { url: ARTICLE_IMAGE_DEFAULT_2, alt: '' },
  { url: ARTICLE_IMAGE_DEFAULT_3, alt: '' },
];

@Component({
  selector: 'slice-push-articles',
  template: `
    <div class="content">
      <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
      <div class="mobile-only">
        <cb-article-thumbnail *ngFor="let article of articles" [article]="article"> </cb-article-thumbnail>
      </div>
      <div class="desktop-only wrapper">
        <div *ngFor="let article of articles" class="article">
          <a
            [attr.href]="article.url"
            class="picture link-with-image"
            [class.hover-link]="isHoverLink(article)"
            (mouseenter)="onHoverLink(article, true)"
            (mouseleave)="onHoverLink(article, false)"
          >
            <span class="frame">
              <cb-scalable-img [image]="article.image"></cb-scalable-img>
            </span>
          </a>
          <div class="panel">
            <h3 class="title">
              <a
                [attr.href]="article.url"
                (mouseenter)="onHoverLink(article, true)"
                (mouseleave)="onHoverLink(article, false)"
                [class.hover-link]="isHoverLink(article)"
                >{{ article.title }}</a
              >
            </h3>
            <div class="panel-body">
              <cb-article-info-bar
                [publishOn]="article.publishOn"
                [readingTime]="article.readingTime"
                [type]="article.type"
              >
              </cb-article-info-bar>
              <div class="description" [innerHTML]="article.description | trustHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./slice-push-articles.component.scss'],
})
export class SlicePushArticlesComponent implements SliceWithData<PushArticlesSlice>, OnInit {
  @Input() data: PushArticlesSlice;
  @Input() isFirstSlice?: boolean;

  hoverLink = new Map<string, boolean>();
  articles: ArticleHead[] = [];

  ngOnInit(): void {
    this.articles = this.data.pushArticles.map((article, index) => ({
      ...article,
      image: article.image && article.image.url ? article.image : ARTICLE_IMAGE_DEFAULTS[index],
    }));
  }

  onHoverLink(article: ArticleHead, state: boolean): void {
    this.hoverLink.set(article.url as string, state);
  }

  isHoverLink(article: ArticleHead): boolean {
    return this.hoverLink.has(article.url as string) && !!this.hoverLink.get(article.url as string);
  }
}

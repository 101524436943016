import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { TagCommanderService } from '../../services/tag-commander.service';

@Directive({
  selector: '[analyticsOnNestedLink]',
})
export class AnalyticsOnNestedLinkDirective implements AfterViewInit {
  @Input() analyticsSubPath: string;
  @Input() analyticsActive = true;
  @Input() analyticsFullFormatPath = false;

  hyperlink: HTMLAnchorElement;

  constructor(
    private readonly tagCommanderService: TagCommanderService,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit(): void {
    this.initHyperlinkClickEvent();
  }

  initHyperlinkClickEvent(): void {
    const hyperlink = this.elementRef.nativeElement.querySelector('a');
    if (hyperlink) {
      this.hyperlink = hyperlink;
      hyperlink.addEventListener('click', this.trackClickEvent.bind(this));
    }
  }

  trackClickEvent(): void {
    if (!this.analyticsActive) {
      return;
    }

    this.tagCommanderService.trackClickActionEvent(
      this.hyperlink.innerText,
      this.analyticsSubPath,
      this.analyticsFullFormatPath,
    );
  }
}

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

import { BooleanProperty } from '../../../utils/coercion/boolean-property.decorator';
import { TooltipPosition } from './tooltip.types';

@Component({
  selector: 'ui-tooltip',
  template: `
    <button
      class="tooltip-container"
      [uiTooltip]="message"
      [tooltipPosition]="position"
      (clickOutside)="close()"
      *ngIf="!!this.message"
    >
      <div #content><ng-content></ng-content></div>
      <i class="ico ico-info-dark-blue" aria-hidden="true" *ngIf="content.children.length === 0"></i>
      <span class="cdk-visually-hidden">Activer le button pour avoir les informations</span>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tooltip.component.scss'],
})
export class UiTooltipComponent {
  @Input() message = '';
  @Input() position: TooltipPosition;
  @Input() @BooleanProperty() @HostBinding('class.no-margin') noMargin = false;

  constructor(private readonly _elementRef: ElementRef<HTMLElement>) {}

  close(): void {
    this._elementRef.nativeElement.blur();
  }
}

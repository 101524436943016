<form class="form" [formGroup]="regForm" (ngSubmit)="onSubmit()" novalidate>
  <div id="identity">
    <h2>Votre demande</h2>
    <div formFieldControl id="clientFunction" class="form-field-control--large-size">
      <label>
        Votre relation avec LCL : *
        <form-field-select
          formFieldSelect
          [options]="clientFunctionOptions"
          [formControlName]="'clientFunction'"
          [selectError]="selectError"
          [placeholder]="placeholder_relation"
        ></form-field-select>
      </label>
      <form-field-error></form-field-error>
    </div>
  </div>

  <p class="mandatory-field-text">*Champs obligatoires</p>
  <p>
    {{ text_rgpd }}
  </p>

  <div class="button-wrapper">
    <cb-cta-btn-link
      class="button"
      [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
  </div>
</form>

import { Component, HostBinding, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { AppsAdSlice, Image } from '@repo/shared';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';
import { getImageTag } from '../../../helpers/file.helpers';

@Component({
  selector: 'slice-apps-ad',
  template: `
    <cb-mini-title *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>
    <div>
      <div class="left-pane">
        <div class="features-block">
          <div [innerHTML]="data.description"></div>
        </div>
        <div class="picture-block">
          <img [attr.src]="data.appsAdImages.picture.url" [attr.alt]="data.appsAdImages.picture.alt" />
        </div>
      </div>
      <div class="right-pane">
        <div class="app-block">
          <img [attr.src]="data.appsAdImages.app.url" [attr.alt]="data.appsAdImages.app.alt" />
        </div>
        <div class="store-block">
          <div>
            <img class="year-img" [attr.src]="data.appsAdImages.year.url" [attr.alt]="data.appsAdImages.year.alt" />
            <div class="store-links">
              <a
                *ngFor="let store of data.appStores"
                [attr.href]="store.link.url"
                [attr.target]="store.link.target"
                analyticsOn
                [analyticsLabel]="getAnalyticsLabel(store.image)"
              >
                <img [attr.src]="store.image.url" [attr.alt]="store.image.alt" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./slice-apps-ad.component.scss'],
})
export class SliceAppsAdComponent implements SliceWithData {
  @Input() data: AppsAdSlice;

  @HostBinding('attr.id')
  public get id(): string | null {
    return this.data?.anchor || null;
  }

  getAnalyticsLabel(image: Image): string {
    return SLICE_TAGGING_NAME[this.data.type] + '_' + getImageTag(image);
  }
}

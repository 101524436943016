import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HrefPhoneDirective } from './href-phone/href-phone.directive';
import { UiStickyModule } from './sticky/sticky.module';

@NgModule({
  imports: [CommonModule, UiStickyModule],
  declarations: [HrefPhoneDirective],
  exports: [HrefPhoneDirective, UiStickyModule],
})
export class AtomsModule {}

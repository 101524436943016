import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JsonLdModule } from '../json-ld/json-ld.module';

import { AlertBannerModule } from '../alert-banner/alert-banner.module';
import { CmsRoutingModule } from './cms.routing';
import { CmsComponent } from './cms.component';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { PageService } from './page.service';
import { SmartBannerModule } from '../smart-banner/smart-banner.module';
import { UnsupportedBrowserModule } from '../ui/molecules/unsupported-browser/unsupported-browser.module';
import { AppModalModule } from '../ui/templates/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    CmsRoutingModule,
    HeaderModule,
    FooterModule,
    AlertBannerModule,
    SmartBannerModule,
    JsonLdModule,
    UnsupportedBrowserModule,
    AppModalModule,
  ],
  providers: [PageService],
  declarations: [CmsComponent],
})
export class CmsModule {}

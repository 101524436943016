import {
  Component,
  forwardRef,
  EventEmitter,
  Output,
  Input,
  Directive,
  Inject,
  Self,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractFormComponent } from '../../../cms/slices/slice-forms/abstract-form-component';
import { FORM_COMPONENT } from '../../../../tokens';
import { JSONSchema7 } from 'json-schema';

@Directive({
  selector: 'form-field-select[formFieldSelect]',
  exportAs: 'formFieldSelect',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-select',
  },
})
export class FormFieldSelectDirective implements OnInit, OnDestroy {
  @Input() formControlName: string;

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  private _subscription: Subscription = new Subscription();

  private _validationSchema: JSONSchema7;

  constructor(
    @Inject(FORM_COMPONENT) public readonly _parentSliceFormComponent: AbstractFormComponent,
    @Self() public readonly ngControl: NgControl,
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  ngOnInit(): void {
    if (this._parentSliceFormComponent) {
      this._validationSchema = this._parentSliceFormComponent.getFormFieldValidationSchema(this.formControlName);
    } else {
      throw Error(
        'You must to create a token injection to define which' +
          ' class implements the abstract SliceFormComponent class.',
      );
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }
}

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'form-field-select',
  template: `
    <ng-select
      [ngClass]="{ 'form-field-select--invalid': this.selectError || this.touchError }"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="placeholder || 'Sélectionner dans la liste'"
      (change)="_selectionChange($event)"
      [ngModel]="selectedValue"
    >
      <ng-container *ngIf="sendValue === 'string'">
        <ng-option *ngFor="let option of options" [value]="option">{{ option }} </ng-option>
      </ng-container>
      <ng-container *ngIf="sendValue === 'number'">
        <ng-option *ngFor="let option of options; index as value" [value]="value">{{ option }} </ng-option>
      </ng-container>
    </ng-select>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldSelectComponent),
      multi: true,
    },
  ],
})
export class FormFieldSelectComponent implements ControlValueAccessor {
  public value: any;
  public touchError = false;
  _parentSliceFormComponent: any;
  _validationSchema: JSONSchema7;

  @Input() options: [];
  @Input() selectError: boolean;
  @Output() selectionChange = new EventEmitter();
  @Input() formControlName: string;
  @Input() selectedValue: string;
  @Input() sendValue: 'string' | 'number' = 'number';
  @Input() placeholder: string;

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  onChangeEvent(value: any): void {
    this.selectionChange.emit(value);
  }

  _selectionChange(value: any): void {
    this.setValue(value);

    this._changeDetectorRef.detectChanges();
  }

  setValue(value: any): void {
    this.value = value;
    // eslint-disable-next-line no-extra-boolean-cast
    this.selectError = this.touchError = !!!value.toString() ? true : false;
    this.onTouched();
    this.onChange(value);
    this.onChangeEvent(value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: any) => void): void {
    this.onTouched = fn;
  }
}

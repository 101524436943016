import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Optional,
} from '@angular/core';
import { Link, Logos } from '@repo/shared';
import { TagCommanderService } from '../services/tag-commander.service';
import { WindowLoadEventService } from '../window-load-event.service';
import { first } from 'rxjs/operators';

const CLOSE_BUTTON_ICON = '/assets/images/ic_bt_close_smart.svg';

@Component({
  selector: 'app-smart-banner',
  template: `
    <div class="wrapper">
      <div class="message" [innerHTML]="message"></div>
      <div class="test">
        <cb-cta-btn-link class="button_ca" [button]="button" (click)="handleButtonClicked()"></cb-cta-btn-link>
        <img
          *ngIf="image && image.url"
          [attr.src]="image.url"
          [attr.width]="image.dimensions.width"
          [attr.height]="image.dimensions.height"
        />
        <img
          class="close-button"
          (click)="handleBannerClosed()"
          [attr.src]="closeButtonIcon"
          alt="icone de fermeture"
        />
      </div>
    </div>
  `,
  styleUrls: ['./smart-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartBannerComponent implements AfterViewInit {
  closeButtonIcon: string = CLOSE_BUTTON_ICON;

  @Input() message: string;
  @Input() image: Logos;
  @Input() button: Link;
  @Input() campaignCode: string;

  @Output() closeSmartBanner = new EventEmitter<any>();

  private readonly TAG_SUBPATH = 'Header::Smartapp_banner';

  constructor(
    private readonly tagCommanderService: TagCommanderService,
    @Optional() private readonly windowLoadEventService: WindowLoadEventService,
  ) {}

  ngAfterViewInit(): void {
    if (this.windowLoadEventService && this.campaignCode) {
      this.windowLoadEventService.load$.pipe(first()).subscribe(() => {
        this.tagCommanderService.trackEventPrint(this.campaignCode);
      });
    }
  }

  handleBannerClosed(): void {
    this.tagCommanderService.trackClickNavigationEvent('Fermeture', this.TAG_SUBPATH);
    this.closeSmartBanner.emit();
  }

  handleButtonClicked(): void {
    this.tagCommanderService.trackClickActionEvent(this.button.label as string, this.TAG_SUBPATH);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Link } from '@repo/shared';
import { TagCommanderService } from '../../../../services/tag-commander.service';

@Component({
  selector: 'zone1-tableau-comparatif',
  template: `
    <div class="zone1" [ngStyle]="{ 'background-color': data.couleur_fond_zone_1 }">
      <div class="lisere" [ngStyle]="{ 'background-color': data.couleur_lisere }"></div>
      <h3 *ngIf="data.titre_tableau" class="title clamp-lines">{{ data.titre_tableau }}</h3>
      <ng-container *ngIf="data.texte_lisere">
        <div class="sous-titre" [ngStyle]="{ background: selectSousTitreBackground() }">
          <h4 class="clamp-single-line">
            {{ data.texte_lisere }}
          </h4>
        </div>
      </ng-container>
      <div class="tableau-icones">
        <ng-container *ngIf="data.tableau_picto_zone_1.length > 1">
          <liste-pictos
            [ngStyle]="{ 'background-color': data.couleur_fond_zone_1 }"
            *ngIf="data.tableau_picto_zone_1.length > 1"
            [data]="data.tableau_picto_zone_1"
          ></liste-pictos>
        </ng-container>
        <ng-container *ngIf="data.tableau_picto_zone_2.length > 1">
          <liste-pictos
            [ngStyle]="{ 'background-color': data.couleur_fond_zone_2 }"
            *ngIf="data.tableau_picto_zone_2.length > 1"
            [data]="data.tableau_picto_zone_2"
          ></liste-pictos>
        </ng-container>
      </div>
      <zone-promo class="zone-promo" *ngIf="isZonePromoVisible" [data]="data"></zone-promo>
      <div class="zone3">
        <zone-prix [data]="data"></zone-prix>
        <zone-description-tableau [data]="data"></zone-description-tableau>
        <div class="button-wrapper" *ngIf="this.data.libelle_bouton && this.data.url_bouton.url">
          <cb-cta-btn-link
            class="action-button"
            [button]="CTAButton"
            type="primary"
            (linkClicked)="clickHandler(this.data.libelle_bouton)"
          ></cb-cta-btn-link>
        </div>
        <zone-liste-avantages [data]="data"></zone-liste-avantages>
      </div>
    </div>
  `,
  styleUrls: ['./zone1-tableau-comparatif.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Zone1TableauComparatifComponent {
  @Input() data: any;

  constructor(private readonly tagCommanderService: TagCommanderService) {}

  get CTAButton(): Link {
    return {
      label: this.data.libelle_bouton,
      url: this.data.url_bouton.url,
      target: this.data.url_bouton.target,
    };
  }

  clickHandler(buttonLabel: string): void {
    this.tagCommanderService.trackClickNavigationEvent(buttonLabel);
  }

  // la zone promo est visible si :
  // On a pas de tableau picto
  // si on a un tableau picto, sa taille doit être supérieure à 1
  // Et la zone doit avoir des données entrables dedans !
  get isZonePromoVisible(): boolean {
    return (
      !(this.data.tableau_picto_zone_2 && this.data.tableau_picto_zone_2.length > 1) && this.data.couleur_fond_zone_2
    );
  }

  selectSousTitreBackground(): string {
    return this.data.couleur_lisere_2 || 'none';
  }
}

import { forkJoin, Observable } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationError, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { AgencyDetailsSlice, Data, Link, Page } from '@repo/shared';
import { AGENCY_DETAILS_DOCUMENT } from '@repo/shared/cms.pages';
import { AgenciesService } from '../../../../../src/app/services/agencies.service';
import { Agency } from '../../../../../src/app/services/agencies.type';
import { HttpStatusCode } from '../../../common/constants/http-status-code';
import { ErrorType, PageNotFoundError } from '../../../errors';
import { HttpResponseService } from '../../../http-response.service';
import { StateService } from '../../../services/state.service';
import { FooterService } from '../../footer/footer.service';
import { HeaderService } from '../../header/header.service';
import { PageService } from '../../page.service';
import { NotFoundService } from '../not-found.service';

interface AgencyIdNameMatch {
  agencyId: string;
  agencyName: string;
}

@Injectable()
export class AgencyDetailsResolverService implements Resolve<Page> {
  constructor(
    private readonly pageService: PageService,
    private readonly headerService: HeaderService,
    private readonly footerService: FooterService,
    private readonly responseService: HttpResponseService,
    private readonly notFoundService: NotFoundService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly stateService: StateService,
    private readonly agencyService: AgenciesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Page> {
    const uid = AGENCY_DETAILS_DOCUMENT.uid;

    const {
      agencyId,
      // agencyName,
    } = this.matchAgencyIdName(route.params.agencyIdName);
    if (!agencyId) {
      return this.resolveNotFoundErrorPage();
    }

    return forkJoin([this.getPageByUid(uid), this.agencyService.getAgencyDetails(agencyId)]).pipe(
      tap(([page]) => this.reloadHeaderFooterIfNecessary(page)),
      map(([page, agency]) => {
        const agencyDetailsSlice = this.findAgencyDetailsSlice(page);
        if (!agencyDetailsSlice || !agency) {
          throw new PageNotFoundError();
        }
        this.mutatePageData(page, agency.name);
        this.mutatePageAgencyDetailsSlice(agencyDetailsSlice, agency);
        return page;
      }),
      catchError(error => {
        if (error.type === ErrorType.PageNotFound) {
          return this.resolveNotFoundErrorPage();
        }
        this.preventRoutingToRootWhenError(state.url);
        throw error;
      }),
    );
  }

  private matchAgencyIdName(agencyIdName: string): AgencyIdNameMatch {
    // eslint-disable-next-line no-useless-escape
    const agencyIdNameRegex = /^(\d{5})-([a-z0-9\-\/\']+)$/i;
    agencyIdName = decodeURIComponent(agencyIdName);
    const [, agencyId, agencyName]: string[] = (agencyIdName || '').match(agencyIdNameRegex) || [];
    return { agencyId, agencyName };
  }

  private resolveNotFoundErrorPage(): Observable<Page> {
    this.responseService.setStatus(HttpStatusCode.NotFound);
    return this.notFoundService.getNotFoundPage().pipe(tap(page => this.reloadHeaderFooterIfNecessary(page)));
  }

  private reloadHeaderFooterIfNecessary(page: Page): void {
    const homepageId = page.data.homepageId as string;
    if (homepageId !== this.stateService.get().homepageId) {
      this.headerService.reload(homepageId);
      this.footerService.reload(homepageId);
      this.stateService.update({ homepageId });
    }
  }

  private getPageByUid(uid: string): Observable<Page> {
    return this.pageService.getPageByUid(uid, {}, false);
  }

  private findAgencyDetailsSlice(page: Page): AgencyDetailsSlice {
    return (page.data.pageContent?.body.find(
      slice => slice.type === 'agency_details',
    ) as unknown) as AgencyDetailsSlice;
  }

  private mutatePageData(page: Page, agencyName: string): void {
    const homePageLink = page.data.breadcrumb?.links[0] as Link;
    const agenceBanqueLink: Link = { label: 'Nos Agences', url: '/agence-banque' };
    const agencyDetailsLink: Link = { label: agencyName, url: null };

    const {
      data: { breadcrumb, seo },
    } = page;

    page.data = {
      ...page.data,
      breadcrumb: {
        ...breadcrumb,
        links: [homePageLink, agenceBanqueLink, agencyDetailsLink],
      },
      seo: {
        ...seo,
        title: `${agencyName} - Banque et Assurance`,
      },
    } as Data;
  }

  private mutatePageAgencyDetailsSlice(agencyDetailsSlice: AgencyDetailsSlice, agency: Agency): void {
    agencyDetailsSlice.agencyDetails = agency;
    agencyDetailsSlice.title = agency.name;
  }

  private preventRoutingToRootWhenError(currentUrl: string): void {
    // Workaround similar to:
    // https://github.com/angular/angular/issues/16981#issuecomment-397218203
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationError),
        take(1),
      )
      .subscribe(() => this.location.replaceState(currentUrl));
  }
}

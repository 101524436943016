import { Inject, Injectable, OnDestroy } from '@angular/core';
import { newSweetScroll, SweetScroll, SweetScrollOptions } from '../vendors/sweet-scroll';
import { Subscription } from 'rxjs';
import { WINDOW } from './window.provider';
import { ResponsiveService } from './responsive.service';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { STICKY_HEADER_HEIGHT_DESKTOP } from '../common/constants/sizes';
import { getAbsoluteOffsets } from '../helpers/dom.helpers';

export const SCROLLER_OFFSET_TOP = 30;

@Injectable({
  providedIn: 'root',
})
export class DOMService implements OnDestroy {
  private _viewportOffset = [0, 0];
  private _sweetScroll: SweetScroll;
  private _subscription: Subscription;

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    readonly responsiveService: ResponsiveService,
    readonly activatedRoute: ActivatedRoute,
    readonly viewportScroller: ViewportScroller,
  ) {
    if (this.win) {
      const scrollerOffsetWithStickyHeader = this.activatedRoute.snapshot.queryParams.iframe
        ? SCROLLER_OFFSET_TOP
        : STICKY_HEADER_HEIGHT_DESKTOP + SCROLLER_OFFSET_TOP;

      this._subscription = this.responsiveService.subscribeOnDesktop(matches => {
        if (matches) {
          this.setViewportOffset([0, scrollerOffsetWithStickyHeader]);
        } else {
          this.setViewportOffset([0, SCROLLER_OFFSET_TOP]);
        }
      });
    }

    this._sweetScroll = newSweetScroll();
  }

  scrollTo(element: HTMLElement, options?: Partial<SweetScrollOptions>): void {
    if (!element) {
      return;
    }
    const elementOffsets = getAbsoluteOffsets(element);
    const top = elementOffsets.top - this._viewportOffset[1];

    this._sweetScroll.to(
      { left: 0, top },
      {
        ...options,
        duration: 2500,
        easing: 'easeOutExpo',
        cancellable: true,
      },
    );
  }

  ngOnDestroy(): void {
    if (this._sweetScroll) {
      this._sweetScroll.destroy();
    }

    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  setViewportOffset(offset: [number, number]): void {
    this._viewportOffset = offset;
    this.viewportScroller.setOffset(offset);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { CommonBehaviorsModule } from '../../common-behaviors/common-behaviors.module';
import { CarouselPaneComponent } from './carousel-pane/carousel-pane.component';
import { SliceCarouselServicesComponent } from './slice-carousel-services.component';

@NgModule({
  imports: [CommonModule, CommonBehaviorsModule, InViewportModule],
  declarations: [CarouselPaneComponent, SliceCarouselServicesComponent],
  exports: [SliceCarouselServicesComponent],
  entryComponents: [SliceCarouselServicesComponent],
})
export class SliceCarouselServicesModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { FaqAccordionSlice } from '@repo/shared';
import { WithContext, FAQPage } from 'schema-dts';
import { SchemaOrgService } from '../../schema-org.service';

@Component({
  selector: 'slice-faq-accordion',
  template: `
    <ui-expansion-list [id]="data.anchor">
      <ui-expansion-panel *ngFor="let question of data.questions" [title]="question.text">
        <div [innerHTML]="question.answer" class="answer"></div>
      </ui-expansion-panel>
    </ui-expansion-list>
    <app-json-ld *ngIf="schema" [json]="schema"></app-json-ld>
  `,
  styleUrls: ['./slice-faq-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceFaqAccordionComponent implements SliceWithData {
  @Input() data: FaqAccordionSlice;

  constructor(private readonly schemaOrgService: SchemaOrgService) {}

  get schema(): WithContext<FAQPage> | undefined {
    return this.schemaOrgService.getFAQPageSchema(this.data && this.data.questions);
  }
}

export interface SweetScroll {
  to(distance: any, options?: Partial<SweetScrollOptions>): void;
  toTop(distance: any, options?: Partial<SweetScrollOptions>): void;
  toLeft(distance: any, options?: Partial<SweetScrollOptions>): void;
  toElement($element: Element, options?: Partial<SweetScrollOptions>): void;
  stop(gotoEnd?: boolean): void;
  update(options: Partial<SweetScrollOptions>): void;
  destroy(): void;
}

export interface SweetScrollOptions {
  trigger: string;
  header: string | Element;
  duration: number;
  easing: string | EasingFunction;
  offset: number;
  vertical: boolean;
  horizontal: boolean;
  cancellable: boolean;
  updateURL: boolean | string;
  preventDefault: boolean;
  stopPropagation: boolean;
  quickMode: boolean;
  before: BeforeHandler | null;
  after: AfterHandler | null;
  step: StepHandler | null;
  cancel: CancelHandler | null;
  complete: CompleteHandler | null;
}

export type EasingFunction = (x: number, t: number, b: number, c: number, d: number, s?: number) => number;
export type BeforeHandler = (offset: Offset, $trigger: Element | null, scroller: SweetScroll) => boolean | void;
export type AfterHandler = (offset: Offset, $trigger: Element | null, scroller: SweetScroll) => void;
export type StepHandler = (time: number, scroller: SweetScroll) => void;
export type CancelHandler = (scroller: SweetScroll) => void;
export type CompleteHandler = (isCancel: boolean, scroller: SweetScroll) => void;

export interface Offset {
  top: number;
  left: number;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _SweetScroll: any = require('sweet-scroll');

export function newSweetScroll(options?: Partial<SweetScrollOptions>, container?: string | Element): SweetScroll {
  let sweetScroll: SweetScroll;

  if (options && container) {
    sweetScroll = new _SweetScroll(options, container);
  } else if (options) {
    sweetScroll = new _SweetScroll(options);
  } else {
    sweetScroll = new _SweetScroll();
  }

  return sweetScroll;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FiltersBlocksService } from '../../../services/filters-blocks.service';

@Component({
  selector: 'filter-block',
  template: `
    <div class="dropdown-container" [ngClass]="filterBlockPositionClasses" [class.down]="showFilters">
      <span *ngIf="selectedFilters?.length" class="circle"></span>
      <span>{{ filterLabel }}</span>
      <div class="arrow-icon ic ic-chevron" (click)="this.toggleShowFilters()"></div>
    </div>
    <div class="filters-container" *ngIf="showFilters">
      <span
        class="rectangle"
        [class.selected]="f.selected"
        *ngFor="let f of filtersObjects"
        (click)="triggerFilterClicked(f.name)"
      >
        {{ f.name }}
      </span>
    </div>
  `,
  styleUrls: ['./filter-block.scss'],
})
export class FilterBlockComponent implements OnInit, OnDestroy {
  @Output() filtersClickedEvent = new EventEmitter<Map<number, string[]>>();

  @Input() filterLabel: string;
  @Input() filters: string[];
  @Input() filterNumber: number;

  showFilters = false;
  selectedFilters: string[] = [];
  filtersObjects: any = [];

  subscription: Subscription;

  get filterBlockPositionClasses(): string {
    if (this.filterNumber === 1) {
      return 'first-filter';
    } else if (this.filterNumber === 2) {
      return 'second-filter';
    }
    return (null as unknown) as string;
  }

  constructor(private filtersBlocksService: FiltersBlocksService) {}

  ngOnInit(): void {
    this.subscription = this.filtersBlocksService.filterToggled$.subscribe(lastToggledfilterNumber => {
      if (this.filterNumber !== lastToggledfilterNumber) {
        if (this.showFilters) {
          this.showFilters = !this.showFilters;
        }
      }
    });

    this.filters.map(f =>
      this.filtersObjects.push({
        name: f,
        selected: false,
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  triggerFilterClicked(filterName: string): void {
    this.filtersObjects.forEach(f => {
      if (f.name === filterName) {
        f.selected = !f.selected;
      }
    });
    this.selectedFilters = this.filtersObjects.filter(f => f.selected).map(f => f.name);
    this.filtersClickedEvent.emit(new Map().set(this.filterNumber, this.selectedFilters));
  }

  toggleShowFilters(): void {
    this.showFilters = !this.showFilters;
    this.filtersBlocksService.updateToggle(this.filterNumber);
  }

  public resetButtonFilters(): void {
    this.selectedFilters = [];
    this.showFilters = false;
    this.filtersObjects.forEach(f => (f.selected = false));
    this.filtersBlocksService.closeDropDowns();
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Observable, Subject, Subscription, zip } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { JSONSchema7 } from 'json-schema';
import { FormControlStatus } from '../form-field-control/form-field-control.directive';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[formFieldRadioGroup]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'radiogroup',
    class: 'form-field-radio-group',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldRadioGroupDirective),
      multi: true,
    },
  ],
})
export class FormFieldRadioGroupDirective implements AfterViewInit, OnDestroy {
  readonly statusChanges: Subject<FormControlStatus> = new Subject<FormControlStatus>();

  get validationSchema(): JSONSchema7 {
    return this._validationSchema;
  }

  get model(): any {
    return this._model;
  }

  set model(value: any) {
    if (this._model !== value) {
      this._model = value;
      this._updateSelectedRadioFromValue();
      this._checkSelectedRadioButton();
    }
  }

  get selected(): FormFieldRadioComponent | null {
    return this._selected;
  }

  set selected(selected: FormFieldRadioComponent | null) {
    this._selected = selected;
    this.model = selected ? selected.value : null;
    this._checkSelectedRadioButton();
  }

  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get firstNgControl(): NgControl {
    return this._radioFieldChildren && this._radioFieldChildren.first.ngControl;
  }

  @ContentChildren(forwardRef(() => FormFieldRadioComponent), { descendants: true }) // tslint:disable-line:no-use-before-declare
  private _radioFieldChildren: QueryList<FormFieldRadioComponent>;
  private _subscription = new Subscription();
  private _validationSchema: JSONSchema7;
  private _model: any = null;
  private _selected: FormFieldRadioComponent | null = null;
  private _required = false;

  onTouched: any = () => {};

  ngAfterViewInit(): void {
    if (this._radioFieldChildren) {
      const childrenNgControl: NgControl[] = this._radioFieldChildren
        .map(radioChild => radioChild.ngControl)
        .filter(ngControl => ngControl);

      if (childrenNgControl.length) {
        const childrenNgControlStatusChanges$ = childrenNgControl
          .map(ngControl => ngControl.statusChanges as Observable<any>)
          .filter(Boolean);

        this._subscription = zip(...childrenNgControlStatusChanges$)
          .pipe(
            map((values: FormControlStatus[]) => {
              return values.reduce<FormControlStatus>((acc, value) => (value !== 'VALID' ? value : acc), 'VALID');
            }),
          )
          .subscribe(status => this.statusChanges.next(status));
      }
    }

    this._bindToAttributes();
  }

  _touch(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  _checkSelectedRadioButton(): void {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
    this.statusChanges.complete();
  }

  private _bindToAttributes(): void {
    if (this.validationSchema) {
      const { minLength } = this.validationSchema;

      if ((minLength as number) > 0) {
        this.required = true;
      }
    }
  }

  private _updateSelectedRadioFromValue(): void {
    const isAlreadySelected = this.selected !== null && this.selected.value === this.model;

    if (this._radioFieldChildren && !isAlreadySelected) {
      this._selected = null;
      this._radioFieldChildren.forEach(radio => {
        radio.checked = this.model === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }
}

let nextUniqueId = 0;

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'form-field-radio',
  template: `
    <input
      #input
      class="form-field-radio-input cdk-visually-hidden"
      type="radio"
      autocomplete="off"
      [id]="inputId"
      [attr.name]="name"
      [required]="required"
      (change)="_onInputChange($event)"
      (click)="_onInputClick($event)"
    />
    <label [attr.for]="inputId" class="form-field-radio-label">
      <ng-content></ng-content>
    </label>
  `,
})
export class FormFieldRadioComponent implements OnDestroy, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() name: string;
  @Input() displayMode = 'inclusiveLabel';

  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('input', { static: true }) _inputRef: ElementRef<HTMLInputElement>;
  @Input() @HostBinding('attr.tabindex') tabindex = '-1';

  @HostBinding('class.form-field-radio')
  get inclusiveLabelRadioButton(): boolean {
    return this.displayMode === 'inclusiveLabel';
  }

  @HostBinding('class.form-field-round-radio')
  get asideLabelRadioButton(): boolean {
    return this.displayMode === 'asideLabel';
  }

  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    value = coerceBooleanProperty(value);
    if (this._checked !== value) {
      this._checked = value;
      if (value && this._radioGroup && this._radioGroup.model !== this.value) {
        this._radioGroup.selected = this;
      } else if (!value && this._radioGroup && this._radioGroup.model === this.value) {
        this._radioGroup.selected = null;
      }

      if (value) {
        this._radioDispatcher.notify(this.id, this.name);
      }

      this._changeDetectorRef.markForCheck();
    }
  }

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  get required(): boolean {
    return this._radioGroup && this._radioGroup.required;
  }

  get value(): string {
    return this._value;
  }

  @Input()
  set value(value: string) {
    if (this.value !== value) {
      this._value = value;
      if (this._radioGroup !== null) {
        if (!this.checked) {
          this.checked = this._radioGroup.model === value;
        }
        if (this.checked) {
          this._radioGroup.model = this;
        }
      }
    }
  }

  get model(): string {
    return this._model;
  }

  set model(value: string) {
    if (this.model !== value) {
      this._model = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  @HostBinding('class.form-field-radio--checked') private _checked: boolean;

  private _radioGroup: FormFieldRadioGroupDirective;
  private _subscription: Subscription = new Subscription();
  private _uniqueId = `form-field-radio-${++nextUniqueId}`;

  @Input() @HostBinding('attr.id') id: string = this._uniqueId;

  private _value: string = (null as unknown) as string;
  private _model: string;

  constructor(
    @Optional() radioGroup: FormFieldRadioGroupDirective,
    @Self() @Optional() public ngControl: NgControl,
    private _changeDetectorRef: ChangeDetectorRef,
    private _radioDispatcher: UniqueSelectionDispatcher,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this._radioGroup = radioGroup;

    this._removeListener = _radioDispatcher.listen((id: string, name: string): void => {
      if (id !== this.id && name === this.name) {
        this.checked = false;
        this.model = (null as unknown) as string;
      }
    });
  }

  @HostListener('focus') onFocusEvent(): void {
    if (this._inputRef) {
      this._inputRef.nativeElement.focus();
      this.focused.emit(true);
      this.checked = true;
      this.model = this.value;
      this.onChangeEvent();

      if (this._radioGroup) {
        this._radioGroup._touch();
      }
      this._changeDetectorRef.detectChanges();
    }
  }

  onChangeEvent(): void {
    this.changed.emit(this.value);
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  @HostListener('click') onClickEvent(): void {
    if (this._inputRef) {
      this._inputRef.nativeElement.click();
    }
  }

  _onInputClick(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('change')
  _onInputChange(event: Event): void {
    event.stopPropagation();
    this.checked = true;
    this.model = this.value;
    this.onChangeEvent();

    if (this._radioGroup) {
      this._radioGroup._touch();
    }
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._removeListener();
    this._subscription.unsubscribe();
    this._subscription = (null as unknown) as Subscription;
  }

  writeValue(_value: string): void {
    // @note(enten_s) Instruction below seems to be a bug because on runtime I saw form
    // control call writeValue with value "" which will break radio group behavior.
    // It's an issue because writeValue() is a part of FormControlAccessor.
    // That means currently form control doesn't works as expected.
    // this.value = value;

    this._changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: any) => void): void {
    this.onTouched = fn;
  }

  private _removeListener: () => void = () => {};
}

import { Router, NavigationEnd } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { WINDOW } from '../../services/window.provider';

export const JS_CODE = ` const COOKIE_NAME = 'blnk_landing_page_parameters'; var urltoget = new URL(window.location.href); var cookie = Cookies.get(COOKIE_NAME); const parameters = cookie ? JSON.parse(cookie) : {}; urltoget.searchParams.forEach((value, key) => { parameters[key] = value } ); parameters.referrer = document.referrer; Cookies.set(COOKIE_NAME, JSON.stringify(parameters), {domain: '.lcl.fr'}); `;
export const CDN = 'https://cdn.jsdelivr.net/npm/js-cookie@rc/dist/js.cookie.min.js';

@Injectable()
export class HeaderLoadScriptService {
  externalScript: HTMLScriptElement | null;
  inlineScript: HTMLScriptElement | null;
  private readonly urlsToLoadScript = [
    '/professionnel',
    '/professionnel/compte-bancaire-professionnel/compte-essentiel-pro-en-ligne',
  ];

  constructor(@Inject(WINDOW) private win: Window, private router: Router) {}

  loadScriptOnRouteChange(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.shouldLoadScript(event.url)) {
        this.loadExternalScript(CDN);
        this.loadInlineScript(JS_CODE);
      } else {
        this.removeScript('external_script');
        this.removeScript('inline_script');
      }
    });
  }

  private shouldLoadScript(url: string): boolean {
    return this.urlsToLoadScript.some(u => url.includes(u));
  }

  private loadExternalScript(src: string): void {
    if (!this.externalScript) {
      this.externalScript = this.createScriptTag(src);
    }
  }

  private loadInlineScript(inlineCode: string): void {
    if (this.externalScript && !this.inlineScript) {
      this.inlineScript = this.createScriptTag(null);
      this.inlineScript.innerHTML = `${inlineCode}`;
    }
  }

  removeScript(id: string): void {
    const scriptToRemove = this.win.document.getElementById(id);
    if (scriptToRemove) {
      scriptToRemove.remove();
      this.externalScript = null;
      this.inlineScript = null;
    }
  }

  private createScriptTag(src: string | null): HTMLScriptElement {
    const scriptElement = this.win.document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.id = src ? 'external_script' : 'inline_script';

    if (src) {
      scriptElement.src = src;
    }

    this.win.document.head.appendChild(scriptElement);
    return scriptElement;
  }
}

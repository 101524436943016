import { Component, Input } from '@angular/core';
import { ArticleHead } from '@repo/shared';

@Component({
  selector: 'article-head',
  template: `
    <div class="content" *ngIf="head && head.title && head.description">
      <div>
        <h1 class="title">{{ head.title }}</h1>
        <cb-article-info-bar
          [publishOn]="head.publishOn"
          [readingTime]="head.readingTime"
          [type]="head.type"
        ></cb-article-info-bar>
        <div class="description" [innerHTML]="head.description"></div>
      </div>
      <div>
        <img *ngIf="head.image.url" [attr.src]="head.image.url" [attr.alt]="head.image.alt" />
      </div>
    </div>
  `,
  styleUrls: ['./article-head.component.scss'],
})
export class ArticleHeadComponent {
  @Input() head: ArticleHead;
}

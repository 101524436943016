import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { GraphQLModule } from './graphql.module';
import { HeaderModule } from './cms/header/header.module';
import { FooterModule } from './cms/footer/footer.module';
import { TechnicalErrorComponent } from './cms/page/technical-error/technical-error.component';
import { ObservableErrorHandler } from './services/observable-error-handler.service';
import { AppHammerConfig } from './app-hammer.config';

export const APP_ID = 'lcl_fr';

@NgModule({
  declarations: [AppComponent, TechnicalErrorComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: APP_ID }),
    HammerModule,
    GraphQLModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    NgProgressModule.withConfig({
      spinner: false,
      meteor: false,
    }),
    NgProgressHttpModule,
  ],
  exports: [GraphQLModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ObservableErrorHandler,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { AfterViewInit, Component, ElementRef, HostBinding, Inject, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { BlocSizeType, SolutionProduct } from '@repo/shared';
import { SLICE_TAGGING_NAME } from '../../../../common/constants/tagging';
import { WINDOW } from '../../../../services/window.provider';
import { triggerElementByClassName } from '../../../../helpers/dom.helpers';

@Component({
  selector: 'ssp-solution-product',
  template: `
    <div *ngIf="data">
      <cb-scalable-img class="mobile-only" [image]="data.image?.mobile" position="top"></cb-scalable-img>
      <cb-responsive-img [desktopImage]="data.image"></cb-responsive-img>
      <div class="card-content">
        <h2 class="title" [innerHTML]="data.title"></h2>
        <p class="description" [innerHTML]="data.description"></p>
        <ul *ngIf="data.linkedProducts" class="linked-products">
          <li *ngFor="let product of data.linkedProducts" class="linked-product">
            <a
              [href]="product.url"
              class="product-link"
              analyticsOn
              [analyticsLabel]="getProductAnalyticsLabel(product.title)"
            >
              <h3 class="label">{{ product.title }}</h3>
              <div class="product-link-content" [innerHTML]="product.description"></div>
            </a>
            <cb-cta-link
              *ngIf="product.simulatorLink"
              [link]="product.simulatorLink"
              class="simulator-link"
              [analyticsLabel]="getSimulatorAnalyticsLabel(product.title)"
            ></cb-cta-link>
          </li>
        </ul>
        <div *ngIf="data.complementContact" class="complement-contact" [innerHTML]="data.complementContact"></div>
      </div>
    </div>
  `,
  styleUrls: ['./solution-product.component.scss'],
})
export class SolutionProductComponent implements AfterViewInit {
  @Input() data: SolutionProduct;

  protected _right: boolean;

  constructor(@Inject(WINDOW) private win: Window, private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    if (this.win) {
      triggerElementByClassName(this.win, this.elementRef, '.gal', this.handleAnchorClick);
    }
  }
  handleAnchorClick = event => {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    const link = anchor.href.startsWith('https://outil') ? anchor.href.substring(7) : anchor.href;
    this.win.parent.postMessage({ link }, '*');
  };

  get right(): boolean {
    return this._right;
  }

  @Input() set right(value: boolean) {
    this._right = coerceBooleanProperty(value);
  }

  @HostBinding('class.large')
  get cssClassLarge(): boolean {
    return !!this.data && this.data.imageSize === BlocSizeType.Large;
  }

  @HostBinding('class.right')
  get cssClassEven(): boolean {
    return this.right;
  }

  @HostBinding('attr.id')
  get anchor(): string | null {
    return this.data?.anchor || null;
  }

  getProductAnalyticsLabel(linkLabel: string): string {
    return `${SLICE_TAGGING_NAME['solution_products']}_${linkLabel}`;
  }

  getSimulatorAnalyticsLabel(linkLabel: string): string {
    return `${SLICE_TAGGING_NAME['solution_products']}_Simulation_${linkLabel}`;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { PipesModule } from '../../pipes/pipes.module';
import { PageComponent } from './page/page.component';
import { PageResolverService } from './page-resolver.service';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
import { PagesModule } from './pages/pages.module';
import { SlicesModule } from '../slices/slices.module';
import { AnalyticsModule } from '../../analytics/analytics.module';
import { CommonBehaviorsModule } from '../common-behaviors/common-behaviors.module';
import { UiStickyModule } from '../../ui/atoms/sticky/sticky.module';
import { ProfessionalsConnectionResolverService } from './professionals-connection/professionals-connection-resolver.service';
import { PROFESSIONALS_CONNECTION_PATH, AGENCY_DETAILS_PATH } from '../../common/constants/routes';
import { AgencyDetailsResolverService } from './agency-details/agency-details-resolver.service';

// WARNING! In projects/front/src/api/index.ts, the ngRenderMiddleware
// reflect the routing logic of the angular application to fetch
// content data from bff before server side rendering.
// In that way, if the content data for dynamic page doesn't exist,
// we avoid server side rendering and return not found page directly.
//
// If you add more routes, you will need to adjust logic inside
// page-routing.helpers.ts.
const routes: Routes = [
  {
    path: AGENCY_DETAILS_PATH,
    component: PageComponent,
    resolve: {
      page: AgencyDetailsResolverService,
    },
  },
  {
    path: PROFESSIONALS_CONNECTION_PATH,
    component: PageComponent,
    resolve: {
      page: ProfessionalsConnectionResolverService,
    },
  },
  {
    path: ':market/:universe/:theme/:uid',
    component: PageComponent,
    resolve: {
      page: PageResolverService,
    },
  },
  {
    path: ':marketOrUniverse/:universeOrTheme/:uid',
    component: PageComponent,
    resolve: {
      page: PageResolverService,
    },
  },
  {
    path: ':marketOrUniverse/:uid',
    component: PageComponent,
    resolve: {
      page: PageResolverService,
    },
  },
  {
    path: ':uid',
    component: PageComponent,
    resolve: {
      page: PageResolverService,
    },
  },
  {
    path: '**',
    component: PageComponent,
    resolve: {
      page: PageResolverService,
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PipesModule,
    PagesModule,
    SlicesModule,
    AnalyticsModule,
    CommonBehaviorsModule,
    UiStickyModule,
  ],
  declarations: [PageComponent, DynamicPageComponent],
  entryComponents: [PageComponent, DynamicPageComponent],
})
export class PageRoutingModule {}

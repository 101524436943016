import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SimplePage } from '@repo/shared';
import { Location } from '@angular/common';
import { SlicesNewsBlocService } from '../../../../services/slices-newsbloc.service';

export const STYLE_CLASS_BY_PAGE_TYPE: Record<string, string> = {
  page_simple: 'simple-page',
  page_simple_institutional: 'simple-page',
  contact: 'contact',
  sitemap: 'sitemap',
  thematic_faq: 'thematic-faq',
  thematic_faq_free: 'thematic-faq',
  landing_page_step: 'landing-page-step',
  page_timeline: 'timeline-page',
  mag_thematic_page: 'mag-thematic-page',
  simulator_encapsulated: 'simulator-encapsulated',
};

@Component({
  selector: 'cms-simple-page',
  template: `
    <div *ngIf="data" class="wrapper" [ngClass]="classByPageType">
      <h1 class="title">{{ data.title }}</h1>
      <p class="subtitle" *ngIf="isTotalItemsVisible()">{{ totalItemsFormatted() }}</p>
      <div *ngIf="isBackLinkVisible()" class="back-link-line">
        <a [href]="data.backButtonLink.url" [attr.target]="data.backButtonLink.target" class="back-link">
          <span class="label">{{ data.backButtonLabel }}</span>
        </a>
      </div>
      <button *ngIf="isBackButtonVisible()" (click)="backToPreviousPage()" class="btn-back-to-previous-page">
        <span class="label">{{ data.backButtonLabel }}</span>
      </button>
      <cb-slices [data]="data.body"></cb-slices>
    </div>
  `,
  styleUrls: ['./simple-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SimplePageComponent implements OnInit {
  private totalItem = 0;

  @Input() data: SimplePage;
  @Input() type: string;

  constructor(
    private readonly location: Location,
    private slicesNewsBlocService: SlicesNewsBlocService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getObservableTotalItem();
    this.changeDetectorRef.detectChanges();
  }

  get totalArticles(): number {
    return this.totalItem;
  }

  get classByPageType(): string {
    return STYLE_CLASS_BY_PAGE_TYPE[this.type];
  }

  backToPreviousPage(): void {
    this.location.back();
  }

  isBackButtonVisible(): boolean {
    return this.data && this.type === 'landing_page_step' && !!this.data.backButtonLabel;
  }

  isBackLinkVisible(): boolean {
    return !!(this.data?.backButtonLabel && this.data.backButtonLink?.url);
  }

  /**
   * Subscribe to the observable number of articles
   */
  getObservableTotalItem(): void {
    this.slicesNewsBlocService.geTotalItemsSource$().subscribe(value => {
      this.totalItem = value;
    });
  }
  /**
   * Format articles to show
   */
  totalItemsFormatted(): string {
    return `${this.totalItem} article${this.totalItem > 1 ? 's' : ''}`;
  }

  /**
   * Show nombres articles conditions
   */
  isTotalItemsVisible(): boolean {
    return this.type === 'mag_thematic_page';
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliceFaqAccordionComponent } from './slice-faq-accordion.component';
import { UiExpansionPanelModule } from '../../../ui/molecules/expansion-panel/expansion-panel.module';
import { JsonLdModule } from '../../../json-ld/json-ld.module';

@NgModule({
  declarations: [SliceFaqAccordionComponent],
  imports: [CommonModule, UiExpansionPanelModule, JsonLdModule],
  exports: [SliceFaqAccordionComponent],
  entryComponents: [SliceFaqAccordionComponent],
})
export class SliceFaqAccordionModule {}

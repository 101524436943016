import { LandingPageEventHeroSlice } from '@repo/shared/index';
import { map } from 'rxjs/operators';
import { ResponsiveService } from '../../../services/responsive.service';
import { ChangeDetectionStrategy, Component, Input, Inject } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { WINDOW } from '../../../services/window.provider';
import { Observable } from 'rxjs';

@Component({
  selector: 'slice-landing-page-event-hero',
  template: `
    <div *ngIf="content$ | async as content" class="wrapper" [ngStyle]="{ backgroundImage: content.backgroundImage }">
      <div class="logo">
        <a
          *ngIf="shouldDisplayLogoWithLink; else noLinkLogo"
          [attr.href]="data.logo_link.url"
          [attr.target]="data.logo_link.target"
        >
          <img [src]="content.logoUrl" />
        </a>

        <ng-template #noLinkLogo>
          <img *ngIf="shouldDisplayLogo" [src]="content.logoUrl" />
        </ng-template>
      </div>

      <div class="main">
        <h1 class="title" [innerHTML]="data.title"></h1>
        <h2 class="subtitle" [innerHTML]="data.subtitle"></h2>
        <div class="accroche" *ngIf="data.accroche" [innerHTML]="data.accroche"></div>
      </div>
    </div>
  `,
  styleUrls: ['./slice-landing-page-event-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceLandingPageEventHeroComponent implements SliceWithData<LandingPageEventHeroSlice> {
  @Input() data: LandingPageEventHeroSlice;

  readonly content$: Observable<{
    isMobile: boolean;
    backgroundImage: string;
    logoUrl?: string;
  }>;

  get shouldDisplayLogoWithLink(): boolean {
    return !!(this.data.event_logo?.url && this.data.logo_link);
  }

  get shouldDisplayLogo(): boolean {
    return !!this.data.event_logo?.url;
  }

  constructor(@Inject(WINDOW) private readonly win: Window, private responsiveService: ResponsiveService) {
    this.content$ = this.responsiveService.isDesktopMatched().pipe(
      map(isDesktop => ({
        isMobile: !isDesktop,
        backgroundImage: `url("${
          isDesktop || !this.win ? this.data.event_banner.url : this.data.event_banner?.mobile?.url
        }")`,
        logoUrl: isDesktop || !this.win ? this.data.event_logo?.url : this.data.event_logo?.mobile?.url,
      })),
    );
  }
}

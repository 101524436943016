import { Component, Input } from '@angular/core';
import { TimelineSlice, TimelineAction } from '@repo/shared';
import { SliceWithData } from '../../../../../typings';
import { SLICE_TAGGING_NAME } from '../../../../common/constants/tagging';

@Component({
  selector: 'app-slice-timeline',
  template: `
    <h3 *ngIf="data?.title" class="title">{{ data.title }}</h3>
    <st-mobile-timeline-steps [steps]="data?.timelineSteps"></st-mobile-timeline-steps>
    <st-desktop-timeline-steps [steps]="data?.timelineSteps"></st-desktop-timeline-steps>
    <div *ngIf="action" class="action">
      <h3 *ngIf="action.title" class="action-title">{{ action.title }}</h3>
      <div *ngIf="action.description" [innerHtml]="action.description" class="action-description"></div>
      <cb-cta-btn-link
        *ngIf="action.button"
        [button]="action.button"
        class="action-button"
        [sliceType]="data.type"
        [analyticsLabel]="getAnalyticsLabel(action.button.label)"
      ></cb-cta-btn-link>
    </div>
  `,
  styleUrls: ['./slice-timeline.component.scss'],
})
export class SliceTimelineComponent implements SliceWithData<TimelineSlice> {
  @Input() data: TimelineSlice;

  getAnalyticsLabel(label: string): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${label}`;
  }

  get action(): TimelineAction | undefined {
    return this.data && this.data.action;
  }
}

import { Component, HostBinding, Input, Optional } from '@angular/core';
import { FormFieldControlDirective } from '../form-field-control/form-field-control.directive';

@Component({
  selector: 'form-field-error',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field-error',
    role: 'alert',
  },

  template: '{{message}}',
})
export class FormFieldErrorComponent {
  static ERROR_MESSAGE = 'Champ obligatoire';

  get isHidden(): boolean {
    if (typeof this._isHidden === 'boolean') {
      return this._isHidden;
    } else if (this._parentFormFieldControl && this._parentFormFieldControl.status === 'INVALID') {
      return false;
    } else {
      return true;
    }
  }

  @HostBinding('class.form-field-error--hidden')
  set isHidden(value: boolean) {
    this._isHidden = value;
  }

  get message(): string {
    if (this._message) {
      return this._message;
    } else if (this._parentFormFieldControl && this._parentFormFieldControl.errorMessage) {
      return this._parentFormFieldControl.errorMessage;
    } else {
      return FormFieldErrorComponent.ERROR_MESSAGE;
    }
  }

  @Input('message')
  set message(value: string) {
    if (value) {
      this._message = value;
    }
  }

  private _isHidden;
  private _message: string;

  constructor(@Optional() private readonly _parentFormFieldControl: FormFieldControlDirective) {}
}
